import {Button, CloseButton, Modal} from "@themesberg/react-bootstrap";
import React, {useRef, useState} from "react";
import {uploadCampaignSourceFile, useGetCampaignSources} from "../../../api/campaigns";
import * as Yup from "yup";
import {Formik, Form as FormikForm} from "formik";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {FeedbackAlert} from "../../utils/FeedbackAlert";
import {InputFileField} from "../../../commons/forms/InputFileField";
import {withMultiFetchSWR} from "../../../api/helpersSWR";

interface Props {
  isShown: boolean;
  campaignId: number;
  campaignName: string;
  onClosePopup: () => void;
}

const Schema = Yup.object({
  selectedMappingId: Yup.number().required().min(0, "Campo obbligatorio"),
  requestBody: Yup.object({
    file: Yup.string().required()
  })
});

export const ManualUploadCampaignSourcePopup = withMultiFetchSWR(
  ({campaignId}: Props) => [
    {
      useFetchFunc: () => useGetCampaignSources(campaignId),
      shouldFetch: true,
      elseData: null
    }
  ], ({props, endpointsResponse}) => {

    const {campaignId, campaignName, isShown, onClosePopup} = props;

    const [campaignSources] = endpointsResponse.data;

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    const uploadRef = useRef(null);

    return (
      <Modal centered show={isShown} size="lg">
        <Formik
          initialValues={{requestBody: {file: ''}, selectedMappingId: ''}}
          validationSchema={Schema}
          onSubmit={(values, {setSubmitting}) => {
            const formData = new FormData();
            formData.append('file', uploadRef.current.files[0]);
            uploadCampaignSourceFile(campaignId, values.selectedMappingId as unknown as number, formData)
              .then(r => setResponseStatus("SUCCESS"))
              .catch(e => setResponseStatus("FAIL"))
              .finally(() => setSubmitting(false));
          }}
        >
          {({isSubmitting, submitForm}) => (
            <>
              <Modal.Header
                style={{justifyContent: "space-between", alignItems: "center", paddingBottom: "0.7rem", paddingTop: "0.7rem"}}
              >
                <Modal.Title style={{fontSize: "20px"}}>Import manuale di sorgenti</Modal.Title>
                <CloseButton onClick={onClosePopup}/>
              </Modal.Header>
              <Modal.Body className="m-0 pt-2 px-3">
                <FormikForm>
                  <InputFileField
                    name="requestBody.file" accept=".csv, .xls, .xlsx" label="Sorgente da importare" withRef={uploadRef}
                  />
                  <small>
                    Seleziona il file da importare come sorgente della campagna
                    <em style={{fontWeight: 700}}> {campaignName}</em> (formati accettati: .csv, .xls, .xlsx).
                  </small>
                  <InputSelectField
                    className="mt-3" name="selectedMappingId" label="Template di mapping" optionValueType="number">
                    <>
                      <option key='blankChoice' hidden value={-1}>Seleziona mapping</option>
                      {campaignSources.data.sources.map(source => (
                        <option key={source.id} value={source.id}>{source.name}</option>
                      ))}
                    </>
                  </InputSelectField>
                  <small className="mb-3">
                    Seleziona il template di mapping per il sorgente.
                  </small>
                </FormikForm>
              </Modal.Body>
              <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Button type="button" variant="outline-primary" onClick={onClosePopup}>Annulla</Button>
                  <Button onClick={submitForm} disabled={isSubmitting}>Carica file</Button>
                </div>
                <FeedbackAlert
                  status={responseStatus}
                  successMessage="L'importazione del file è avvenuta con successo!"
                  failMessage="Ops! Qualcosa è andato storto, l'importazione non è riuscita."
                />
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    );
  });