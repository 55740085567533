import {useGetAllCustomersForAgency, useGetAllCustomersForLoggedAgency} from "../../../api/dsp/agencyCustomers";
import React from "react";
import {InternalUserOnly} from "../../../components/usersUtils/InternalUserOnly";
import {ExternalUserOnly} from "../../../components/usersUtils/ExternalUserOnly";
import {AgencyCustomerOptionsExternal, AgencyCustomerOptionsInternal} from "./AgencyCustomerOptions";

export const RenderAgencyCustomerName = (props: {agencyId: number, agencyCustomerId: number, onNameObtained?: (name: string) => void}) => {
  return <>
    <InternalUserOnly>
      <RenderAgencyCustomerNameInternal agencyId={props.agencyId} agencyCustomerId={props.agencyCustomerId} onNameObtained={props.onNameObtained} />
    </InternalUserOnly>
    <ExternalUserOnly>
      <RenderAgencyCustomerNameExternal agencyCustomerId={props.agencyCustomerId} onNameObtained={props.onNameObtained} />
    </ExternalUserOnly>
  </>;
};

export const RenderAgencyCustomerNameInternal = (props: {agencyId: number, agencyCustomerId: number, onNameObtained?: (name: string) => void}) => {
  const response = useGetAllCustomersForAgency(props.agencyId);
  const customers = response.data;
  const name = customers?.find(c => c.id === props.agencyCustomerId)?.name;
  if (!response.error) {
    if (name) {
      if(props.onNameObtained)
        props.onNameObtained(name);
      return <div>{name}</div>;
    } else {
      return <div> Loading... </div>;
    }
  } else {
    return <div/>;
  }
}

export const RenderAgencyCustomerNameExternal = (props: {agencyCustomerId: number, onNameObtained?: (name: string) => void}) => {
  const response = useGetAllCustomersForLoggedAgency();
  const customers = response.data;
  const name = customers?.find(c => c.id === props.agencyCustomerId)?.name;
  if (!response.error) {
    if (name) {
      if(props.onNameObtained)
        props.onNameObtained(name);
      return <div>{name}</div>;
    } else {
      return <div> Loading... </div>;
    }
  } else {
    return <div/>;
  }
}