import React, {useState} from 'react';
import {Button, ButtonGroup, Card, Dropdown} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH, faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {
  AgencyCustomer,
  deleteCustomerForLoggedAgency,
  useGetAllCustomersForLoggedAgency
} from "../api/dsp/agencyCustomers";
import {CreateOrEditAgencyCustomerPopup} from "./dsp/campaign/CreateOrEditAgencyCustomerPopup";
import {buildRegexMatchPattern, useMutateManyV2} from "../api/swr-fetcher";
import {withMultiFetchSWR} from "../api/helpersSWR";
import {SmartTable} from "../commons/table/SmartTable";
import {Routes} from "../routes";
import {useHistory} from "react-router-dom";
import {TopLevelDropdownMenu} from "../components/utils/TopLevelDropdownMenu";
import InComponentPreloader from "../components/utils/InComponentPreloader";

export const AgencyCustomerListPage = withMultiFetchSWR(
  (props: {}, urlParams: {}) => [{useFetchFunc: useGetAllCustomersForLoggedAgency}],
  ({props, endpointsResponse}) => {

    const {data: [customerList]} = endpointsResponse;
    const [agencyCustomerPopupMode, setAgencyCustomerPopupMode] = useState<"EDIT" | "CREATE" | "CLOSED">("CLOSED");

    const mutateMany = useMutateManyV2();
    const history = useHistory();

    return (
      <Card className="mb-4">
        <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h5 style={{display: "inline-block", margin: 0}}>Lista clienti</h5>
          <Button onClick={() => setAgencyCustomerPopupMode("CREATE")}>
            Crea cliente
          </Button>
          {agencyCustomerPopupMode === "CREATE" &&
            <CreateOrEditAgencyCustomerPopup
              isShown={true}
              onClosePopup={() => setAgencyCustomerPopupMode("CLOSED")}
              customer={null}
              onSuccessfulSubmit={() => history.push(Routes.AgencyCustomers.path)}
            />
          }
        </Card.Header>
        <Card.Body className="pt-3">
          <SmartTable<AgencyCustomer, never>
            pagination={true}
            tableKey="agency-customer-list-table"
            syncWithElementsProp={true}
            enableHiddenColumns={false}
            getElementKey={(customer) => customer.id}
            sort={{
              defaultSortingColIndex: 1,
              defaultArrowVisible: true
            }}
            elements={customerList ?? []}
            columns={[
              {
                title: "",
                cellRenderer: (customer) =>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                    </span>
                    </Dropdown.Toggle>
                    <TopLevelDropdownMenu align="left">
                      <Dropdown.Item
                        onClick={() => setAgencyCustomerPopupMode("EDIT")}>
                        <FontAwesomeIcon icon={faPencilAlt}/> Modifica
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => deleteCustomerForLoggedAgency(customer.id)
                          .then(() => mutateMany(buildRegexMatchPattern("dsp/agency-customers")))}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/> Elimina
                      </Dropdown.Item>
                      {agencyCustomerPopupMode === "EDIT" &&
                        <CreateOrEditAgencyCustomerPopup
                          isShown={true}
                          onClosePopup={() => setAgencyCustomerPopupMode("CLOSED")}
                          customer={customer}
                          onSuccessfulSubmit={() => history.push(Routes.AgencyCustomers.path)}
                        />
                      }
                    </TopLevelDropdownMenu>
                  </Dropdown>
              },
              {
                title: "Nome",
                cellRenderer: (customer) => customer.name,
                compareElementsByColumn: (customerA, customerB) =>
                  customerA.name.localeCompare(customerB.name)
              }
            ]}
          />
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);