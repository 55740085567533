import {DisplayType, VideoType} from "./priceLists";
import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "../common";
import {useGetPaginatedItems, useSWRNoStaleData} from "../helpersSWR";
import useSWR from "swr";
import {axiosFetcher} from "../swr-fetcher";
import axios, {AxiosResponse} from "axios";
import {WebCategory} from "./webCategories";
import {DspTarget} from "./targets";
import {WebList} from "./webLists";

export type AgeRangeType = "18-24" | "25-34" | "35-44" | "45-54" | "55-64" | "65+";

export type GenderType = "m" | "f" | "o";

type SnippetType = {
  snippet: string,
  adformat: number
}

export interface CampaignDspCommon {
  name: string,
  agency_customer_id: number | null,
  starts_at: string, //YYYY-MM-DD
  ends_at: string  //YYYY-MM-DD
  audience: {
    age_range: AgeRangeType[],
    gender: GenderType[],
    locality: string
  },
  custom_websites: number[] | null,
  budget: number,
  notes: string,
  snippets: SnippetType[],
  url: string,
  target_kpis: {
    [kpi_id: number]: number
  }
}

export interface CampaignDspDisplayType extends CampaignDspCommon {
  ad_type: "disp",
  type: DisplayType
}

export interface CampaignDspVideoType extends CampaignDspCommon {
  ad_type: "vid",
  type: VideoType
}

export type CampaignDspBase = CampaignDspDisplayType | CampaignDspVideoType;

export enum DSPStatus {
  New=0,
  Takeover=1,
  Declined=2,
  WiP=3,
  Started=4
}

export function getNameForDspStatus(status: DSPStatus) {
  switch (status) {
    case DSPStatus.New:
      return "NUOVA RICHIESTA";
    case DSPStatus.Takeover:
      return "PRESA IN CARICO";
    case DSPStatus.Declined:
      return "RIFIUTATA";
    case DSPStatus.WiP:
      return "IN ELABORAZIONE";
    case DSPStatus.Started:
      return "CAMPAGNA CREATA"
  }
}
export type CampaignDspRequest = CampaignDspBase & {objectives: number[], custom_categories: number[] | null, weblist_id: number | null}
export type CampaignDspRequestWithId = EntityWithId<CampaignDspRequest>;
export type CampaignDsp = CampaignDspRequestWithId & {
  agency_id: number,
  agency_customer_id: number,
  status: DSPStatus,
  objectives: DspTarget[] | null
  categories: WebCategory[] | null,
  weblist: WebList | null,
  uploaded_file: string
}
export type CampaignDspListPage = EndpointWrapperPaged<{ campaigns: CampaignDsp[] }>

export function useGetAllCampaignDsp() {
  return useGetPaginatedItems(
    "dsp/campaigns",
    (p: CampaignDspListPage) => p.data.campaigns
  )
}

export function useGetAllCampaignDspNoStaleData() {
  return useGetPaginatedItems(
    "dsp/campaigns",
    (p: CampaignDspListPage) => p.data.campaigns,
    {},
    true
  );
}

export type CampaignDspResponse = EndpointWrapper<{ campaign: CampaignDsp }>;

export function useGetCampaignDsp(campaignDspId: number) {
  return useSWR<CampaignDspResponse>(`/dsp/campaigns/${campaignDspId}`, axiosFetcher);
}

export function useGetCampaignDspNoStaleData(campaignDspId: number) {
  return useSWRNoStaleData<CampaignDspResponse>(`/dsp/campaigns/${campaignDspId}`, axiosFetcher) as ReturnType<typeof useSWR<CampaignDspResponse>>;
}

export const createCampaignDsp = (data: CampaignDspRequest) => {
  return axios.post('/dsp/campaigns', data) as Promise<AxiosResponse<CampaignDspResponse>>;
};

export const uploadZipFileToCampaignDsp = (campaignDspId: number, zipFile: File) => {
  const formData = new FormData();
  formData.append('file', zipFile);
  return axios.post(`/dsp/campaigns/${campaignDspId}/upload`, formData) as Promise<AxiosResponse<any>>;
}

type ZipFileResponse = EndpointWrapper<{url: string}>

export const useGetDownloadLinkInfoForCampaignDspZipFile = (campaignDspId: number | null) => {
  return useSWR<ZipFileResponse>(campaignDspId !== null ? `/dsp/campaigns/${campaignDspId}/asset` : null, axiosFetcher);
}

export const updateCampaignDsp = (campaignDspId: number, data: CampaignDspRequest) => {
  return axios.put(`/dsp/campaigns/${campaignDspId}`, data) as Promise<AxiosResponse<CampaignDspResponse>>;
}

export const deleteCampaignDsp = (campaignDspId: number) => {
  return axios.delete(`/dsp/campaigns/${campaignDspId}`);
};

interface DspPricingCalculateCommon {
  weblist_id: number | null,
  custom_categories: boolean,
  has_custom_audience: boolean,
  has_custom_websites: boolean,
  agency_customer_id: number | null
}

export interface DspPricingCalculateDisplay extends DspPricingCalculateCommon {
  format: "disp",
  type: DisplayType
}

export interface DspPricingCalculateVideo extends DspPricingCalculateCommon {
  format: "vid",
  type: VideoType
}

export type DspPricingCalculate = DspPricingCalculateDisplay | DspPricingCalculateVideo;

export function calculateDspPricing(data: DspPricingCalculate) {
  return axios.post('/dsp/pricings/calculate', data) as Promise<AxiosResponse<EndpointWrapper<{ price: number}>>>;
}

export const updateDspStatus = (campaignDspId: number, data: {status: DSPStatus}) => {
  return axios.post(`/dsp/campaigns/${campaignDspId}/status`, data) as Promise<AxiosResponse<CampaignDspResponse>>;
};
