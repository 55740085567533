import React from 'react';
import {Button, ButtonGroup, Card, Dropdown} from "@themesberg/react-bootstrap";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faEllipsisH, faPencilAlt, faTimesCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useSWRConfig} from "swr";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {Routes} from "../../../routes";
import {SmartTable} from "../../../commons/table/SmartTable";
import {
  deleteDspFormat,
  DspFormat,
  useGetAllDspFormats,
} from "../../../api/dsp/formats";
import {buildRegexMatchPattern, useMutateMany, useMutateManyV2} from "../../../api/swr-fetcher";
import {TopLevelDropdownMenu} from "../../../components/utils/TopLevelDropdownMenu";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

export const DspFormatListPage = withMultiFetchSWR(
  (props: {}, urlParams: {}) => [{useFetchFunc: () => useGetAllDspFormats()}],
  ({props, endpointsResponse}) => {

    const {data: [formatList]} = endpointsResponse;

    const mutateMany = useMutateMany();
    const {cache} = useSWRConfig();
    const history = useHistory();

    return (
      <Card className="mb-4">
        <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h5 style={{display: "inline-block", margin: 0}}>Lista formati</h5>
          <Button onClick={() => history.push(Routes.Dsp.Format.Create.path)}>
            Crea formato
          </Button>
        </Card.Header>
        <Card.Body className="pt-3">
          <SmartTable<DspFormat, never>
            pagination={true}
            tableKey="dsp-format-list-table"
            syncWithElementsProp={true}
            enableHiddenColumns={false}
            getElementKey={(format) => format.id}
            sort={{
              defaultSortingColIndex: 1,
              defaultArrowVisible: true
            }}
            elements={formatList ?? []}
            columns={[
              {
                title: "",
                cellRenderer: (format) =>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                    </span>
                    </Dropdown.Toggle>
                    <TopLevelDropdownMenu align="left">
                      <Dropdown.Item
                        onClick={() => history.push(Routes.Dsp.Format.Edit.buildPath(format.id))}>
                        <FontAwesomeIcon icon={faPencilAlt}/> Modifica
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => deleteDspFormat(format.id).then(() => mutateMany(cache, buildRegexMatchPattern("dsp/ad-formats")))}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/> Elimina
                      </Dropdown.Item>
                    </TopLevelDropdownMenu>
                  </Dropdown>
              },
              {
                title: "Nome",
                cellRenderer: (format) => format.name,
                compareElementsByColumn: (formatA, formatB) =>
                  formatA.name.localeCompare(formatB.name)
              },
              {
                title: "Base",
                cellRenderer: (format) => `${format.width}px`,
                compareElementsByColumn: (formatA, formatB) => formatA.width - formatB.width
              },
              {
                title: "Altezza",
                cellRenderer: (format) => `${format.height}px`,
                compareElementsByColumn: (formatA, formatB) => formatA.height - formatB.height
              },
              {
                title: "Attivo",
                cellRenderer: (format) => format.active ?
                  <FontAwesomeIcon icon={faCheckCircle} color={"green"} style={{fontSize: "17px"}}/> :
                  <FontAwesomeIcon icon={faTimesCircle} style={{fontSize: "17px"}}/>,
                compareElementsByColumn: (formatA, formatB) =>
                    (formatA.active === formatB.active) ? 0 : (formatA.active ? -1 : 1)
              }
            ]}
          />
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);