import {
  Chart as ChartJS,
  LineController,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  DoughnutController
} from "chart.js";
import 'chartjs-adapter-date-fns';

ChartJS.register(LinearScale, TimeScale, LineController, Title, PointElement, LineElement, Tooltip, Legend, ArcElement, DoughnutController);