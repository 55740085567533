import React, {useState} from 'react';
import * as Yup from 'yup';
import {Button, Card, Nav, Tab} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik} from "formik";
import {FeedbackAlert} from "../components/utils/FeedbackAlert";
import {InputField} from "../commons/forms/InputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faChartPie} from "@fortawesome/free-solid-svg-icons";
import styles from "./ReportTemplateFormPage.module.scss";
import {SmallButton} from "../components/utils/buttons/SmallButton";
import {withMultiFetchSWR} from "../api/helpersSWR";
import {
  createReportTemplate,
  DefaultTab,
  ReportTemplate,
  SubdomainTab,
  updateReportTemplate,
  useGetReportTemplateNoStaleData
} from "../api/reportTemplates";
import {Redirect, useHistory} from "react-router-dom";
import {Routes} from "../routes";
import {KPI, useGetKpis} from "../api/kpis";
import {InputCheckField} from "../commons/forms/InputCheckField";
import {ItemsSelectionPopup} from "../components/utils/ItemsSelectionPopup";
import InComponentPreloader from "../components/utils/InComponentPreloader";

const Schema = Yup.object({
  name: Yup.string().required(),
  tabs: Yup.array().of(Yup.object(
    {
      view_type: Yup.string().required().oneOf(["default", "subdomain"]),
      table: Yup.object({
        kpis: Yup.array().of(Yup.number()).required()
          .min(1, "È necessario selezionare almeno 1 KPI.")
      }).required(),
      charts: Yup.array().of(Yup.object(
        {
          chart_type: Yup.string().nullable().oneOf(["line", "pie", null]),
          kpi_id: Yup.number().typeError("È necessario selezionare almeno 1 KPI.")
            .nullable()
            .when('chart_type', {
            is: 'pie',
            then: (schema) =>
              schema.required("È necessario selezionare almeno 1 KPI."),
            otherwise: schema => schema
          }),
          kpis: Yup.array().of(Yup.number()).when('chart_type', {
            is: 'line',
            then: (schema) => schema.required()
              .min(1, "È necessario selezionare almeno 1 KPI.")
              .max(3),
            otherwise: schema => schema
          })
        }
      )).min(0).max(1)
    }
  )).min(1, "Attiva almeno una tab tra quella per serie temporali e quella per domini.").max(2).required()
});

interface Props {
}

interface UrlParams {
  templateId: string
}

export const ReportTemplateFormPage = withMultiFetchSWR(
  (props: Props, {templateId}: UrlParams) => [
    {
      useFetchFunc: () => useGetReportTemplateNoStaleData(templateId),
      shouldFetch: templateId != null,
      elseData: {
        name: "",
        tabs: []
      }
    },
    {
      useFetchFunc: useGetKpis
    }
  ], ({props, endpointsResponse, urlParams}) => {

    const {templateId} = urlParams;
    const isNewTemplate = templateId == undefined;
    const {data: [template, kpis]} = endpointsResponse;

    const history = useHistory();

    const [shownKpiSelectionPopup, setShownKpiSelectionPopup] =
      useState<"NONE" | "DEFAULT_TABLE" | "DEFAULT_GRAPH" | "SUBDOMAIN_TABLE" | "SUBDOMAIN_GRAPH">("NONE");
    const [shouldRedirectToReportTemplateListPage, setShouldRedirectToReportTemplateListPage] = useState(false);
    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    if (shouldRedirectToReportTemplateListPage) {
      return <Redirect to={Routes.CampaignsReportTemplates.ViewAll.path}/>
    }

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header>
          <h5
            className="mb-0">{isNewTemplate ? "Crea template di report" : `Modifica template di report ${template.name}`}</h5>
        </Card.Header>
        <Card.Body>
          <Formik<ReportTemplate>
            initialValues={template}
            validationSchema={Schema}
            onSubmit={(values, {setSubmitting}) => {
              const newValues = {...values};
              newValues.tabs = newValues.tabs.map(t => ({...t, name: t.view_type === "default" ? "ST" : "SD"}))
              let promise;
              if (isNewTemplate)
                promise = createReportTemplate(newValues);
              else
                promise = updateReportTemplate(newValues);
              promise
                .then(r => history.push(Routes.CampaignsReportTemplates.ViewAll.path))
                .catch(e => setResponseStatus("FAIL"));
            }}
          >
            {({isSubmitting, dirty, values, setFieldValue, errors, touched, validateForm, setFieldTouched}) => (
              <FormikForm style={{display: "flex", flexDirection: "column", gap: "45px"}}>
                <InputField name="name" type="text" label="Nome del template di report" placeholder="Inserisci nome"/>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Tab.Container defaultActiveKey="default-tab">
                    <Nav fill className={`mx-0 nav-tabs ${styles.tabContainer}`}>
                      <Nav.Item className="mb-0">
                        <Nav.Link eventKey="default-tab">
                          <FontAwesomeIcon icon={faChartLine} className="me-2"/> Serie temporale
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="mb-0">
                        <Nav.Link eventKey="subdomain-tab">
                          <FontAwesomeIcon icon={faChartPie} className="me-2"/> Serie per domini
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className={styles.tabContent}>
                      <Tab.Pane eventKey="default-tab">
                        <div className={`py-2 ${styles.tabPaneContent}`}>
                          <InputCheckField
                            type="switch"
                            name="tabs"
                            getIsChecked={(tabs: ReportTemplate["tabs"]) => tabs.find(tab => tab.view_type === "default") !== undefined}
                            getValueToSetOnChange={(checked, currentTabs) => {
                              let newTabs;
                              if (!checked) {
                                newTabs = currentTabs.filter(tab => tab.view_type !== "default");
                              } else {
                                newTabs = [...currentTabs, getDefaultTabDefaultConfig()];
                              }
                              return newTabs;
                            }}
                            label="ATTIVA SERIE TEMPORALE"
                          />
                          {getDefaultTabIndex(values) >= 0 &&
                            <>
                              <div className={styles.tabSection}>
                                <div className={styles.tabSectionHeader}>
                                  <h6 className="m-0 p-0">Lista KPI (tabella)</h6>
                                  <SmallButton
                                    onClick={() => setShownKpiSelectionPopup("DEFAULT_TABLE")}
                                    style={{justifySelf: "start"}}
                                  >
                                    Modifica
                                  </SmallButton>
                                  { //@ts-ignore
                                    errors?.tabs?.[getDefaultTabIndex(values)]?.table?.kpis !== undefined && touched?.tabs?.[getDefaultTabIndex(values)]?.table?.kpis !== undefined &&
                                    <div style={{color: "red", fontSize: "12px"}}>
                                      { //@ts-ignore
                                        errors?.tabs?.[getDefaultTabIndex(values)]?.table?.kpis
                                      }
                                    </div>
                                  }
                                </div>
                                <div>
                                  {values.tabs[getDefaultTabIndex(values)].table.kpis.length > 0 ?
                                    kpis.data.kpis
                                      .filter(kpi => values.tabs[getDefaultTabIndex(values)].table.kpis.includes(kpi.id))
                                      .map(kpi => kpi.name)
                                      .join(", ")
                                    : "Nessun KPI selezionato."
                                  }
                                </div>
                              </div>
                              <div className={styles.tabSection}>
                                <InputCheckField
                                  name={`tabs[${getDefaultTabIndex(values)}].charts[0]`}
                                  getIsChecked={value => (value as DefaultTab["charts"][number]).chart_type === "line"}
                                  label="Attiva grafico a linee"
                                  type="switch"
                                  getValueToSetOnChange={(isNowChecked) => {
                                    let chart: DefaultTab["charts"][number];
                                    if (isNowChecked)
                                      chart = {
                                        chart_type: "line",
                                        kpis: []
                                      }
                                    else
                                      chart = {
                                        chart_type: null,
                                        kpis: []
                                      }
                                    setTimeout(() => validateForm(), 10);
                                    setFieldTouched(`tabs[${getDefaultTabIndex(values)}].charts[0].kpis`, false);
                                    return chart;
                                  }}
                                />
                                {(values.tabs[getDefaultTabIndex(values)] as DefaultTab).charts[0].chart_type &&
                                  <>
                                    <div className={styles.tabSectionHeader}>
                                      <h6 className="m-0 p-0">Lista KPI (grafico)</h6>
                                      <SmallButton
                                        onClick={() => setShownKpiSelectionPopup("DEFAULT_GRAPH")}
                                        style={{justifySelf: "start"}}
                                        disabled={values.tabs[getDefaultTabIndex(values)].table.kpis.length === 0}
                                      >
                                        Modifica
                                      </SmallButton>
                                      { //@ts-ignore
                                        errors?.tabs?.[getDefaultTabIndex(values)]?.charts?.[0]?.kpis !== undefined && touched?.tabs?.[getDefaultTabIndex(values)]?.charts?.[0]?.kpis !== undefined &&
                                        <div style={{color: "red", fontSize: "12px"}}>
                                          { //@ts-ignore
                                            errors?.tabs?.[getDefaultTabIndex(values)]?.charts?.[0]?.kpis
                                          }
                                        </div>
                                      }
                                    </div>
                                    <div>
                                      {(values.tabs[getDefaultTabIndex(values)] as DefaultTab).charts[0].kpis.length > 0 ?
                                        kpis.data.kpis
                                          .filter(kpi => (values.tabs[getDefaultTabIndex(values)] as DefaultTab).charts[0].kpis.includes(kpi.id))
                                          .map(kpi => kpi.name)
                                          .join(", ")
                                        : "Nessun KPI selezionato."
                                      }
                                    </div>
                                  </>
                                }
                              </div>
                            </>
                          }
                          {shownKpiSelectionPopup === "DEFAULT_TABLE" &&
                            <ItemsSelectionPopup<KPI>
                              popupKey={"default-table-kpi-sel-popup"}
                              getItemView={(kpi) => <div>{kpi.name}</div>}
                              onItemsSelected={(selectedKpis) => {
                                setFieldValue(
                                  `tabs[${getDefaultTabIndex(values)}].table.kpis`,
                                  selectedKpis.map(kpi => kpi.id)
                                );
                                setFieldValue(
                                  `tabs[${getDefaultTabIndex(values)}].charts[0].kpis`,
                                  (values.tabs[getDefaultTabIndex(values)] as DefaultTab).charts[0].kpis
                                    .filter(kpiId => selectedKpis.map(kpi => kpi.id).includes(kpiId))
                                );
                                setTimeout(() => validateForm(), 10);
                                setFieldTouched(`tabs[${getDefaultTabIndex(values)}].table.kpis`);
                                setShownKpiSelectionPopup("NONE");
                              }}
                              onClosePopup={() => setShownKpiSelectionPopup("NONE")}
                              isShown={true}
                              itemList={kpis.data.kpis}
                              title="KPI per la serie temporale"
                              subtitle="Seleziona i KPI per la tabella:"
                              alreadyAssociatedItemList={
                                kpis.data.kpis
                                  .filter(kpi => values.tabs[getDefaultTabIndex(values)].table.kpis.includes(kpi.id))
                              }
                            />
                          }
                          {shownKpiSelectionPopup === "DEFAULT_GRAPH" &&
                            <ItemsSelectionPopup<KPI>
                              popupKey={"default-graph-kpi-sel-popup"}
                              getItemView={(kpi) => <div>{kpi.name}</div>}
                              onItemsSelected={(selectedKpis) => {
                                setFieldValue(
                                  `tabs[${getDefaultTabIndex(values)}].charts[0].kpis`,
                                  selectedKpis.map(kpi => kpi.id)
                                );
                                setTimeout(() => validateForm(), 10);
                                setFieldTouched(`tabs[${getDefaultTabIndex(values)}].charts[0].kpis`);
                                setShownKpiSelectionPopup("NONE");
                              }}
                              onClosePopup={() => setShownKpiSelectionPopup("NONE")}
                              isShown={true}
                              itemList={kpis.data.kpis
                                .filter(kpi => values.tabs.find(t => t.view_type === "default").table.kpis.includes(kpi.id))
                              }
                              title="KPI per la serie temporale"
                              subtitle="Seleziona al massimo 3 KPI che saranno visualizzati di default nel grafico a linee:"
                              selectableItemsMaxNumber={3}
                              alreadyAssociatedItemList={
                                kpis.data.kpis
                                  .filter(kpi => (values.tabs[getDefaultTabIndex(values)] as DefaultTab).charts[0].kpis.includes(kpi.id))
                              }
                            />
                          }
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="subdomain-tab">
                        <div className={`py-2 ${styles.tabPaneContent}`}>
                          <InputCheckField
                            type="switch"
                            name="tabs"
                            getIsChecked={(tabs: ReportTemplate["tabs"]) => tabs.find(tab => tab.view_type === "subdomain") !== undefined}
                            getValueToSetOnChange={(checked, currentTabs) => {
                              let newTabs;
                              if (!checked) {
                                newTabs = currentTabs.filter(tab => tab.view_type !== "subdomain");
                              } else {
                                newTabs = [...currentTabs, getSubdomainTabDefaultConfig()];
                              }
                              return newTabs;
                            }}
                            label="ATTIVA SERIE PER DOMINI"
                          />
                          {getSubdomainTabIndex(values) >= 0 &&
                            <>
                              <div className={styles.tabSection}>
                                <div className={styles.tabSectionHeader}>
                                  <h6 className="m-0 p-0">Lista KPI (tabella)</h6>
                                  <SmallButton
                                    onClick={() => setShownKpiSelectionPopup("SUBDOMAIN_TABLE")}
                                    style={{justifySelf: "start"}}
                                  >
                                    Modifica
                                  </SmallButton>
                                  { //@ts-ignore
                                    errors?.tabs?.[getSubdomainTabIndex(values)]?.table?.kpis !== undefined && touched?.tabs?.[getSubdomainTabIndex(values)]?.table?.kpis !== undefined &&
                                    <div style={{color: "red", fontSize: "12px"}}>
                                      { //@ts-ignore
                                        errors?.tabs?.[getSubdomainTabIndex(values)]?.table?.kpis
                                      }
                                    </div>
                                  }
                                </div>
                                <div>
                                  {values.tabs[getSubdomainTabIndex(values)].table.kpis.length > 0 ?
                                    kpis.data.kpis
                                      .filter(kpi => values.tabs[getSubdomainTabIndex(values)].table.kpis.includes(kpi.id))
                                      .map(kpi => kpi.name)
                                      .join(", ")
                                    : "Nessun KPI selezionato."
                                  }
                                </div>
                              </div>
                              <div className={styles.tabSection}>
                                <InputCheckField
                                  name={`tabs[${getSubdomainTabIndex(values)}].charts[0]`}
                                  getIsChecked={value => (value as SubdomainTab["charts"][number]).chart_type === "pie"}
                                  label="Attiva grafico a torta"
                                  type="switch"
                                  getValueToSetOnChange={(isNowChecked) => {
                                    let chart: SubdomainTab["charts"][number];
                                    if (isNowChecked)
                                      chart = {
                                        chart_type: "pie",
                                        kpi_id: null
                                      }
                                    else
                                      chart = {
                                        chart_type: null,
                                        kpi_id: null
                                      }
                                    setTimeout(() => validateForm(), 10);
                                    setFieldTouched(`tabs[${getSubdomainTabIndex(values)}].charts[0].kpi_id`, false);
                                    return chart;
                                  }}
                                />
                                {(values.tabs[getSubdomainTabIndex(values)] as SubdomainTab).charts[0].chart_type &&
                                  <>
                                    <div className={styles.tabSectionHeader}>
                                      <h6 className="m-0 p-0">Lista KPI (grafico)</h6>
                                      <SmallButton
                                        onClick={() => setShownKpiSelectionPopup("SUBDOMAIN_GRAPH")}
                                        style={{justifySelf: "start"}}
                                        disabled={values.tabs[getSubdomainTabIndex(values)].table.kpis.length === 0}
                                      >
                                        Modifica
                                      </SmallButton>
                                      { //@ts-ignore
                                        errors?.tabs?.[getSubdomainTabIndex(values)]?.charts?.[0]?.kpi_id !== undefined && touched?.tabs?.[getSubdomainTabIndex(values)]?.charts?.[0]?.kpi_id !== undefined &&
                                        <div style={{color: "red", fontSize: "12px"}}>
                                          { //@ts-ignore
                                            errors?.tabs?.[getSubdomainTabIndex(values)]?.charts?.[0]?.kpi_id
                                          }
                                        </div>
                                      }
                                    </div>
                                    <div>
                                      {(values.tabs[getSubdomainTabIndex(values)] as SubdomainTab).charts[0].kpi_id !== null ?
                                        kpis.data.kpis
                                          .filter(kpi => (values.tabs[getSubdomainTabIndex(values)] as SubdomainTab).charts[0].kpi_id === kpi.id)
                                          .map(kpi => kpi.name)
                                          .join(", ")
                                        : "Nessun KPI selezionato."
                                      }
                                    </div>
                                  </>
                                }
                              </div>
                            </>
                          }
                          {shownKpiSelectionPopup === "SUBDOMAIN_TABLE" &&
                            <ItemsSelectionPopup<KPI>
                              popupKey={"subdomain-table-kpi-sel-popup"}
                              getItemView={(kpi) => <div>{kpi.name}</div>}
                              onItemsSelected={(selectedKpis) => {
                                setFieldValue(
                                  `tabs[${getSubdomainTabIndex(values)}].table.kpis`,
                                  selectedKpis.map(kpi => kpi.id)
                                );
                                if (
                                  !selectedKpis.map(kpi => kpi.id).includes(
                                    (values.tabs[getSubdomainTabIndex(values)] as SubdomainTab).charts[0].kpi_id)
                                ) {
                                  setFieldValue(`tabs[${getSubdomainTabIndex(values)}].charts[0].kpi_id`, null);
                                }
                                setTimeout(() => validateForm(), 10);
                                setFieldTouched(`tabs[${getSubdomainTabIndex(values)}].table.kpis`);
                                setShownKpiSelectionPopup("NONE");
                              }}
                              onClosePopup={() => setShownKpiSelectionPopup("NONE")}
                              isShown={true}
                              itemList={kpis.data.kpis}
                              title="KPI per la serie sui domini"
                              subtitle="Seleziona i KPI per la tabella:"
                              alreadyAssociatedItemList={
                                kpis.data.kpis
                                  .filter(kpi => values.tabs[getSubdomainTabIndex(values)].table.kpis.includes(kpi.id))
                              }
                            />
                          }
                          {shownKpiSelectionPopup === "SUBDOMAIN_GRAPH" &&
                            <ItemsSelectionPopup<KPI>
                              popupKey={"subdomain-graph-kpi-sel-popup"}
                              getItemView={(kpi) => <div>{kpi.name}</div>}
                              onItemsSelected={(selectedKpis) => {

                                setFieldValue(
                                  `tabs[${getSubdomainTabIndex(values)}].charts[0].kpi_id`,
                                  selectedKpis[0]?.id ?? null
                                );
                                setTimeout(() => validateForm(), 10);
                                setFieldTouched(`tabs[${getSubdomainTabIndex(values)}].charts[0].kpi_id`);
                                setShownKpiSelectionPopup("NONE");
                              }}
                              onClosePopup={() => setShownKpiSelectionPopup("NONE")}
                              isShown={true}
                              itemList={kpis.data.kpis
                                .filter(kpi => values.tabs[getSubdomainTabIndex(values)].table.kpis.includes(kpi.id))
                              }
                              title="KPI per la serie sui domini"
                              subtitle="Seleziona al massimo 1 KPI che sarà visualizzato di default nel grafico a linee:"
                              isMultiSelect={false}
                              alreadyAssociatedItemList={
                                [kpis.data.kpis.find(kpi =>
                                  (values.tabs[getSubdomainTabIndex(values)] as SubdomainTab).charts[0].kpi_id === kpi.id
                                )]
                              }
                            />
                          }
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                  { //@ts-ignore
                    typeof errors?.tabs === "string" && touched?.tabs !== undefined &&
                    <div style={{color: "red", fontSize: "14px", marginTop: "5px"}}>
                      { //@ts-ignore
                        errors?.tabs
                      }
                    </div>
                  }
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Button type="button" variant="outline-primary" onClick={() => {
                    setShouldRedirectToReportTemplateListPage(true)
                  }}>
                    Annulla
                  </Button>
                  <Button type="submit" variant="primary" disabled={!dirty || isSubmitting}>
                    {isNewTemplate ? "Crea template" : "Conferma modifiche"}
                  </Button>
                </div>
                <FeedbackAlert status={responseStatus}/>
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);

function getDefaultTabIndex(values: ReportTemplate): number {
  return values.tabs.indexOf(values.tabs.find(t => t.view_type === "default"));
}

function getSubdomainTabIndex(values: ReportTemplate): number {
  return values.tabs.indexOf(values.tabs.find(t => t.view_type === "subdomain"));
}

function getDefaultTabDefaultConfig(): ReportTemplate["tabs"][number] {
  return {
    name: "ST",
    table: {
      name: "Tabella serie temporali",
      kpis: []
    },
    charts: [{
      kpis: [],
      chart_type: "line"
    }],
    view_type: "default"
  }
}

function getSubdomainTabDefaultConfig(): ReportTemplate["tabs"][number] {
  return {
    name: "SD",
    table: {
      name: "Tabella serie domini",
      kpis: []
    },
    charts: [{
      kpi_id: null,
      chart_type: "pie"
    }],
    view_type: "subdomain"
  }
}
