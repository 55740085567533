import {Attribute} from "../../api/attributes";
import {ExcludedRow, Level1OutputRow} from "../../api/DomainAnalysis";
import {KPI} from "../../api/kpis";
import React from "react";
import {roundNumberToTwoDecimals} from "../../components/Campaigns/CampaignDataPanel";

export function getOutputAttributes(attributeList: Attribute[], rows: (Level1OutputRow | ExcludedRow)[]) {
  return attributeList
    .filter(a => rows[0].combination.map(attr => attr.attribute_id).includes(a.id));
}

export function getOutputKpis(kpiList: KPI[], rows: Level1OutputRow[]) {
  return kpiList
    .filter(k => rows[0].values.map(kpi => kpi.kpi_id).includes(k.id));
}

export function getCommonColumnsInOutputTable(outputAttributes: Attribute[], outputKpis: KPI[]) {
  return [
    {
      title: "Dominio",
      cellRenderer: (row) => row.domain,
      compareElementsByColumn:
        (rowA, rowB) => rowA.domain.localeCompare(rowB.domain)
    },
    ...outputAttributes.map(attribute => ({
      title: attribute.name,
      cellRenderer: (row: Level1OutputRow) => row.combination.find(a => a.attribute_id === attribute.id).value,
      compareElementsByColumn:
        (rowA: Level1OutputRow, rowB: Level1OutputRow) => rowA.combination.find(a => a.attribute_id === attribute.id).value
          .localeCompare(rowB.combination.find(a => a.attribute_id === attribute.id).value)
    })),
    ...outputKpis.map(kpi => ({
      title: kpi.name,
      cellRenderer: (row: Level1OutputRow) =>
        <div>
          {kpi.is_percentage ?
            `${roundNumberToTwoDecimals(row.values.find(v => v.kpi_id === kpi.id).kpi_value * 100, true)}%` :
            roundNumberToTwoDecimals(row.values.find(v => v.kpi_id === kpi.id).kpi_value, false)
          }
        </div>,
      compareElementsByColumn:
        (rowA: Level1OutputRow, rowB: Level1OutputRow) => rowA.values.find(v => v.kpi_id === kpi.id).kpi_value
          - (rowB.values.find(v => v.kpi_id === kpi.id).kpi_value)
    }))
  ];
}