import {Form} from "@themesberg/react-bootstrap";
import {useField} from "formik";
import * as React from "react";
import styles from "./MultipleCheckField.module.scss"

interface Props<I extends number | string | any> {
  name: string;
  label?: string;
  items: Array<{
    label: React.ReactNode,
    id: I
  }>;
  isMultiSelect?: boolean;
  formCheckClassName?: string;
  formCheckStyle?: any;
  numOfColumns?: number;
  disabled?: boolean;
  containerStyle?: any;
  showOptionalFieldLabel?: boolean;
}

export function MultipleCheckField<I>(props: Props<I>) {
  const [field, meta, helpers] = useField(props);

  const {
    label,
    items,
    isMultiSelect = true,
    formCheckClassName,
    formCheckStyle,
    numOfColumns = 4,
    containerStyle = {},
    showOptionalFieldLabel = false,
    disabled = false,
    ...otherProps
  } = props;

  function onChange(itemId: I, checked: boolean) {
    if (isMultiSelect)
      onChangeListMode(itemId, checked);
    else
      onChangeSingleItemMode(itemId, checked);
  }

  function onChangeSingleItemMode(itemId: I, checked: boolean) {
    helpers.setValue(itemId)
  }

  function onChangeListMode(itemId: I, checked: boolean) {
    if (checked && !field.value.includes(itemId))
      helpers.setValue([...field.value, itemId])
    else if (!checked && field.value.includes(itemId)) {
      helpers.setValue(field.value.filter(i => i != itemId))
    }
  }

  return (
    <Form.Group id={field.name} className={styles.container} style={containerStyle}>
      {label &&
        <Form.Label className={`${(showOptionalFieldLabel && !disabled) ? styles.fieldLabel : "mb-0"} label`}>
          {label}
        </Form.Label>
      }
      <div className={styles.checksGrid} style={{"--grid-cols": numOfColumns} as React.CSSProperties}>
        {items.map((item) => (
          <div style={{display: "flex"}}>
            <Form.Check
              inline
              checked={isMultiSelect ? field.value.includes(item.id) : field.value === item.id}
              onChange={e => onChange(item.id, e.target.checked)}
              disabled={disabled}
              onBlur={field.onBlur}
              className={formCheckClassName}
              style={{...formCheckStyle, marginRight: "8px"}}
              type={isMultiSelect ? "checkbox" : "radio"}
              {...otherProps}
            />
            <label style={{fontWeight: 500}}>
              {item.label}
            </label>
          </div>
        ))}
      </div>
      {meta.error && meta.touched &&
        <Form.Control.Feedback style={{display: "block"}} className="error"
                               type="invalid">{meta.error}</Form.Control.Feedback>
      }
    </Form.Group>
  );

}