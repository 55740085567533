import {useField} from "formik";
import * as React from "react";
import {useState} from "react";
import {DateRangePicker} from "react-dates";
import "react-dates/initialize";
import "./InputDateField.scss";

interface Props {
  startDateName: string;
  endDateName: string;
  isDayBlocked: (day) => boolean;
  startDatePlaceholderText?: string;
  endDatePlaceholderText?: string;
  displayFormat?: string;
  isOutsideRange?: () => boolean;
  disabled?: boolean;
}

export const InputDateRangeField = (props: Props) => {
  const {
    startDateName,
    endDateName,
    startDatePlaceholderText,
    endDatePlaceholderText,
    isDayBlocked,
    displayFormat,
    isOutsideRange,
    disabled = false
  } = props;

  const [fieldStartDate, metaStartDate, helpersStartDate] = useField(startDateName);
  const [fieldEndDate, metaEndDate, helpersEndDate] = useField(endDateName);

  const [focusedInput, setFocusedInput] = useState<null | "startDate" | "endDate">(null);

  return (
    <div className={`${metaEndDate.touched && metaEndDate.error ? "error" : ""} inputDateRangeFieldContainer`}>
      <DateRangePicker
        small={true}
        startDate={fieldStartDate.value}
        startDateId={startDateName}
        endDate={fieldEndDate.value}
        endDateId={endDateName}
        onDatesChange={({startDate, endDate}) => {
          helpersStartDate.setValue(startDate);
          helpersEndDate.setValue(endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={(newFocusedInput) => {
          setFocusedInput(newFocusedInput);
          if(newFocusedInput === null) {
            helpersStartDate.setTouched(true);
            helpersEndDate.setTouched(true);
          }
        }}
        isDayBlocked={isDayBlocked}
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        displayFormat={displayFormat}
        isOutsideRange={isOutsideRange}
        disabled={disabled}
      />
      {(metaEndDate.touched && metaEndDate.error) &&
        <div className="errorMsg">{metaEndDate.error}</div>
      }
    </div>
  );

};

InputDateRangeField.defaultProps = {
  startDatePlaceholderText: "Data inizio",
  endDatePlaceholderText: "Data fine",
  displayFormat: "DD/MM/YYYY"
}