import React from 'react';
import {Button, ButtonGroup, Card, Dropdown} from "@themesberg/react-bootstrap";
import {Routes} from "../routes";
import {useHistory} from "react-router-dom";
import {SmartTable} from "../commons/table/SmartTable";
import {deleteCampaign, ReportTemplate, useGetAllReportTemplates} from "../api/reportTemplates";
import {useMultiFetchSWR} from "../api/helpersSWR";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie, faEllipsisH, faLineChart, faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {getAuthenticatedUser} from "../commons/auth";
import {useSWRConfig} from "swr";
import { TopLevelDropdownMenu } from '../components/utils/TopLevelDropdownMenu';

export const ReportTemplateListPage = () => {

  const {data: [reportTemplateList]} = useMultiFetchSWR([{useFetchFunc: useGetAllReportTemplates}]);
  const {mutate} = useSWRConfig();
  const user = getAuthenticatedUser();
  const history = useHistory();

  return (
    <Card className="mb-4">
      <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <h5 style={{display: "inline-block", margin: 0}}>Lista template di report</h5>
        <Button onClick={() => history.push(Routes.CampaignsReportTemplates.Create.path)}>
          Crea template
        </Button>
      </Card.Header>
      <Card.Body className="pt-3">
        <SmartTable<ReportTemplate, never>
          pagination={true}
          tableKey="template-report-list-table"
          syncWithElementsProp={true}
          enableHiddenColumns={false}
          getElementKey={(template) => template.name}
          sort={{
            defaultSortingColIndex: 1,
            defaultArrowVisible: true
          }}
          elements={reportTemplateList?.data?.templates ?? []}
          columns={[
            {
              title: "",
              cellRenderer: (template) =>
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                    </span>
                  </Dropdown.Toggle>
                  <TopLevelDropdownMenu align="left">
                    <Dropdown.Item
                      onClick={() => history.push(Routes.CampaignsReportTemplates.Edit.buildPath(template.id))}>
                      <FontAwesomeIcon icon={faPencilAlt}/> Modifica
                    </Dropdown.Item>
                    {user.role === 'sysadmin' &&
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => deleteCampaign(template.id).then(r => mutate("/settings/viewers"))}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/> Elimina
                      </Dropdown.Item>
                    }
                  </TopLevelDropdownMenu>
                </Dropdown>
            },
            {
              title: "Nome",
              cellRenderer: (template) => template.name,
              compareElementsByColumn:
                (templateA, templateB) => templateA.name.localeCompare(templateB.name)
            },
            {
              title: "Tab serie temporali",
              cellRenderer: (template) =>
                template.tabs.find(tab => tab.view_type === "default") !== undefined ?
                  (template.tabs.find(tab => tab.view_type === "default").charts?.[0]?.chart_type === "line" ?
                    <div>
                      Attiva <FontAwesomeIcon style={{marginLeft: "5px"}} icon={faLineChart}/>
                    </div>
                    : "Attiva")
                  : "Non attiva",
              compareElementsByColumn:
                (templateA, templateB) =>
                  (templateA.tabs.find(tab => tab.view_type === "default") !== undefined ? "Attiva" : "Non attiva")
                    .localeCompare(
                      templateB.tabs.find(tab => tab.view_type === "default") !== undefined ? "Attiva" : "Non attiva"
                    )
            },
            {
              title: "Tab serie per domini",
              cellRenderer: (template) =>
                template.tabs.find(tab => tab.view_type === "subdomain") !== undefined ?
                  (template.tabs.find(tab => tab.view_type === "subdomain").charts?.[0]?.chart_type === "pie" ?
                    <div>
                      Attiva <FontAwesomeIcon style={{marginLeft: "5px"}} icon={faChartPie}/>
                    </div>
                    : "Attiva")
                  : "Non attiva",
              compareElementsByColumn:
                (templateA, templateB) =>
                  (templateA.tabs.find(tab => tab.view_type === "subdomain") !== undefined ? "Attiva" : "Non attiva")
                    .localeCompare(
                      templateB.tabs.find(tab => tab.view_type === "subdomain") !== undefined ? "Attiva" : "Non attiva"
                    )
            }
          ]}
        />
      </Card.Body>
    </Card>
  );
}