import {Form, Formik} from "formik";
import {Button, CloseButton, Modal} from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import {CampaignDsp, DSPStatus, getNameForDspStatus, updateDspStatus} from "../../../api/dsp/campaignDsp";
import * as Yup from "yup";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {buildRegexMatchPattern, useMutateManyV2} from "../../../api/swr-fetcher";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";

const Schema = Yup.object({
  status: Yup.number().typeError("Campo obbligatorio").required()
});

interface Props {
  isShown: boolean,
  onClosePopup: () => any;
  campaign: CampaignDsp;
  oldStatus: DSPStatus;
}

export function EditDspStatusPopup(props: Props) {

  const {isShown, onClosePopup, campaign, oldStatus} = props;
  const mutateMany = useMutateManyV2();
  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

  return (
    <Formik
      initialValues={{status: oldStatus}}
      validationSchema={Schema}
      onSubmit={(values) => {
        return updateDspStatus(campaign.id, values).then(r => mutateMany(buildRegexMatchPattern("dsp/campaigns")))
          .then(r => onClosePopup())
          .catch(e => setResponseStatus("FAIL"));
      }}
    >
      {({values, isSubmitting, submitForm}) => (
        <Form>
          <Modal centered show={isShown}>
            <Modal.Header
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "0.7rem",
                paddingTop: "0.7rem"
              }}
            >
              <Modal.Title style={{fontSize: "20px"}}>
                Modifica stato per richiesta campagna {campaign.name}
              </Modal.Title>
              <CloseButton onClick={onClosePopup}/>
            </Modal.Header>
            <Modal.Body className="m-0 pt-2 px-3" style={{display: "flex", flexDirection: "column", gap: "10px"}}>
              <InputSelectField
                name="status" showValid={false} label="Stato richiesta" optionValueType="number"
                children={<>
                  <option key={DSPStatus.New} value={DSPStatus.New}>{getNameForDspStatus(DSPStatus.New)}</option>
                  <option key={DSPStatus.Takeover} value={DSPStatus.Takeover}>{getNameForDspStatus(DSPStatus.Takeover)}</option>
                  <option key={DSPStatus.Declined} value={DSPStatus.Declined}>{getNameForDspStatus(DSPStatus.Declined)}</option>
                  <option key={DSPStatus.WiP} value={DSPStatus.WiP}>{getNameForDspStatus(DSPStatus.WiP)}</option>
                  <option key={DSPStatus.Started} value={DSPStatus.Started}>{getNameForDspStatus(DSPStatus.Started)}</option>
                </>}
              />
            </Modal.Body>
            <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Button type="button" variant="outline-primary" onClick={onClosePopup}>
                  Annulla
                </Button>
                <Button onClick={submitForm} variant="primary" disabled={isSubmitting}>
                  Conferma modifica
                </Button>
              </div>
              <FeedbackAlert status={responseStatus}/>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
