import React, {useState} from 'react';
import * as Yup from 'yup';
import {Button, Card} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik} from "formik";
import {InputField} from "../../../commons/forms/InputField";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {Routes} from "../../../routes";
import {useHistory} from "react-router-dom";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {
  createDspTarget,
  DspTarget,
  DspTargetBase,
  DspTargetResponse,
  updateDspTarget,
  useGetDspTargetNoStaleData
} from "../../../api/dsp/targets";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

const Schema = Yup.object({
  name: Yup.string().required(),
  group: Yup.string().required()
});

export const DspTargetFormPage = withMultiFetchSWR(
  (props: {}, urlParams: {targetId: string}) => [
    {
      useFetchFunc: () => useGetDspTargetNoStaleData(parseInt(urlParams.targetId)),
      shouldFetch: urlParams.targetId !== undefined,
      elseData: {
        data: {
          objective: getDefaultConfig()
        }
      }
    }
  ], ({urlParams, endpointsResponse}) => {
    const targetId = urlParams.targetId ? parseInt(urlParams.targetId) : undefined;
    const isNewTarget = targetId == undefined;
    const targetResponse: DspTargetResponse = endpointsResponse.data[0] as unknown as DspTargetResponse;
    const target = targetResponse.data.objective;

    const history = useHistory();

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header>
          <h5
            className="mb-0">{isNewTarget ? "Creazione audience" : `Modifica audience ${target.name}`}</h5>
        </Card.Header>
        <Card.Body>
          <Formik<DspTarget | DspTargetBase>
            initialValues={target}
            validationSchema={Schema}
            onSubmit={(values, {setSubmitting}) => {
              let promise;
              if (isNewTarget)
                promise = createDspTarget(values);
              else
                promise = updateDspTarget(targetId, values);
              return promise
                .then(r => history.push(Routes.Dsp.Target.ViewAll.path))
                .catch(e => setResponseStatus("FAIL"));
            }}
          >
            {({isSubmitting, dirty, values}) => (
              <FormikForm style={{display: "flex", flexDirection: "column", gap: "25px"}}>
                <InputField name="group" type="text" label="Nome del gruppo" placeholder="Inserisci nome gruppo"/>
                <InputField name="name" type="text" label="Nome dell'audience" placeholder="Inserisci nome audience"/>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "30px"}}>
                  <Button type="button" variant="outline-primary" onClick={() => history.push(Routes.Dsp.Target.ViewAll.path)}>
                    Annulla
                  </Button>
                  <Button type="submit" variant="primary" disabled={!dirty || isSubmitting}>
                    {isNewTarget ? "Crea audience" : "Conferma modifiche"}
                  </Button>
                </div>
                <FeedbackAlert status={responseStatus}/>
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);

function getDefaultConfig() {
  return {
    group: "",
    name: ""
  }
}
