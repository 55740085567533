import React, {useEffect, useState} from "react";
import styles from "./RowBriefTable.module.scss";
import {SmartTable} from "../../commons/table/SmartTable";
import moment from "moment";
import {compareDates} from "../utils/DateUtils";
import {KPI, useGetKpis} from "../../api/kpis";
import {roundNumberToTwoDecimals} from "./CampaignDataPanel";
import {
  CampaignRowsDataSeriesForTab,
  CampaignRowsSubdomainDataSeries,
  CampaignRowsTimeDataSeries
} from "../../api/campaigns";
import {withMultiFetchSWR} from "../../api/helpersSWR";

interface Props<T extends "default" | "subdomain"> {
  rows: CampaignRowsDataSeriesForTab<T>["rows"],
  type: T,
  campaignPanelKey?: string
}

export const RowBriefTable = withMultiFetchSWR(
  (props: Props<any>, urlParams: {}) => [
    {useFetchFunc: useGetKpis}
  ], ({props, endpointsResponse}) => {
  const {rows, type, campaignPanelKey} = props;
  const [kpisResponse] = endpointsResponse.data;
  const kpis = kpisResponse.data.kpis;

  const [currentCampaignKpis, setCurrentCampaignKpis] = useState<KPI[]>([]);

  useEffect(() => {
    setCurrentCampaignKpis(
      Object.keys(rows[0]?.values ?? {}).map(k => (kpis as KPI[]).find(kpi => kpi.id === +k))
    );
  }, [rows]);

  if (!kpis)
    return null;

  type timeRow = CampaignRowsTimeDataSeries["rows"][number];
  type domainRow = CampaignRowsSubdomainDataSeries["rows"][number];

  let headerCol = {};
  if(type === "default") {
    headerCol = {
      title: "Data",
      compareElementsByColumn:
        (rowA: timeRow, rowB: timeRow) => compareDates(moment(rowA.kpi_date), moment(rowB.kpi_date)),
      cellRenderer: (row: timeRow) => moment(row.kpi_date).format("DD/MM/YYYY"),
      columnCanBeHidden: false
    };
  } else if (type === "subdomain") {
    headerCol = {
      title: "Dominio",
      compareElementsByColumn:
        (rowA: domainRow, rowB: domainRow) => rowA.domain.localeCompare(rowB.domain),
      cellRenderer: (row: domainRow) => row.domain,
      columnCanBeHidden: false
    };
  }

  return (
    <div className={styles.container}>
      <SmartTable<(typeof rows)[number], {}>
        tableKey={`${campaignPanelKey}-campaignKpiValuesTable`}
        syncWithElementsProp={true}
        elements={rows}
        pagination={true}
        getElementKey={row => type==="default" ? (row as timeRow).kpi_date : (row as domainRow).domain}
        sort={{
          defaultSortingColIndex: 0,
          defaultArrowVisible: true
        }}
        enableHiddenColumns={true}
        columns={[
          // @ts-ignore
          headerCol,
          ...currentCampaignKpis.map(kpi => ({
            title: kpi.name,
            compareElementsByColumn:
              (rowA, rowB) => rowA.values[kpi.id].value - rowB.values[kpi.id].value,
            cellRenderer: row => {
              if (kpi.is_percentage)
                return `${roundNumberToTwoDecimals(row.values[kpi.id].value * 100, true)}%`
              else
                return roundNumberToTwoDecimals(row.values[kpi.id].value, false)
            }
          }))
        ]}
      />
    </div>
  );
});
