import React, {useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
import {Step, Steps, Wizard} from 'react-albus';
import {Card, Col, Row} from "@themesberg/react-bootstrap";
import axios, {AxiosResponse} from "axios";

import Step1 from "./Step1";
import Step2 from "./Step2";
import {
  attachSource,
  Campaign,
  CampaignSource,
  createCampaign,
  deleteSource,
  EndpointResponseCampaignCreation,
  updateCampaign,
  updateCampaignKpi,
  updateSource,
  useGetCampaignNoStaleData,
  useGetCampaignSources
} from "../../api/campaigns";
import {useSWRConfig} from "swr";
import {useGetMappingsNoStaleData} from "../../api/mappings";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {getKpisFromMappings} from "../../commons/Utils";
import {Routes} from "../../routes";
import {useMutateMany} from "../../api/swr-fetcher";
import {EndpointWrapper} from "../../api/common";
import {useGetKpis} from "../../api/kpis";
import InComponentPreloader from "../../components/utils/InComponentPreloader";
import {Step3} from "./Step3";

export interface CampaignStep1Data {
  name: string;
  advertiser: string;
  budget: number | "";
  price_per_impression: number | "",
  delivered_kpi_id: number | "",
  dates: Array<{
    starts_at: string;
    ends_at: string;
  }>
}

export interface CampaignStep2Data {
  sources: Array<{
    id?: number,
    name: string,
    mapping_id: number,
    import_type: "mail" | "manual" | "download",
    mapping_subject: string,
    dates: null
  }>;
}

export interface CampaignStep3Data {
  objectives: Array<{
    kpi_id: number;
    objective: number;
  }>;
}

interface UrlParams {
  campaignId: number | undefined;
}

export const CampaignConfigurationPage = withMultiFetchSWR(
  (props: {}, {campaignId}: UrlParams) => [
    {
      useFetchFunc: useGetMappingsNoStaleData
    },
    {
      useFetchFunc: () => useGetCampaignNoStaleData(campaignId),
      shouldFetch: campaignId !== null && campaignId !== undefined,
      elseData: {
        data: {
          campaign: {
            name: "",
            advertiser: "",
            delivered_kpi_id: "",
            budget: "",
            price_per_impression: "",
            dates: [],
            campaign_kpis: []
          }
        }
      }
    },
    {
      useFetchFunc: () => useGetCampaignSources(campaignId),
      shouldFetch: campaignId !== null && campaignId !== undefined,
      elseData: {
        data: {
          sources: []
        }
      }
    },
    {useFetchFunc: useGetKpis}
  ], ({urlParams: {campaignId}, endpointsResponse}) => {

    const history = useHistory();

    const {cache} = useSWRConfig();

    const mutateMany = useMutateMany();

    const {data: [mappings, campaignData, campaignSources, kpisResponse]} = endpointsResponse;

    const kpis = kpisResponse.data.kpis;

    const [step, setStep] = useState(1);
    const [step1Data, setStep1Data] = useState<CampaignStep1Data>({
      advertiser: campaignData.data.campaign.advertiser,
      delivered_kpi_id: campaignData.data.campaign.delivered_kpi_id,
      budget: campaignData.data.campaign.budget,
      dates: campaignData.data.campaign.dates,
      name: campaignData.data.campaign.name,
      price_per_impression: campaignData.data.campaign.price_per_impression
    });
    const [step2Data, setStep2Data] = useState<CampaignStep2Data>({
      sources: campaignSources.data.sources.map(s => ({
        id: s.id,
        name: s.name,
        import_type: s.import_type,
        mapping_id: s.mapping_id,
        dates: null,
        mapping_subject: s.extra?.subject ?? ''
      }))
    });
    const [step3Data, setStep3Data] = useState<CampaignStep3Data>({
      objectives: campaignData.data.campaign.campaign_kpis
        .filter(kpi => kpi.objective !== null)
        .map(kpi => ({
          kpi_id: kpi.kpi_id,
          objective: kpi.objective
        }))
    });

    const [campaignCreated, setCampaignCreated] = useState(false);

    if (campaignCreated) {
      return <Redirect to="/campaigns"/>;
    }

    if (step === 4) {
      return (
        <div>
          {
            campaignId === null &&
            <h5>Creazione in corso...</h5>
          }
          {
            campaignId !== null &&
            <h5>Aggiornamento campagna in corso...</h5>
          }
          <p>Attendere prego...</p>
        </div>
      );
    }

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col xs={12}>
              <h5>{campaignId ? "Modifica campagna" : "Crea nuova campagna"}</h5>
            </Col>
            <Col xs={12}>
              <div className="progress" style={{marginBottom: '0.5rem', minHeight: '15px'}}>
                <div className="progress-bar" role="progressbar" aria-valuenow={Math.trunc(step / 3 * 100)}
                     aria-valuemin={0}
                     aria-valuemax={100}
                     style={{width: `${Math.trunc(step / 3 * 100)}%`, backgroundColor: '#007bff'}}
                >
                  {Math.trunc(step / 3 * 100)}%
                </div>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="pt-0">
          <Wizard>
            <Steps>
              <Step
                id="first"
                render={({next}) =>
                  <Step1
                    showCampaignListPage={() => history.push(Routes.Campaigns.ViewAll.path)}
                    initialValues={step1Data}
                    next={(data) => {
                      setStep(2);
                      setStep1Data(data);
                      next();
                    }}
                    kpis={kpis}
                  />}
              />
              <Step
                id="second"
                render={({next, previous}) =>
                  <Step2
                    initialValues={step2Data}
                    deliveredKpiId={step1Data.delivered_kpi_id as number}
                    mappings={mappings}
                    previous={(data) => {
                      setStep2Data(data);
                      setStep(1);
                      previous();
                    }}
                    next={(data) => {
                      setStep(3);
                      setStep2Data(data);
                      next();
                    }}
                    kpis={kpis}
                  />}
              />
              <Step
                id="third"
                render={({previous}) =>
                  <Step3
                    kpisFromMappings={getKpisFromMappings(step2Data.sources, mappings)}
                    initialValues={step3Data}
                    isNewCampaign={campaignId === null || campaignId === undefined}
                    previous={(data) => {
                      setStep(2);
                      setStep3Data(data);
                      previous();
                    }}
                    next={(data) => {
                      setStep(4);
                      setStep3Data(data);
                      createOrUpdateCampaign(
                        campaignId,
                        campaignData.data.campaign,
                        campaignSources.data.sources,
                        step1Data,
                        step2Data,
                        data
                      ).then(responses => {
                        const success = responses.every(response => response.status < 300 && response.data.success);
                        if (success) {
                          setCampaignCreated(true);
                          mutateMany(cache, /^\/campaigns\?.+/).then(() => {
                          });
                          history.push(Routes.Campaigns.ViewAll.path);
                        } else {
                          console.error(responses);
                        }
                      }).catch(err => {
                        console.error(err);
                        setStep(3);
                      });
                    }}
                  />
                }
              />
            </Steps>
          </Wizard>
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);


function createOrUpdateCampaign(
  campaignId: number | undefined | null,
  campaign: Campaign,
  initialCampaignSources: CampaignSource[],
  step1Data: CampaignStep1Data,
  step2Data: CampaignStep2Data,
  step3Data: CampaignStep3Data
) {
  const isNewCampaign = campaignId === undefined || campaignId === null;
  const createOrUpdate = isNewCampaign ? createCampaign : (data) => updateCampaign(campaignId, data);
  return createOrUpdate({
    ...step1Data,
    dates: step1Data.dates.map(interval => ({
      // @ts-ignore
      starts_at: interval.starts_at,
      // @ts-ignore
      ends_at: interval.ends_at,
    })),
  }).then(res => axios.all([
      ...step2Data.sources.map(source => {
        const newCampaignId = isNewCampaign ?
          (res as AxiosResponse<EndpointWrapper<EndpointResponseCampaignCreation>>).data.data.campaign
          : campaignId;
        if (source.id !== undefined)
          return updateSource(newCampaignId, source.id, source);
        else
          return attachSource(newCampaignId, source);
      }),
      ...initialCampaignSources
        .filter(initialSource => step2Data.sources.find(source => source.id === initialSource.id) === undefined)
        .map(initialSource => deleteSource(campaignId, initialSource.id))
    ])
      .then(() => axios.all([
        ...step3Data.objectives
          .map(kpiWithObjective => updateCampaignKpi(
            isNewCampaign ? res.data.data.campaign : campaignId,
            {
              ...kpiWithObjective,
              fixes: campaign.campaign_kpis.find(kpi => kpi.kpi_id === kpiWithObjective.kpi_id)?.fixes ?? []
            }
          )),
        ...campaign.campaign_kpis
          .filter(oldKpi => oldKpi.objective !== null && oldKpi.objective !== undefined) // get kpi already existing that had an objective
          .filter(oldKpi => step3Data.objectives.find(kpi => kpi.kpi_id === oldKpi.kpi_id) === undefined) // but now that objective has been removed
          .map(oldKpi => updateCampaignKpi(campaignId, { // thus, we update these kpi setting objective to null
            kpi_id: oldKpi.kpi_id,
            fixes: oldKpi.fixes,
            objective: null,
          }))
      ]))
  );
}
