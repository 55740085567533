import axios from 'axios';
import {axiosFetcher, fetcherWithParams} from "./swr-fetcher";
import useSWRInfinite from "swr/infinite";
import {useEffect} from "react";
import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "./common";
import {useSWRNoStaleData} from "./helpersSWR";
import useSWR from "swr";

export interface UserUpdate {
  email: string,
  password?: string,
  password_confirmation?: string,
  role: "sysadmin" | "admin" | "operator" | "customer" | "agency" | "aggregators",
  first_name: string,
  last_name: string,
  activation_limit?: number,
  status: 0 | 1, // 0 disabled, 1 enabled
  notes?: string
}

export interface GetResponseUserBase {
  email: string,
  first_name: string,
  last_name: string,
  activation_limit: number | null,
  role: "sysadmin" | "admin" | "operator" | "customer" | "agency" | "aggregators",
  notes: string | null,
  user_id: number | null,
  status: 0 | 1,
  created_at: string; //YYYY-MM-DDTHH:MM:SS+HH:MM
  updated_at: string; //YYYY-MM-DDTHH:MM:SS+HH:MM
}

export type GetResponseUser = EntityWithId<GetResponseUserBase>;

export type GetAllUsers = EndpointWrapperPaged<{
  users: GetResponseUser[]
}>

const pageSize = 50;
export function useGetAllUsers() {
  const {data, error, mutate, isValidating, setSize} = useSWRInfinite<GetAllUsers>(
    (pageIndex, previousPageData: GetAllUsers) => {
    if (pageIndex === 0 || pageIndex < previousPageData.data.total_pages)
      return {
        url: `/users`,
        params: {
          limit: pageSize,
          page: pageIndex,
        }
      };
    else
      return null;
    },
    fetcherWithParams
  )
  useEffect(
    () => {
      setSize(data?.[0]?.data?.total_pages ?? 1)
    },
    [data?.[0]?.data?.total_pages]
  );
  return {data: data?.flatMap(d => d.data.users) ?? null, error, mutate, isValidating};
}

export type UserResponse = EndpointWrapper<{user: GetResponseUser}>

export function useGetUser(userId: number) {
  return useSWR<UserResponse>(`/users/${userId}`, axiosFetcher);
}

export function useGetUserNoStaleData(userId: number) {
  return useSWRNoStaleData<UserResponse>(`/users/${userId}`, axiosFetcher);
}

export const createUser = (user: UserUpdate) => {
  return axios.post('/users', user).then(res => res.data);
};

export const updateUser = (id, user: UserUpdate) => {
  return axios.put(`/users/${id}`, user).then(res => res.data);
};

export const deleteUser = (id) => {
  return axios.delete(`/users/${id}`);
}
