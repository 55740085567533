import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {InputField} from "../commons/forms/InputField";
import * as Yup from 'yup';
import {Button, Card} from "@themesberg/react-bootstrap";
import styles from "./EmailSourcesPage.module.scss"
import {InputCheckField} from "../commons/forms/InputCheckField";
import {InputSelectField} from "../commons/forms/InputSelectField";
import {
  IMAPSettings,
  storeIMAPSettings,
  useGetIMAPSettings,
  useGetIMAPSettingsNoStaleData,
  useGetWidgetsSettingsNoStaleData
} from "../api/settings";
import {FeedbackAlert} from "../components/utils/FeedbackAlert";
import {withMultiFetchSWR} from "../api/helpersSWR";
import {useSWRConfig} from "swr";
import {buildRegexMatchPattern, useMutateManyV2} from "../api/swr-fetcher";
import InComponentPreloader from "../components/utils/InComponentPreloader";

const Schema = Yup.object({
  host: Yup.string().required(),
  port: Yup.number().typeError("Formato non valido").moreThan(0, "Formato non valido").required(),
  encryption: Yup.string().oneOf(["ssl", "tls", "none"]).required(),
  force_validate_cert: Yup.boolean().required(),
  username: Yup.string().required(),
  password: Yup.string().required()
});

export const EmailSourcesPage = withMultiFetchSWR(
  (props: {}, urlParams: {}) => [
    {useFetchFunc: useGetIMAPSettingsNoStaleData}
  ], ({props, endpointsResponse}) => {

    const initialValues = endpointsResponse?.data?.[0]?.data ?? {
      host: "",
      port: "",
      encryption: "",
      force_validate_cert: false,
      username: "",
      password: ""
    };
    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");
    const mutateMany = useMutateManyV2();

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header>
          <h5 className="mb-0">Importazione automatica sorgenti da mail</h5>
        </Card.Header>
        <Card.Body>
          <div className="mb-3">
            Inserisci le informazioni sulla casella di email da utilizzare per l’importazione dei sorgenti di dati in
            maniera automatica.
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={(values, {setSubmitting}) => {
              storeIMAPSettings(values as unknown as IMAPSettings)
                .then(r => {
                  mutateMany(buildRegexMatchPattern('settings/imap'));
                  setResponseStatus("SUCCESS");
                })
                .catch(e => setResponseStatus("FAIL"))
                .finally(() => setSubmitting(false));
            }}
          >
            {({isSubmitting, dirty, submitForm, values}) => (
              <Form className={styles.gridContainer}>
                <InputField className={styles.host} name="host" label="Host server IMAP"
                            placeholder="Inserisci indirizzo"
                            type="text"/>
                <InputField name="port" label="Porta" placeholder="Inserisci numero di porta" type="number"/>
                <InputSelectField
                  name="encryption" label="Tipo di encryption"
                  children={
                    <>
                      <option key='blankChoice' hidden value="">
                        Seleziona tipo di encryption
                      </option>
                      <option key="ssl" value="ssl">SSL</option>
                      <option key="tls" value="tls">TLS</option>
                      <option key="null" value="none">Nessuno</option>
                    </>
                  }
                />
                <InputField className={styles.name} name="username" label="Indirizzo email"
                            placeholder="Inserisci indirizzo email" type="text"/>
                <InputField className={styles.password} name="password" label="Password"
                            placeholder="Inserisci password"
                            type="password"/>
                <InputCheckField className={styles.switch} name="force_validate_cert"
                                 label="Attiva validazione del certificato server" type="switch"/>
                <Button
                  className={`${styles.button} mt-3`} type="submit" variant="primary" disabled={!dirty || isSubmitting}
                >
                  Salva modifiche
                </Button>
                <FeedbackAlert className={styles.feedback} status={responseStatus}/>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);