import axios, {AxiosResponse} from 'axios';
import useSWR from "swr";
import {axiosFetcher, fetcherWithParams} from "./swr-fetcher";
import {CampaignQueryParams} from "./CampaignsQueryParams";
import {useGetPaginatedItems, useSWRNoStaleData} from "./helpersSWR";
import {ReportTemplate, useGetAssociationBetweenReportTemplateAndCampaign} from "./reportTemplates";
import useSWRInfinite from "swr/infinite";
import {useEffect} from "react";
import {EndpointWrapper, EndpointWrapperPaged} from "./common";
import {isAuthenticatedUserInternal} from "../commons/Utils";
import {getAuthenticatedUser} from "../commons/auth";
import {KPI} from "./kpis";

export interface EndpointResponseCampaign {
  campaign: Campaign;
}

export interface EndpointResponseCampaign {
  campaign: Campaign;
}

export interface Campaign {
  id: number;
  campaign_kpis: Array<{ // list of KPIs
    kpi_id: number;
    name: string;
    description: string;
    computed_value?: number;
    pacing?: number; // percentage % (only if KPI is an objective KPI
    future_pacing?: number; // percentage %
    objective?: number;
    fixes: Array<{
      type: "percentage" | "increment";
      value: number
    }>
  }>;
  name: string;
  advertiser: string;
  budget: number;
  actual_budget: number;
  delivered_kpi_id: number;
  status: "COMPLETED" | "ACTIVE" | "PLANNED";
  price_per_impression: number;
  dates: Array<{
    starts_at: string; //YYYY-MM-DD
    ends_at: string; //YYYY-MM-DD
  }>;
  operator_id: number;
  created_at: string; //YYYY-MM-DDTHH:MM:SS+HH:MM
  updated_at: string; //YYYY-MM-DDTHH:MM:SS+HH:MM
}

interface SingleCampaignResponse extends Campaign {
  delivered_kpi: KPI,
  templates: ReportTemplate[]
}

export interface EndpointResponseCampaignWithTemplate {
  campaign: SingleCampaignResponse;
}

export type PaginatedCampaignList = EndpointWrapperPaged<{ campaigns: Campaign[] }>


export function useGetAllCampaigns() {
  return useGetPaginatedItems(
    "/campaigns",
    (p: PaginatedCampaignList) => p.data.campaigns
  )
}

export interface CampaignSource {
  id: number,
  name: string,
  url: string,
  import_type: "download" | "mail" | "manual",
  dates: Array<{
    starts_at: string, //YYYY-MM-DD
    ends_at: string  //YYYY-MM-DD
  }>,
  mapping_id: number,
  campaign_id: number,
  extra: {
    subject: string
  }
}

export interface CampaignRowsTimeDataSeries {
  pages: number,
  total: number,
  rows: Array<{
    kpi_date: string, //YYYY-MM-DDTHH:MM:SS.000000Z
    values: {
      [kpiId: number]: {
        kpi: number, //kpi ID
        value: number
      }
    };
  }>
}

export interface CampaignRowsSubdomainDataSeries {
  pages: number,
  total: number,
  rows: Array<{
    domain: string,
    values: {
      [kpiId: number]: {
        kpi: number, //kpi ID
        value: number
      }
    };
  }>
}

export interface EndpointResponseCampaignSources {
  sources: CampaignSource[]
}

export interface EndpointResponseCampaignCreation {
  campaign: number; //Campaign ID
}

export function getCampaigns(queryParams: CampaignQueryParams) {
  return axios.get(`/campaigns`, {params: {queryParams}}) as Promise<AxiosResponse<EndpointWrapper<{ campaigns: Campaign[] }>>>;
}

export function useGetCampaign(id: number) {
  return useSWR<EndpointWrapper<EndpointResponseCampaignWithTemplate>>(`/campaigns/${id}`, axiosFetcher);
}

export function useGetCampaignNoStaleData(id: number) {
  return useSWRNoStaleData<EndpointWrapper<EndpointResponseCampaign>>(`/campaigns/${id}`, axiosFetcher);
}

export function useGetCampaignSources(campaignId: number) {
  return useSWR<EndpointWrapper<EndpointResponseCampaignSources>>(`/campaigns/${campaignId}/sources`, axiosFetcher);
}

export const createCampaign = (data) => {
  return axios.post<EndpointWrapper<EndpointResponseCampaignCreation>>('/campaigns', data) as Promise<AxiosResponse<EndpointWrapper<EndpointResponseCampaignCreation>>>;
};

export const updateCampaign = (id, data) => {
  return axios.put(`/campaigns/${id}`, data);
};

export const deleteCampaign = (id) => {
  return axios.delete(`/campaigns/${id}`);
};

export const deleteCampaignInADateRange = (id, startDate, endDate) => {
  return axios.delete(`/campaigns/${id}/rows`, {params: {starts_at: startDate, ends_at: endDate}});
};

export const attachSource = (id, data) => {
  return axios.post(`/campaigns/${id}/sources`, data);
}

export const updateSource = (campaignId, sourceId, data) => {
  return axios.put(`/campaigns/${campaignId}/sources/${sourceId}`, data);
}

export const deleteSource = (campaignId, sourceId) => {
  return axios.delete(`/campaigns/${campaignId}/sources/${sourceId}`);
}

export const deleteCampaignKpi = (id, kpiId) => {
  return axios.delete(`/campaigns/${id}/kpi/${kpiId}`);
};

export const updateCampaignKpi = (campaignId, data) => {
  return axios.put(`/campaigns/${campaignId}/kpi`, data);
};

export const uploadCampaignSourceFile = (id: number, source: number, data: any) => {
  return axios.post(
    `/campaigns/${id}/sources/${source}/upload`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
  );
};

export function useGetCampaignDataRows(campaignId: number) {
  return useSWR<EndpointWrapper<CampaignRowsTimeDataSeries>>(`/campaigns/${campaignId}/view/all`, axiosFetcher);
}

const pageSize = 64;
export type CampaignRowsDataSeriesForTab<T extends "default" | "subdomain"> = T extends "default" ? CampaignRowsTimeDataSeries : CampaignRowsSubdomainDataSeries;
export function useGetCampaignDataRowsForAttachedTemplate<T extends "default" | "subdomain">(campaignId: number, tabType: T) {
  const template = useGetCampaign(campaignId).data?.data.campaign.templates?.[0];

  const {data: templateAssociation} = useGetAssociationBetweenReportTemplateAndCampaign(isAuthenticatedUserInternal() ? campaignId : null);
  let userId;
  if(isAuthenticatedUserInternal()) {
    userId = templateAssociation?.data.user_id_list?.[0] ?? null;
  } else {
    userId = getAuthenticatedUser().id;
  }

  const tabName = template?.tabs.find(tab => tab.view_type === tabType)?.name;

  const {data, error, mutate, isValidating, size, setSize} = useSWRInfinite<EndpointWrapper<CampaignRowsDataSeriesForTab<T>>>(
    (pageIndex, previousPageData) => {
      if (tabName && userId !== null && (pageIndex === 0 || pageIndex < previousPageData.data.pages))
      return {
          url: `/campaigns/${campaignId}/view/`,
          params: {
            tab: tabName,
            limit: pageSize,
            page: pageIndex,
            user_id: isAuthenticatedUserInternal() ? userId : null
          }
        };
      else
        return null;
    },
    fetcherWithParams
  )
  useEffect(() => {
      const newSize = Math.max(data?.[0]?.data?.pages ?? 1, 1);
      if(size != newSize)
        setSize(newSize)
    },
    [data?.[0]?.data?.pages]
  );

  if (tabName && data !== null)
    return {data: data?.map(d => d.data.rows as CampaignRowsDataSeriesForTab<T>["rows"]).flat() ?? null, error, mutate, isValidating};
  else
    return {
      data: [],
      error: null,
      isValidating: false,
      mutate: null
    };

}

export type CampaignKPIData = Array<CampaignRowsTimeDataSeries["rows"][number]>;