import React, {useState} from 'react';
import * as Yup from 'yup';
import {Button, Card} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik} from "formik";
import {InputField} from "../../../commons/forms/InputField";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {Routes} from "../../../routes";
import {useHistory} from "react-router-dom";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {
  createDspWebsite,
  DspWebsiteRequestBody,
  updateDspWebsite,
  useGetDspWebsiteNoStaleData,
} from "../../../api/dsp/websites";
import {MultipleCheckField} from "../../../commons/forms/MultipleCheckField";
import {useGetAllWebCategories} from "../../../api/dsp/webCategories";
import {useGetAllWebLists} from "../../../api/dsp/webLists";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CategoryWebsiteInfoPopup} from "./CategoryWebsiteInfoPopup";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

const Schema = Yup.object({
  domain: Yup.string().required(),
  category_id: Yup.number().required().typeError("È necessario selezionare il settore di competenza"),
  lists: Yup.array().required()
});

export const DspWebsiteFormPage = withMultiFetchSWR(
  (props: {}, urlParams: {websiteId: string}) => [
    {
      useFetchFunc: () => useGetDspWebsiteNoStaleData(parseInt(urlParams.websiteId)),
      shouldFetch: urlParams.websiteId !== undefined,
      elseData: {
        data: {
          website: getDefaultConfig()
        }
      }
    },
    {useFetchFunc: useGetAllWebCategories},
    {useFetchFunc: useGetAllWebLists}
  ], ({urlParams, endpointsResponse}) => {
    const websiteId = urlParams.websiteId ? parseInt(urlParams.websiteId) : undefined;
    const isNewWebsite = websiteId == undefined;
    //const websiteResponse: WebsiteResponse = endpointsResponse.data[0] as unknown as WebsiteResponse;
    const [websiteResponse, categoriesResponse, listResponse] = endpointsResponse.data;
    const website = websiteResponse.data.website;

    const history = useHistory();

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    const [infoPopupForCategoryId, setInfoPopupForCategoryId] = useState<null | number>(null)

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header>
          <h5
            className="mb-0">{isNewWebsite ? "Creazione sito" : `Modifica sito ${website.domain}`}</h5>
        </Card.Header>
        <Card.Body>
          <Formik<DspWebsiteRequestBody>
            initialValues={{
              domain: website.domain,
              category_id: website.category.id,
              lists: website.lists.map(l => l.id)
            }}
            validationSchema={Schema}
            onSubmit={(values, {setSubmitting}) => {
              let promise;
              if (isNewWebsite)
                promise = createDspWebsite(values);
              else
                promise = updateDspWebsite(websiteId, values);
              return promise
                .then(r => history.push(Routes.Dsp.Website.ViewAll.path))
                .catch(e => setResponseStatus("FAIL"));
            }}
          >
            {({isSubmitting, dirty, values, errors}) => (
              <FormikForm style={{display: "flex", flexDirection: "column", gap: "30px"}}>
                <InputField name="domain" type="text" label="Dominio del sito" placeholder="Inserisci dominio"/>
                <MultipleCheckField
                  label={"Seleziona liste applicabili: "}
                  name={"lists"}
                  items={listResponse.map(l => ({label: l.name, id: l.id}))}
                  isMultiSelect={true}
                  numOfColumns={6}
                />
                <MultipleCheckField
                  label={"Seleziona settore di competenza: "}
                  name={"category_id"}
                  items={categoriesResponse.map(c => ({
                    label: (
                      <div
                        role={"button"}
                        onClick={() => setInfoPopupForCategoryId(c.id)}
                        style={{display: "flex", flexDirection: "row", gap: "7px", alignItems: "center"}}
                      >
                        {`${c.name} (${c.domains.length !== 1 ? c.domains.length + " siti" : "1 sito"})`}
                        <FontAwesomeIcon icon={faCircleInfo} ></FontAwesomeIcon>
                      </div>
                    ),
                    id: c.id
                  }))}
                  isMultiSelect={false}
                  numOfColumns={3}
                />
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "30px"}}>
                  <Button type="button" variant="outline-primary" onClick={() => history.push(Routes.Dsp.Website.ViewAll.path)}>
                    Annulla
                  </Button>
                  <Button type="submit" variant="primary" disabled={!dirty || isSubmitting}>
                    {isNewWebsite ? "Crea sito" : "Conferma modifiche"}
                  </Button>
                </div>
                <FeedbackAlert status={responseStatus}/>
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
        {infoPopupForCategoryId !== null &&
            <CategoryWebsiteInfoPopup
              key={infoPopupForCategoryId}
              categoryId={infoPopupForCategoryId}
              onClosePopup={() => setInfoPopupForCategoryId(null)}
            />
        }
      </Card>
    );
  },
  InComponentPreloader
);

function getDefaultConfig() {
  return {
    domain: "",
    category: {
      id: null,
      name: ""
    },
    lists: []
  }
}
