import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Button, Card, Form} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik, useFormikContext} from "formik";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {Routes} from "../../../routes";
import {useHistory} from "react-router-dom";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {
  createDspPriceList,
  DspPriceList,
  DspPriceListBase,
  DspPriceListResponse,
  updateDspPriceList,
  useGetPriceListNoStaleData
} from "../../../api/dsp/priceLists";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {InputField} from "../../../commons/forms/InputField";
import {GetResponseUser, useGetAllUsers} from "../../../api/users";
import {useGetAllWebLists, WebList} from "../../../api/dsp/webLists";
import {useGetAllCustomersForAgency} from "../../../api/dsp/agencyCustomers";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

const Schema = Yup.object({
  price: Yup.number().min(0, "Inserisci un prezzo valido").required(),
  extra_audience: Yup.number()
    .min(0, "Inserisci una percentuale valida")
    .required(),
  weblist_id: Yup.number().min(0).required().nullable(),
  agency_customer_id: Yup.number().required().nullable(),
  agency_id: Yup.number().typeError("Campo obbligatorio").required(),
  format: Yup.string().required(),
  type: Yup.string().required()
});

export const DspPriceListFormPage = withMultiFetchSWR(
  (props: {}, urlParams: { priceListId: string }) => [
    {
      useFetchFunc: () => useGetPriceListNoStaleData(parseInt(urlParams.priceListId)),
      shouldFetch: urlParams.priceListId !== undefined,
      elseData: {
        data: {
          pricing: getDefaultConfig()
        }
      }
    },
    {useFetchFunc: useGetAllUsers},
    {useFetchFunc: useGetAllWebLists}
  ], ({urlParams, endpointsResponse}) => {
    const priceListId = urlParams.priceListId ? parseInt(urlParams.priceListId) : undefined;
    const isNewPriceList = priceListId == undefined;
    const priceListResponse: DspPriceListResponse = endpointsResponse.data[0] as unknown as DspPriceListResponse;
    const priceList = priceListResponse.data.pricing;

    const userList = endpointsResponse.data[1] as unknown as GetResponseUser[];
    const agencyList = userList.filter(user => user.role === "customer");

    const webListList = endpointsResponse.data[2] as unknown as WebList[];

    const [selectedAgencyId, setSelectedAgencyId] = useState(null)
    const SelectedAgencyIdSetterComp = () => {
      const values: DspPriceList = useFormikContext().values as DspPriceList;
      useEffect(() => setSelectedAgencyId(values.agency_id))
      return null;
    }
    const {data: agencyCustomerList} = useGetAllCustomersForAgency(selectedAgencyId);

    const history = useHistory();

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header>
          <h5
            className="mb-0">{isNewPriceList ? "Creazione listino prezzi" : `Modifica listino prezzi`}</h5>
        </Card.Header>
        <Card.Body>
          <Formik<DspPriceList | DspPriceListBase>
            initialValues={priceList}
            validationSchema={Schema}
            onSubmit={(values, {setSubmitting}) => {

              let promise;
              if (isNewPriceList)
                promise = createDspPriceList(values);
              else
                promise = updateDspPriceList(priceListId, values);
              return promise
                .then(r => history.push(Routes.Dsp.PriceList.ViewAll.path))
                .catch(e => setResponseStatus("FAIL"));
            }}
          >
            {({isSubmitting, dirty, values, setFieldValue, touched, setFieldTouched}) => (
              <FormikForm style={{display: "grid", flexDirection: "column", gap: "25px"}}>
                <SelectedAgencyIdSetterComp/>
                <div style={{display: "grid", gap: "25px", gridTemplateColumns: "1fr 1fr"}}>
                  <InputSelectField
                    name="agency_id"
                    label="Agenzia"
                    optionValueType="number"
                    children={
                      <>
                        <option key='blankChoice' hidden value="">Seleziona agenzia</option>
                        {agencyList.map(agency => (
                          <option key={agency.id}
                                  value={agency.id}>{`${agency.first_name} ${agency.last_name}`}</option>
                        ))}
                      </>
                    }
                  />
                  <InputSelectField
                    name="agency_customer_id"
                    label="Cliente"
                    optionValueType="number"
                    disabled={values.agency_id == null}
                    children={
                      <>
                        <option key='all-customers' value={null}>Tutti i clienti</option>
                        {agencyCustomerList?.map(customer => (
                          <option key={customer.id} value={customer.id}>{customer.name}</option>
                        ))}
                      </>
                    }
                  />
                  <InputSelectField
                    name="format"
                    label="Formato"
                    optionValueType="text"
                    children={
                      <>
                        <option key='blankChoice' hidden value="">Seleziona formato</option>
                        <option key="disp" value="disp">Display / Native</option>
                        <option key="vid" value="vid">Video</option>
                      </>
                    }
                  />
                  <InputSelectField
                    name="type"
                    label="Tipologia"
                    optionValueType="text"
                    disabled={values.format == undefined}
                    children={
                      <>
                        <option key='blankChoice' hidden value="">Seleziona tipologia</option>
                        {values.format === "disp" && <option key="cpc" value="cpc">CPC (costo per click)</option>}
                        <option key="cpm" value="cpm">CPM (costo per mille impression)</option>
                        {values.format === "disp" && <option key="cpv" value="cpv">CPV (costo per visit)</option>}
                        {values.format === "vid" &&
                          <option key="cpcv" value="cpcv">CPCV (costo per completed view)</option>}
                      </>
                    }
                  />
                  <Form.Group id="mode-selector">
                    <Form.Label className="label">Seleziona modalità</Form.Label>
                    <Form.Control
                      as="select"
                      className="input form-select"
                      value={getModeSelectorValue(values)}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "list") {
                          setFieldValue("weblist_id", "");
                          setFieldTouched("weblist_id", false);
                          setFieldValue("price_per_customs", false);
                          setFieldValue("price_per_category", false);
                        } else if (value === "category") {
                          setFieldValue("weblist_id", null);
                          setFieldValue("price_per_customs", false);
                          setFieldValue("price_per_category", true);
                        } else {
                          setFieldValue("weblist_id", null);
                          setFieldValue("price_per_customs", true);
                          setFieldValue("price_per_category", false);
                        }
                      }}
                      isInvalid={getModeSelectorValue(values) === "" && touched.price_per_category}
                      onBlur={() => setFieldTouched("price_per_category")}
                      children={
                        <>
                          <option key='blankChoice' hidden value="">Seleziona modalità</option>
                          <option key="list" value="list">Lista</option>
                          <option key="category" value="category">Settore</option>
                          <option key="custom" value="custom">Siti custom</option>
                        </>
                      }
                      id={`mode-selector-form-control`}
                    />
                  </Form.Group>
                  {values.weblist_id !== null &&
                    <InputSelectField
                      name="weblist_id"
                      label="Lista"
                      optionValueType="number"
                      children={
                        <>
                          <option key='blankChoice' hidden value="">Seleziona lista</option>
                          {webListList.map(webList => (
                            <option key={webList.id} value={webList.id}>{webList.name}</option>
                          ))}
                        </>
                      }
                      containerStyle={{gridColumn: 2, gridRow: 3}}
                    />
                  }
                  <InputField
                    name="price" type="number" min={0}  step={0.01} label={`Prezzo [\u20ac]`}
                    placeholder="Inserisci prezzo" containerStyle={{gridColumn: 1, gridRow: 4}}
                  />
                  <InputField
                    name="extra_audience" type="number" min={0} max={100} step={0.01} label="Extra per audience custom [%]"
                    placeholder="Inserisci percentuale" containerStyle={{gridColumn: 2, gridRow: 4}}
                  />
                </div>
                <div
                  style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "30px"}}>
                  <Button type="button" variant="outline-primary"
                          onClick={() => history.push(Routes.Dsp.PriceList.ViewAll.path)}>
                    Annulla
                  </Button>
                  <Button type="submit" variant="primary" disabled={!dirty || isSubmitting}>
                    {isNewPriceList ? "Crea listino prezzi" : "Conferma modifiche"}
                  </Button>
                </div>
                <FeedbackAlert status={responseStatus}/>
                <FormikFormHelper isNewPriceList={isNewPriceList}/>
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);

function getDefaultConfig() {
  return {
    price: undefined,
    extra_audience: undefined,
    weblist_id: null,
    agency_customer_id: null,
    agency_id: undefined,
    price_per_customs: false,
    price_per_category: false,
    format: undefined,
    type: ""
  }
}

function FormikFormHelper({isNewPriceList}) {
  const {setFieldValue} = useFormikContext();
  const values = useFormikContext().values as unknown as (DspPriceList | DspPriceListBase);

  useEffect(() => {
    if(isNewPriceList)
      setFieldValue("type", "");
  }, [values.format]);

  return null;
}

export function getModeSelectorValue(values: DspPriceList | DspPriceListBase): string {
  if (values.price_per_customs)
    return "custom";
  if (values.price_per_category)
    return "category";
  if (values.weblist_id !== null)
    return "list";
  return "";
}