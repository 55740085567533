import * as React from "react";
import styles from "./LineItemsArray.module.scss";
import {LineWrappedItem} from "./LineWrappedItem";

interface Props<T> {
  title?: string | JSX.Element;
  common?: JSX.Element;
  items: Array<{title?: string | JSX.Element, key?: string | number, element: JSX.Element}>
  className?: string;
};

export type ArrayRendererComponent = React.ComponentType<Props<any>>;

export const LineItemsArray = (props: Props<any>) => (
  <div className={styles.container}>
    <div className={styles.header}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      {props.common && <div className={styles.common}>{props.common}</div>}
    </div>
    <div className={styles.items}>
      {props.items.map(item => (
        <LineWrappedItem key={item.key} title={item.title} className="formFieldArrayItem">
          {item.element}
        </LineWrappedItem>
      ))}
    </div>
  </div>
);
