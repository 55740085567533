import {Button, ButtonGroup} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik, useFormikContext} from "formik";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import styles from "./Level1Form.module.scss";
import {Level1InputData} from "../../api/DomainAnalysis";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {Campaign, useGetAllCampaigns} from "../../api/campaigns";
import {InputDateRangeField} from "../../commons/forms/InputDateRangeField";
import {FormikHelperWithUseValues, LevelInputFormProps} from "./DomainAnalysisPage";
import {InputField} from "../../commons/forms/InputField";
import {SmallButton} from "../../components/utils/buttons/SmallButton";
import {FormFieldsArray} from "../../commons/forms/FormFieldsArray";
import {InputSelectField} from "../../commons/forms/InputSelectField";
import {useGetKpis} from "../../api/kpis";
import {Attribute, useGetAttributes} from "../../api/attributes";
import {ItemsSelectionPopup} from "../../components/utils/ItemsSelectionPopup";

const Schema = Yup.object({
  use_future_pacing: Yup.boolean(),
  campaigns: Yup.array().required().when('use_future_pacing', {
    is: true,
    then: (schema) =>
      schema.min(1, "Seleziona una campagna su cui effettuare l'analisi"),
    otherwise: (schema) =>
      schema.min(1, "Seleziona almeno una campagna per effettuare l'analisi")
  }),
  attributes: Yup.array().required().min(1, "Seleziona almeno una dimensione di analisi"),
  dates: Yup.array(Yup.object({
    starts_at: Yup.string().required("Seleziona il periodo di riferimento"),
    ends_at: Yup.string().required("Seleziona il periodo di riferimento")
  })).required().min(1, "Seleziona il periodo di riferimento"),
  objectives: Yup.array(Yup.object({
    kpi_id: Yup.number().typeError("Seleziona un KPI").required("Seleziona un KPI"),
    target: Yup.number().typeError("Indica l'obiettivo").required("Indica l'obiettivo").min(0, "Inserire valore valido"),
  })).required().min(1, "Seleziona almeno un obiettivo"),
  domain_blacklist_str: Yup.string()
    .test(
      "is-there-at-least-one-domain",
      "Specificare almeno un dominio",
      value => value === null || isThereAtLeastOneDomain(value)
    ).test(
      "are-domains-valid",
      ({originalValue}) => getNonValidDomains(originalValue).reduce((str, d) => `${str} ${d}`, "I seguenti domini non sono validi:"),
      value => value === null || getNonValidDomains(value).length === 0
    ).nullable(),
  domain_whitelist_str: Yup.string()
    .test(
      "is-there-at-least-one-domain",
      "Specificare almeno un dominio",
      value => value === null || isThereAtLeastOneDomain(value)
    ).test(
      "are-domains-valid",
      ({originalValue}) => getNonValidDomains(originalValue).reduce((str, d) => `${str} ${d}`, "I seguenti domini non sono validi:"),
      value => value === null || getNonValidDomains(value).length === 0
    ).nullable()
});

type Props = LevelInputFormProps<Level1InputData>;

type Level1FormikValues = Omit<Level1InputData, "domain_whitelist" | "domain_blacklist"> & {
  domain_whitelist_str: string,
  domain_blacklist_str: string
}

export const Level1Form = withMultiFetchSWR(
  (props: Props, urlParams: { analysisId: string }) => [
    {useFetchFunc: useGetAllCampaigns},
    {useFetchFunc: useGetKpis},
    {useFetchFunc: useGetAttributes}
  ], ({props, urlParams, endpointsResponse}) => {

    const {initialValues, onSubmit, formikRef, useValues} = props;
    const {data: [campaigns, kpis, attributes]} = endpointsResponse;

    const percentageKpiIdList = kpis.data.kpis.filter(kpi => kpi.is_percentage).map(kpi => kpi.id);

    const [isShownSingleCampaignSelectionPopup, setIsShownSingleCampaignSelectionPopup] = useState(false);
    const [isShownMultipleCampaignsSelectionPopup, setIsShownMultipleCampaignsSelectionPopup] = useState(false);
    const [isShownAttributesSelectionPopup, setIsShownAttributesSelectionPopup] = useState(false);

    function getFormattedInitialValues(initialValues: Level1InputData): Level1FormikValues {
      if (initialValues === null || initialValues === undefined)
        return getDefaultBulkAnalysisConfig();
      else
        return {
          ...initialValues,
          objectives: initialValues.objectives.map(obj => percentageKpiIdList.includes(obj.kpi_id) ? ({
            ...obj,
            target: obj.target * 100
          }) : obj),
          dates: initialValues.dates.map(date => ({
            starts_at: moment(date.starts_at) as unknown as string,
            ends_at: moment(date.ends_at) as unknown as string
          })),
          domain_blacklist_str: initialValues.domain_blacklist !== null ?
            initialValues.domain_blacklist.reduce((str, d) => `${str} ${d}`, "") :
            null,
          domain_whitelist_str: initialValues.domain_whitelist !== null ?
            initialValues.domain_whitelist.reduce((str, d) => `${str} ${d}`, "") :
            null
        };
    }


    return (
      <Formik<Level1FormikValues>
        initialValues={getFormattedInitialValues(initialValues)}
        validationSchema={Schema}
        innerRef={formikRef}
        onSubmit={(values) => {
          let valuesToSubmit;

          if (values.use_future_pacing)
            valuesToSubmit = {...values}
          else
            valuesToSubmit = {
              ...values,
              dates: values.dates.map(date => ({
                starts_at: (date.starts_at as unknown as moment.Moment).format('YYYY-MM-DD'),
                ends_at: (date.ends_at as unknown as moment.Moment).format('YYYY-MM-DD')
              }))
            }

          valuesToSubmit.domain_blacklist = valuesToSubmit.domain_blacklist_str !== null ? valuesToSubmit.domain_blacklist_str.trim().split(/\s+/) : null;
          delete valuesToSubmit.domain_blacklist_str;
          valuesToSubmit.domain_whitelist = valuesToSubmit.domain_whitelist_str !== null ? valuesToSubmit.domain_whitelist_str.trim().split(/\s+/) : null;
          delete valuesToSubmit.domain_whitelist_str;

          valuesToSubmit.objectives = valuesToSubmit.objectives.map(
            obj => percentageKpiIdList.includes(obj.kpi_id) ? ({...obj, target: obj.target / 100}) : obj
          );

          return onSubmit(valuesToSubmit)
        }}
      >
        {({isSubmitting, values, errors, touched, setFieldValue, setFieldTouched, setValues, setTouched}) => (
          <FormikForm style={{display: "flex", flexDirection: "column", gap: "20px"}}>
            <FormikHelperWithUseValues useValues={useValues} values={values}/>
            <ButtonGroup>
              <Button
                onClick={() => {
                  if (initialValues && !initialValues.use_future_pacing)
                    setValues(getFormattedInitialValues(initialValues));
                  else
                    setValues({...getDefaultBulkAnalysisConfig(), use_future_pacing: false});
                  setTouched({});
                }}
                className={
                  `${styles.buttongroupBackground} ${values.use_future_pacing === true ? styles.disabledButton : ""}`
                }
                style={{width: "50%"}}
              >
                Elaborazione pre-executive (Bulk)
              </Button>
              <Button
                onClick={() => {
                  if (initialValues && initialValues.use_future_pacing)
                    setValues(getFormattedInitialValues(initialValues));
                  else
                    setValues({...getDefaultBulkAnalysisConfig(), use_future_pacing: true});
                  setTouched({});
                }}
                className={
                  `${styles.buttongroupBackground} ${values.use_future_pacing === false ? styles.disabledButton : ""}`
                }
                style={{width: "50%"}}
              >
                Elaborazione in-executive (Predittiva)
              </Button>
            </ButtonGroup>
            <div style={{display: "flex", flexDirection: "column", gap: "35px", marginLeft: "15px"}}>
              <div>
                <label style={{marginBottom: "10px"}}>
                  {`${values.use_future_pacing ? "Campagna attiva" : "Campagne"} da analizzare`}
                </label>
                <div
                  style={{display: "grid", gridTemplateColumns: "auto 1fr", gap: "15px", alignItems: "center"}}>
                  <Button
                    style={{padding: "4px 5px 1px 1px"}}
                    onClick={() => {
                      if (values.use_future_pacing)
                        setIsShownSingleCampaignSelectionPopup(true);
                      else
                        setIsShownMultipleCampaignsSelectionPopup(true);
                    }}
                  >
                    <FontAwesomeIcon style={{fontSize: "18px", paddingLeft: "5px"}}
                                     icon={faArrowUpRightFromSquare}/>
                  </Button>
                  {isShownSingleCampaignSelectionPopup &&
                    <ItemsSelectionPopup<Campaign>
                      listGroupItemStyle={{padding: "3px 6px"}}
                      size="xl"
                      closePopupBtnDescription={"Chiudi"}
                      confirmBtnDescription={"Conferma"}
                      popupKey={"single-campaign-sel-popup"}
                      getItemView={(campaign) => getCampaignViewInPopup(campaign)}
                      onItemsSelected={(selectedCampaigns) => {
                        setFieldValue("campaigns", selectedCampaigns.map(campaign => campaign.id));
                        setIsShownSingleCampaignSelectionPopup(false);
                      }}
                      onClosePopup={() => setIsShownSingleCampaignSelectionPopup(false)}
                      isShown={true}
                      itemList={campaigns.filter(c => c.status === "ACTIVE")}
                      title="Campagna attiva da analizzare"
                      alreadyAssociatedItemList={
                        values.campaigns.length === 0 ? [] : [campaigns.find(campaign => campaign.id === values.campaigns[0])]
                      }
                      isMultiSelect={false}
                    />
                  }
                  {isShownMultipleCampaignsSelectionPopup &&
                    <ItemsSelectionPopup<Campaign>
                      listGroupItemStyle={{padding: "3px 6px"}}
                      size="xl"
                      closePopupBtnDescription={"Chiudi"}
                      confirmBtnDescription={"Conferma"}
                      popupKey={"multiple-campaign-sel-popup"}
                      getItemView={(campaign) => getCampaignViewInPopup(campaign)}
                      onItemsSelected={(selectedCampaigns) => {
                        setFieldValue("campaigns", selectedCampaigns.map(campaign => campaign.id));
                        setIsShownMultipleCampaignsSelectionPopup(false);
                      }}
                      onClosePopup={() => setIsShownMultipleCampaignsSelectionPopup(false)}
                      isShown={true}
                      itemList={campaigns.filter(c => c.status === "ACTIVE" || c.status === "COMPLETED")}
                      title="Campagne da analizzare"
                      alreadyAssociatedItemList={
                        campaigns.filter(campaign => values.campaigns.includes(campaign.id))
                      }
                      isMultiSelect={true}
                      canSelectAllItems={true}
                      deselectAllItemsBtnDescription={"Deseleziona tutte"}
                      selectAllItemsBtnDescription={"Seleziona tutte"}
                    />
                  }
                  {values.campaigns.length > 0 &&
                    <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                      {campaigns
                        .filter(campaign => values.campaigns.includes(campaign.id))
                        .map(campaign => (
                          <div>
                            <span style={{fontWeight: 700}}>{`${campaign.name} `}</span>
                            ({campaign.advertiser})
                          </div>
                        ))}
                    </div>
                  }
                  {values.campaigns.length === 0 &&
                    "Nessuna campagna selezionata"
                  }
                  {errors["campaigns"] && touched["campaigns"] &&
                    <div style={{color: "red", fontSize: "0.875em", gridColumn: 2}}>
                      {errors["campaigns"]}
                    </div>
                  }
                  {values.use_future_pacing && !errors["campaigns"] && errors["objectives"] && touched["objectives"] &&
                    <div style={{color: "red", fontSize: "0.875em", gridColumn: 2}}>
                      La campagna selezionata non è valida, poiché non vi sono obiettivi impostati sui kpi di qualità
                    </div>
                  }
                </div>
              </div>
              {!values.use_future_pacing &&
                <div>
                  <label style={{marginBottom: "8px"}}>
                    Periodo di tempo di riferimento
                  </label>
                  <InputDateRangeField
                    startDateName="dates[0].starts_at"
                    endDateName="dates[0].ends_at"
                    isOutsideRange={() => false}
                    isDayBlocked={() => false}
                  />
                </div>
              }
              <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                <div style={{display: "flex", flexDirection: "row", gap: "15px", alignItems: "center"}}>
                  <label>Domini da analizzare:</label>
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        setFieldValue("domain_blacklist_str", null);
                        setFieldValue("domain_whitelist_str", null);
                        setFieldTouched("domain_blacklist_str", false);
                        setFieldTouched("domain_whitelist_str", false);
                      }}
                      className={`
                            ${styles.buttongroupBackground}
                            ${(values.domain_whitelist_str !== null || values.domain_blacklist_str !== null) ? styles.disabledButton : ""}
                          `}
                    >
                      Tutti i domini
                    </Button>
                    <Button
                      onClick={() => {
                        setFieldValue("domain_blacklist_str", null);
                        setFieldValue("domain_whitelist_str", (values.domain_blacklist_str === null || values.domain_whitelist_str == "") ? "" : values.domain_blacklist_str);
                        setFieldTouched("domain_blacklist_str", false);
                        setFieldTouched("domain_whitelist_str", false);
                      }}
                      className={`
                            ${styles.buttongroupBackground}
                            ${((values.domain_whitelist_str === null && values.domain_blacklist_str === null) ||
                        values.domain_blacklist_str !== null) ? styles.disabledButton : ""}
                          `}
                    >
                      Whitelist
                    </Button>
                    <Button
                      onClick={() => {
                        setFieldValue("domain_blacklist_str", (values.domain_whitelist_str === null || values.domain_whitelist_str == "") ? "" : values.domain_whitelist_str);
                        setFieldValue("domain_whitelist_str", null);
                        setFieldTouched("domain_blacklist_str", false);
                        setFieldTouched("domain_whitelist_str", false);
                      }}
                      className={`
                            ${styles.buttongroupBackground}
                            ${((values.domain_whitelist_str === null && values.domain_blacklist_str === null) ||
                        values.domain_whitelist_str !== null) ? styles.disabledButton : ""}
                          `}
                    >
                      Blacklist
                    </Button>
                  </ButtonGroup>
                </div>
                {(values.domain_blacklist_str !== null || values.domain_whitelist_str !== null) &&
                  <InputField
                    name={values.domain_blacklist_str !== null ? "domain_blacklist_str" : "domain_whitelist_str"}
                    type="text" placeholder={`Aggiungi la lista dei domini da ${values.domain_blacklist_str !== null ?
                    "escludere" : "analizzare"}, separati da uno o più spazi bianchi`}
                    showValid={true} isMultiLine={true} multiLineNumber={3}
                  />
                }
              </div>
              {!values.use_future_pacing &&
                <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
                  <label>Obiettivi sui KPI:</label>
                  <FormFieldsArray
                    getDefaultItem={() => ({kpi_id: null, target: null})}
                    addNewItemBtnLabel="Aggiungi KPI"
                    addNewItemBtnComponent={SmallButton}
                    name={"objectives"}
                    renderItem={({buildFieldName, removeItem, item}) => (
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                        alignItems: "start",
                        marginLeft: "5px"
                      }}>
                        <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "start"}}>
                          <label style={{marginTop: "11px"}}>KPI</label>
                          <InputSelectField
                            name={buildFieldName("kpi_id")} optionValueType="number"
                            showValid={false}
                            children={
                              <>
                                <option key='kpi-blankChoice' hidden value={null}>
                                  Seleziona il KPI
                                </option>
                                {kpis.data.kpis.filter(kpi =>
                                  !(values.objectives.map(value => value.kpi_id as unknown as number).includes(kpi.id)) ||
                                  kpi.id === item.kpi_id
                                ).map((kpi) => (
                                  <option key={buildFieldName(kpi.id)} value={kpi.id}>{kpi.name}</option>
                                ))
                                }
                              </>
                            }
                          />
                        </div>
                        <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "start"}}>
                          <label style={{marginTop: "11px"}}>{`Obiettivo${percentageKpiIdList.includes(item.kpi_id) ? ` %` : ""}`}</label>
                          <InputField
                            name={buildFieldName("target")} type="number" min={0} step={0.01} showValid={false}
                            placeholder={`Inserisci obiettivo ${percentageKpiIdList.includes(item.kpi_id) ? "%" : ""}`}
                          />
                        </div>
                        <SmallButton
                          style={{marginTop: "5px"}} variant="danger"
                          onClick={removeItem} disabled={values.objectives.length === 1}
                        >
                          Elimina KPI
                        </SmallButton>
                      </div>
                    )}
                  />
                </div>
              }
              <div>
                <label style={{marginBottom: "10px"}}>
                  Dimensioni aggiuntive di analisi
                </label>
                <div
                  style={{display: "grid", gridTemplateColumns: "auto 1fr", gap: "15px", alignItems: "center"}}>
                  <Button
                    style={{padding: "4px 5px 1px 1px"}}
                    onClick={() => setIsShownAttributesSelectionPopup(true)}
                  >
                    <FontAwesomeIcon style={{fontSize: "18px", paddingLeft: "5px"}} icon={faArrowUpRightFromSquare}/>
                  </Button>
                  {isShownAttributesSelectionPopup &&
                    <ItemsSelectionPopup<Attribute>
                      listGroupItemStyle={{padding: "3px 6px"}}
                      closePopupBtnDescription={"Chiudi"}
                      confirmBtnDescription={"Conferma"}
                      popupKey={"attributes-sel-popup"}
                      getItemView={(attribute) => <div>{attribute.name}</div>}
                      onItemsSelected={(selectedAttributes) => {
                        setFieldValue("attributes", selectedAttributes.map(attribute => attribute.id));
                        setIsShownAttributesSelectionPopup(false);
                      }}
                      onClosePopup={() => setIsShownAttributesSelectionPopup(false)}
                      isShown={true}
                      itemList={attributes.data}
                      title="Dimensioni aggiuntive di analisi"
                      alreadyAssociatedItemList={
                        attributes.data.filter(attribute => values.attributes.includes(attribute.id))
                      }
                      canSelectAllItems={true}
                      selectAllItemsBtnDescription="Seleziona tutte"
                      deselectAllItemsBtnDescription="Deseleziona tutte"
                      isMultiSelect={true}
                    />
                  }
                  {values.attributes?.length > 0 ?
                    attributes.data
                      .filter(attribute => values.attributes.includes(attribute.id))
                      .map(attribute => attribute.name)
                      .join(", ")
                    : "Nessuna dimensione di analisi selezionata"
                  }
                  {errors["attributes"] && touched["attributes"] &&
                    <div style={{color: "red", fontSize: "0.875em", gridColumn: 2}}>
                      {errors["attributes"]}
                    </div>
                  }
                </div>
              </div>
            </div>
            <FormikHelpers campaigns={campaigns}/>
          </FormikForm>
        )}
      </Formik>
    );
  }
);

function FormikHelpers(props: { campaigns: Campaign[] }) {
  const {campaigns} = props;
  const {values, validateForm, setFieldValue} = useFormikContext();
  const formValues = values as Level1FormikValues;

  useEffect(
    () => {
      validateForm();
    },
    [formValues.dates]
  );

  useEffect(
    () => {
      if (formValues.use_future_pacing && formValues.campaigns?.length > 0) {
        const selectedCampaign = campaigns.find(c => c.id === formValues.campaigns[0]);
        setFieldValue("dates", [{
          starts_at: selectedCampaign.dates[0].starts_at,
          ends_at: selectedCampaign.dates[selectedCampaign.dates.length - 1].ends_at
        }]);
        setFieldValue("objectives",
          selectedCampaign.campaign_kpis
            .map(kpi => ({kpi_id: kpi.kpi_id, target: kpi.objective}))
            .filter(kpi => kpi.target !== null && kpi.kpi_id !== selectedCampaign.delivered_kpi_id)
        );
      }
    }, [formValues.campaigns]
  );

  return <></>;
}

function getDefaultBulkAnalysisConfig() {
  return {
    use_future_pacing: false,
    campaigns: [],
    attributes: [],
    dates: [{
      starts_at: null,
      ends_at: null
    }],
    objectives: [{
      kpi_id: null,
      target: null
    }],
    domain_blacklist_str: null,
    domain_whitelist_str: null
  }
}

function getCampaignViewInPopup(campaign: Campaign): JSX.Element {
  return <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "15px"}}>
    <div>
      <span style={{fontWeight: 700}}>{`${campaign.name} `}</span>
      ({campaign.advertiser})
    </div>
    <div>
      {campaign.dates.map((dataRange, index) => (
        <div key={`campaign_${campaign.id}_dates_${index}`}>
          {`${moment(dataRange.starts_at).format("DD/MM/YYYY")} - ${moment(dataRange.ends_at).format("DD/MM/YYYY")}`}
        </div>
      ))}
    </div>
  </div>;
}

function isThereAtLeastOneDomain(domains: string): boolean {
  return domains !== undefined && domains !== null && !domains.match(/^\s+$/);
}

function getNonValidDomains(domains: string): string[] {
  return isThereAtLeastOneDomain(domains) ?
    domains.trim().split(/\s+/)
      .filter(d => !d.match(/^(www\.)?([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.)+[a-zA-Z\-]{2,}(\.[a-zA-Z\-]{2,})*$/))
    : []
}