import React, {useState} from 'react';
import {Button, Card} from "@themesberg/react-bootstrap";
import {Form, Formik} from "formik";
import styles from "./WidgetsSettings.module.scss";
import {InputField} from "../commons/forms/InputField";
import * as Yup from "yup";
import {storeWidgetsSettings, useGetWidgetsSettingsNoStaleData, WidgetsThresholdsRequestBody} from "../api/settings";
import {FeedbackAlert} from "../components/utils/FeedbackAlert";
import {buildRegexMatchPattern, useMutateManyV2} from "../api/swr-fetcher";
import {withMultiFetchSWR} from "../api/helpersSWR";
import InComponentPreloader from "../components/utils/InComponentPreloader";

const Schema = Yup.object({
  win_rate_threshold: Yup.number()
    .required("Inserire una percentuale valida")
    .typeError("Inserire una percentuale valida")
    .min(0, "Inserire una percentuale  valida (>=0%)"),
  kpi_pacing_threshold: Yup.number()
    .required("Inserire una percentuale valida")
    .typeError("Inserire una percentuale  valida")
    .min(0, "Inserire una percentuale  valida (>=0%)"),
  kpi_dispensing_threshold: Yup.number()
    .required("Inserire una percentuale valiida")
    .typeError("Inserire una percentuale  valida")
    .min(0, "Inserire una percentuale  valida (>=0%)")
});

export const WidgetsSettingsPage = withMultiFetchSWR(
  () => [{ useFetchFunc: useGetWidgetsSettingsNoStaleData }],
  ({endpointsResponse}) => {

  const widgets = endpointsResponse.data[0];

  const mutateMany = useMutateManyV2();
  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Header>
        <h5 className="mb-0">Soglie dei Widgets</h5>
      </Card.Header>
      <Card.Body>
        <Formik<WidgetsThresholdsRequestBody>
          initialValues={
            widgets?.data?.thresholds ? {
              kpi_dispensing_threshold: widgets.data.thresholds.kpi_dispensing * 100,
              win_rate_threshold: widgets.data.thresholds.winrate * 100,
              kpi_pacing_threshold: widgets.data.thresholds.kpi_pacing * 100,
            } : ({
              kpi_dispensing_threshold: "",
              win_rate_threshold: "",
              kpi_pacing_threshold: "",
            } as unknown as WidgetsThresholdsRequestBody)
          }
          validationSchema={Schema}
          onSubmit={(values, {setSubmitting}) => {
            const valuesToSubmit = {
              kpi_dispensing_threshold: values.kpi_dispensing_threshold / 100,
              win_rate_threshold: values.win_rate_threshold / 100,
              kpi_pacing_threshold: values.kpi_pacing_threshold / 100
            }
            storeWidgetsSettings(valuesToSubmit)
              .then(r => {
                setResponseStatus("SUCCESS");
                mutateMany(buildRegexMatchPattern("settings/thresholds"));
              })
              .catch(e => setResponseStatus("FAIL"))
              .finally(() => setSubmitting(false));
          }}
        >
          {({isSubmitting, dirty}) => (
            <Form className={styles.formContainer}>
              <InputField name="win_rate_threshold" label="Soglia di WinRate [%]" step={0.01}
                          placeholder="Inserisci la soglia winrate" type="number" style={{width: "400px"}}
              />
              <small className="d-block mt-1 mb-4">
                Il widget conteggia tutte le campagne il cui winRate medio è oltre la soglia prefissata.
              </small>
              <InputField name="kpi_pacing_threshold" label="Future Pacing KPI Qualitativi [%]" step={0.01}
                          placeholder="Inserisci percentuale di completamento" type="number" style={{width: "400px"}}
              />
              <small className="d-block mt-1 mb-4">
                Il widget conteggia tra tutte le campagne che hanno superato la percentuale di completamento prefissata,
                quelle che hanno il future pacing, di almeno un KPI qualitativo, al di sotto dei target di campagna.
              </small>
              <InputField name="kpi_dispensing_threshold" label="Future Pacing KPI di Erogazione [%]" step={0.01}
                          placeholder="Inserisci percentuale di completamento" type="number" style={{width: "400px"}}
              />
              <small className="d-block mt-1">
                Il widget conteggia tra tutte le campagne che hanno superato la percentuale di completamento prefissata,
                quelle che hanno il future pacing del kpi di erogazione al di sotto del target di campagna.
              </small>
              <Button className={styles.submitBtn} type="submit" variant="primary" disabled={!dirty || isSubmitting}>
                Conferma modifiche
              </Button>
              <FeedbackAlert status={responseStatus}/>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
},
  InComponentPreloader
);