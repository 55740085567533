import React from "react";

export const Routes = {
    // pages
    Login: { path: "/" },
    DashboardOverview: { path: "/dashboard/" },
    Users: {
        ViewAll: { path: "/users" },
        Create: { path: "/users/new" },
        Edit: {
            path: "/users/:userId/edit",
            buildPath: (userId) => `/users/${userId}/edit`
        },
        View: {
            path: "/users/:userId/view",
            buildPath: (userId) => `/users/${userId}/view`
        }
    },
    AgencyCustomers: { path: "/customers" },
    Mappings: {
        ViewAll: { path: "/mappings" },
        Create: { path: "/mappings/new" },
        Edit: {
            path: "/mappings/:mappingId/edit",
            buildPath: (mappingId) => `/mappings/${mappingId}/edit`
        },
        View: {
            path: "/mappings/:mappingId/view",
            buildPath: (mappingId) => `/mappings/${mappingId}/view`
        }
    },
    Campaigns: {
        ViewAll: { path: "/campaigns" },
        Edit: {
            path: "/campaigns/:campaignId/edit",
            buildPath: (campaignId) => `/campaigns/${campaignId}/edit`
        },
        Create: { path: "/campaigns/new/edit" },
        View: {
            path: "/campaigns/:campaignId/view",
            buildPath: (campaignId) => `/campaigns/${campaignId}/view`
        },
        Report: {
            path: "/campaigns/:campaignId/report",
            buildPath: (campaignId) => `/campaigns/${campaignId}/report`
        },
        KpiCorrection: {
            path: "/campaigns/:campaignId/kpiCorrection",
            buildPath: (campaignId) => `/campaigns/${campaignId}/kpiCorrection`
        },
    },
    CampaignsReportTemplates: {
        ViewAll: { path: "/campaigns-report-templates" },
        Create: { path: "/campaigns-report-templates/new" },
        Edit: {
            path: "/campaigns-report-templates/:templateId",
            buildPath: (templateId) => `/campaigns-report-templates/${templateId}`
        },
    },
    Dsp: {
        Website: {
            ViewAll: { path: "/dsp/websites" },
            Create: { path: "/dsp/websites/new" },
            Edit: {
                path: "/dsp/websites/:websiteId",
                buildPath: (websiteId) => `/dsp/websites/${websiteId}`
            }
        },
        Target: {
            ViewAll: { path: "/dsp/targets" },
            Create: { path: "/dsp/targets/new" },
            Edit: {
                path: "/dsp/targets/:targetId",
                buildPath: (targetId) => `/dsp/targets/${targetId}`
            }
        },
        PriceList: {
            ViewAll: { path: "/dsp/price-lists" },
            Create: { path: "/dsp/price-lists/new" },
            Edit: {
                path: "/dsp/price-lists/:priceListId",
                buildPath: (priceListId) => `/dsp/price-lists/${priceListId}`
            }
        },
        Format: {
            ViewAll: { path: "/dsp/formats" },
            Create: { path: "/dsp/formats/new" },
            Edit: {
                path: "/dsp/formats/:formatId",
                buildPath: (formatId) => `/dsp/formats/${formatId}`
            }
        },
        KpiTarget: {
            path: "/dsp/kpi-targets"
        },
        Campaign: {
            ViewAll: { path: "/dsp/campaigns" },
            Create: { path: "/dsp/campaigns/new" },
            View: {
                path: "/dsp/campaigns/:campaignId",
                buildPath: (campaignId) => `/dsp/campaigns/${campaignId}`
            }
        }
    },
    DomainAnalysis: {
        ViewAll: { path: "/domain-analysis" },
        Create: { path: "/domain-analysis/new" },
        Edit: {
            path: "/domain-analysis/:analysisId",
            buildPath: (analysisId) => `/domain-analysis/${analysisId}`
        },
    },
    UserProfile: { path: "/profile" },
    Settings: {
        EmailSources: {path: "/settings/emailSources"},
        Notifications: {path: "/settings/notifications"},
        Widgets: {path: "/settings/thresholds"},
    },
    NotFound: { path: "/404" }
};