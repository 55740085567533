import React, {useEffect, useState} from 'react';
import {Card} from "@themesberg/react-bootstrap";

import {DateRangePicker} from "react-dates/esm";
import "../../commons/forms/InputDateField.scss";
import styles from "./BriefChart.module.scss";
import {compareDates} from "../utils/DateUtils";
import moment from "moment";
import {Campaign, CampaignKPIData, CampaignRowsTimeDataSeries} from "../../api/campaigns";
import CampaignGraphAndTableTimeSeries from "./CampaignGraphAndTableTimeSeries";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {useGetKpis} from "../../api/kpis";

const CHART_COLORS = [
  {
    lineColor: 'rgb(20,96,182)',
    bgColor: 'rgb(20,96,182,0.35)'
  },
  {
    lineColor: 'rgb(135,190,231)',
    bgColor: 'rgb(135,190,231,0.35)'
  },
  {
    lineColor: 'rgb(112,122,187)',
    bgColor: 'rgb(112,122,187,0.35)'
  }
];

// The function below will return a string if alwaysAddTwoDecimals = true, a number otherwise.
// In case the result is used for more calculations, use alwaysAddTwoDecimals = false and eventually
// alwaysAddTwoDecimals = true just for visualizing the label.
export function roundNumberToTwoDecimals(num: number, alwaysAddTwoDecimals: boolean): string | number {
  const result = (Math.round((num * 1000) / 10) / 100);
  if (alwaysAddTwoDecimals)
    return result.toFixed(2);
  else
    return result;
}

interface Props {
  campaignKpis: Campaign["campaign_kpis"],
  rows: CampaignRowsTimeDataSeries["rows"],
  initialGraphKpiIdList: number[],
  showGraph?: boolean,
  campaignId: number
}

export default withMultiFetchSWR(
  (props: Props, urlParams: {}) => [{useFetchFunc: useGetKpis}],
  ({props, endpointsResponse}) => {
    const {campaignKpis, rows, initialGraphKpiIdList, showGraph = true, campaignId} = props;
    const [kpisResponse] = endpointsResponse.data;
    const kpis = kpisResponse.data.kpis;

    const [shownKpis, setShownKpis] = useState(campaignKpis.filter(kpi => initialGraphKpiIdList.includes(kpi.kpi_id)).slice(0, 3));
    const kpiIds = campaignKpis.map(k => k.kpi_id);
    const shownKpiIds = shownKpis.map(k => k.kpi_id);

    const [focusedInputDateRangeFilter, setFocusedInputDateRangeFilter] = useState(null);
    const [startDateRangeFilter, setStartDateRangeFilter] = useState<string | undefined>(undefined);
    const [endDateRangeFilter, setEndDateRangeFilter] = useState<string | undefined>(undefined);

    // If this page gets hit as first page, we need to wait kpis to be loaded
    if (kpis === null) {
      return null;
    }

    const kpiValues: CampaignKPIData = rows;

    useEffect(() => {
      if (!startDateRangeFilter || !endDateRangeFilter) {
        const sortedDates = [...kpiValues].map(row => moment(row.kpi_date)).sort(compareDates);
        if (!startDateRangeFilter) {
          if (sortedDates.length > 0)
            setStartDateRangeFilter(sortedDates[0].format('YYYY-MM-DD'));
          else
            setStartDateRangeFilter(moment().add(-1, "year").format('YYYY-MM-DD'));
        }
        if (!endDateRangeFilter) {
          if (sortedDates.length > 1)
            setEndDateRangeFilter(sortedDates[sortedDates.length - 1].format('YYYY-MM-DD'));
          else if (sortedDates.length === 1)
            setEndDateRangeFilter(sortedDates[0].clone().add(1, "year").format('YYYY-MM-DD'));
          else
            setEndDateRangeFilter(moment().add(1, "year").format('YYYY-MM-DD'));
        }
      }
    }, []);

    const [kpiValuesFiltered, setKpiValuesFiltered] = useState<CampaignKPIData>(kpiValues);

    useEffect(() => {
      setKpiValuesFiltered(([...kpiValues] as CampaignKPIData)
        .filter(row => {
          const date = moment(row.kpi_date).format('YYYY-MM-DD');
          return (!startDateRangeFilter || moment(startDateRangeFilter).isSameOrBefore(date))
            && (!endDateRangeFilter || moment(endDateRangeFilter).isSameOrAfter(date));
        }).sort((rowA, rowB) => compareDates(moment(rowB.kpi_date), moment(rowA.kpi_date))));
    }, [kpiValues, startDateRangeFilter, endDateRangeFilter])

    return (
      <div className="chart-container mt-2">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            flexWrap: "wrap",
            justifyContent: "center",
            margin: "20px 0"
          }}
        >
          {
            campaignKpis
              .filter(k => k.objective !== null)
              .map(k => {

                if (k.kpi_id in rows[0].values) {
                  const isPercentage = kpis.find(kpi => kpi.id === k.kpi_id).is_percentage;
                  return (
                    <Card key={k.kpi_id} border="light" className="shadow-sm" style={{width: "250px"}}>
                      <Card.Body className="text-center">
                        <h5 className="text-soft-green">{k.name}</h5>
                        <h6 className="d-flex justify-content-between mb-0">
                          Valore attuale:
                          <b>{isPercentage ?
                            `${roundNumberToTwoDecimals((k?.computed_value ?? k?.pacing * k.objective) * 100, true)}%` :
                            roundNumberToTwoDecimals(k?.computed_value ?? k?.pacing * k.objective, false)}
                          </b>
                        </h6>
                        <h6
                          className="d-flex justify-content-between mb-0">
                          Obiettivo:
                          <b>
                            {isPercentage ?
                              `${roundNumberToTwoDecimals(k.objective * 100, true)}%` :
                              roundNumberToTwoDecimals(k.objective, false)}
                          </b>
                        </h6>
                        <h6 className="d-flex justify-content-between mb-0">
                          Pacing:
                          <b>{roundNumberToTwoDecimals(k?.pacing * 100, true)}%</b>
                        </h6>
                        <h6 className="d-flex justify-content-between mb-0">
                          Future pacing:
                          <b>{roundNumberToTwoDecimals(k?.future_pacing * 100, true)}%</b>
                        </h6>
                      </Card.Body>
                    </Card>
                  );
                } else {
                  return (
                    <Card key={k.kpi_id} border="light" className="shadow-sm" style={{width: "250px"}}>
                      <Card.Body className="text-center">
                        <h5 className="text-soft-green">{k.name}</h5>
                        Nessun dato presente
                      </Card.Body>
                    </Card>
                  )
                }
              })
          }
        </div>

        <div className={`${styles.dateContainer} my-4 inputDateRangeFieldContainer`}>
          <div className={styles.dateRangeTitle}>Filtra i dati per periodo</div>
          <DateRangePicker
            small={true}
            startDate={moment(startDateRangeFilter, 'YYYY-MM-DD')}
            startDateId="start_date_filter_id"
            endDate={moment(endDateRangeFilter, 'YYYY-MM-DD')}
            endDateId="end_date_filter_id"
            onDatesChange={({startDate, endDate}) => {
              if (startDate)
                setStartDateRangeFilter(startDate.format('YYYY-MM-DD'));
              if (endDate)
                setEndDateRangeFilter(endDate.format('YYYY-MM-DD'));
            }}
            focusedInput={focusedInputDateRangeFilter}
            onFocusChange={setFocusedInputDateRangeFilter}
            isOutsideRange={() => false}
            startDatePlaceholderText="Data inizio"
            endDatePlaceholderText="Data fine"
            displayFormat="DD/MM/YYYY"
          />
        </div>

        <CampaignGraphAndTableTimeSeries
          showGraph={true}
          campaignKpis={campaignKpis}
          rows={kpiValuesFiltered}
          initialGraphKpiIdList={initialGraphKpiIdList}
          campaignPanelKey={`campaign-[${campaignId}]-visualize-all-data`}
        />
      </div>
    );
  }
);
