
import React, {useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../routes";


export default ({resetErrorBoundary}) => {

  //clear cache
  useEffect(() => {
    localStorage.setItem('refreshCache', '1');
  }, []);

  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <h1 className="text-primary mt-5">
                  Si è verificato un <span className="fw-bolder" style={{color: "red"}}>errore</span>
                </h1>
                <p className="lead my-4">
                  Oops! Si è presentato un errore inaspettato.
                </p>
                <Button as={Link} variant="primary" className="animate-hover" to={Routes.DashboardOverview.path} onClick={resetErrorBoundary}>
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  Torna alla homepage
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
