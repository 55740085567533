import useSWR from "swr";
import {axiosFetcher} from "./swr-fetcher";
import axios from "axios";
import {EndpointWrapper} from "./common";

export interface CampaignNotification {
  id: number,
  kpi_id: number,
  campaign_id: number,
  campaign: {
    id: number,
    name: string
  },
  kpi: {
    id: number,
    name: string
  }
}

export type CampaignNotifications = CampaignNotification[];

export function useGetNotifications() {
  return useSWR<EndpointWrapper<CampaignNotifications>>(`notifications`, axiosFetcher);
}

export const deleteNotification = (id) => {
  return axios.delete(`notifications/${id}`);
}