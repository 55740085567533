import axios, {AxiosRequestConfig} from 'axios';
import {mutate, useSWRConfig} from "swr";

export const axiosFetcher = (url) => axios.get(url).then(res => res.data);

export const axiosFetcherWithConfig = (url: string, config: AxiosRequestConfig) => axios.get(url, config).then(res => res.data);

export const fetcherWithParams = ({url, params}) => axios.get(url, {params}).then(res => res.data);


export const mutateMany = (cache, regex) => {
    const keys = cache.keys();
    const mutations = [];
    let regexp = new RegExp(regex);
    for (let key of keys) {
        if (regexp.test(key)) {
            mutations.push(mutate(key));
        }
    }
    return Promise.all(mutations);
}

export const useMutateMany = () => {
  const {mutate} = useSWRConfig();
  return (cache, regex) => {
      const keys = cache.keys();
      const mutations = [];
      let regexp = new RegExp(regex);
      for (let key of keys) {
          if (regexp.test(key)) {
              mutations.push(mutate(key));
          }
      }
      return Promise.all(mutations);
  }
}

export const useMutateManyV2 = () => {
    const {mutate, cache} = useSWRConfig();
    return (regex) => {
        // @ts-ignore
        const keys = cache.keys();
        const mutations = [];
        let regexp = new RegExp(regex);
        for (let key of keys) {
            if (regexp.test(key)) {
                mutations.push(mutate(key));
            }
        }
        return Promise.all(mutations);
    }
}

function regexEscape(str) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function buildRegexMatchPattern(pattern: string) {
    const escapedPattern = regexEscape(pattern);
    return new RegExp('^.*' + escapedPattern + '.*$');
}