import {Mapping, useGetMappings} from "../api/mappings";
import {UserRole} from "../api/common";
import {getAuthenticatedUser} from "./auth";

export function getKpisFromMappings(sources: Array<{ mapping_id: number}>, mappings: ReturnType<typeof useGetMappings>["data"]) {
  return sources
    .map(source => source.mapping_id)
    .map(mappingId => mappings.data.find(mapping => mapping.id == mappingId))
    .flatMap((mapping) => (mapping as Mapping).fields)
    .filter(mappingField => mappingField.type === "kpi")
    .filter((mappingField, index, arr) => arr.findIndex(mapF => mapF.id === mappingField.id) === index)
    .map(mappingField => ({
      kpi_name: mappingField.name,
      kpi_id: mappingField.id
    }))
}

export function assertUserRole(allowedRoles: UserRole[], message?: string) {
  const user = getAuthenticatedUser();
  if (!allowedRoles.includes(user.role)) {
    if(message)
      throw Error(message);
    else
      throw Error(message ?? `Error! User must have one of those roles: ${JSON.stringify(allowedRoles)}`);
  }

}

export function isAuthenticatedUserInternal() {
  const user = getAuthenticatedUser();
    return user.role === "sysadmin" || user.role === "admin" || user.role === "operator";
}