import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {InputField} from "../commons/forms/InputField";
import * as Yup from 'yup';
import {Button, Card} from "@themesberg/react-bootstrap";
import styles from "./UserProfilePage.module.scss";
import {getAuthenticatedUser} from "../commons/auth";
import {getUserRoleLabel, getUserStatusLabel} from "../commons/user-constants";
import {updateUser} from "../api/users";
import {FeedbackAlert} from "../components/utils/FeedbackAlert";

export const PasswordChangeFormSchema = Yup.object({
  password: Yup.string().required()
    .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z])).*$/, "La password deve contenere almeno 8 caratteri, di cui almeno 1 carattere minuscolo e 1 numero"),
  password_confirmation: Yup.string().required()
    .oneOf([ Yup.ref('password') ], 'Le due password non coincidono')
});

const PasswordChangeForm = ({userInfo, setShowPwdChangeSection, setResponseStatus}) => (
  <Formik
    initialValues={{ password: '', password_confirmation: ''}}
    validationSchema={PasswordChangeFormSchema}
    onSubmit={(values, { setSubmitting }) => {
      setShowPwdChangeSection(false);
      updateUser(userInfo.id, {
        email: userInfo.email,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        role: userInfo.role,
        status: userInfo.status,
        password: values.password,
        password_confirmation: values.password_confirmation,
      }).then(r => setResponseStatus("SUCCESS"))
        .catch(e => setResponseStatus("FAIL"))
        .finally(() => setSubmitting(false));
    }}
  >
    {({isSubmitting }) => (
      <Form className={styles.container}>
        <div className={styles.fieldContainer}>
          <InputField
            className={styles.field} name="password" label="Password" placeholder="Inserisci la nuova password"
            type="password" autoCompleteEnabled={false}
          />
          <InputField
            className={styles.field} name="password_confirmation" label="Conferma password"
            placeholder="Conferma la nuova password" type="password"
          />
        </div>
        <Button className={styles.button} type="submit" variant="primary" disabled={isSubmitting}>
          Conferma modifica
        </Button>
      </Form>
    )}
  </Formik>
);

export const UserProfilePage = () => {

  const [userInfo, setUserInfo] = useState(null);
  const [showPwdChangeSection, setShowPwdChangeSection] = useState(false);
  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

  useEffect(() => {
    setUserInfo(getAuthenticatedUser());
  }, []);

  if(userInfo === null)
    return <div>Caricamento in corso...</div>;

  const {id, email, first_name, last_name, role, status} = userInfo;

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Header>
        <h5 className="mb-0">Profilo</h5>
      </Card.Header>
      <Card.Body className={`${styles.container} ${styles.page}`}>
        <div>
          <span className={styles.label}>Nome: </span><span>{first_name}</span>
        </div>
        <div>
          <span className={styles.label}>Cognome: </span><span>{last_name}</span>
        </div>
        <div>
          <span className={styles.label}>E-mail: </span><span>{email}</span>
        </div>
        <div>
          <span className={styles.label}>Ruolo: </span><span>{getUserRoleLabel(role)}</span>
        </div>
        <div>
          <span className={styles.label}>Stato profilo: </span><span>{getUserStatusLabel(status)}</span>
        </div>
        {!showPwdChangeSection &&
          <Button
            className={`${styles.button} ${styles.newPwdButton}`}
            variant="primary"
            onClick={() => setShowPwdChangeSection(true)}
          >
            Cambia password
          </Button>
        }
        {showPwdChangeSection &&
          <PasswordChangeForm
            userInfo={userInfo} setShowPwdChangeSection={setShowPwdChangeSection} setResponseStatus={setResponseStatus}
          />
        }
        <FeedbackAlert
          status={responseStatus}
          successMessage="Il cambio password è avvenuto con successo!"
          failMessage="Qualcosa è andato storto! Il cambio password è fallito."
        />
      </Card.Body>
    </Card>
  );

}