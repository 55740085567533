import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "../common";
import axios, {AxiosResponse} from "axios";
import {useGetPaginatedItems} from "../helpersSWR";
import {assertUserRole} from "../../commons/Utils";
import {SWRInfiniteConfiguration} from "swr/infinite/dist/infinite/types";

export interface AgencyCustomerBase {
  name: string
}

export type AgencyCustomer = EntityWithId<AgencyCustomerBase>;
export type AgencyCustomersPage = EndpointWrapperPaged<{ customers: AgencyCustomer[] }>

export function useGetAllCustomersForAgency(agencyId: number | null | undefined, swrInfiniteConfig: SWRInfiniteConfiguration = {}) {
  return useGetPaginatedItems(
    (agencyId !== null && agencyId !== undefined) ? "dsp/agency-customers" : null,
    (p: AgencyCustomersPage) => p.data.customers,
    {agency_id: agencyId},
    false,
    50,
    swrInfiniteConfig
  )
}

function _useGetAllCustomersForLoggedAgency(noStaleData = false) {
  assertUserRole(["customer"], "Endpoint useGetAllCustomersForLoggedAgency() can only be called by a user with role 'customer'");

  return useGetPaginatedItems(
    "dsp/agency-customers",
    (p: AgencyCustomersPage) => p.data.customers, {},
    noStaleData
  )
}

/** this function can only be called when the logged user has role = "customer" */
export const useGetAllCustomersForLoggedAgency = () => _useGetAllCustomersForLoggedAgency(false);

/** this function can only be called when the logged user has role = "customer" */
export const useGetAllCustomersForLoggedAgencyNoStaleData = () => _useGetAllCustomersForLoggedAgency(true);

export type AgencyCustomerResponse = EndpointWrapper<{ agency_customer: AgencyCustomer }>

/** this function can only be called when the logged user has role = "customer" */
export const createNewCustomerForLoggedAgency = (data: AgencyCustomerBase) => {
  assertUserRole(["customer"], "Endpoint createNewCustomerForLoggedAgency(data) can only be called by a user with role 'customer'");
  return axios.post('dsp/agency-customers', data) as Promise<AxiosResponse<AgencyCustomerResponse>>;
};

/** this function can only be called when the logged user has role = "customer" */
export const updateCustomerForLoggedAgency= (customerId: number, data: AgencyCustomerBase) => {
  assertUserRole(["customer"], "Endpoint updateCustomerForLoggedAgency(customerId, data) can only be called by a user with role 'customer'");
  return axios.put(`dsp/agency-customers/${customerId}`, data) as Promise<AxiosResponse<AgencyCustomerResponse>>
}

/** this function can only be called when the logged user has role = "customer" */
export const deleteCustomerForLoggedAgency = (customerId: number) => {
  assertUserRole(["customer"], "Endpoint deleteCustomerForLoggedAgency(customerId) can only be called by a user with role 'customer'");
  return axios.delete(`dsp/agency-customers/${customerId}`);
}