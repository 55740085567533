import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {useGetWebCategory} from "../../../api/dsp/webCategories";
import {Button, CloseButton, ListGroup, ListGroupItem, Modal} from "@themesberg/react-bootstrap";
import React from "react";

interface PopupProps {
  categoryId: number,
  onClosePopup: () => void
}

export const CategoryWebsiteInfoPopup = withMultiFetchSWR(
  (props: PopupProps) => [
    {useFetchFunc: () => useGetWebCategory(props.categoryId),}
  ], ({props, endpointsResponse}) => {

    const {onClosePopup} = props;

    const [category] = endpointsResponse.data;

    return (
      <Modal centered show={true} size="lg">
        <Modal.Header
          style={{justifyContent: "space-between", alignItems: "center", paddingBottom: "0.7rem", paddingTop: "0.7rem"}}
        >
          <Modal.Title style={{fontSize: "20px"}}>{category.data.category.name}</Modal.Title>
          <CloseButton onClick={onClosePopup}/>
        </Modal.Header>
        <Modal.Body className="m-0 pt-2 px-3" style={{overflowY: "auto"}}>
          {category.data.category.domains.length === 0 && "Non è attualmente presente alcun sito web per questo settore"}
          <ListGroup>
            {category.data.category.domains.map(domainName => (
              <ul style={{marginBottom: 0}}>
                <li>
                  <ListGroupItem style={{padding: "3px 0 3px 0"}}>{domainName}</ListGroupItem>
                </li>
              </ul>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Button type="button" variant="outline-primary" onClick={onClosePopup}> Chiudi </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
);
