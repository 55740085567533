import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "../common";
import axios, {AxiosResponse} from "axios";
import {useGetPaginatedItems, useSWRNoStaleData} from "../helpersSWR";
import useSWR from "swr";
import {axiosFetcher} from "../swr-fetcher";

export interface WebListBase {
  name: string
  domains: string[]
}

export type WebList = EntityWithId<WebListBase>;
export type WebListPage = EndpointWrapperPaged<{ list: WebList[] }>

function _useGetAllWebLists(noStaleData = false) {
  return useGetPaginatedItems(
    "dsp/web-lists",
    (p: WebListPage) => p.data.list,
    {},
    noStaleData
  )
}
export const useGetAllWebLists = () => _useGetAllWebLists(false);
export const useGetAllWebListsNoStaleData = () => _useGetAllWebLists(true);

type WebListResponse = EndpointWrapper<{ list: WebList }>;

export function useGetWebList(webListId: number) {
  return useSWR<WebListResponse>(`/dsp/web-lists/${webListId}`, axiosFetcher);
}

export function useGetWebListNoStaleData(webListId: number) {
  return useSWRNoStaleData<WebListResponse>(`/dsp/web-lists/${webListId}`, axiosFetcher);
}

export const createWebList = (data: WebListBase) => {
  return axios.post('/dsp/web-lists', data) as Promise<AxiosResponse<WebListResponse>>;
};

export const updateWebList = (webListId: number, data: WebListBase) => {
  return axios.put(`/dsp/web-lists/${webListId}`, data) as Promise<AxiosResponse<WebListResponse>>;
}

export const deleteWebList = (webListId: number) => {
  return axios.delete(`/dsp/web-lists/${webListId}`);
};
