import {useGetAllCustomersForAgency, useGetAllCustomersForLoggedAgency} from "../../../api/dsp/agencyCustomers";
import React from "react";
import {isAuthenticatedUserInternal} from "../../../commons/Utils";
import {InternalUserOnly} from "../../../components/usersUtils/InternalUserOnly";
import {ExternalUserOnly} from "../../../components/usersUtils/ExternalUserOnly";

export const AgencyCustomerOptions = ({agencyId}) => {
  return <>
    <InternalUserOnly>
      <AgencyCustomerOptionsInternal agencyId={agencyId} />
    </InternalUserOnly>
    <ExternalUserOnly>
      <AgencyCustomerOptionsExternal />
    </ExternalUserOnly>
  </>;
};

export const AgencyCustomerOptionsInternal = ({agencyId}) => {
  let {data: customers} = useGetAllCustomersForAgency(agencyId, {revalidateOnFocus: false, revalidateOnMount: false, dedupingInterval: 3000});
  if (customers)
    return <>
      {customers.map(c => (<option key={c.id} value={c.id} >{c.name}</option>))}
    </>
  else
    return <></>;
};

export const AgencyCustomerOptionsExternal = () => {
  let {data: customers} = useGetAllCustomersForLoggedAgency();
  if (customers)
    return <>
      {customers.map(c => (<option key={c.id} value={c.id} >{c.name}</option>))}
    </>
  else
    return <></>;
};