import axios from 'axios';
import useSWR from "swr";
import {axiosFetcher} from "./swr-fetcher";
import {EndpointWrapper} from "./common";

export interface EndpointResponseKpi {
  kpis: Array<KPI>;
}

export interface KPI {
  id: number;
  name: string;
  description: string;
  is_percentage: boolean;
  formula: string;
}

export interface EndpointRequestKpi {
  kpi_id: number;
  fixes: Array<{
    type: "fixed" | "increment" | "starts_from" | "ends_at" | "percentage";
    value: number;
  }>,
  objective?: number;
}

export function useGetKpis() {
  return useSWR<EndpointWrapper<EndpointResponseKpi>>('/kpis', axiosFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: false
  });
}

export const updateKpi = (campaignId: number, kpi: EndpointRequestKpi) => {
  return axios.put(`/campaigns/${campaignId}/kpi`, kpi).then(res => res.data);
};
