import {getAuthenticatedUser} from "../../commons/auth";
import {isAuthenticatedUserInternal} from "../../commons/Utils";

export function ExternalUserOnly(props) {

  if (!isAuthenticatedUserInternal()) {
    return props.children;
  } else {
    return <></>;
  }

}