import React, {useState} from 'react';
import * as Yup from 'yup';
import {Button, Card} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik} from "formik";
import {useHistory} from "react-router-dom";
import {createUser, deleteUser, updateUser, useGetUserNoStaleData, UserUpdate} from "../../api/users";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {Routes} from "../../routes";
import {FeedbackAlert} from "../../components/utils/FeedbackAlert";
import {InputField} from "../../commons/forms/InputField";
import {InputSelectField} from "../../commons/forms/InputSelectField";
import {buildRegexMatchPattern, useMutateManyV2} from "../../api/swr-fetcher";
import {userRoles} from "./UserListPage";
import InComponentPreloader from "../../components/utils/InComponentPreloader";

const Schema = Yup.object({
  email: Yup.string().email().required(),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  role: Yup.string().required(),
  notes: Yup.string().nullable(),
  status: Yup.number().typeError("Campo obbligatorio").required()
});

export const UserFormPage = withMultiFetchSWR(
  (props: {mode: "CREATE" | "EDIT" | "VIEW"}, urlParams: {userId: string}) => [
    {
      useFetchFunc: () => useGetUserNoStaleData(parseInt(urlParams.userId)),
      shouldFetch: urlParams.userId !== undefined,
      elseData: {
        data: {
          user: getDefaultConfig()
        }
      }
    }
  ], ({props, urlParams, endpointsResponse}) => {

    const {mode} = props;

    const userId = urlParams.userId ? parseInt(urlParams.userId) : undefined;
    const userResponse = endpointsResponse.data[0];
    const user = userResponse.data.user;
    const initialValues = {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      role: user.role,
      notes: user.notes,
      status: user.status,
      activation_limit: user?.activation_limit
    }

    const mutateMany = useMutateManyV2();
    const history = useHistory();

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
          <h5
            className="mb-0">{mode==="CREATE" ? "Crea utente" : (mode === "EDIT" ?
            `Modifica utente ${user.first_name} ${user.first_name}` : `Profilo utente ${user.first_name} ${user.first_name}`)}
          </h5>
          {(mode === "EDIT" || mode === "VIEW") &&
            <Button
              type="button" variant="danger"
              onClick={() => deleteUser(user.id)
                .then(() => {
                  mutateMany(buildRegexMatchPattern("users"));
                  history.push(Routes.Users.ViewAll.path);
                })
              }
            >
              Elimina utente
            </Button>
          }
        </Card.Header>
        <Card.Body>
          <Formik<UserUpdate>
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={(values) => {
              let promise;
              if (mode === "CREATE")
                promise = createUser(values);
              else if(mode === "EDIT")
                promise = updateUser(userId, values);
              return promise
                .then(r => history.push(Routes.Users.ViewAll.path))
                .catch(e => setResponseStatus("FAIL"));
            }}
          >
            {({isSubmitting, errors, values, setFieldValue, setFieldTouched}) => (
              <FormikForm style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px"}}>
                <InputField
                  name="first_name" type="text" label="Nome" placeholder="Inserisci nome" disabled={mode === "VIEW"}
                />
                <InputField
                  name="last_name" type="text" label="Cognome" placeholder="Inserisci cognome" disabled={mode === "VIEW"}
                />
                <InputField
                  containerStyle={{gridColumn: "-1/1"}} name="email" type="email" label="Email"
                  disabled={mode === "VIEW"} placeholder="Inserisci indirizzo email" autoCompleteEnabled={false}
                />
                {mode === "CREATE" &&
                  <>
                    <InputField
                      name="password" type="password" label="Password" placeholder="Inserisci password"
                      autoCompleteEnabled={false}
                    />
                    <InputField
                    name="password_confirmation" type="password" label="Conferma password" placeholder="Conferma password"
                    />
                  </>
                }
                <InputSelectField
                  name="role" label="Ruolo" optionValueType="text" showValid={false} disabled={mode === "VIEW"}
                  children={
                    <>
                      <option key="customer" value="customer">{userRoles["customer"]}</option>
                      <option key="operator" value="operator">{userRoles["operator"]}</option>
                      <option key="admin" value="admin">{userRoles["admin"]}</option>
                      <option key="sysadmin" value="sysadmin">{userRoles["sysadmin"]}</option>
                      {/*<option key="agency" value="agency">{userRoles["agency"]}</option>*/}
                      {/*<option key="aggregators" value="aggregators">{userRoles["aggregators"]}</option>*/}
                    </>
                  }
                />
                <InputSelectField
                  name="status" label="Stato" optionValueType="number" showValid={false} disabled={mode === "VIEW"}
                  children={
                    <>
                      <option key="active" value={1}>Attivo</option>
                      <option key="inactive" value={0}>Disattivo</option>
                    </>
                  }
                />
                <InputField
                  name="notes" type="text" label="Note aggiuntive" placeholder="Inserisci note aggiuntive"
                  isMultiLine={true} containerStyle={{gridColumn: "-1/1"}} disabled={mode === "VIEW"} showValid={false}
                />
                {(mode === "CREATE" || mode === "EDIT") &&
                  <>
                    <div
                      style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "25px", gridColumn: "-1/1"}}
                    >
                      <Button type="button" variant="outline-primary" onClick={() => history.push(Routes.Users.ViewAll.path)}>
                        Annulla
                      </Button>
                      <Button type="submit" variant="primary" disabled={isSubmitting}>
                        {mode === "CREATE" ? "Crea utente" : "Conferma modifiche"}
                      </Button>
                    </div>
                    <FeedbackAlert status={responseStatus} style={{gridColumn: "-1/1"}}/>
                  </>
                }
                {mode === "VIEW" &&
                  <div
                    style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "30px", gridColumn: "-1/1"}}
                  >
                    <Button
                      type="button" variant="outline-primary" onClick={() => history.push(Routes.Users.ViewAll.path)}
                    >
                      Indietro
                    </Button>
                    <Button
                      type="button" variant="outline-primary" onClick={() => history.push(Routes.Users.Edit.buildPath(userId))}
                    >
                      Modifica profilo
                    </Button>
                  </div>
                }
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);

function getDefaultConfig() {
  return {
    email: null,
    first_name: null,
    last_name: null,
    password: null,
    password_confirmation: null,
    role: "customer",
    notes: null,
    status: 1
  }
}
