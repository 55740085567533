import {EndpointWrapper} from "./common";
import useSWR from "swr";
import {axiosFetcher} from "./swr-fetcher";

interface Widget {
  title: string,
  value: number,
  campaigns: Array<{
    campaign_id: number
  }>;
}

export function useGetHomepageWidgets() {
  return useSWR<EndpointWrapper<{ widgets: Widget[]}>>('/widgets', axiosFetcher);
}