import React from "react";
import styles from "./LineWrappedItem.module.scss"

interface Props {
  title?: string | JSX.Element;
  className?: string;
  children: JSX.Element;
}

export const LineWrappedItem = (props: Props) => (
  <div className={`${styles.container} ${props.className}`} style={{marginBottom: "10px"}}>
    <div className={styles.line}/>
    <div className={styles.mainSection}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      <div className="wrappedContent">
        {props.children}
      </div>
    </div>
  </div>
)