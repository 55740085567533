import React, {useRef, useState} from 'react';
import {Button, ButtonGroup, Card, Dropdown, Form} from "@themesberg/react-bootstrap";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH, faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useSWRConfig} from "swr";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {Routes} from "../../../routes";
import {SmartTable} from "../../../commons/table/SmartTable";
import {
  deleteDspPriceList,
  DspPriceList,
  DspPriceListBase,
  useGetAllDspPriceListsNoStaleData
} from "../../../api/dsp/priceLists";
import {GetResponseUser, useGetAllUsers} from "../../../api/users";
import {useGetAllWebListsNoStaleData, WebList} from "../../../api/dsp/webLists";
import {buildRegexMatchPattern, useMutateMany} from "../../../api/swr-fetcher";
import {CopyPriceListingsPopup} from "./CopyPriceListingsPopup";
import {getModeSelectorValue} from "./DspPriceListFormPage";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {useGetAllCustomersForAgency} from "../../../api/dsp/agencyCustomers";
import {InputField} from "../../../commons/forms/InputField";
import {RenderAgencyCustomerName} from "../utils/RenderAgencyCustomerName";
import {AgencyCustomerOptions} from "../utils/AgencyCustomerOptions";
import { TopLevelDropdownMenu } from '../../../components/utils/TopLevelDropdownMenu';
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

interface Filters {
  agency?: {
    value: number,
  },
  agencyCustomer?: {
    value: number,
  },
  type?: {
    value: "custom" | "category" | "list" | "all",
  }
  price?: {
    min?: number | "";
    max?: number | "";
  }
}



export const DspPriceListListPage = withMultiFetchSWR(
  (props: {}, urlParams: {}) => [
    { useFetchFunc: () => useGetAllDspPriceListsNoStaleData() },
    { useFetchFunc: () => useGetAllUsers() },
    { useFetchFunc: () => useGetAllWebListsNoStaleData() }
  ],
  ({props, endpointsResponse}) => {

    const {data: [priceListList, userList, webListList]} = endpointsResponse;

    const agencyList = userList.filter(user => user.role === "customer");

    const mutateMany = useMutateMany();
    const {cache} = useSWRConfig();
    const history = useHistory();

    const [showCopyPopup, setShowCopyPopup] = useState(false)

    const agencyCustomerIdToNameMap = useRef({}).current;

    return (
      <Card className="mb-4">
        <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h5 style={{display: "inline-block", margin: 0}}>Listini prezzi</h5>
          <div style={{display: "flex", flexDirection: "row", gap: "15px"}}>
            <Button onClick={() => setShowCopyPopup(true)}>
              Duplica listino prezzi
            </Button>
            <Button onClick={() => history.push(Routes.Dsp.PriceList.Create.path)}>
              Crea listino prezzi
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pt-3">
          <SmartTable<DspPriceList, Filters>
            pagination={true}
            tableKey="dsp-priceList-list-table"
            syncWithElementsProp={true}
            enableHiddenColumns={false}
            filter={{
              filterParamsInitialValue: {},
              applyFiltersFunction: (elements, filterParams) => elements
                .filter(el => !filterParams.agency || filterParams.agency.value === -1 || el.agency_id ===  filterParams.agency.value)
                .filter(el => !filterParams.agencyCustomer || filterParams.agencyCustomer.value === -1 || el.agency_customer_id ===  filterParams.agencyCustomer.value)
                .filter(el => !filterParams.type || filterParams.type.value === "all" || getModeSelectorValue(el) === filterParams.type.value)
                .filter(el => !filterParams.price || ( (filterParams.price.min === undefined || filterParams.price.min === "" || filterParams.price.min <= el.price) && (filterParams.price.max === undefined || filterParams.price.max === "" || filterParams.price.max >= el.price)) )
            }}
            getElementKey={(priceList) => priceList.id}
            sort={{
              defaultSortingColIndex: 1,
              defaultArrowVisible: true
            }}
            elements={priceListList ?? []}
            columns={[
              {
                title: "",
                cellRenderer: (priceList) =>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                    </span>
                    </Dropdown.Toggle>
                    <TopLevelDropdownMenu align="left">
                      <Dropdown.Item
                        onClick={() => history.push(Routes.Dsp.PriceList.Edit.buildPath(priceList.id))}>
                        <FontAwesomeIcon icon={faPencilAlt}/> Modifica
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => deleteDspPriceList(priceList.id).then(r =>  mutateMany(cache, buildRegexMatchPattern("dsp/pricings")))}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/> Elimina
                      </Dropdown.Item>
                    </TopLevelDropdownMenu>
                  </Dropdown>
              },
              {
                title: "Agenzia",
                cellRenderer: (priceList) => getAgencyNameFromUserListById(userList, priceList.agency_id),
                compareElementsByColumn:
                  (priceListA, priceListB) =>
                    getAgencyNameFromUserListById(userList, priceListA.agency_id).localeCompare(
                      getAgencyNameFromUserListById(userList, priceListB.agency_id)
                    ),
                columnFilter: {
                  key: "agency",
                  fieldsRenderer: ({getFieldName, setValues, values}) =>
                    <InputSelectField
                      name={getFieldName("value")}
                      optionValueType="number"
                      showValid={false}
                      style={{maxWidth: "500px"}}
                      onChange={(e) => {
                        const newVal = {...values};
                        if(newVal.agencyCustomer)
                          newVal.agencyCustomer.value = -1;
                        setValues({
                          ...newVal,
                          agency: {
                            value: parseInt(e.target.value)
                          }
                        });
                      }}
                    >
                      <>
                        <option key='blankChoice' value={-1} >TUTTI</option>
                        {agencyList.map(a => (
                          <option key={a.id} value={a.id} >{a.first_name} {a.last_name}</option>
                        ))}
                      </>
                    </InputSelectField>,
                  filterParamsFieldDefaultValue: () => ({
                    value: -1
                  })
                }
              },
              {
                title: "Cliente",
                cellRenderer: (priceList) =>
                  (priceList.agency_customer_id === null ? "TUTTI" :
                  <RenderAgencyCustomerName agencyId={priceList.agency_id} agencyCustomerId={priceList.agency_customer_id} onNameObtained={(name) => agencyCustomerIdToNameMap[priceList.agency_id] = name} />),
                compareElementsByColumn:
                  (pA, pB) =>
                    (pA.agency_customer_id === null ? "TUTTI" : (agencyCustomerIdToNameMap[pA.agency_customer_id] ?? ""))
                      .localeCompare(pB.agency_customer_id === null ? "TUTTI" : (agencyCustomerIdToNameMap[pB.agency_customer_id] ?? "")),
                columnFilter: {
                  key: "agencyCustomer",
                  fieldsRenderer: ({getFieldName, values}) => {
                    return (
                      <InputSelectField
                        name={getFieldName("value")}
                        optionValueType="number"
                        showValid={false}
                        style={{maxWidth: "500px"}}
                      >
                        <>
                          <option key='blankChoice' value={-1} >TUTTI</option>
                          {(values.agency.value === -1 ? agencyList.map(a => a.id) : [values.agency.value]).map(agencyId => (
                            <AgencyCustomerOptions agencyId={agencyId} />
                          ))}
                        </>
                      </InputSelectField>
                    );
                  },
                  filterParamsFieldDefaultValue: () => ({
                    value: -1
                  })
                }
              },
              {
                title: "Tipo listino",
                cellRenderer: (priceList) => getPriceListTypeDescription(priceList, webListList),
                compareElementsByColumn: (pA, pB) => getPriceListTypeDescription(pA, webListList).localeCompare(getPriceListTypeDescription(pB, webListList)),
                columnFilter: {
                  key: "type",
                  fieldsRenderer: ({getFieldName}) =>
                    <InputSelectField
                      name={getFieldName("value")}
                      optionValueType="text"
                      showValid={false}
                      style={{maxWidth: "250px"}}
                    >
                      <>
                        <option key='all' value="all" >TUTTI</option>
                        <option key='custom' value="custom">Domini custom</option>
                        <option key='category' value="category" >Settore</option>
                        <option key='list' value="list">Categoria</option>
                      </>
                    </InputSelectField>,
                  filterParamsFieldDefaultValue: () => ({
                    value: "all"
                  })
                }
              },
              {
                title: "Extra per audience custom",
                cellRenderer: (priceList) => `${priceList.extra_audience} %`,
                compareElementsByColumn:
                  (priceListA, priceListB) => priceListA.extra_audience - priceListB.extra_audience
              },
              {
                title: "Prezzo",
                cellRenderer: (priceList) => `${priceList.price} \u20ac`,
                compareElementsByColumn:
                  (priceListA, priceListB) => priceListA.price - priceListB.price,
                columnFilter: {
                  key: "price",
                  fieldsRenderer: ({getFieldName, setFieldValue, fieldValue}) =>
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                      <Form.Control
                        as="select"
                        className="input form-select"
                        value={getPriceFilterSelectedOption(fieldValue)}
                        onChange={(e) => {
                          const oldVal = getPriceFilterSelectedOption(fieldValue);
                          const newVal = e.target.value as "min" | "max" | "interval";
                          if (oldVal === "min" && newVal === "max") {
                            setFieldValue({max: fieldValue.min});
                          } else if (oldVal === "max" && newVal === "min") {
                            setFieldValue({min: fieldValue.max});
                          } else if (oldVal === "interval" && newVal === "min") {
                            setFieldValue({min: fieldValue.min});
                          } else if (oldVal === "interval" && newVal === "max") {
                            setFieldValue({max: fieldValue.max});
                          } else if (oldVal === "min" && newVal === "interval") {
                            setFieldValue({
                              min: fieldValue.min,
                              max: fieldValue.min
                            });
                          } else if (oldVal === "max" && newVal === "interval") {
                            setFieldValue({
                              min: fieldValue.max,
                              max: fieldValue.max
                            })
                          }
                        }
                        }
                        id="actual-budget-form-select"
                        style={{maxWidth: "300px"}}
                        children={
                          <>
                            <option key="blankChoice" hidden value="">Seleziona tipo di filtro sulla spesa</option>
                            <option key="min" value="min">Maggiore di...</option>
                            <option key="max" value="max">Minore di...</option>
                            <option key="interval" value="interval">Compresa tra...</option>
                          </>
                        }
                      />
                      {getPriceFilterSelectedOption(fieldValue) === "interval" &&
                          <>
                              <InputField name={getFieldName("min")} type="number" placeholder="Spesa minima [€]"/>
                              <InputField name={getFieldName("max")} type="number" placeholder="Spesa massima [€]"/>
                          </>
                      }
                      {getPriceFilterSelectedOption(fieldValue) === "min" &&
                          <InputField showValid={false} name={getFieldName("min")} type="number" placeholder="Spesa minima [€]"/>
                      }
                      {getPriceFilterSelectedOption(fieldValue) === "max" &&
                          <InputField showValid={false} name={getFieldName("max")} type="number" placeholder="Spesa massima [€]"/>
                      }
                    </div>,
                  filterParamsFieldDefaultValue: () => ({
                    min: ""
                  })
                }

              }
            ]}
          />
        </Card.Body>
        {showCopyPopup &&
            <CopyPriceListingsPopup onClosePopup={() => setShowCopyPopup(false)} />
        }
      </Card>
    );
  },
  InComponentPreloader
);

function getAgencyNameFromUserListById(userList: GetResponseUser[], agencyId: number): string {
  const agency = userList.find(user => user.id === agencyId);
  return `${agency.first_name} ${agency.last_name}`;
}

function getWebListNameById(webListList: WebList[], webListId: number): string {
  return webListList.find(webList => webList.id === webListId).name;
}

function getPriceListTypeDescription(priceList: DspPriceListBase, webListList: WebList[]): string {
  return priceList.price_per_category ? "Per settore" : (
    priceList.price_per_customs ? "Domini custom" : getWebListNameById(webListList, priceList.weblist_id));
}

function getPriceFilterSelectedOption(
  value: { min: number | undefined | "", max: number | undefined | "" }
): "interval" | "max" | "min" {
  return (value.min !== undefined && value.max !== undefined) ? "interval" : (value.min !== undefined ? "min" : "max");
}

