import {Button} from "@themesberg/react-bootstrap";
import {FieldArray, FieldArrayRenderProps, FieldHookConfig, useField} from "formik";
import * as React from "react";
import {ArrayRendererComponent, LineItemsArray} from "../../components/utils/LineItemsArray";

interface Props<T> {
  name: string;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  className?: string;
  getDefaultItem: () => any;
  getTitleForItem?: (args: ItemArgs<T>) => (string | JSX.Element);
  renderItem: (args: ItemArgs<T>) => JSX.Element;
  arrayRendererComponent?: ArrayRendererComponent;
  addNewItemBtnComponent?: React.ComponentType<any>;
  addNewItemBtnLabel?: string;
  disableAddNewItemBtn?: (items: T[]) => boolean;
  containerStyle?: any;
  hideAddNewItemBtn?: (items: T[]) => boolean;
  isListFlipped?: boolean;
}

interface ItemArgs<T> {
  item: T,
  buildFieldName: (fieldName?: string | number) => string,
  removeItem: () => void,
  index: number,
  arrayHelpers: FieldArrayRenderProps
}

export const FormFieldsArray = (props: Props<any>) => {
  const [field, meta, helpers] = useField(props as FieldHookConfig<any>);

  const {
    arrayRendererComponent: ArrayRenderer,
    addNewItemBtnComponent: ButtonRenderer,
    disableAddNewItemBtn,
    getDefaultItem,
    getTitleForItem,
    addNewItemBtnLabel,
    renderItem,
    name,
    title,
    subtitle,
    className,
    containerStyle = {},
    hideAddNewItemBtn,
    isListFlipped = true,
    ...otherProps
  } = props;

  function getItems(arrayHelpers) {
    const arr = (field.value as any[]).map((item, index) => {
      const itemArgs: ItemArgs<typeof item> = {
        item: item,
        index: index,
        removeItem: () => arrayHelpers.remove(index),
        buildFieldName: (fieldName) => (fieldName !== "" && fieldName !== undefined) ? `${name}[${index}].${fieldName}` : `${name}[${index}]`,
        arrayHelpers: arrayHelpers
      };
      return {
        key: `${name}[${index}]`,
        title: getTitleForItem(itemArgs),
        element: renderItem(itemArgs)
      }
    });
    if (isListFlipped)
      return arr.reverse();
    else
      return arr;
  }

  return (
    // @ts-ignore
    <FieldArray name={name} style={containerStyle}>
      {(arrayHelpers) => (
        // @ts-ignore
        <ArrayRenderer
          title={title}
          common={
            <>
              {subtitle !== "" &&
                <div style={{marginBottom: "15px"}}>
                  {subtitle}
                </div>
              }
              {!hideAddNewItemBtn(field.value) &&
                // @ts-ignore
                <ButtonRenderer
                  variant="outline-primary"
                  onClick={() => arrayHelpers.push(getDefaultItem())}
                  disabled={disableAddNewItemBtn(field.value)}
                >
                  {addNewItemBtnLabel}
                </ButtonRenderer>
              }
            </>
          }
          items={getItems(arrayHelpers)}
        />
      )}
    </FieldArray>
  );
};

FormFieldsArray.defaultProps = {
  addNewItemBtnLabel: "Aggiungi nuovo elemento",
  addNewItemBtnComponent: Button,
  arrayRendererComponent: LineItemsArray,
  getTitleForItem: () => null,
  disableAddNewItemBtn: () => false,
  hideAddNewItemBtn: () => false,
  subtitle: "",
  title: ""
}
