import {GetResponseUser, updateUser} from "../../api/users";
import {Form, Formik} from "formik";
import {InputField} from "../../commons/forms/InputField";
import {Button, CloseButton, Modal} from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import {PasswordChangeFormSchema} from "../UserProfilePage";
import {buildRegexMatchPattern, useMutateManyV2} from "../../api/swr-fetcher";
import {FeedbackAlert} from "../../components/utils/FeedbackAlert";
import {useHistory} from "react-router-dom";
import {Routes} from "../../routes";

interface Props {
  isShown: boolean,
  onClosePopup: () => any;
  userInfo: GetResponseUser
}

export function EditUserPasswordPopup(props: Props) {

  const {isShown, onClosePopup, userInfo} = props;
  const mutateMany = useMutateManyV2();
  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

  return (
    <Formik
      initialValues={{password: '', password_confirmation: ''}}
      validationSchema={PasswordChangeFormSchema}
      onSubmit={(values) => {
        return updateUser(userInfo.id, {
          email: userInfo.email,
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          role: userInfo.role,
          status: userInfo.status,
          password: values.password,
          password_confirmation: values.password_confirmation,
        }).then(r => mutateMany(buildRegexMatchPattern("users")))
          .then(r => onClosePopup())
          .catch(e => setResponseStatus("FAIL"));
      }}
    >
      {({values, isSubmitting, submitForm}) => (
        <Form>
          <Modal centered show={isShown}>
            <Modal.Header
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "0.7rem",
                paddingTop: "0.7rem"
              }}
            >
              <Modal.Title style={{fontSize: "20px"}}>
                Modifica password per {userInfo.first_name} {userInfo.last_name}
              </Modal.Title>
              <CloseButton onClick={onClosePopup}/>
            </Modal.Header>

            <Modal.Body className="m-0 pt-2 px-3" style={{display: "flex", flexDirection: "column", gap: "10px"}}>
              <InputField name="password" label="Password" placeholder="Inserisci la nuova password" type="password"/>
              <InputField
                name="password_confirmation" label="Conferma password" placeholder="Conferma la nuova password"
                type="password"
              />
            </Modal.Body>
            <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Button type="button" variant="outline-primary" onClick={onClosePopup}>
                  Annulla
                </Button>
                <Button onClick={submitForm} variant="primary" disabled={isSubmitting}>
                  Conferma modifica
                </Button>
              </div>
              <FeedbackAlert
                status={responseStatus}
                successMessage="Il cambio password è avvenuto con successo!"
                failMessage="Qualcosa è andato storto! Il cambio password è fallito."
              />
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
