import React, {useEffect, useState} from "react";
import {Button, Form, Row} from "@themesberg/react-bootstrap";
import * as Yup from "yup";
import {Formik} from "formik";
import {FormFieldsArray} from "../../commons/forms/FormFieldsArray";
import {InputField} from "../../commons/forms/InputField";
import {InputSelectField} from "../../commons/forms/InputSelectField";
import styles from "./Step2.module.scss";
import {InputCheckField} from "../../commons/forms/InputCheckField";
import {SmallButton} from "../../components/utils/buttons/SmallButton";
import {CampaignStep2Data} from "./CampaignConfigurationPage";
import {useGetMappings} from "../../api/mappings";
import {getKpisFromMappings} from "../../commons/Utils";
import {FeedbackAlert} from "../../components/utils/FeedbackAlert";
import {KPI} from "../../api/kpis";

const Schema = Yup.object({
  sources: Yup.array(
    Yup.object({
      name: Yup.string().required(),
      mapping_id: Yup.number().required().min(0, "Campo obbligatorio"),
      import_type: Yup.string().required().oneOf(["manual", "mail", "download"]),
      mapping_subject: Yup.string()
    })
  ).min(1, "Campo obbligatorio")
});

const getDefaultConfig = () => ({
  mapping_id: '',
  name: '',
  import_type: 'manual',
  mapping_subject: '',
  dates: null
});

interface Props {
  initialValues: CampaignStep2Data,
  deliveredKpiId: number,
  mappings: ReturnType<typeof useGetMappings>["data"],
  previous: (values: CampaignStep2Data) => void,
  next: (values: CampaignStep2Data) => void,
  kpis: KPI[]
}

export default (props: Props) => {
  const {initialValues, deliveredKpiId, mappings, previous, next, kpis} = props;

  const [nextBtnAlreadyPressed, setNextBtnAlreadyPressed] = useState(false);
  const [isDeliveredKpiInSource, setIsDeliveredKpiInSource] = useState(false);

  let initialValuesFormik;
  if (initialValues?.sources?.length > 0)
    initialValuesFormik = {
      sources: initialValues.sources.map(mapping => ({
        ...mapping,
        mapping_subject: mapping.import_type === 'manual' ? '' : mapping.mapping_subject
      }))
    }
  else
    initialValuesFormik = {sources: [getDefaultConfig()]};

  return (
    <Row className="mt-3">
      <Formik
        initialValues={initialValuesFormik}
        validationSchema={Schema}
        onSubmit={(values, {setSubmitting}) => {
          setNextBtnAlreadyPressed(true);
          if(!isDeliveredKpiInSource) {
            setSubmitting(false);
            return ;
          }
          const valuesToSubmit =
            {
              sources: values.sources.map(mapping => ({
                ...mapping,
                mapping_subject: mapping.import_type === 'manual' ? null : mapping.mapping_subject
              }))
            };
          next(valuesToSubmit as CampaignStep2Data);
        }}
      >
        {({values, submitForm, errors}) => (
          <Form>
            <Step2FormikHelper
              deliveredKpiId={deliveredKpiId}
              mappings={mappings}
              sources={values.sources}
              setIsDeliveredKpiInSource={setIsDeliveredKpiInSource}
            />
            <FormFieldsArray
              title="Mapping"
              subtitle="Associa uno o più mapping alla campagna"
              getDefaultItem={getDefaultConfig}
              addNewItemBtnLabel="Aggiungi mapping"
              addNewItemBtnComponent={SmallButton}
              name={"sources"}
              renderItem={({buildFieldName, removeItem, item}) => (
                <div className={styles.itemContainer}>
                  <InputField
                    name={buildFieldName("name")} type="text" label="Nome mapping" placeholder="Inserisci il nome"
                  />
                  <InputSelectField
                    name={buildFieldName("mapping_id")}
                    optionValueType="number"
                    label="Mapping selezionato"
                    children={
                      <>
                        <option key='blankChoice' hidden value={-1}>
                          Seleziona mapping
                        </option>
                        {
                          mappings.data
                            .map((mapping) => (
                              <option key={mapping.id} value={mapping.id}>{mapping.name}</option>
                            ))
                        }
                      </>
                    }
                  />
                  <InputCheckField
                    className={styles.switch} name={buildFieldName("import_type")} type="switch"
                    label="Attiva importazione automatica tramite mail"
                    getIsChecked={(value) => value === "mail"}
                    getValueToSetOnChange={(checked) => checked ? "mail" : "manual"}
                  />
                  {item.import_type === "mail" &&
                    <InputField
                      className={styles.mailSubject} name={buildFieldName("mapping_subject")}
                      type="text" label="Oggetto mail" placeholder="Inserisci l'oggetto della mail"
                    />
                  }
                  <SmallButton
                    className={styles.deleteItemBtn} style={{alignSelf: "flex-start"}}
                    variant="danger" onClick={removeItem} disabled={values.sources.length === 1}
                  >
                    Elimina mapping
                  </SmallButton>
                </div>
              )}
            />
            <div className={styles.footerSection}>
              <div className={styles.buttonsContainer}>
                <Button type="button" variant="primary" onClick={() => previous(values as CampaignStep2Data)}>
                  Indietro
                </Button>
                <Button type="button" variant="primary" onClick={submitForm}>
                  Continua
                </Button>
              </div>
              {!isDeliveredKpiInSource && nextBtnAlreadyPressed &&
                <FeedbackAlert
                status="FAIL"
                failMessage={
                  `Seleziona un mapping che includa il kpi di erogazione (${kpis.find(kpi => kpi.id === deliveredKpiId).name})`
                }
                />
              }
            </div>
          </Form>
        )}
      </Formik>
    </Row>
  );
}

interface Step2FormikHelperProps {
  sources: Array<{ mapping_id: number | string }>,
  mappings: ReturnType<typeof useGetMappings>["data"],
  deliveredKpiId: number,
  setIsDeliveredKpiInSource: (value: boolean) => void
}

function Step2FormikHelper(props: Step2FormikHelperProps) {

  const {sources, mappings, deliveredKpiId, setIsDeliveredKpiInSource} = props;

  useEffect(() => {
    const validSources = sources.filter(s => Number.isInteger(s.mapping_id)) as Array<{ mapping_id: number}>;
    setIsDeliveredKpiInSource(
      getKpisFromMappings(validSources, mappings).find(kpi => kpi.kpi_id === deliveredKpiId) !== undefined
    );
  }, [sources, deliveredKpiId]);

  return null;
}
