import React from 'react';
import useSWRInfinite from "swr/infinite";
import {axiosFetcher} from "../api/swr-fetcher";

export default ({Component, getKey, onComplete, ...rest}) => {
    const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite(
        getKey, axiosFetcher
    );

    if (!data) return "Loading...";

    if (error) {
        return <div>failed to load</div>;
    }

    return <Component data={data} {...rest} />;
};