import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useParams} from 'react-router-dom';
import {Routes} from '../routes';

import NotFoundPage from "./NotFound";
import DataFetchComponent from "../components/DataFetchComponent";
import CampaignBrief from "../components/Campaigns/CampaignBrief";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/utils/Preloader";
import {UserProfilePage} from "./UserProfilePage.tsx";
import {EmailSourcesPage} from "./EmailSourcesPage";
import {WidgetsSettingsPage} from "./WidgetsSettingsPage";
import {NotificationsSettingsPage} from "./NotificationsSettingsPage";
import {CampaignKpiCorrectionPage} from "./CampaignKpiCorrectionPage";
import {CampaignListPage} from "./CampaignListPage";
import {ReportTemplateFormPage} from "./ReportTemplateFormPage";
import {CampaignConfigurationPage} from "./CampaignConfigurationPage/CampaignConfigurationPage";
import {ReportTemplateListPage} from "./ReportTemplateListPage";
import {CampaignReportTemplateViewPage} from "./CampaignReportTemplateViewPage";
import {DspFormatListPage} from "./dsp/format/DspFormatListPage";
import {DspWebsiteListPage} from "./dsp/website/DspWebsiteListPage";
import {DspTargetListPage} from "./dsp/target/DspTargetListPage";
import {DspPriceListListPage} from "./dsp/price-list/DspPriceListListPage";
import {DspFormatFormPage} from "./dsp/format/DspFormatFormPage";
import {DspWebsiteFormPage} from "./dsp/website/DspWebsiteFormPage";
import {DspPriceListFormPage} from "./dsp/price-list/DspPriceListFormPage";
import {DspTargetFormPage} from "./dsp/target/DspTargetFormPage";
import {DspCampaignFormPage} from "./dsp/campaign/DspCampaignFormPage";
import {DspCampaignListPage} from "./dsp/campaign/DspCampaignListPage";
import {AgencyCustomerListPage} from "./AgencyCustomerListPage";
import {DashboardOverview} from "./dashboard/DashboardOverview";
import {UserFormPage} from "./users/UserFormPage";
import {UserListPage} from "./users/UserListPage";
import {MappingFormPage} from "./mappings/MappingFormPage";
import {MappingListPage} from "./mappings/MappingListPage";
import {DspKpiTargetFormPage} from "./dsp/kpi-target/DspKpiTargetFormPage";
import {DomainAnalysisPage} from "./domainAnalysis/DomainAnalysisPage";
import {ErrorBoundary} from "react-error-boundary";
import ErrorPage from "./ErrorPage";
import {LoginPage} from "./LoginPage";
import {DomainAnalysisListPage} from "./domainAnalysis/DomainAnalysisListPage";

const PublicRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={props => (<Component {...props} />)}/>
  );
};

const ProtectedRoute = ({component: Component, ...rest}) => {

  const params = useParams();

  const loggedIn = localStorage.getItem('token') !== null;
  if (!loggedIn) {
    return <Redirect to={Routes.Login.path}/>;
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Sidebar/>

        <main className="content">
          <Navbar/>
          <ErrorBoundary
            FallbackComponent={ErrorPage}
            resetKeys={[Component, props, params]}
          >
            <Component {...props} />
          </ErrorBoundary>
        </main>
      </>
    )}
    />
  );
};

const ProtectedRouteWithPreloading = ({component: Component, getKey, withParams = false, ...rest}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const loggedIn = localStorage.getItem('token') !== null;
  if (!loggedIn) {
    return <Redirect to={Routes.Login.path}/>;
  }

  const Inner = (props) => {
    let params = useParams();
    return (
      <>
        <Preloader show={!loaded}/>
        <Sidebar/>
        <main className="content">
          <Navbar/>
          <ErrorBoundary
            FallbackComponent={ErrorPage}
            resetKeys={[Component, props, params]}
          >
            <DataFetchComponent
              getKey={withParams ?
                (pageIndex, previousPageData) => getKey(params, pageIndex, previousPageData) :
                getKey
              }
              Component={Component}
              {...props}
            />
          </ErrorBoundary>
        </main>
      </>
    );
  };

  return (
    <Route {...rest} render={() => <Inner/>}/>
  );
};

export default () => {
  const history = useHistory();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      resetKeys={[history.location]}
    >
      <Switch>
        <PublicRoute exact path={Routes.Login.path} component={LoginPage}/>
        <ProtectedRoute exact path={Routes.DashboardOverview.path} component={DashboardOverview}/>

        <ProtectedRoute exact path={Routes.Campaigns.ViewAll.path} component={CampaignListPage}/>
        <ProtectedRoute exact path={Routes.Campaigns.Create.path} component={CampaignConfigurationPage}/>
        <ProtectedRoute exact path={Routes.Campaigns.Edit.path} component={CampaignConfigurationPage}/>
        <ProtectedRouteWithPreloading
          exact path={Routes.Campaigns.View.path}
          withParams={true}
          getKey={(params, pageIndex, previousPageData) => {
            const {campaignId} = params;
            if (pageIndex > 0 && previousPageData && (previousPageData.data.pages === 0 || (pageIndex + 1) < previousPageData.data.pages)) {
              return null;
            }
            return `/campaigns/${campaignId}/view/all?limit=50&page=${pageIndex}`;
          }}
          component={CampaignBrief}
        />

        <ProtectedRoute exact path={Routes.AgencyCustomers.path} component={AgencyCustomerListPage}/>

        <ProtectedRoute exact path={Routes.CampaignsReportTemplates.ViewAll.path} component={ReportTemplateListPage}/>
        <ProtectedRoute exact path={Routes.CampaignsReportTemplates.Create.path} component={ReportTemplateFormPage}/>
        <ProtectedRoute exact path={Routes.CampaignsReportTemplates.Edit.path} component={ReportTemplateFormPage}/>

        <ProtectedRoute exact path={Routes.Dsp.Format.ViewAll.path} component={DspFormatListPage}/>
        <ProtectedRoute exact path={Routes.Dsp.Format.Create.path} component={DspFormatFormPage}/>
        <ProtectedRoute exact path={Routes.Dsp.Format.Edit.path} component={DspFormatFormPage}/>

        <ProtectedRoute exact path={Routes.Dsp.Website.ViewAll.path} component={DspWebsiteListPage}/>
        <ProtectedRoute exact path={Routes.Dsp.Website.Create.path} component={DspWebsiteFormPage}/>
        <ProtectedRoute exact path={Routes.Dsp.Website.Edit.path} component={DspWebsiteFormPage}/>

        <ProtectedRoute exact path={Routes.Dsp.PriceList.ViewAll.path} component={DspPriceListListPage}/>
        <ProtectedRoute exact path={Routes.Dsp.PriceList.Create.path} component={DspPriceListFormPage}/>
        <ProtectedRoute exact path={Routes.Dsp.PriceList.Edit.path} component={DspPriceListFormPage}/>

        <ProtectedRoute exact path={Routes.Dsp.Target.ViewAll.path} component={DspTargetListPage}/>
        <ProtectedRoute exact path={Routes.Dsp.Target.Create.path} component={DspTargetFormPage}/>
        <ProtectedRoute exact path={Routes.Dsp.Target.Edit.path} component={DspTargetFormPage}/>

        <ProtectedRoute exact path={Routes.Dsp.KpiTarget.path} component={DspKpiTargetFormPage}/>

        <ProtectedRoute exact path={Routes.Dsp.Campaign.ViewAll.path} component={DspCampaignListPage}/>
        <ProtectedRoute exact path={Routes.Dsp.Campaign.Create.path} component={DspCampaignFormPage}/>
        <ProtectedRoute exact path={Routes.Dsp.Campaign.View.path} component={DspCampaignFormPage}/>

        <ProtectedRoute exact path={Routes.UserProfile.path} component={UserProfilePage}/>

        <ProtectedRoute exact path={Routes.Users.ViewAll.path} component={UserListPage} />
        <ProtectedRoute exact path={Routes.Users.Create.path} component={() => <UserFormPage mode="CREATE"/>}/>
        <ProtectedRoute exact path={Routes.Users.Edit.path} component={() => <UserFormPage mode="EDIT"/>}/>
        <ProtectedRoute exact path={Routes.Users.View.path} component={() => <UserFormPage mode="VIEW"/>}/>

        <ProtectedRoute exact path={Routes.Mappings.ViewAll.path} component={MappingListPage} />
        <ProtectedRoute exact path={Routes.Mappings.Create.path} component={() => <MappingFormPage mode="CREATE"/>}/>
        <ProtectedRoute exact path={Routes.Mappings.Edit.path} component={() => <MappingFormPage mode="EDIT"/>}/>
        <ProtectedRoute exact path={Routes.Mappings.View.path} component={() => <MappingFormPage mode="VIEW"/>}/>


        <ProtectedRoute exact path={Routes.Settings.Widgets.path} component={WidgetsSettingsPage}/>
        <ProtectedRoute exact path={Routes.Settings.EmailSources.path} component={EmailSourcesPage}/>
        <ProtectedRoute exact path={Routes.Settings.Notifications.path} component={NotificationsSettingsPage}/>

        <ProtectedRoute exact path={Routes.Campaigns.KpiCorrection.path} component={CampaignKpiCorrectionPage}/>

        <ProtectedRoute exact path={Routes.Campaigns.Report.path} component={CampaignReportTemplateViewPage}/>

        <ProtectedRoute exact path={Routes.DomainAnalysis.ViewAll.path} component={DomainAnalysisListPage}/>
        <ProtectedRoute exact path={Routes.DomainAnalysis.Create.path} component={DomainAnalysisPage}/>
        <ProtectedRoute exact path={Routes.DomainAnalysis.Edit.path} component={DomainAnalysisPage}/>

        <PublicRoute exact path={Routes.NotFound.path} component={NotFoundPage}/>

        <Redirect to={Routes.NotFound.path}/>
      </Switch>
    </ErrorBoundary>
  );
}
