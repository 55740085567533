import React from "react";
import {Button, Form, Row} from "@themesberg/react-bootstrap";
import * as Yup from "yup";
import {Formik} from "formik";
import {FormFieldsArray} from "../../commons/forms/FormFieldsArray";
import styles from "./Step3.module.scss";
import {InputSelectField} from "../../commons/forms/InputSelectField";
import {InputField} from "../../commons/forms/InputField";
import {SmallButton} from "../../components/utils/buttons/SmallButton";
import {CampaignStep3Data} from "./CampaignConfigurationPage";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {useGetKpis} from "../../api/kpis";
import {roundNumberToTwoDecimals} from "../../components/Campaigns/CampaignDataPanel";

const Schema = Yup.object({
  objectives: Yup.array(
    Yup.object({
      kpi_id: Yup.number().required().min(0, "Campo obbligatorio"),
      objective: Yup.number().required().typeError("Campo obbligatorio")
    })
  ).min(1)
});

interface Props {
  initialValues: CampaignStep3Data,
  kpisFromMappings: Array<{
    kpi_id: number,
    kpi_name: string
  }>,
  isNewCampaign: boolean;
  previous: (values: CampaignStep3Data) => void,
  next: (values: CampaignStep3Data) => void
}

export const Step3 = withMultiFetchSWR((props: Props, urlParams) => [
    {
      useFetchFunc: useGetKpis
    }
  ], ({props, endpointsResponse}) => {

    const {data: [kpis]} = endpointsResponse;
    const {initialValues, kpisFromMappings, previous, next, isNewCampaign} = props;

    const percentageKpiIdList = kpis.data.kpis.filter(kpi => kpi.is_percentage).map(kpi => kpi.id);

    return (
      <Row className="mt-3">
        <Formik
          initialValues={{
            objectives: initialValues.objectives.length > 0 ?
              initialValues.objectives.map(obj => percentageKpiIdList.includes(obj.kpi_id) ? ({
                ...obj,
                objective: roundNumberToTwoDecimals(obj.objective * 100, true)
              }) : ({
                ...obj,
                objective: roundNumberToTwoDecimals(obj.objective, false)
              }))
              : [{kpi_id: "", objective: ""}]
          }}
          validationSchema={Schema}
          onSubmit={values => {
            const valuesToSubmit = {
                objectives: values.objectives
                  .map(obj =>
                    ({
                      ...obj,
                      objective: parseFloat(obj.objective)
                    })
                  )
                  .map(
                    obj => percentageKpiIdList.includes(obj.kpi_id) ? ({...obj, objective: obj.objective / 100}) : obj
                  )
              }
            ;
            next(valuesToSubmit);
          }}
        >
          {({values, submitForm, errors, setFieldValue}) => (
            <Form>
              <FormFieldsArray
                title="Obiettivi KPI"
                subtitle="Imposta gli obiettivi per i KPI associati alla campagna."
                getDefaultItem={() => (
                  {
                    kpi_id: "",
                    objective: ""
                  }
                )}
                addNewItemBtnLabel="Aggiungi obiettivo"
                addNewItemBtnComponent={SmallButton}
                disableAddNewItemBtn={(objectives) => objectives.length === kpisFromMappings.length}
                name={"objectives"}
                renderItem={({buildFieldName, removeItem, item}) => (
                  <div className={styles.formContainer}>
                    <InputSelectField
                      name={buildFieldName("kpi_id")} label="KPI"
                      optionValueType="number"
                      children={
                        <>
                          <option key={buildFieldName('blankChoice')} hidden value={-1}>
                            Seleziona KPI
                          </option>
                          {kpisFromMappings.filter(kpi =>
                            !(values.objectives.map(value => value.kpi_id).includes(kpi.kpi_id)) ||
                            kpi.kpi_id === item.kpi_id
                          ).map((kpi) => (
                            <option key={buildFieldName(kpi.kpi_id)} value={kpi.kpi_id}>{kpi.kpi_name}</option>
                          ))}
                        </>
                      }
                    />
                    <InputField
                      name={buildFieldName("objective")} type="number"
                      label={`Obiettivo${percentageKpiIdList.includes(item.kpi_id) ? ` %` : ""}`}
                      placeholder={`Inserisci valore ${percentageKpiIdList.includes(item.kpi_id) ? "percentuale" : ""}`}
                      step={0.01}
                      onBlur={() => setFieldValue(
                        buildFieldName("objective"),
                        roundNumberToTwoDecimals(item.objective, percentageKpiIdList.includes(item.kpi_id))
                      )}
                    />
                    <SmallButton
                      className={styles.deleteItemBtn} style={{alignSelf: "flex-start"}} variant="danger"
                      onClick={removeItem} disabled={values.objectives.length === 1}
                    >
                      Elimina obiettivo
                    </SmallButton>
                  </div>
                )}
              />
              <div className={styles.buttonsSection}>
                <Button type="button" variant="primary" onClick={() => previous(values as CampaignStep3Data)}>
                  Indietro
                </Button>
                <Button type="button" variant="primary" onClick={submitForm}>
                  {isNewCampaign ? "Crea campagna" : "Conferma modifiche"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Row>
    );
  }
);