import {Button, CloseButton, Form, Modal} from "@themesberg/react-bootstrap";
import React, {useRef, useState} from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import {InputFileField} from "../../../commons/forms/InputFileField";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {uploadTargetsCsv} from "../../../api/dsp/targets";
import styles from "../website/WebsiteImportPopup.module.scss";
import {buildRegexMatchPattern, useMutateManyV2} from "../../../api/swr-fetcher";

interface Props {
  isShown: boolean;
  onClosePopup: () => void;
}

const Schema = Yup.object({
  file: Yup.string().required()
});

export function TargetImportPopup(props: Props) {

  const {isShown, onClosePopup} = props;
  const uploadRef = useRef(null);
  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "FAIL">("DEFAULT");

  const mutateMany = useMutateManyV2();

  return (
    <Modal centered show={isShown} size="lg">
      <Formik
        initialValues={{file: ''}}
        validationSchema={Schema}
        onSubmit={(values, {setSubmitting}) => {
          uploadTargetsCsv(uploadRef.current.files[0])
            .then(r => {
              mutateMany(buildRegexMatchPattern("dsp/objectives"));
              onClosePopup();
            })
            .catch(e => setResponseStatus("FAIL"))
            .finally(() => setSubmitting(false));
        }}
      >
        {({isSubmitting, submitForm, values, errors}) => (
          <>
            <Modal.Header
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "0.7rem",
                paddingTop: "0.7rem"
              }}
            >
              <Modal.Title style={{fontSize: "20px"}}>Importa audience da file csv</Modal.Title>
              <CloseButton onClick={onClosePopup}/>
            </Modal.Header>
            <Modal.Body className="m-0 pt-2 px-3">
              <Form style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                <InputFileField
                  name="file" accept=".csv" label="File da importare" withRef={uploadRef}
                />
                <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                  <div>Il file csv dovrà essere organizzato in campi delimitati dal separatore virgola (,).</div>
                  <div>L'intestazione deve contenere in ordine
                    <div className={styles.accentLabel}>Group</div>
                    (corrispondente al nome del gruppo) e
                    <div className={styles.accentLabel}>Name</div>
                    (corrispondente al nome dell'audience).
                  </div>
                  <div>
                    Ad esempio, un file valido potrebbe essere:
                    <div style={{display: "flex", flexDirection: "column", gap: "5px", padding: "5px 0 5px 30px"}}>
                      <small>
                        Group,Name
                      </small>
                      <small>
                        Nome_gruppo_1,Nome_audience_1
                      </small>
                      <small>
                        Nome_gruppo_2,Nome_audience_2
                      </small>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Button type="button" variant="outline-primary" onClick={onClosePopup}>Annulla</Button>
                <Button disabled={isSubmitting} onClick={submitForm}>Carica file</Button>
              </div>
              <FeedbackAlert
                status={responseStatus}
                failMessage="Ops! Qualcosa è andato storto, l'importazione non è riuscita."
              />
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}