import axios from "axios";
import {getAuthenticatedUser} from "./auth";

let beUrlFromConfig = undefined;
let mockUrlFromConfig = undefined;
let mockedUrlRegexListFromConfig = undefined;

try {
  beUrlFromConfig = BE_URL; // eslint-disable-line
} catch (e) {}

try {
  mockUrlFromConfig = MOCK_URL; // eslint-disable-line
} catch (e) {}

try {
  mockedUrlRegexListFromConfig = MOCKED_REQUEST_REGEX_LIST; // eslint-disable-line
} catch (e) {}

const defaultBeUrl = process.env.REACT_APP_BACKEND_URL;

const beUrl = beUrlFromConfig ?? defaultBeUrl;
export const mockUrl = mockUrlFromConfig ?? "http://adboard.mocks.smg-team.net";
export const mockedUrlRegexList = mockedUrlRegexListFromConfig ?? [];

export const localStorageCacheProvider = () => {
  // When initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem('app-cache', appCache);
    if (localStorage.getItem('refreshCache') === '1') {
      localStorage.removeItem('refreshCache');
      localStorage.removeItem('app-cache');
    }
  });

  // We still use the map for write & read for performance.
  return map
};

export const configApiDefaults = () => {
  // axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.withCredentials = false;

  // Set base url and handle mocked endpoints
  // Note: we can not set axios.defaults.baseURL globally, otherwise config.url in this adapter will already contain the
  // baseUrl. Maybe it's a bug with axios
  axios.interceptors.request.use(function (config) {
    const shouldUrlBeMocked = mockedUrlRegexList.some(r => r.test(config.url));
    if (shouldUrlBeMocked)
      config.baseURL = mockUrl;
    else
      config.baseURL = beUrl;
    return config;
  });

  const user = getAuthenticatedUser();
  if (user) {
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  axios.interceptors.response.use(undefined, error => {
    const response = error.response.data;
    if (!response.success && response.data.message === 'Unauthenticated.') {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('token_type');
      window.location.href = '/';
      return;
    }
    return Promise.reject(error);
  });
}

const default_user =  process.env.REACT_APP_DEFAULT_USR;
const default_pwd = process.env.REACT_APP_DEFAULT_PWD;

export function shouldUseDefaultCredentials() {
  return default_user && default_pwd
}

export function getDefaultCredentialPassword() {
  if(shouldUseDefaultCredentials())
    return default_pwd
  else
    return ''
}

export function getDefaultCredentialUser() {
  if(shouldUseDefaultCredentials())
    return default_user
  else
    return ''
}