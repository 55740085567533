import {Form} from "@themesberg/react-bootstrap";
import {useField} from "formik";
import * as React from "react";
import {RefObject, useRef} from "react";

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  style?: any
  accept: string;
  withRef?: ReturnType<typeof useRef>;
  disabled?: boolean;
}

export const InputFileField = (props: Props) => {
  const [field, meta, helpers] = useField(props);

  const {label, className, withRef, ...otherProps} = props;

  return (
    <Form.Group controlId={field.name} className={className}>
      {label && <Form.Label className="label">{label}</Form.Label>}
      <Form.Control
        {...otherProps}
        {...field}
        ref={withRef as RefObject<HTMLInputElement>}
        isValid={meta.error === undefined && meta.touched}
        isInvalid={meta.error !== undefined && meta.touched}
      />
      <Form.Control.Feedback className="error" type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );

};

InputFileField.defaultProps = {
  type: "file"
}