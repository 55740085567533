import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "../common";
import axios, {AxiosResponse} from "axios";
import {useGetPaginatedItems, useSWRNoStaleData} from "../helpersSWR";
import useSWR from "swr";
import {axiosFetcher} from "../swr-fetcher";

export interface DspTargetBase {
  group: string,
  name: string
}

export type DspTarget = EntityWithId<DspTargetBase>;
export type DspTargetsPage = EndpointWrapperPaged<{ objectives: DspTarget[] }>

export function useGetAllDspTargets() {
  return useGetPaginatedItems(
    "dsp/objectives",
    (p: DspTargetsPage) => p.data.objectives
  );
}

export function useGetAllDspTargetsNoStaleData() {
  return useGetPaginatedItems(
    "dsp/objectives",
    (p: DspTargetsPage) => p.data.objectives,
    {},
    true
  );
}

export type DspTargetResponse = EndpointWrapper<{ objective: DspTarget }>

export function useGetDspTarget(targetId: number) {
  return useSWR<DspTargetResponse>(`/dsp/objectives/${targetId}`, axiosFetcher);
}

export function useGetDspTargetNoStaleData(targetId: number) {
  return useSWRNoStaleData<DspTargetResponse>(`/dsp/objectives/${targetId}`, axiosFetcher);
}

export const createDspTarget = (data: DspTargetBase) => {
  return axios.post('/dsp/objectives', data) as Promise<AxiosResponse<DspTargetResponse>>;
};

export const updateDspTarget = (targetId: number, data: DspTargetBase) => {
  return axios.put(`/dsp/objectives/${targetId}`, data) as Promise<AxiosResponse<DspTargetResponse>>;
}

export const deleteDspTarget = (targetId: number) => {
  return axios.delete(`/dsp/objectives/${targetId}`);
};

export function uploadTargetsCsv(csv: File) {
  const formData = new FormData();
  formData.append('file', csv);
  return axios.post(`dsp/objectives/bulk`, formData) as Promise<AxiosResponse<EndpointWrapper<{ count: number }>>>;
}