import {Level3Data, Level3OutputRow} from "../../api/DomainAnalysis";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {useGetAttributes} from "../../api/attributes";
import {useGetKpis} from "../../api/kpis";
import {SmartTable} from "../../commons/table/SmartTable";
import React, {useState} from "react";
import {getCommonColumnsInOutputTable, getOutputAttributes, getOutputKpis} from "./utils";
import {Card, Form} from "@themesberg/react-bootstrap";
import styles from "./Level3Output.module.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

interface Props {
  outputData: Level3Data;
}

export const Level3Output = withMultiFetchSWR((props: Props) => [
    {useFetchFunc: useGetAttributes},
    {useFetchFunc: useGetKpis}
  ],
  ({props, endpointsResponse}) => {

    const {outputData} = props;
    const [{data: attributeList}, {data: kpiList}] = endpointsResponse.data;

    const [areIncludedVisible, setAreIncludedVisible] = useState<boolean>(true);
    const [areSuggestionsVisible, setAreSuggestionsVisible] = useState<boolean>(true);
    const [areRowsVisible, setAreRowsVisible] = useState<boolean>(true);

    const outputAttributes = getOutputAttributes(attributeList, outputData.rows);
    const outputKpis = getOutputKpis(kpiList.kpis, outputData.rows);

    if (outputData.rows.length === 0)
      return <div>L'analisi non ha prodotto risultati.</div>

    return (
      <div style={{display: "flex", flexDirection: "column", gap: "30px"}}>
        <Card className={styles.card}>
          <Card.Header className={styles.collapsableHeader}
                       onClick={() => setAreIncludedVisible(prevState => !prevState)}>
            <Form.Label className={`label mb-0`}>
              Combinazioni Aggiunte
            </Form.Label>
            {areIncludedVisible &&
              <FontAwesomeIcon className={styles.icon} icon={faAngleUp}/>
            }
            {!areIncludedVisible &&
              <FontAwesomeIcon className={styles.icon} icon={faAngleDown}/>
            }
          </Card.Header>
          {areIncludedVisible && outputData.included.length === 0 &&
            <div className={styles.labelInBody}>
              L'analisi non ha restituito combinazioni aggiunte.
            </div>
          }
          {areIncludedVisible && outputData.included.length !== 0 &&
            <SmartTable<Level3OutputRow, never>
              pagination={false}
              tableKey="level-3-output-table-included"
              syncWithElementsProp={true}
              enableHiddenColumns={false}
              getElementKey={(row) => `included-${row.domain}-${row.combination.map(c => c.value).join('-')}`}
              sort={{
                defaultSortingColIndex: 0,
                defaultArrowVisible: true
              }}
              elements={outputData.included ?? []}
              columns={getCommonColumnsInOutputTable(outputAttributes, outputKpis)}
            />
          }
        </Card>
        <Card>
          <Card.Header className={styles.collapsableHeader}
                       onClick={() => setAreSuggestionsVisible(prevState => !prevState)}>
            <Form.Label className={`label mb-0`}>
              Suggerimenti
            </Form.Label>
            {areSuggestionsVisible &&
              <FontAwesomeIcon className={styles.icon} icon={faAngleUp}/>
            }
            {!areSuggestionsVisible &&
              <FontAwesomeIcon className={styles.icon} icon={faAngleDown}/>
            }
          </Card.Header>
          {areSuggestionsVisible && outputData.suggestions.length === 0 &&
            <div className={styles.labelInBody}>
              L'analisi non ha restituito suggerimenti.
            </div>
          }
          {areSuggestionsVisible && outputData.suggestions.length !== 0 &&
            <SmartTable<Level3OutputRow, never>
              pagination={false}
              tableKey="level-3-output-table-suggestions"
              syncWithElementsProp={true}
              enableHiddenColumns={false}
              getElementKey={(row) => `suggestion-${row.domain}-${row.combination.map(c => c.attribute_id).join('-')}`}
              sort={{
                defaultSortingColIndex: 0,
                defaultArrowVisible: true
              }}
              elements={outputData.suggestions ?? []}
              columns={getCommonColumnsInOutputTable(outputAttributes, outputKpis)}
            />
          }
        </Card>
        <Card>
          <Card.Header className={styles.collapsableHeader} onClick={() => setAreRowsVisible(prevState => !prevState)}>
            <Form.Label className={`label mb-0`}>
              Combinazioni Valide
            </Form.Label>
            {areRowsVisible &&
              <FontAwesomeIcon className={styles.icon} icon={faAngleUp}/>
            }
            {!areRowsVisible &&
              <FontAwesomeIcon className={styles.icon} icon={faAngleDown}/>
            }
          </Card.Header>
          {areRowsVisible &&
            <SmartTable<Level3OutputRow, never>
              pagination={false}
              tableKey="level-3-output-table-rows"
              syncWithElementsProp={true}
              enableHiddenColumns={false}
              getElementKey={(row) => `row-${row.domain}-${row.combination.map(c => c.attribute_id).join('-')}`}
              sort={{
                defaultSortingColIndex: 0,
                defaultArrowVisible: true
              }}
              elements={outputData.rows ?? []}
              columns={getCommonColumnsInOutputTable(outputAttributes, outputKpis)}
            />
          }
        </Card>
      </div>
    );
  }
);
