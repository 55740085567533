import {SmartTable} from "../commons/table/SmartTable";
import React, {useEffect, useRef} from "react";
import {Campaign, PaginatedCampaignList} from "../api/campaigns";
import moment from "moment";
import {
  CampaignQueryParams,
  ExactEnumValueFilter,
  NumberInRangeFilter,
  SearchInValueFilter
} from "../api/CampaignsQueryParams";
import {InputField} from "../commons/forms/InputField";
import {Button, Card, Form} from "@themesberg/react-bootstrap";
import {InputSelectField} from "../commons/forms/InputSelectField";
import {ProgressBar} from "../components/utils/ProgressBar";
import {CampaignActions} from "../components/Campaigns/CampaignActions";
import {compareDates} from "../components/utils/DateUtils";
import {InputDateRangeField} from "../commons/forms/InputDateRangeField";
import {InputDateField} from "../commons/forms/InputDateField";
import useSWRInfinite from "swr/infinite";
import {fetcherWithParams} from "../api/swr-fetcher";
import {Routes} from "../routes";
import {useHistory} from "react-router-dom";
import {EndpointWrapper} from "../api/common";
import {InternalUserOnly} from "../components/usersUtils/InternalUserOnly";
import {ExternalUserOnly} from "../components/usersUtils/ExternalUserOnly";
import {faArrowUpRightFromSquare, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";

interface FilterParams {
  name?: SearchInValueFilter;
  advertiser?: SearchInValueFilter;
  actual_budget?: NumberInRangeFilter;
  dates?: {
    start_date?: moment.Moment;
    end_date?: moment.Moment;
  };
  status?: ExactEnumValueFilter<"COMPLETED" | "ACTIVE" | "PLANNED">;
}

export const CampaignListPage = () => {

  const history = useHistory();

  return (
    <Card className="mb-4">
      <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <h5 style={{display: "inline-block", margin: 0}}>Lista campagne</h5>
        <InternalUserOnly>
          <Button onClick={() => history.push(Routes.Campaigns.Create.path)}>Crea campagna</Button>
        </InternalUserOnly>
      </Card.Header>
      <Card.Body>
        <SmartTable<Campaign, FilterParams>
          getElementKey={campaign => campaign.id}
          pagination={true}
          tableKey="campaignListPageTable"
          sort={{
            defaultSortingColIndex: 1,
            defaultArrowVisible: true
          }}
          elements={[]}
          enableHiddenColumns={true}
          filter={{
            filterParamsInitialValue: {
              dates: {
                start_date: moment().add(-1, "months")
              }
            },
            filterParamsValidate: (values) => {
              const errors = {};

              if (values.name !== undefined) {
                if (values.name.searchFor === "")
                  errors["name"] = {searchFor: "Campo obbligatorio"};
              }

              if (values.advertiser !== undefined) {
                if (values.advertiser.searchFor === "")
                  errors["advertiser"] = {searchFor: "Campo obbligatorio"};
              }

              if (values.actual_budget !== undefined) {
                errors["actual_budget"] = {};
                if (values.actual_budget.max < values.actual_budget.min)
                  errors["actual_budget"]["max"] = "Intervallo non valido"
                if (values.actual_budget.min as unknown === "")
                  errors["actual_budget"]["min"] = "Campo obbligatorio"
                if (values.actual_budget.max as unknown === "")
                  errors["actual_budget"]["max"] = "Campo obbligatorio"
                if (Object.keys(errors["actual_budget"]).length === 0)
                  delete errors["actual_budget"]
              }

              if (values.status !== undefined) {
                if (
                  values.status.value !== "COMPLETED" &&
                  values.status.value !== "ACTIVE" &&
                  values.status.value !== "PLANNED"
                )
                  errors["status"] = {value: "Inserire stato valido"};
                if (values.status.value as unknown as string === "")
                  errors["status"] = {value: "Campo obbligatorio"};
              }

              return errors;
            },
            useApplyFiltersHook: (filterParams, setElements, setIsLoading, elements) => {
              const {
                data,
                error,
                isValidating,
                mutate,
                size,
                setSize
              } = useSWRInfinite<PaginatedCampaignList>(
                (pageIndex, previousPageData) => {
                  if (pageIndex > 0 && previousPageData && (
                    pageIndex >= previousPageData.data.total_pages || previousPageData.data.campaigns.length === 0
                  )) return null;
                  const limit = 50;
                  let newFilterParams;
                  if ("dates" in filterParams)
                    newFilterParams = {
                      ...filterParams,
                      dates: {
                        start_date: filterParams.dates.start_date?.format("YYYY-MM-DD"),
                        end_date: filterParams.dates.end_date?.format("YYYY-MM-DD")
                      }
                    };
                  else
                    newFilterParams = {
                      ...filterParams
                    };
                  let queryParams: CampaignQueryParams;
                  if (Object.keys(newFilterParams).length === 0)
                    queryParams = {
                      limit: limit,
                      page: pageIndex
                    };
                  else
                    queryParams = {
                      limit: limit,
                      page: pageIndex,
                      query: newFilterParams
                    };
                  return {
                    url: `/campaigns`,
                    params: queryParams
                  };
                }, fetcherWithParams,
                {
                  revalidateAll: true,
                  revalidateIfStale: true,
                  persistSize: true,
                  initialSize: 10000
                }
              )
              useEffect(() => {
                if (error) {
                  console.error(error);
                } else if (data) {
                  const newElements = data.flatMap(dataPage => dataPage.data.campaigns);
                  if(!_.isEqual(newElements, elements)) {
                    setElements(newElements);
                    setIsLoading(false);
                  }
                } else {
                  setIsLoading(true);
                }
              }, [data, error, elements])
            }
          }}
          columns={[
            {
              title: "",
              cellRenderer: (campaign, triggerElementsChanged) =>
                <>
                  <InternalUserOnly>
                    <CampaignActions
                      campaign={campaign}
                      triggerElementsChanged={triggerElementsChanged}
                    />
                  </InternalUserOnly>
                  <ExternalUserOnly>
                    <span>
                      <FontAwesomeIcon
                        style={{cursor: "pointer", fontSize: "16px"}}
                        icon={faArrowUpRightFromSquare}
                        onClick={() => history.push(Routes.Campaigns.Report.buildPath(campaign.id))}
                      />
                    </span>
                  </ExternalUserOnly>
                </>,
              columnCanBeHidden: false
            },
            {
              title: "Nome",
              cellRenderer: campaign => campaign.name,
              compareElementsByColumn: (campaignA, campaignB) => campaignA.name.localeCompare(campaignB.name),
              columnFilter: {
                key: "name",
                fieldsRenderer: ({getFieldName}) =>
                  <InputField
                    name={getFieldName("searchFor")} type="text" placeholder="Inserisci il nome della campagna..."
                    style={{maxWidth: "500px"}}
                  />,
                filterParamsFieldDefaultValue: () => ({
                  searchFor: ""
                })
              }
            },
            {
              title: "Advertiser",
              cellRenderer: campaign => campaign.advertiser,
              compareElementsByColumn: (campaignA, campaignB) => campaignA.advertiser.localeCompare(campaignB.advertiser),
              columnFilter: {
                key: "advertiser",
                fieldsRenderer: ({getFieldName}) =>
                  <InputField
                    name={getFieldName("searchFor")} type="text" placeholder="Inserisci l'advertiser..."
                    style={{maxWidth: "500px"}}
                  />,
                filterParamsFieldDefaultValue: () => ({
                  searchFor: ""
                })
              }
            },
            {
              title: "Periodo di attività",
              cellRenderer: campaign => (
                <div>
                  {campaign.dates.map((dataRange, index) => (
                    <div key={`campaign_${campaign.id}_dates_${index}`}>
                      {`${moment(dataRange.starts_at).format("DD/MM/YYYY")} - ${moment(dataRange.ends_at).format("DD/MM/YYYY")}`}
                    </div>
                  ))}
                </div>
              ),
              compareElementsByColumn: (campaignA, campaignB) => compareDates(
                [...campaignA.dates].map(dateInterval => moment(dateInterval.starts_at)).sort(compareDates)[0],
                [...campaignB.dates].map(dateInterval => moment(dateInterval.starts_at)).sort(compareDates)[0]
              ),
              columnFilter: {
                key: "dates",
                fieldsRenderer: ({getFieldName, setFieldValue, fieldValue}) =>
                  <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                    <Form.Control
                      as="select"
                      className="input form-select"
                      value={getDateRangeFilterSelectedOption(fieldValue)}
                      onChange={(e) => {
                        const oldVal = getDateRangeFilterSelectedOption(fieldValue);
                        const newVal = e.target.value as "interval" | "end" | "start";
                        if (oldVal === "start" && newVal === "end") {
                          setFieldValue({end_date: fieldValue.start_date});
                        } else if (oldVal === "end" && newVal === "start") {
                          setFieldValue({start_date: fieldValue.end_date});
                        } else if (oldVal === "interval" && newVal === "start") {
                          setFieldValue({start_date: fieldValue.start_date});
                        } else if (oldVal === "interval" && newVal === "end") {
                          setFieldValue({end_date: fieldValue.end_date});
                        } else if (oldVal === "start" && newVal === "interval") {
                          setFieldValue({
                            start_date: fieldValue.start_date,
                            end_date: fieldValue.start_date.clone().add(1, "month")
                          });
                        } else if (oldVal === "end" && newVal === "interval") {
                          setFieldValue({
                            start_date: fieldValue.end_date.clone().add(-1, "month"),
                            end_date: fieldValue.end_date
                          });
                        }
                      }
                      }
                      id="date-range-form-select"
                      style={{maxWidth: "300px"}}
                      children={
                        <>
                          <option key="blankChoice" hidden value="">Seleziona tipo di filtro sulla data</option>
                          <option key="interval" value="interval">Compreso tra...</option>
                          <option key="start" value="start">A partire dal...</option>
                          <option key="end" value="end">Entro il...</option>
                        </>
                      }
                    />
                    {getDateRangeFilterSelectedOption(fieldValue) === "interval" &&
                      <InputDateRangeField
                        startDateName={getFieldName("start_date")}
                        isDayBlocked={() => false}
                        isOutsideRange={() => false}
                        endDateName={getFieldName("end_date")}
                      />
                    }
                    {getDateRangeFilterSelectedOption(fieldValue) === "start" &&
                      <InputDateField
                        dateName={getFieldName("start_date")}
                        isDayBlocked={() => false}
                        isOutsideRange={() => false}
                      />
                    }
                    {getDateRangeFilterSelectedOption(fieldValue) === "end" &&
                      <InputDateField
                        dateName={getFieldName("end_date")}
                        isDayBlocked={() => false}
                        isOutsideRange={() => false}
                      />
                    }
                  </div>,
                filterParamsFieldDefaultValue: () => ({
                  start_date: moment(),
                  end_date: moment()
                })
              }
            },
            {
              title: "Stato",
              cellRenderer: campaign => {
                switch (campaign.status) {
                  case 'PLANNED':
                    return 'Non iniziata';
                  case 'ACTIVE':
                    return 'Attiva';
                  case 'COMPLETED':
                    return 'Finita';
                  default:
                    return '-';
                }
              },
              compareElementsByColumn: (campaignA, campaignB) =>
                campaignA?.status.localeCompare(campaignB?.status),
              columnFilter: {
                key: "status",
                fieldsRenderer: ({getFieldName}) =>
                  <InputSelectField name={getFieldName("value")} style={{maxWidth: "500px"}} children={
                    <>
                      <option key="blankChoice" hidden value="">Seleziona stato della campagna</option>
                      <option key="planned" value="PLANNED">Non iniziata</option>
                      <option key="active" value="ACTIVE">Attiva</option>
                      <option key="completed" value="COMPLETED">Finita</option>
                    </>
                  }/>,
                filterParamsFieldDefaultValue: () => ({
                  value: ""
                })
              }
            },
            {
              title: "Pacing erogazione",
              cellRenderer: campaign => {
                const delivered_kpi = campaign.campaign_kpis.find(k => k.kpi_id === campaign.delivered_kpi_id);
                if(delivered_kpi?.kpi_id)
                  return (
                    <ProgressBar
                      key={delivered_kpi.kpi_id}
                      label={delivered_kpi.name ?? ""}
                      value={delivered_kpi.pacing * 100 ?? 0}
                    />);
              },
              compareElementsByColumn: (campaignA, campaignB) =>
                campaignA.campaign_kpis.find(k => k.kpi_id === campaignA.delivered_kpi_id).pacing -
                campaignB.campaign_kpis.find(k => k.kpi_id === campaignB.delivered_kpi_id).pacing
            },
            {
              title: "Pacing qualità",
              cellRenderer: campaign =>
                <div style={{display: "flex", flexDirection: "column"}}>
                  {campaign.campaign_kpis
                    .filter(k => k.objective !== null &&
                      k.kpi_id !== campaign.campaign_kpis.find(k => k.kpi_id === campaign.delivered_kpi_id)?.kpi_id)
                    .slice(0,3)
                    .map(k => <ProgressBar key={k.kpi_id} label={k.name} value={k.pacing * 100 ?? 0}/>)
                  }
                </div>
            },
            {
              title: "Spesa",
              cellRenderer: campaign => `${campaign.actual_budget ? (campaign.actual_budget + " €") : "-"}`,
              compareElementsByColumn: (campaignA, campaignB) => campaignA.actual_budget - campaignB.actual_budget,
              columnFilter: {
                key: "actual_budget",
                fieldsRenderer: ({getFieldName, setFieldValue, fieldValue}) =>
                  <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                    <Form.Control
                      as="select"
                      className="input form-select"
                      value={getActualBudgetFilterSelectedOption(fieldValue)}
                      onChange={(e) => {
                        const oldVal = getActualBudgetFilterSelectedOption(fieldValue);
                        const newVal = e.target.value as "min" | "max" | "interval";
                        if (oldVal === "min" && newVal === "max") {
                          setFieldValue({max: fieldValue.min});
                        } else if (oldVal === "max" && newVal === "min") {
                          setFieldValue({min: fieldValue.max});
                        } else if (oldVal === "interval" && newVal === "min") {
                          setFieldValue({min: fieldValue.min});
                        } else if (oldVal === "interval" && newVal === "max") {
                          setFieldValue({max: fieldValue.max});
                        } else if (oldVal === "min" && newVal === "interval") {
                          setFieldValue({
                            min: fieldValue.min,
                            max: fieldValue.min
                          });
                        } else if (oldVal === "max" && newVal === "interval") {
                          setFieldValue({
                            min: fieldValue.max,
                            max: fieldValue.max
                          })
                        }
                      }
                      }
                      id="actual-budget-form-select"
                      style={{maxWidth: "300px"}}
                      children={
                        <>
                          <option key="blankChoice" hidden value="">Seleziona tipo di filtro sulla spesa</option>
                          <option key="min" value="min">Maggiore di...</option>
                          <option key="max" value="max">Minore di...</option>
                          <option key="interval" value="interval">Compresa tra...</option>
                        </>
                      }
                    />
                    {getActualBudgetFilterSelectedOption(fieldValue) === "interval" &&
                      <>
                        <InputField name={getFieldName("min")} type="number" placeholder="Spesa minima [€]"/>
                        <InputField name={getFieldName("max")} type="number" placeholder="Spesa massima [€]"/>
                      </>
                    }
                    {getActualBudgetFilterSelectedOption(fieldValue) === "min" &&
                      <InputField name={getFieldName("min")} type="number" placeholder="Spesa minima [€]"/>
                    }
                    {getActualBudgetFilterSelectedOption(fieldValue) === "max" &&
                      <InputField name={getFieldName("max")} type="number" placeholder="Spesa massima [€]"/>
                    }
                  </div>,
                filterParamsFieldDefaultValue: () => ({
                  min: ""
                })
              }
            }
          ]}
        />
      </Card.Body>
    </Card>
  );
};

function getDateRangeFilterSelectedOption(
  value: { start_date: object | undefined, end_date: object | undefined }
): "interval" | "start" | "end" {
  return (value.start_date !== undefined && value.end_date !== undefined) ? "interval" : (value.start_date !== undefined ? "start" : "end");
}

function getActualBudgetFilterSelectedOption(
  value: { min: number | undefined | "", max: number | undefined | "" }
): "interval" | "max" | "min" {
  return (value.min !== undefined && value.max !== undefined) ? "interval" : (value.min !== undefined ? "min" : "max");
}