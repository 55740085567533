import useSWR from "swr";
import {axiosFetcher} from "./swr-fetcher";
import {EndpointWrapper} from "./common";

export interface Attribute {
  id: number;
  name: string;
  description: string;
}

export function useGetAttributes() {
  return useSWR<EndpointWrapper<Attribute[]>>('/attributes', axiosFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: false
  });
}
