import {Form} from "@themesberg/react-bootstrap";
import {useField} from "formik";
import * as React from "react";

interface Props {
  name: string;
  label?: string;
  optionValueType?: "text" | "number";
  className?: string;
  children?: JSX.Element;
  style?: any;
  disabled?: boolean;
  containerStyle?: any;
  showValid?: boolean,
  onChange?: (e: any) => void;
  value?: any;
}

export const InputSelectField = (props: Props) => {
  const [field, meta, helpers] = useField(props);

  const {label, className, optionValueType, containerStyle = {}, showValid = true, ...otherProps} = props;

  return (
    <Form.Group id={`${field.name}-form-group`} className={className} style={containerStyle}>
      {label && <Form.Label className="label">{label}</Form.Label>}
      <Form.Control
        as="select"
        className="input form-select"
        {...field}
        onChange={(e) => {
          if(optionValueType === "number")
            helpers.setValue(parseInt(e.target.value));
          else
            helpers.setValue(e.target.value);
        }}
        {...otherProps}
        isValid={showValid && meta.error === undefined && meta.touched}
        isInvalid={meta.error !== undefined && meta.touched}
        id={`${field.name}-form-control`}
      />
      <Form.Control.Feedback className="error" type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );

};