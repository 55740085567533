import React, {useState} from 'react';
import * as Yup from 'yup';
import {Button, ButtonGroup, Card} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik} from "formik";
import {useHistory} from "react-router-dom";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {Routes} from "../../routes";
import {FeedbackAlert} from "../../components/utils/FeedbackAlert";
import {buildRegexMatchPattern, useMutateManyV2} from "../../api/swr-fetcher";
import {
  createMapping,
  deleteMapping,
  MappingCreate,
  updateMapping,
  useGetMappingsNoStaleData
} from "../../api/mappings";
import {InputField} from "../../commons/forms/InputField";
import styles from "./MappingFormPage.module.scss";
import {InputSelectField} from "../../commons/forms/InputSelectField";
import {SmallButton} from "../../components/utils/buttons/SmallButton";
import {FormFieldsArray} from "../../commons/forms/FormFieldsArray";
import {useGetKpis} from "../../api/kpis";
import {useGetAttributes} from "../../api/attributes";
import InComponentPreloader from "../../components/utils/InComponentPreloader";

const Schema = Yup.object({
  template: Yup.string().required(),
  file_type: Yup.string().oneOf(["xls", "csv"]).required(),
  header_row: Yup.number().typeError("Campo obbligatorio").when('file_type', {
    is: 'xls',
    then: (schema) => schema.required()
  }),
  data_starts_at_row: Yup.number().typeError("Campo obbligatorio").when('file_type', {
    is: 'xls',
    then: (schema) => schema.required()
  }),
  sheet_name: Yup.string().when('file_type', {
    is: 'xls',
    then: (schema) => schema.required()
  }),
  delimiter: Yup.string().when('file_type', {
    is: 'csv',
    then: (schema) => schema.required()
  }),
  field_mappings: Yup.array(Yup.object({
    id: Yup.string().required(),
    column_name: Yup.string().required()
  })).min(1).required()
});

export const MappingFormPage = withMultiFetchSWR(
  (props: { mode: "CREATE" | "EDIT" | "VIEW" }, urlParams: { mappingId: string }) => [
    {useFetchFunc: useGetKpis},
    {useFetchFunc: useGetAttributes},
    {
      useFetchFunc: () => useGetMappingsNoStaleData(),
      shouldFetch: props.mode !== "CREATE"
    }
  ], ({props, urlParams, endpointsResponse}) => {

    const {mode} = props;

    const {data: [kpiList, attributeList]} = endpointsResponse;

    const mappingId = urlParams.mappingId ? parseInt(urlParams.mappingId) : undefined;

    let mapping;
    if (mode === "CREATE") {
      mapping = getDefaultConfig();
    } else {
      const mappingResponse = endpointsResponse.data[2].data.find(m => m.id === mappingId);
      mapping = {
        template: mappingResponse.name,
        field_mappings: mappingResponse.fields.map(field => ({
          ...field,
          column_name: field.name
        })),
        file_type: mappingResponse.file_type,
        ...mappingResponse.extra
      }
    }

    const mutateMany = useMutateManyV2();
    const history = useHistory();

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header
          style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
          <h5
            className="mb-0">{mode === "CREATE" ? "Crea mapping" : (mode === "EDIT" ?
            `Modifica mapping ${mapping.template}` : `Mapping ${mapping.template}`)}
          </h5>
          {(mode === "EDIT" || mode === "VIEW") &&
            <Button
              type="button" variant="danger"
              onClick={() => deleteMapping(mappingId)
                .then(() => {
                  mutateMany(buildRegexMatchPattern("imports/mappings"));
                  history.push(Routes.Mappings.ViewAll.path);
                })
              }
            >
              Elimina mapping
            </Button>
          }
        </Card.Header>
        <Card.Body>
          <Formik<MappingCreate>
            initialValues={mapping}
            validationSchema={Schema}
            onSubmit={(values) => {
              if (mode === "CREATE")
                createMapping(values)
                  .then(r => history.push(Routes.Mappings.ViewAll.path))
                  .catch(e => setResponseStatus("FAIL"));
              else if (mode === "EDIT")
                updateMapping(mappingId, values)
                  .then(r => history.push(Routes.Mappings.View.buildPath(mappingId)))
                  .catch(e => setResponseStatus("FAIL"));
            }}
          >
            {({isSubmitting, errors, values, setFieldValue, setFieldTouched, submitForm}) => (
              <FormikForm style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px"}}>
                <InputField
                  name="template" type="text" label="Nome mapping" placeholder="Inserisci nome mapping"
                  disabled={mode === "VIEW"} containerStyle={{gridColumn: "-1/1"}}
                />
                <ButtonGroup style={{gridColumn: "-1/1", marginTop: "30px"}}>
                  <Button
                    disabled={mode === "VIEW"}
                    onClick={() => {
                      setFieldValue("file_type", "xls");
                      setFieldValue("header_row", 1);
                      setFieldValue("data_starts_at_row", 2);
                      setFieldValue("sheet_name", null);
                      setFieldValue("delimiter", undefined);
                      setFieldTouched("delimiter", false);
                    }}
                    className={`${styles.buttongroupBackground} ${values.file_type === "xls" ? "" : styles.disabledButton}`}
                    style={{width: "50%"}}
                  >
                    File XLS
                  </Button>
                  <Button
                    disabled={mode === "VIEW"}
                    onClick={() => {
                      setFieldValue("file_type", "csv");
                      setFieldValue("delimiter", null);
                      setFieldValue("header_row", undefined);
                      setFieldValue("data_starts_at_row", undefined);
                      setFieldValue("sheet_name", undefined);
                      setFieldTouched("header_row", false);
                      setFieldTouched("data_starts_at_row", false);
                      setFieldTouched("sheet_name", false);
                    }}
                    className={`${styles.buttongroupBackground} ${values.file_type === "csv" ? "" : styles.disabledButton}`}
                    style={{width: "50%"}}
                  >
                    File CSV
                  </Button>
                </ButtonGroup>
                {values.file_type === "xls" &&
                  <>
                    <InputField
                      name="sheet_name" type="text" label="Nome del foglio XLS" containerStyle={{gridColumn: "-1/1"}}
                      placeholder="Inserisci nome del foglio" disabled={mode === "VIEW"}
                    />
                    <InputField
                      name="header_row" type="number" label="Numero riga dell'intestazione"
                      placeholder="Inserisci numero di riga dell'intestazione" disabled={mode === "VIEW"}
                    />
                    <InputField
                      name="data_starts_at_row" type="number" label="Numero riga di inizio dei dati"
                      placeholder="Inserisci numero di riga dei dati" disabled={mode === "VIEW"}
                    />
                  </>
                }
                {values.file_type === "csv" &&
                  <InputField
                    name="delimiter" type="text" label="Delimitatore del file CSV"
                    placeholder="Inserisci delimitatore" disabled={mode === "VIEW"}
                  />
                }
                <div style={{gridColumn: "-1/1", marginTop: "30px"}}>
                  <FormFieldsArray
                    title={<h5 style={{marginBottom: "8px"}}>Mapping colonne</h5>}
                    getDefaultItem={getDefaultFieldMapping}
                    addNewItemBtnLabel="Aggiungi colonna"
                    hideAddNewItemBtn={() => mode === "VIEW"}
                    name={"field_mappings"}
                    renderItem={({buildFieldName, removeItem, item}) => (
                      <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                        <div
                          style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px", alignItems: "start"}}>
                          <InputField
                            type="text" name={buildFieldName("column_name")} disabled={mode === "VIEW"}
                            showValid={false}
                            label="Nome colonna da mappare" placeholder="Inserisci nome colonna"
                          />
                          <InputSelectField
                            name={buildFieldName("id")} showValid={false} disabled={mode === "VIEW"}
                            value={item.type === "kpi" ? `kpi-${item.id}` : `attribute-${item.id}`}
                            label="Campo relativo alla colonna" optionValueType="number"
                            onChange={(e) => {
                              const selectedType = e.target.value.split("-", 1)[0];
                              setFieldValue(buildFieldName("id"), e.target.value.split("-", 2)[1]);
                              setFieldValue(buildFieldName("type"), selectedType);
                            }}
                            children={<>
                              <option key='blankChoice' hidden value={null}>Seleziona campo</option>
                              {kpiList.data.kpis.map(kpi => (
                                <option key={`kpi-${kpi.id}`} value={`kpi-${kpi.id}`}>
                                  {`KPI: ${kpi.name}`}
                                </option>
                              ))}
                              {attributeList.data.map(attribute => (
                                <option key={`attribute-${attribute.id}`} value={`attribute-${attribute.id}`}>
                                  {`ATTRIBUTO: ${attribute.name}`}
                                </option>
                              ))}
                            </>}
                          />
                        </div>
                        {(mode === "EDIT" || mode === "CREATE") &&
                          <SmallButton
                            style={{alignSelf: "flex-start"}} variant="danger" onClick={removeItem}
                            disabled={values.field_mappings.length < 2}
                          >
                            Elimina colonna
                          </SmallButton>
                        }
                      </div>
                    )}
                  />
                </div>
                {(mode === "CREATE" || mode === "EDIT") &&
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "25px",
                        gridColumn: "-1/1"
                      }}
                    >
                      <Button type="button" variant="outline-primary"
                              onClick={() => history.push(Routes.Mappings.ViewAll.path)}>
                        Annulla
                      </Button>
                      <Button onClick={submitForm} variant="primary" disabled={isSubmitting}>
                        {mode === "CREATE" ? "Crea mapping" : "Conferma modifiche"}
                      </Button>
                    </div>
                    <FeedbackAlert status={responseStatus} style={{gridColumn: "-1/1"}}/>
                  </>
                }
                {mode === "VIEW" &&
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "30px",
                      gridColumn: "-1/1"
                    }}
                  >
                    <Button
                      type="button" variant="outline-primary" onClick={() => history.push(Routes.Mappings.ViewAll.path)}
                    >
                      Indietro
                    </Button>
                    <Button
                      type="button" variant="outline-primary"
                      onClick={() => history.push(Routes.Mappings.Edit.buildPath(mappingId))}
                    >
                      Modifica mapping
                    </Button>
                  </div>
                }
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);

function getDefaultConfig() {
  return {
    template: null,
    field_mappings: [getDefaultFieldMapping()],
    file_type: "xls",
    header_row: 1,
    data_starts_at_row: 2,
    sheet_name: null
  }
}

function getDefaultFieldMapping() {
  return {
    id: null,
    type: null,
    column_name: null
  }
}
