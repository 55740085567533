
import 'dotenv/config';
import moment from 'moment';
import 'moment/locale/it';

import React from 'react';
import ReactDOM from 'react-dom';
import {SWRConfig} from 'swr';
import {HashRouter} from "react-router-dom";
import { SWRDevTools } from "swr-devtools";

import "./ChartJSConfig";
import "./lang/FormValidation";

// core styles
import "./scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/App";
import ScrollToTop from "./components/ScrollToTop";

import {configApiDefaults, localStorageCacheProvider} from './commons/api-config';
import AppWithContext from "./context/Context";

moment.locale('it');
configApiDefaults();

ReactDOM.render(
    <HashRouter basename="" hashType="noslash">
        <ScrollToTop/>
        <SWRConfig value={{
            refreshInterval: 20 * 60 * 1000,          // 20 min
            focusThrottleInterval: 10 * 60 * 1000,    // 10 min
            loadingTimeout: 10000,
            retryDelay: 2500,
            revalidateIfStale: true,
            // revalidateIfStaleFor: 21600,
            provider: localStorageCacheProvider
        }}>
          <SWRDevTools>
            <AppWithContext>
              <HomePage/>
            </AppWithContext>
          </SWRDevTools>
        </SWRConfig>
    </HashRouter>,
    document.getElementById("root")
);
