import React, {useState} from 'react';
import * as Yup from "yup";
import {Button, Card} from "@themesberg/react-bootstrap";
import {Form, Formik} from "formik";
import {FormFieldsArray} from "../commons/forms/FormFieldsArray";
import {InputField} from "../commons/forms/InputField";
import {InputSelectField} from "../commons/forms/InputSelectField";
import styles from "./NotificationsSettingsPage.module.scss";
import {SmallButton} from "../components/utils/buttons/SmallButton";
import {FeedbackAlert} from "../components/utils/FeedbackAlert";
import {
  NotificationThresholds,
  storeNotificationsSettings,
  useGetNotificationsSettingsNoStaleData
} from "../api/settings";
import {useGetKpis} from "../api/kpis";
import {withMultiFetchSWR} from "../api/helpersSWR";
import {buildRegexMatchPattern, useMutateManyV2} from "../api/swr-fetcher";
import InComponentPreloader from "../components/utils/InComponentPreloader";

const Schema = Yup.object({
  thresholds: Yup.array(
    Yup.object().shape({
      kpi_id: Yup.number().required().min(0),
      value: Yup.number().required().min(0, "La soglia inserita deve essere un numero > 0")
    })
  ).required()
});

export const NotificationsSettingsPage = withMultiFetchSWR((props, urlParams) => [
  {
    useFetchFunc: useGetNotificationsSettingsNoStaleData
  },
  {
    useFetchFunc: useGetKpis
  }
], ({urlParams, endpointsResponse}) => {

  const {data: [notifications, kpis]} = endpointsResponse;

  const percentageKpiIdList = kpis.data.kpis.filter(kpi => kpi.is_percentage).map(kpi => kpi.id);

  const mutateMany = useMutateManyV2();

  let initialValues;
  if (notifications?.data?.thresholds === undefined || notifications.data?.thresholds.length === 0)
    initialValues = {
      thresholds:
        [
          {
            kpi_id: "",
            operator: ">",
            value: ""
          }
        ]
    };
  else
    initialValues = {
      thresholds: notifications.data.thresholds.map(
        obj => percentageKpiIdList.includes(obj.kpi_id) ? ({...obj, value: obj.value * 100}) : obj
      )
    };

  const kpiList = kpis.data.kpis.map(kpi => ({kpi_id: kpi.id, kpi_name: kpi.name}));

  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Header>
        <h5 className="mb-0">Notifiche</h5>
      </Card.Header>
      <Card.Body>
        <Formik<NotificationThresholds>
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={(values, {setSubmitting}) => {
            const valuesToSubmit = {
              thresholds: values.thresholds.map(
                obj => percentageKpiIdList.includes(obj.kpi_id) ? ({...obj, value: obj.value / 100}) : obj
              )
            };
            storeNotificationsSettings(valuesToSubmit)
              .then(r => mutateMany(buildRegexMatchPattern('notifications/thresholds')))
              .then(() => setResponseStatus("SUCCESS"))
              .catch(e => setResponseStatus("FAIL"))
              .finally(() => setSubmitting(false));
          }}
        >
          {({isSubmitting, dirty, values}) => (
            <Form className={styles.formContainer}>
              <FormFieldsArray
                title="Soglie KPI"
                subtitle="Inserisci per ciascun KPI interessato la soglia che, se superata, genererà una notifica globale."
                getDefaultItem={() => ({kpi_id: "", operator: ">", value: ""})}
                addNewItemBtnLabel="Aggiungi notifica"
                disableAddNewItemBtn={(thresholds) => thresholds.length === kpiList.length}
                name={"thresholds"}
                renderItem={({buildFieldName, removeItem, item}) => (
                  <div className={styles.itemContainer}>
                    <InputSelectField
                      name={buildFieldName("kpi_id")} label="KPI" optionValueType="number"
                      children={
                        <>
                          <option key='blankChoice' hidden value={-1}>
                            Seleziona il KPI
                          </option>
                          {kpiList.filter(kpi =>
                            !(values.thresholds.map(value => value.kpi_id as unknown as number).includes(kpi.kpi_id)) ||
                            kpi.kpi_id === item.kpi_id
                          ).map((kpi) => (
                            <option key={buildFieldName(kpi.kpi_id)} value={kpi.kpi_id}>{kpi.kpi_name}</option>
                          ))
                          }
                        </>
                      }
                    />
                    <InputField
                      name={buildFieldName("value")}
                      type="number"
                      label={`Soglia${percentageKpiIdList.includes(item.kpi_id) ? ` %` : ""} (da superare)`}
                      placeholder={`Inserisci valore ${percentageKpiIdList.includes(item.kpi_id) ? "percentuale" : ""}`}
                    />
                    <SmallButton
                      className={styles.deleteItemBtn} style={{alignSelf: "flex-start"}} variant="danger"
                      onClick={removeItem}
                    >
                      Elimina notifica
                    </SmallButton>
                  </div>
                )}
              />
              <Button className={styles.submitBtn} type="submit" disabled={!dirty || isSubmitting}>
                Conferma modifiche
              </Button>
              <FeedbackAlert status={responseStatus}/>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}, InComponentPreloader);