import {useHistory} from "react-router-dom";
import {Routes} from "../../../routes";
import {Button, ButtonGroup, Card, Dropdown, Form} from "@themesberg/react-bootstrap";
import {SmartTable} from "../../../commons/table/SmartTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faEdit, faEllipsisH, faEye} from "@fortawesome/free-solid-svg-icons";
import React, {useRef, useState} from "react";
import {CampaignDsp, DSPStatus, getNameForDspStatus, useGetAllCampaignDsp} from "../../../api/dsp/campaignDsp";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import moment from "moment/moment";
import {compareDates} from "../../../components/utils/DateUtils";
import {useGetAllUsers} from "../../../api/users";
import {ExternalUserOnly} from "../../../components/usersUtils/ExternalUserOnly";
import {isAuthenticatedUserInternal} from "../../../commons/Utils";
import {getAuthenticatedUser} from "../../../commons/auth";
import {RenderAgencyCustomerName} from "../utils/RenderAgencyCustomerName";
import {InputField} from "../../../commons/forms/InputField";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {AgencyCustomerOptions} from "../utils/AgencyCustomerOptions";
import {InputDateRangeField} from "../../../commons/forms/InputDateRangeField";
import {InputDateField} from "../../../commons/forms/InputDateField";
import {InternalUserOnly} from "../../../components/usersUtils/InternalUserOnly";
import {EditDspStatusPopup} from "./EditDspStatusPopup";
import {TopLevelDropdownMenu} from "../../../components/utils/TopLevelDropdownMenu";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

interface Filters {
  name?: {
    searchFor: string,
  },
  agency?: {
    value: number,
  },
  agencyCustomer?: {
    value: number,
  },
  dates?: {
    start_date?: moment.Moment;
    end_date?: moment.Moment;
  },
  budget?: {
    min?: number | "";
    max?: number | "";
  },
  status?: {
    value: DSPStatus | -1,
  },
}

export const DspCampaignListPage = withMultiFetchSWR(
  () => [
    {useFetchFunc: () => useGetAllCampaignDsp()},
    {
      useFetchFunc: () => useGetAllUsers(),
      shouldFetch: isAuthenticatedUserInternal(),
      elseData: [getAuthenticatedUser()]
    }
  ],
  ({endpointsResponse}) => {
    const history = useHistory();

    const [dspList, userList] = endpointsResponse.data;
    const agencyList = userList.filter(user => user.role === "customer");
    const [isEditDSPStatusPopupShown, setIsEditDSPStatusPopupShown] = useState(false);

    const agencyCustomerIdToNameMap = useRef({}).current;

    // @ts-ignore
    // @ts-ignore
    return (
      <Card className="mb-4">
        <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h5 style={{display: "inline-block", margin: 0}}>Lista richieste campagne</h5>
          <ExternalUserOnly>
            <Button onClick={() => history.push(Routes.Dsp.Campaign.Create.path)}>
              Crea nuova richiesta
            </Button>
          </ExternalUserOnly>
        </Card.Header>
        <Card.Body className="pt-3">
          <SmartTable<CampaignDsp, Filters>
            pagination={true}
            tableKey="dsp-format-list-table"
            syncWithElementsProp={true}
            enableHiddenColumns={false}
            getElementKey={(format) => format.id}
            sort={{
              defaultSortingColIndex: 1,
              defaultArrowVisible: true
            }}
            filter={{
              filterParamsInitialValue: {},
              applyFiltersFunction: (elements, filterParams) => elements
                .filter(el => !filterParams.name || filterParams.name.searchFor === "" || el.name.toLowerCase().includes(filterParams.name.searchFor.toLowerCase().trim()))
                .filter(el => !filterParams.agency || filterParams.agency.value === -1 || el.agency_id === filterParams.agency.value)
                .filter(el => !filterParams.agencyCustomer || filterParams.agencyCustomer.value === -1 || el.agency_customer_id === filterParams.agencyCustomer.value)
                .filter(el => !filterParams.dates || ((!filterParams.dates.start_date || filterParams.dates.start_date.isSameOrBefore(moment(el.ends_at))) && (!filterParams.dates.end_date || filterParams.dates.end_date.isSameOrAfter(moment(el.starts_at)))))
                .filter(el => !filterParams.budget || ((filterParams.budget.min === undefined || filterParams.budget.min === "" || filterParams.budget.min <= el.budget) && (filterParams.budget.max === undefined || filterParams.budget.max === "" || filterParams.budget.max >= el.budget)))
                .filter(el => !filterParams.status || filterParams.status.value === -1 || el.status === filterParams.status.value)
            }}
            elements={dspList}
            columns={[
              {
                title: "",
                cellRenderer: (dsp) =>
                  <>
                    <ExternalUserOnly>
                    <span>
                      <FontAwesomeIcon
                        style={{cursor: "pointer", fontSize: "16px"}}
                        icon={faArrowUpRightFromSquare}
                        onClick={() => history.push(Routes.Dsp.Campaign.View.buildPath(dsp.id))}
                      />
                    </span>
                    </ExternalUserOnly>
                    <InternalUserOnly>
                      <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link"
                                         className="text-dark m-0 p-0">
                        <span className="icon icon-sm">
                          <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                        </span>
                        </Dropdown.Toggle>
                        <TopLevelDropdownMenu align="left">
                          <Dropdown.Item
                            onClick={() => history.push(Routes.Dsp.Campaign.View.buildPath(dsp.id))}>
                            <FontAwesomeIcon icon={faEye}/> Visualizza
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setIsEditDSPStatusPopupShown(true)}>
                            <FontAwesomeIcon icon={faEdit}/> Modifica stato richiesta
                          </Dropdown.Item>
                          {isEditDSPStatusPopupShown &&
                              <EditDspStatusPopup
                                  isShown={true}
                                  onClosePopup={() => setIsEditDSPStatusPopupShown(false)}
                                  campaign={dsp}
                                  oldStatus={dsp.status}
                              />
                          }
                        </TopLevelDropdownMenu>
                      </Dropdown>
                    </InternalUserOnly>
                  </>
              },
              {
                title: "Nome",
                cellRenderer: (dsp) => dsp.name,
                compareElementsByColumn: (dspA, dspB) =>
                  dspA.name.localeCompare(dspB.name),
                columnFilter: {
                  key: "name",
                  fieldsRenderer: ({getFieldName}) =>
                    <InputField
                      name={getFieldName("searchFor")} type="text"
                      placeholder="Inserisci il nome..."
                      showValid={false}
                      style={{maxWidth: "500px"}}
                    />,
                  filterParamsFieldDefaultValue: () => ({
                    searchFor: ""
                  })
                }
              },
              // @ts-ignore
              ...(isAuthenticatedUserInternal() ? [{
                title: "Agenzia",
                cellRenderer: (dsp) => {
                  const agency = agencyList.find(a => a.id === dsp.agency_id);
                  return `${agency.first_name} ${agency.last_name}`;
                },
                compareElementsByColumn: (dspA, dspB) => {
                  const agencyA = agencyList.find(a => a.id === dspA.agency_id);
                  const agencyB = agencyList.find(a => a.id === dspB.agency_id);
                  return `${agencyA.first_name} ${agencyA.last_name}`.localeCompare(`${agencyB.first_name} ${agencyB.last_name}`)
                },
                columnFilter: {
                  key: "agency",
                  fieldsRenderer: ({getFieldName, setValues, values}) =>
                    <InputSelectField
                      name={getFieldName("value")}
                      optionValueType="number"
                      showValid={false}
                      style={{maxWidth: "500px"}}
                      onChange={(e) => {
                        const newVal = {...values};
                        if (newVal.agencyCustomer)
                          newVal.agencyCustomer.value = -1;
                        setValues({
                          ...newVal,
                          agency: {
                            value: parseInt(e.target.value)
                          }
                        });
                      }}
                    >
                      <>
                        <option key='blankChoice' value={-1}>TUTTI</option>
                        {agencyList.map(a => (
                          <option key={a.id}
                                  value={a.id}>{a.first_name} {a.last_name}</option>
                        ))}
                      </>
                    </InputSelectField>,
                  filterParamsFieldDefaultValue: () => ({
                    value: -1
                  })
                }
              }] : []),
              {
                title: isAuthenticatedUserInternal() ? "Cliente agenzia" : "Cliente",
                cellRenderer: (dsp) => dsp.agency_customer_id === null ? "-" :
                  <RenderAgencyCustomerName
                    key={`${dsp.agency_id}-${dsp.agency_customer_id}}`}
                    agencyId={dsp.agency_id}
                    agencyCustomerId={dsp.agency_customer_id}
                    onNameObtained={(name) => agencyCustomerIdToNameMap[dsp.agency_id] = name}
                  />,
                compareElementsByColumn: (dspA, dspB) =>
                  (dspA.agency_customer_id === null ? "-" : (agencyCustomerIdToNameMap[dspA.agency_customer_id] ?? ""))
                    .localeCompare(dspB.agency_customer_id === null ? "-" : (agencyCustomerIdToNameMap[dspB.agency_customer_id] ?? "")),
                columnFilter: {
                  //@ts-ignore
                  key: "agencyCustomer",
                  fieldsRenderer: ({getFieldName, values}) => {
                    return (
                      <InputSelectField
                        name={getFieldName("value")}
                        optionValueType="number"
                        showValid={false}
                        style={{maxWidth: "500px"}}
                      >
                        <>
                          <option key='blankChoice' value={-1}>TUTTI</option>
                          {(values.agency.value === -1 ? agencyList.map(a => a.id) : [values.agency.value]).map(agencyId => (
                            <AgencyCustomerOptions agencyId={agencyId}/>
                          ))}
                        </>
                      </InputSelectField>
                    );
                  },
                  filterParamsFieldDefaultValue: () => ({
                    value: -1
                  })
                }
              },
              {
                title: "Periodo di attività",
                cellRenderer: dsp => (
                  <div>
                    <div key={`dsp_${dsp.id}_dates`}>
                      {`${moment(dsp.starts_at).format("DD/MM/YYYY")} - ${moment(dsp.ends_at).format("DD/MM/YYYY")}`}
                    </div>
                  </div>
                ),
                compareElementsByColumn: (dspA, dspB) => compareDates(
                  moment(dspA.starts_at),
                  moment(dspB.starts_at)
                ),
                columnFilter: {
                  //@ts-ignore
                  key: "dates",
                  fieldsRenderer: ({getFieldName, setFieldValue, fieldValue}) =>
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                      <Form.Control
                        as="select"
                        className="input form-select"
                        value={getDateRangeFilterSelectedOption(fieldValue)}
                        onChange={(e) => {
                          const oldVal = getDateRangeFilterSelectedOption(fieldValue);
                          const newVal = e.target.value as "interval" | "end" | "start";
                          if (oldVal === "start" && newVal === "end") {
                            setFieldValue({end_date: fieldValue.start_date});
                          } else if (oldVal === "end" && newVal === "start") {
                            setFieldValue({start_date: fieldValue.end_date});
                          } else if (oldVal === "interval" && newVal === "start") {
                            setFieldValue({start_date: fieldValue.start_date});
                          } else if (oldVal === "interval" && newVal === "end") {
                            setFieldValue({end_date: fieldValue.end_date});
                          } else if (oldVal === "start" && newVal === "interval") {
                            setFieldValue({
                              start_date: fieldValue.start_date,
                              end_date: fieldValue.start_date.clone().add(1, "month")
                            });
                          } else if (oldVal === "end" && newVal === "interval") {
                            setFieldValue({
                              start_date: fieldValue.end_date.clone().add(-1, "month"),
                              end_date: fieldValue.end_date
                            });
                          }
                        }
                        }
                        id="date-range-form-select"
                        style={{maxWidth: "300px"}}
                        children={
                          <>
                            <option key="blankChoice" hidden value="">Seleziona tipo di
                              filtro sulla data
                            </option>
                            <option key="interval" value="interval">Compreso tra...</option>
                            <option key="start" value="start">A partire dal...</option>
                            <option key="end" value="end">Entro il...</option>
                          </>
                        }
                      />
                      {getDateRangeFilterSelectedOption(fieldValue) === "interval" &&
                          <InputDateRangeField
                              startDateName={getFieldName("start_date")}
                              isDayBlocked={() => false}
                              isOutsideRange={() => false}
                              endDateName={getFieldName("end_date")}
                          />
                      }
                      {getDateRangeFilterSelectedOption(fieldValue) === "start" &&
                          <InputDateField
                              dateName={getFieldName("start_date")}
                              isDayBlocked={() => false}
                              isOutsideRange={() => false}
                          />
                      }
                      {getDateRangeFilterSelectedOption(fieldValue) === "end" &&
                          <InputDateField
                              dateName={getFieldName("end_date")}
                              isDayBlocked={() => false}
                              isOutsideRange={() => false}
                          />
                      }
                    </div>,
                  filterParamsFieldDefaultValue: () => ({
                    start_date: moment(),
                    end_date: moment()
                  })
                }
              },
              {
                title: "Budget",
                cellRenderer: (dsp) => `${dsp.budget} €`,
                compareElementsByColumn: (dspA, dspB) => dspA.budget - dspB.budget,
                columnFilter: {
                  //@ts-ignore
                  key: "budget",
                  fieldsRenderer: ({getFieldName, setFieldValue, fieldValue}) =>
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                      <Form.Control
                        as="select"
                        className="input form-select"
                        value={getBudgetFilterSelectedOption(fieldValue)}
                        onChange={(e) => {
                          const oldVal = getBudgetFilterSelectedOption(fieldValue);
                          const newVal = e.target.value as "min" | "max" | "interval";
                          if (oldVal === "min" && newVal === "max") {
                            setFieldValue({max: fieldValue.min});
                          } else if (oldVal === "max" && newVal === "min") {
                            setFieldValue({min: fieldValue.max});
                          } else if (oldVal === "interval" && newVal === "min") {
                            setFieldValue({min: fieldValue.min});
                          } else if (oldVal === "interval" && newVal === "max") {
                            setFieldValue({max: fieldValue.max});
                          } else if (oldVal === "min" && newVal === "interval") {
                            setFieldValue({
                              min: fieldValue.min,
                              max: fieldValue.min
                            });
                          } else if (oldVal === "max" && newVal === "interval") {
                            setFieldValue({
                              min: fieldValue.max,
                              max: fieldValue.max
                            })
                          }
                        }
                        }
                        id="actual-budget-form-select"
                        style={{maxWidth: "300px"}}
                        children={
                          <>
                            <option key="blankChoice" hidden value="">Seleziona tipo di
                              filtro sulla spesa
                            </option>
                            <option key="min" value="min">Maggiore di...</option>
                            <option key="max" value="max">Minore di...</option>
                            <option key="interval" value="interval">Compresa tra...</option>
                          </>
                        }
                      />
                      {getBudgetFilterSelectedOption(fieldValue) === "interval" &&
                          <>
                              <InputField name={getFieldName("min")} type="number"
                                          placeholder="Spesa minima [€]"/>
                              <InputField name={getFieldName("max")} type="number"
                                          placeholder="Spesa massima [€]"/>
                          </>
                      }
                      {getBudgetFilterSelectedOption(fieldValue) === "min" &&
                          <InputField showValid={false} name={getFieldName("min")} type="number"
                                      placeholder="Spesa minima [€]"/>
                      }
                      {getBudgetFilterSelectedOption(fieldValue) === "max" &&
                          <InputField showValid={false} name={getFieldName("max")} type="number"
                                      placeholder="Spesa massima [€]"/>
                      }
                    </div>,
                  filterParamsFieldDefaultValue: () => ({
                    min: ""
                  })
                }
              },
              {
                title: "Stato richiesta",
                cellRenderer: (dsp) => getNameForDspStatus(dsp.status),
                compareElementsByColumn: (dspA, dspB) =>
                  getNameForDspStatus(dspA.status).localeCompare(getNameForDspStatus(dspB.status)),
                columnFilter: {
                  //@ts-ignore
                  key: "status",
                  fieldsRenderer: ({getFieldName, values}) => {
                    return (
                      <InputSelectField
                        name={getFieldName("value")}
                        optionValueType="number"
                        showValid={false}
                        style={{maxWidth: "500px"}}
                      >
                        <>
                          <option key='blankChoice' value={-1}>TUTTI</option>
                          <option key='status-0' value={0}> {getNameForDspStatus(0)} </option>
                          <option key='status-1' value={1}> {getNameForDspStatus(1)} </option>
                          <option key='status-2' value={2}> {getNameForDspStatus(2)} </option>
                          <option key='status-3' value={3}> {getNameForDspStatus(3)} </option>
                          <option key='status-4' value={4}> {getNameForDspStatus(4)} </option>
                        </>
                      </InputSelectField>
                    );
                  },
                  filterParamsFieldDefaultValue: () => ({
                    value: -1
                  })
                }
              },
            ]}
          />
        </Card.Body>
      </Card>
    )
  },
  InComponentPreloader
);

function getDateRangeFilterSelectedOption(
  value: { start_date: object | undefined, end_date: object | undefined }
): "interval" | "start" | "end" {
  return (value.start_date !== undefined && value.end_date !== undefined) ? "interval" : (value.start_date !== undefined ? "start" : "end");
}

function getActualBudgetFilterSelectedOption(
  value: { min: number | undefined | "", max: number | undefined | "" }
): "interval" | "max" | "min" {
  return (value.min !== undefined && value.max !== undefined) ? "interval" : (value.min !== undefined ? "min" : "max");
}

function getBudgetFilterSelectedOption(
  value: { min: number | undefined | "", max: number | undefined | "" }
): "interval" | "max" | "min" {
  return (value.min !== undefined && value.max !== undefined) ? "interval" : (value.min !== undefined ? "min" : "max");
}