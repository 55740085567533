import React, {useState} from 'react';
import {Button, ButtonGroup, CloseButton, Dropdown, Modal} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faEllipsisH,
  faEye,
  faPencilAlt,
  faTrash,
  faTrashAlt,
  faUpload,
  faUsers,
  faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import {getAuthenticatedUser} from "../../commons/auth";
import {Campaign, deleteCampaign} from "../../api/campaigns";
import {useSWRConfig} from "swr";
import {useMutateMany} from "../../api/swr-fetcher";
import {ManualUploadCampaignSourcePopup} from "./Popups/ManualUploadCampaignSourcePopup";
import {RemoveCampaignDataInDateRangePopup} from "./Popups/RemoveCampaignDataInDateRangePopup";
import {Routes} from "../../routes";
import {CampaignViewersSelectionPopup} from "./Popups/CampaignViewersSelectionPopup";
import {TopLevelDropdownMenu} from "../utils/TopLevelDropdownMenu";

interface Props {
  campaign: Campaign,
  triggerElementsChanged: () => void
}

export const CampaignActions = (props: Props) => {
  const {campaign, triggerElementsChanged} = props;
  const history = useHistory();

  const mutateMany = useMutateMany();

  const [showManualUploadPopup, setShowManualUploadPopup] = useState(false);
  const [showDeleteDataInDateRangePopup, setShowDeleteDataInDateRangePopup] = useState(false);
  const [showViewersSelectionPopup, setShowViewersSelectionPopup] = useState(false);
  const [showDeleteCampaignPopup, setShowDeleteCampaignPopup] = useState(false);

  const user = getAuthenticatedUser();
  const {mutate, cache} = useSWRConfig();

  const removeCampaign = () => {
    deleteCampaign(campaign.id).then(res => {
      if (res.status === 204) {
        mutateMany(cache, /^.*campaigns\/*(?:[^\/\d\w\-_]+.*|$)/)
          .then(() => mutateMany(cache, /^.*campaigns\// + `${campaign.id}` + /\/*(?:[^\/\d\w\-_]+.*|$)/))
      }
    });
  };

  return (
    <>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
            </span>
        </Dropdown.Toggle>
        <TopLevelDropdownMenu align="left">
          <Dropdown.Item onClick={() => history.push(Routes.Campaigns.View.buildPath(campaign.id))}>
            <FontAwesomeIcon icon={faEye}/> Visualizza dati
          </Dropdown.Item>
          <Dropdown.Item onClick={() => history.push(Routes.Campaigns.Report.buildPath(campaign.id))}>
            <FontAwesomeIcon icon={faChartLine}/> Visualizza report
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowManualUploadPopup(true)}>
            <FontAwesomeIcon icon={faUpload}/> Carica sorgente
          </Dropdown.Item>
          <Dropdown.Item onClick={() => history.push(Routes.Campaigns.KpiCorrection.buildPath(campaign.id))}>
            <FontAwesomeIcon icon={faWandMagicSparkles}/> Correttivi KPI
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {setShowViewersSelectionPopup(true)}}>
            <FontAwesomeIcon icon={faUsers}/> Gestisci visualizzatori
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowDeleteDataInDateRangePopup(true)}>
            <FontAwesomeIcon icon={faTrash}/> Elimina range di date
          </Dropdown.Item>
          <Dropdown.Item onClick={() => history.push(Routes.Campaigns.Edit.buildPath(campaign.id))}>
            <FontAwesomeIcon icon={faPencilAlt}/> Modifica
          </Dropdown.Item>
          {user.role === 'sysadmin' &&
            <Dropdown.Item className="text-danger" onClick={() => setShowDeleteCampaignPopup(true)}>
              <FontAwesomeIcon icon={faTrashAlt}/> Elimina
            </Dropdown.Item>
          }
        </TopLevelDropdownMenu>
      </Dropdown>
      {showManualUploadPopup &&
        // @ts-ignore
        <ManualUploadCampaignSourcePopup
          campaignId={campaign.id}
          campaignName={campaign.name}
          isShown={showManualUploadPopup}
          onClosePopup={() => setShowManualUploadPopup(false)}
        />
      }
      {showDeleteDataInDateRangePopup &&
        <RemoveCampaignDataInDateRangePopup
          campaignId={campaign.id}
          campaignName={campaign.name}
          isShown={showDeleteDataInDateRangePopup}
          onClosePopup={() => setShowDeleteDataInDateRangePopup(false)}
        />
      }
      {showViewersSelectionPopup &&
        <CampaignViewersSelectionPopup
          campaignId={campaign.id}
          campaignName={campaign.name}
          isShown={true}
          onClosePopup={() => setShowViewersSelectionPopup(false)}
        />
      }
      {showDeleteCampaignPopup &&
        <Modal centered show={showDeleteCampaignPopup}>
          <Modal.Header style={{alignItems: "center"}}>
            <Modal.Title style={{fontSize: "18px"}}>Conferma eliminazione campagna</Modal.Title>
            <CloseButton onClick={() => setShowDeleteCampaignPopup(false)}/>
          </Modal.Header>
          <Modal.Body className="m-0 pt-2 px-3">
            <div>
              Confermi di voler eliminare definitivamente la campagna <em style={{fontWeight: 700}}>{campaign.name}</em>?
            </div>
          </Modal.Body>
          <Modal.Footer style={{justifyContent: "space-between"}}>
            <Button variant="outline-primary" onClick={() => setShowDeleteCampaignPopup(false)}>
              Annulla
            </Button>
            <Button variant="danger" onClick={removeCampaign}>
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
}
