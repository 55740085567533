import {Card, ListGroup, ListGroupItem} from "@themesberg/react-bootstrap";
import React from "react";
import {useHistory} from "react-router-dom";
import {Routes} from "../../routes";
import {Campaign} from "../../api/campaigns";

interface Props {
  title: string,
  value: number | string,
  campaignList: Campaign[]
}

export function CampaignWidget(props: Props) {

  const {title, value, campaignList} = props;
  const history = useHistory();

  return (
    <Card border="light" className="shadow-sm" style={{width: "330px"}}>
      <Card.Body style={{display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", padding: "15px"}}>
        <h5>{title}</h5>
        <div style={{display: "flex", flexDirection: "row", gap: "10px", alignItems: "center"}}>
          <h3 className="p-0 m-0">{value}</h3>
          <h6 className="p-0 m-0">{value === 1 ? "campagna" : "campagne"}</h6>
        </div>
        {campaignList.length > 0 &&
          <ListGroup style={{maxHeight: "150px", overflowY: "auto", alignSelf: "stretch"}}>
            {campaignList.map(campaign => (
              <ListGroupItem
                style={{padding: "2px", cursor: "pointer", textAlign: "center"}}
                key={campaign.id}
                onClick={() => history.push(Routes.Campaigns.View.buildPath(campaign.id))}
              >
                {campaign.name}
              </ListGroupItem>
            ))}
          </ListGroup>
        }
      </Card.Body>
    </Card>
  );
}