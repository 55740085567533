import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import {Button, Card} from "@themesberg/react-bootstrap";
import {Form, Formik, useFormikContext} from "formik";
import {updateTargetKpiSuggestions, useGetTargetKpiSuggestionsNoStaleData} from "../../../api/dsp/targetKpis";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {useGetKpis} from "../../../api/kpis";
import {buildRegexMatchPattern, useMutateManyV2} from "../../../api/swr-fetcher";
import {FormFieldsArray} from "../../../commons/forms/FormFieldsArray";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {SmallButton} from "../../../components/utils/buttons/SmallButton";
import {InputField} from "../../../commons/forms/InputField";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

const Schema = Yup.object({
  disp: Yup.array(
    Yup.object().shape({
      kpi_id: Yup.number().required(),
      suggestion: Yup.string().required()
    })
  ),
  vid: Yup.array(
    Yup.object().shape({
      kpi_id: Yup.number().required(),
      suggestion: Yup.string().required()
    })
  )
});

export const DspKpiTargetFormPage = withMultiFetchSWR((props, urlParams) => [
  {useFetchFunc: useGetTargetKpiSuggestionsNoStaleData},
  {useFetchFunc: useGetKpis}
], ({urlParams, endpointsResponse}) => {

  const {data: [targetKpis, kpiList]} = endpointsResponse;

  const mutateMany = useMutateManyV2();

  const initialValues = {
    disp: targetKpis.data.kpis.disp.length === 0 ? [getDefaultConfig()] : targetKpis.data.kpis.disp,
    vid: targetKpis.data.kpis.vid.length === 0 ? [getDefaultConfig()] : targetKpis.data.kpis.vid
  }

  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Header>
        <h5 className="mb-0">Obiettivi KPI per formato</h5>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={(values) => {
            return updateTargetKpiSuggestions(values)
              .then(r => mutateMany(buildRegexMatchPattern('dsp/target-kpis')))
              .then(() => setResponseStatus("SUCCESS"))
              .catch(e => setResponseStatus("FAIL"));
          }}
        >
          {({isSubmitting, dirty, values, submitForm}) => (
            <Form style={{display: "flex", flexDirection: "column", gap: "50px"}}>
              <FormFieldsArray
                title="Formato Display/Native"
                subtitle="Seleziona un KPI e specifica il suggerimento sull'obiettivo che sarà mostrato ai clienti se selezionato il formato Display."
                getDefaultItem={getDefaultConfig}
                addNewItemBtnLabel="Aggiungi KPI per formato Display"
                disableAddNewItemBtn={(targetKpis) => targetKpis.length === kpiList.data.kpis.length}
                name={"disp"}
                renderItem={({buildFieldName, removeItem, item}) => (
                  <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px"}}>
                    <InputSelectField
                      name={buildFieldName("kpi_id")} label="KPI" optionValueType="number"
                      children={
                        <>
                          <option key='disp-blankChoice' hidden value={undefined}>
                            Seleziona il KPI
                          </option>
                          {kpiList.data.kpis.filter(kpi =>
                            !(values.disp.map(value => value.kpi_id as unknown as number).includes(kpi.id)) ||
                            kpi.id === item.kpi_id
                          ).map((kpi) => (
                            <option key={buildFieldName(`disp-${kpi.id}`)} value={kpi.id}>{kpi.name}</option>
                          ))
                          }
                        </>
                      }
                    />
                    <InputField name={buildFieldName("suggestion")} label="Suggerimento obiettivo" type="text"
                                placeholder="Inserisci suggerimento"
                    />
                    <SmallButton style={{alignSelf: "start", justifySelf: "start"}} variant="danger"
                                 onClick={removeItem}>
                      Elimina KPI
                    </SmallButton>
                  </div>
                )}
              />
              <FormFieldsArray
                title="Formato Video"
                subtitle="Seleziona un KPI e specifica il suggerimento sull'obiettivo che sarà mostrato ai clienti se selezionato il formato Video."
                getDefaultItem={getDefaultConfig}
                addNewItemBtnLabel="Aggiungi KPI per formato Video"
                disableAddNewItemBtn={(targetKpis) => targetKpis.length === kpiList.data.kpis.length}
                name={"vid"}
                renderItem={({buildFieldName, removeItem, item}) => (
                  <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px"}}>
                    <InputSelectField
                      name={buildFieldName("kpi_id")} label="KPI" optionValueType="number"
                      children={
                        <>
                          <option key='vid-blankChoice' hidden value={undefined}>
                            Seleziona il KPI
                          </option>
                          {kpiList.data.kpis.filter(kpi =>
                            !(values.vid.map(value => value.kpi_id as unknown as number).includes(kpi.id)) ||
                            kpi.id === item.kpi_id
                          ).map((kpi) => (
                            <option key={buildFieldName(`vid-${kpi.id}`)} value={kpi.id}>{kpi.name}</option>
                          ))
                          }
                        </>
                      }
                    />
                    <InputField name={buildFieldName("suggestion")} label="Suggerimento obiettivo" type="text"
                                placeholder="Inserisci suggerimento"
                    />
                    <SmallButton style={{alignSelf: "start", justifySelf: "start"}} variant="danger"
                                 onClick={removeItem}>
                      Elimina KPI
                    </SmallButton>
                  </div>
                )}
              />
              <div style={{display: "flex", flexDirection: "column", gap: "15px"}}>
                <Button style={{alignSelf: "end"}} onClick={submitForm} disabled={!dirty || isSubmitting}>
                  Conferma modifiche
                </Button>
                {responseStatus !== "DEFAULT" &&
                  <FeedbackAlert status={responseStatus}/>
                }
                <FormikHelper onChangeValues={() => setResponseStatus("DEFAULT")}/>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}, InComponentPreloader);

function getDefaultConfig() {
  return {
    kpi_id: undefined,
    suggestion: ""
  }
}

function FormikHelper({onChangeValues}) {
  useEffect(
    onChangeValues,
    [useFormikContext().values]
  );
  return <></>;
}
