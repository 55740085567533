import {Campaign} from "../../api/campaigns";
import {Badge, Card} from "@themesberg/react-bootstrap";
import React from "react";
import styles from "./CampaignPreview.module.scss";
import moment from "moment";
import {CircularProgressbar, CircularProgressbarWithChildren} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Routes} from "../../routes";
import {useHistory} from "react-router-dom";
import {isAuthenticatedUserInternal} from "../../commons/Utils";
import {CircularProgressbarWithContent} from "../../components/utils/CircularProgressbarWithContent";

interface Props {
  campaign: Campaign
}

export function CampaignPreview({campaign}: Props) {

  const history = useHistory();

  const deliveredKpi = campaign.campaign_kpis.find(k => k.kpi_id === campaign.delivered_kpi_id);
  const qualityKpi = campaign.campaign_kpis
    .filter(k => k.objective !== null && k.kpi_id !== deliveredKpi.kpi_id)
    .slice(0, 3);

  let status: JSX.Element = <></>;
  switch (campaign.status) {
    case 'PLANNED':
      status =  <Badge className={styles.status} pill bg="Light">NON INIZIATA</Badge>;
      break;
    case 'ACTIVE':
      status =  <Badge className={styles.status} pill bg="success">ATTIVA</Badge>;
      break;
    case 'COMPLETED':
      status =  <Badge className={styles.status} pill bg="primary">COMPLETATA</Badge>;
      break;
  }

  return (
    <Card
      role={"button"}
      border="light"
      className={`table-wrapper table-responsive shadow-sm ${styles.card}`}
      onClick={() => isAuthenticatedUserInternal() ?
        history.push(Routes.Campaigns.View.buildPath(campaign.id)) :
        history.push(Routes.Campaigns.Report.buildPath(campaign.id))
      }
    >
      <div className={styles.header} >
        <h6 className={styles.advertiser}>{campaign.advertiser}</h6>
        <h5 className={styles.name}>{campaign.name}</h5>
        <h6 className={styles.date}> {getCampaignMinStartDate(campaign)} - {getCampaignMaxEndDate(campaign)}</h6>
      </div>
      <div className={styles.pacingOverview}>
        <CircularProgressbarWithContent className={styles.main} value={deliveredKpi.pacing*100}>
          <div className={styles.progressBarLabel}>
            <div className={styles.percentageLabel}>{`${Math.round(deliveredKpi.pacing*100)}%`}</div>
            <div>{deliveredKpi.name}</div>
          </div>
        </CircularProgressbarWithContent>
        {qualityKpi.length > 0 &&
          <div className={styles.inner}>
            {qualityKpi.map(k =>
              <CircularProgressbarWithContent value={k.pacing*100}>
                <div className={styles.progressBarLabel}>
                  <div className={styles.percentageLabel}>{`${Math.round(k.pacing*100)}%`}</div>
                  <div className={styles.kpiLabel}>{k.name}</div>
                </div>
              </CircularProgressbarWithContent>
            )}
          </div>
        }
      </div>
      <div className={styles.footer}>
        {status}
        {campaign.budget &&
          <div>
            <FontAwesomeIcon style={{fontSize: "19px", paddingRight: "5px"}} icon={faCartShopping}/>
            {"€ " + campaign.budget}
          </div>
        }
      </div>
    </Card>
  );
}

function getCampaignMinStartDate(campaign: Campaign): string {
  return campaign.dates.map(d => moment(d.starts_at)).sort((dA, dB) => dA.valueOf() - dB.valueOf())[0].format("DD/MM/YYYY");
}

function getCampaignMaxEndDate(campaign: Campaign): string {
  return campaign.dates.map(d => moment(d.ends_at)).sort((dA, dB) => dB.valueOf() - dA.valueOf())[0].format("DD/MM/YYYY");
}
