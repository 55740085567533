import {withMultiFetchSWR} from "../../api/helpersSWR";
import {deleteMapping, Mapping, useGetMappings} from "../../api/mappings";
import {useHistory} from "react-router-dom";
import {Button, ButtonGroup, Card, Dropdown} from "@themesberg/react-bootstrap";
import {Routes} from "../../routes";
import {SmartTable} from "../../commons/table/SmartTable";
import {deleteDspFormat, DspFormat} from "../../api/dsp/formats";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEllipsisH,
  faEye,
  faPencilAlt,
  faTimesCircle,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {buildRegexMatchPattern, useMutateManyV2} from "../../api/swr-fetcher";
import React from "react";
import {TopLevelDropdownMenu} from "../../components/utils/TopLevelDropdownMenu";
import InComponentPreloader from "../../components/utils/InComponentPreloader";

export const MappingListPage = withMultiFetchSWR(() => [
  {useFetchFunc: useGetMappings}
], ({endpointsResponse}) => {
  const [mappings] = endpointsResponse.data;

  const mutateMany = useMutateManyV2();
  const history = useHistory();

  return (
    <Card className="mb-4">
      <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <h5 style={{display: "inline-block", margin: 0}}>Lista Mappings</h5>
        <Button onClick={() => history.push(Routes.Mappings.Create.path)}>
          Crea nuovo Mapping
        </Button>
      </Card.Header>
      <Card.Body className="pt-3">
        <SmartTable<Mapping, never>
          pagination={true}
          tableKey="dsp-format-list-table"
          syncWithElementsProp={true}
          enableHiddenColumns={false}
          getElementKey={(mapping) => mapping.id}
          sort={{
            defaultSortingColIndex: 1,
            defaultArrowVisible: true
          }}
          elements={mappings.data ?? []}
          columns={[
            {
              title: "",
              cellRenderer: (mapping) =>
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                    </span>
                  </Dropdown.Toggle>
                  <TopLevelDropdownMenu align="left">
                    <Dropdown.Item
                      onClick={() => history.push(Routes.Mappings.View.buildPath(mapping.id))}>
                      <FontAwesomeIcon icon={faEye}/> Visualizza
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => history.push(Routes.Mappings.Edit.buildPath(mapping.id))}>
                      <FontAwesomeIcon icon={faPencilAlt}/> Modifica
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-danger"
                      onClick={() => deleteMapping(mapping.id).then(() => mutateMany(buildRegexMatchPattern("imports/mappings")))}
                    >
                      <FontAwesomeIcon icon={faTrashAlt}/> Elimina
                    </Dropdown.Item>
                  </TopLevelDropdownMenu>
                </Dropdown>
            },
            {
              title: "Nome",
              cellRenderer: (mapping) => mapping.name,
              compareElementsByColumn: (mappingA, mappingB) =>
                mappingA.name.localeCompare(mappingB.name)
            },
            {
              title: "Tipo di file",
              cellRenderer: (mapping) => mapping.file_type === "xls" ? "Excel" : "CSV",
              compareElementsByColumn: (mappingA , mappingB) => (mappingA.file_type === "xls" ? "Excel" : "CSV").localeCompare(mappingB.file_type === "xls" ? "Excel" : "CSV")
            },
            {
              title: "Numero di campi mappati",
              cellRenderer: (mapping) => mapping.fields.length,
              compareElementsByColumn: (mappingA, mappingB) => mappingA.fields.length - mappingB.fields.length
            }
          ]}
        />
      </Card.Body>
    </Card>
  );

}, InComponentPreloader)