import {OverlayTrigger, Tooltip} from "@themesberg/react-bootstrap";
import React from "react";
import {roundNumberToTwoDecimals} from "../Campaigns/CampaignDataPanel";

export const ProgressBar = ({label, value, min = 0, max = 100, size = 'md'}) => {

  let color = '#d91e18';
  if (value >= 25 && value < 50) {
    color = '#f9690e';
  } else if (value >= 50 && value < 75) {
    color = '#00e640';
  } else if (value >= 75) {
    color = '#1e824c';
  }

  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={`tooltip-${label}`} placement="top-start">
          {label} {roundNumberToTwoDecimals(value, true)}%
        </Tooltip>
      }
    >
      <div className="progress" style={{marginBottom: '0.5rem', minHeight: '15px'}}>
        <div
          className="progress-bar" role="progressbar" aria-valuenow={value} aria-valuemin={min} aria-valuemax={max}
          style={{width: `${value}%`, backgroundColor: color}}
        />
      </div>
    </OverlayTrigger>
  );
};