import * as React from "react";
import 'react-circular-progressbar/dist/styles.css';
import {CircularProgressbar} from "react-circular-progressbar";
import {CircularProgressbarDefaultProps} from "react-circular-progressbar/dist/types";
import styles from "./CircularProgressbarWithContent.module.scss";

interface Props {
  value: number,
  progressbarProps?: CircularProgressbarDefaultProps,
  children?: React.ReactNode,
  className?: string
}

export function CircularProgressbarWithContent(props: Props) {
  const {value, children, progressbarProps={}, className} = props;
  return (
    <div className={`${styles.root} ${className}`}>
      <CircularProgressbar value={value} {...progressbarProps} className={styles.progressbar}/>
      <div className={styles.contentContainer}>
        {children}
      </div>
    </div>
  );
}