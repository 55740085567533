import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faSignOutAlt, faTrashAlt, faUser, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {Container, Dropdown, ListGroup, Nav, Navbar} from '@themesberg/react-bootstrap';
import {getAuthenticatedUser} from "../commons/auth";
import {withRouter} from "react-router-dom";
import {Routes} from "../routes";
import {CampaignNotifications, useGetNotifications} from "../api/notifications";
import {Notification} from "./Notification";
import styles from "./Navbar.module.scss";
import {InternalUserOnly} from "./usersUtils/InternalUserOnly";
import {isAuthenticatedUserInternal} from "../commons/Utils";

export default withRouter(({history, ...props}) => {

  const user = getAuthenticatedUser();

  let notifications: CampaignNotifications = [];
  if(isAuthenticatedUserInternal()) {
    const {data: notificationsResponse} = useGetNotifications();
    notifications = notificationsResponse?.data ?? [];
  }

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  }

  const cacheClear = async () => {
    localStorage.setItem('refreshCache', '1');
    alert('La cache è stata eliminata con successo.');
    window.location.href = '/';
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-end w-100">
          <Nav className="align-items-center">
            <InternalUserOnly>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faBell} className="bell-shake"/>
                </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className={`dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0 ${styles.notificationDropDown}`}>
                  <ListGroup className="list-group-flush">
                    <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-1">
                      Notifiche
                    </Nav.Link>
                    {notifications.map( n => <Notification notification={n} key={`notification-${n.id}`}/>)}
                    {notifications.length === 0 &&
                      <div style={{padding: "6px 10px"}}>Nessuna nuova notifica</div>
                    }
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown>
            </InternalUserOnly>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">
                      <FontAwesomeIcon icon={faUserCircle} size="lg"/> {user.first_name} {user.last_name}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={() => history.push(Routes.UserProfile.path)}>
                  <FontAwesomeIcon icon={faUser} className="me-2"/> Profilo
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold" onClick={cacheClear}>
                  <FontAwesomeIcon icon={faTrashAlt} className="text-danger me-2"/> Pulisci cache
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold" onClick={logout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2"/> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
});
