import {Badge, Button, CloseButton, Form, Modal} from "@themesberg/react-bootstrap";
import React, {useRef, useState} from "react";
import * as Yup from "yup";
import {Formik} from "formik";
import {InputFileField} from "../../../commons/forms/InputFileField";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {useGetAllWebCategoriesNoStaleData} from "../../../api/dsp/webCategories";
import {useGetAllWebListsNoStaleData} from "../../../api/dsp/webLists";
import {browserDownloadCSVTemplateForWebsitesBulkImport, uploadWebsitesCsv} from "../../../api/dsp/websites";
import styles from "./WebsiteImportPopup.module.scss";
import {buildRegexMatchPattern, useMutateManyV2} from "../../../api/swr-fetcher";

interface Props {
  isShown: boolean;
  onClosePopup: () => void;
}

const Schema = Yup.object({
  file: Yup.string().required()
});

export const WebsiteImportPopup = withMultiFetchSWR(
  (props: Props) => [
    {useFetchFunc: () => useGetAllWebCategoriesNoStaleData()},
    {useFetchFunc: () => useGetAllWebListsNoStaleData()}
  ], ({props, endpointsResponse}) => {

    const {isShown, onClosePopup} = props;
    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "FAIL">("DEFAULT");
    const [errorMessageList, setErrorMessageList] = useState<string[]>([]);

    const uploadRef = useRef(null);
    const {data: [webCategories, webLists]} = endpointsResponse;

    const mutateMany = useMutateManyV2();

    return (
      <Modal centered show={isShown} size="lg">
        <Formik
          initialValues={{file: ''}}
          validationSchema={Schema}
          onSubmit={(values, {setSubmitting}) => {
            setErrorMessageList([]);
            const formData = new FormData();
            formData.append('file', uploadRef.current.files[0]);
            uploadWebsitesCsv(uploadRef.current.files[0])
              .then(r => {
                mutateMany(buildRegexMatchPattern("dsp/websites"));
                const errors = r.data.data.errors;
                if (errors == null || errors.length === 0)
                  onClosePopup();
                else {
                  setResponseStatus("FAIL");
                  for(const e of errors) {
                    let errorDescription;
                    if(e.error === 1)
                      errorDescription = "è già esistente";
                    else if(e.error === 2)
                      errorDescription = "contiene un errore nel nome del settore"
                    setErrorMessageList(oldState => [...oldState, `Il dominio ${e.domain} ${errorDescription}`]);
                  }
                }
              })
              .catch(e => {
                setResponseStatus("FAIL");
              })
              .finally(() => setSubmitting(false));
          }}
        >
          {({isSubmitting, submitForm}) => (
            <>
              <Modal.Header
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "0.7rem",
                  paddingTop: "0.7rem"
                }}
              >
                <Modal.Title style={{fontSize: "20px"}}>Import siti da file csv</Modal.Title>
                <CloseButton onClick={onClosePopup}/>
              </Modal.Header>
              <Modal.Body className="m-0 pt-2 px-3">
                <Form style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                  <InputFileField
                    name="file" accept=".csv" label="File da importare" withRef={uploadRef}
                  />
                  <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                    <div>Il file csv dovrà essere organizzato in campi delimitati dal separatore virgola (,).</div>
                    <div>
                      La
                      <div className={styles.accentLabel}>prima colonna</div>
                      deve contenere il
                      <div className={styles.accentLabel}>dominio del sito web</div>
                      da aggiungere.
                    </div>
                    <div>La
                      <div className={styles.accentLabel}>seconda colonna</div>
                      deve contenere il
                      <div className={styles.accentLabel}>nome del settore</div>
                      a cui il sito appartiene.
                    </div>
                    <div>Si riepilogano qui sotto tutti i possibili settori:</div>
                    <div>
                      {webCategories.map(category => (
                        <Badge
                          key={`cat-${category.id}`} className="ms-2"
                          style={{color: "black", backgroundColor: "#D3D3D3", padding: "5px"}}>
                          {category.name
                          }</Badge>
                      ))}
                    </div>
                    <div>Le colonne
                      <div className={styles.accentLabel}>dalla terza in avanti</div>
                      corrispondono alle
                      <div className={styles.accentLabel}>liste</div>
                      a cui il sito può appartenere. Per indicare l'appartenenza del sito a una o più liste è
                      necessario inserire una
                      <div className={styles.accentLabel}>X</div>
                      nella/e cella/e desiderata/e.
                    </div>
                    <div>Tutti i nomi saranno acquisiti in modo NON case-sensitive.</div>
                  </div>
                  <Button onClick={browserDownloadCSVTemplateForWebsitesBulkImport}>Scarica template CSV</Button>
                </Form>
              </Modal.Body>
              <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Button type="button" variant="outline-primary" onClick={onClosePopup}>Annulla</Button>
                  <Button onClick={submitForm} disabled={isSubmitting}>Carica file</Button>
                </div>
                <FeedbackAlert
                  status={responseStatus}
                  failMessage={errorMessageList.length === 0 ?
                    "Ops! Si è verificato un errore nell'importazione del file." :
                      `Durante l'import del file si sono verificati i seguenti errori:
                      ${errorMessageList.join(` - `)}`
                  }
                />
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    );
  }
);