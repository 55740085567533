import React, {useCallback, useEffect, useReducer, useRef} from "react";

interface Props {
  children: (ref: React.Ref<HTMLDivElement>) => JSX.Element,
  scrollOptions?: ScrollIntoViewOptions
}

export const ScrollToElementIfExists: React.FC<Props> = ({children, scrollOptions}) => {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  let options = scrollOptions ?? { behavior: 'smooth', block: 'end'};
  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      node.scrollIntoView(options);
    }
  }, [options]);
  return children(ref);
}