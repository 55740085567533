import axios from 'axios';
import useSWR from "swr";
import {axiosFetcher} from "./swr-fetcher";
import {EndpointWrapper} from "./common";
import {useSWRNoStaleData} from "./helpersSWR";

export interface IMAPSettings {
  host: string;
  port: number;
  encryption: "ssl" | "tls" | "none";
  validate_cert: boolean;
  username: string;
  password: string;
}

export interface WidgetsThresholdsRequestBody {
  win_rate_threshold: number,
  kpi_pacing_threshold: number,
  kpi_dispensing_threshold: number
}

export interface WidgetsThresholdsGetResponse {
  winrate: number,
  kpi_pacing: number,
  kpi_dispensing: number
}

export interface NotificationThresholds {
  thresholds: Array<{
    "kpi_id": number,
    "operator": ">",
    "value": number
  }>
}

export function useGetIMAPSettings() {
  return useSWR<EndpointWrapper<IMAPSettings>>('/settings/imap', axiosFetcher);
}

export function useGetIMAPSettingsNoStaleData() {
  return useSWRNoStaleData<EndpointWrapper<IMAPSettings>>('/settings/imap', axiosFetcher);
}

export const storeIMAPSettings = (IMAPSettings: IMAPSettings) => {
  return axios.post('/settings/imap', IMAPSettings).then(res => res.data);
};

export function useGetWidgetsSettings() {
  return useSWR<EndpointWrapper<{thresholds: WidgetsThresholdsGetResponse}>>('/settings/thresholds', axiosFetcher);
}

export function useGetWidgetsSettingsNoStaleData() {
  return useSWRNoStaleData<EndpointWrapper<{thresholds: WidgetsThresholdsGetResponse}>>('/settings/thresholds', axiosFetcher);
}

export const storeWidgetsSettings = (widgetsThresholds: WidgetsThresholdsRequestBody) => {
  return axios.post('/settings/thresholds', widgetsThresholds).then(res => res.data);
}

export function useGetNotificationsSettings() {
  return useSWR<EndpointWrapper<NotificationThresholds>>('/notifications/thresholds', axiosFetcher);
}

export function useGetNotificationsSettingsNoStaleData() {
  return useSWRNoStaleData<EndpointWrapper<NotificationThresholds>>('/notifications/thresholds', axiosFetcher);
}

export const storeNotificationsSettings = (notificationThresholds: NotificationThresholds) => {
  return axios.post('/notifications/thresholds', notificationThresholds).then(res => res.data);
}