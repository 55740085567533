import React from "react";
import {LevelInputFormPropWithId} from "./DomainAnalysisPage";
import {
  ExcludedRow,
  Level3InputData,
  useGetLevel2ExcludedRowsForLevel3InputNoStaleData
} from "../../api/DomainAnalysis";
import {Form as FormikForm, Formik} from "formik";
import * as Yup from "yup";
import {InputField} from "../../commons/forms/InputField";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {SmartTable} from "../../commons/table/SmartTable";
import {getCommonColumnsInOutputTable, getOutputAttributes} from "./utils";
import {useGetAttributes} from "../../api/attributes";
import {useGetKpis} from "../../api/kpis";
import {Form} from "@themesberg/react-bootstrap";

const Schema = Yup.object({
  win_rate_threshold: Yup.number().typeError("Inserisci una percentuale valida")
    .min(0, "Inserisci una percentuale valida")
    .required("Inserisci la soglia di WinRate"),
  combinationKeyList: Yup.array(
    Yup.string()
  ).min(1, "Seleziona almeno una riga da mantenere nell'analisi")
    .required("Seleziona almeno una riga da mantenere nell'analisi")
});

type Props = LevelInputFormPropWithId<Level3InputData>;

type FormikValues = Omit<Level3InputData, "combinations"> & { combinationKeyList: string[] }

export const Level3Form = withMultiFetchSWR((props: Props) => [
    {useFetchFunc: () => useGetLevel2ExcludedRowsForLevel3InputNoStaleData(props.analysisId)},
    {useFetchFunc: useGetAttributes},
    {useFetchFunc: useGetKpis}
  ], ({props, endpointsResponse}) => {

    const {initialValues, onSubmit, formikRef, useValues, submittedInputs, analysisId} = props;
    const [excludedRows, {data: attributeList}, {data: kpiList}] = endpointsResponse.data;

    const outputAttributes = getOutputAttributes(attributeList, excludedRows);

    return (
      <Formik<FormikValues>
        initialValues={getFormattedInitialValues(initialValues, analysisId)}
        validationSchema={Schema}
        innerRef={formikRef}
        onSubmit={(values) => {
          const valuesToSubmit: Level3InputData = {
            win_rate_threshold: values.win_rate_threshold / 100,
            level_1_id: analysisId,
            combinations: excludedRows.filter(row => values.combinationKeyList.includes(getKeyForRow(row)))
          }
          return onSubmit(valuesToSubmit);
        }}
      >
        {({values, setFieldValue, errors}) => (
          <FormikForm style={{display: "flex", flexDirection: "column", gap: "20px"}}>
            <InputField
              name="win_rate_threshold" type="number" label="Soglia limite di Win Rate [%]" min={0} step={0.01} max={100}
              placeholder="Inserisci soglia" containerStyle={{width: "50%"}}
            />
            <div>
              <Form.Label className={`label mb-1`}>
                Seleziona le righe, scartate dall'analisi di livello 2, che vuoi mantenere per il resto dell'analisi
              </Form.Label>
              {errors?.combinationKeyList &&
                <div style={{color: "#FA5252", fontSize: "0.875em"}} className="mb-2">
                  {errors.combinationKeyList}
                </div>
              }
              <SmartTable<ExcludedRow, void>
                tableKey={`input-excluded-rows-analysis-${analysisId}`}
                pagination={false}
                syncWithElementsProp={true}
                enableHiddenColumns={false}
                getElementKey={(row) => getKeyForRow(row)}
                sort={{
                  defaultSortingColIndex: 0,
                  defaultArrowVisible: true
                }}
                elements={excludedRows ?? []}
                columns={getCommonColumnsInOutputTable(outputAttributes, [])}
                onRowClicked={(row, rowKey: string) => {
                  const index = values.combinationKeyList.indexOf(rowKey);
                  if (index != -1) {
                    setFieldValue("combinationKeyList", [...values.combinationKeyList.slice(0, index), ...values.combinationKeyList.slice(index + 1)]);
                  } else {
                    setFieldValue("combinationKeyList", [...values.combinationKeyList, rowKey]);
                  }
                }}
                getRowColor={(row, rowKey: string) => values.combinationKeyList.includes(rowKey) ? "rgb(157,194,227)" : undefined}
              />
            </div>
          </FormikForm>
        )}
      </Formik>
    );
  }
);

function getKeyForRow(row: ExcludedRow) {
  return `${row.domain}-${row.combination.map(c => c.attribute_id).sort().join('-')}`;
}

function getFormattedInitialValues(initialValues: Level3InputData, id: string): FormikValues {
  if (!initialValues)
    return {
      win_rate_threshold: null,
      combinationKeyList: [],
      level_1_id: id
    };
  else
    return {
      win_rate_threshold: initialValues.win_rate_threshold * 100,
      combinationKeyList: initialValues.combinations.map(row => getKeyForRow(row)),
      level_1_id: id
    };
}
