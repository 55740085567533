import {Alert} from "@themesberg/react-bootstrap";
import React from "react";

interface Props {
  status: "SUCCESS" | "FAIL" | "DEFAULT";
  successMessage?: string;
  failMessage?: string;
  className?: string;
  style?: any;
}

export const FeedbackAlert = React.forwardRef<HTMLDivElement, Props>((props, ref) => {

  const {status, successMessage, failMessage, className, style = {}} = props;

  return (
    <div className={className} style={style} ref={ref}>
      {status === "SUCCESS" && <Alert className="my-2" variant="success">{successMessage}</Alert>}
      {status === "FAIL" && <Alert className="my-2" variant="danger">{failMessage}</Alert>}
    </div>
  );
});

FeedbackAlert.defaultProps = {
  status: "DEFAULT",
  successMessage: "Modifiche avvenute con successo!",
  failMessage: "Ops, qualcosa è andato storto!"
}