import _ from "lodash";
import {useEffect, useRef, useState} from "react";
import {
  AnalysisStatus, DomainAnalysisInfo,
  DomainAnalysisLevelData,
  Level1InputData, Level3Data, RunLevel1AnalysisResponse,
  useGetAllDomainAnalysis
} from "../../api/DomainAnalysis";
import {buildRegexMatchPattern, useMutateManyV2} from "../../api/swr-fetcher";


type DomainAnalysisLevelId = number | string;

type DomainAnalysisServiceConfig = {
  levels: Array<{
    id: DomainAnalysisLevelId,
    fetchData: (analysisId: string) => Promise<DomainAnalysisLevelData<any, any>>,
    runAnalysis: (input: any) => Promise<any>
  }>,
  alreadyExistingAnalysis: boolean,
  analysisId?: string,
  analysisPoolingIntervalMillis
} // | {levels: [{id: 1}]}; //level 1 must exist


export function useDomainAnalysisService(config: DomainAnalysisServiceConfig) {

  const [inputDataArr, setInputArr] = useState<any[]>([]);
  const [outputsArr, setOutputsArr] = useState<Array<{ rows: any[], suggestions?: any[], included?: any[] }>>([]);
  const [lastSubmittedLevelIndex, setLastSubmittedLevelIndex] = useState<number | null>(null);
  const [analysisId, setAnalysisId] = useState(config.analysisId ?? null);
  const [analysisSubmitError, setAnalysisSubmitError] = useState(null);
  const [analysisInfo, setAnalysisInfo] = useState<Pick<DomainAnalysisInfo, "created_at" | "updated_at" | "user">>(null);

  function getLevelIdByIndex(index: number) {
    return config.levels[index]?.id ?? null;
  }

  function getIndexByLevelId(levelId: DomainAnalysisLevelId) {
    const levelConfig = config.levels.find(l => l.id === levelId);
    if (levelConfig)
      return config.levels.indexOf(levelConfig);
    else
      return null;
  }

  function getAnalysisStatus(levelId: DomainAnalysisLevelId): "COMPLETED" | "IN_PROGRESS" | "NOT_STARTED" {
    const index = getIndexByLevelId(levelId);
    if (index !== null && lastSubmittedLevelIndex !== null && index <= lastSubmittedLevelIndex) {
      if (outputsArr[index])
        return "COMPLETED";
      else
        return "IN_PROGRESS";
    } else {
      return "NOT_STARTED";
    }
  }

  function getMaxPossibleAnalysisLevel() {
    if (lastSubmittedLevelIndex !== null && inputDataArr[getIndexByLevelId(1)] && lastSubmittedLevelIndex >= getIndexByLevelId(1)) {
      if ((inputDataArr[getIndexByLevelId(1)] as Level1InputData).use_future_pacing)
        return getLevelIdByIndex(config.levels.length - 1)
      else
        return 1;
    } else {
      return null;
    }
  }

  const pollingTimersRef = useRef(config.levels.map(() => null));

  function enableDataPollingForDomainAnalysis(_analysisId: string, levelId: DomainAnalysisLevelId) {
    const index = getIndexByLevelId(levelId);
    if (index === null)
      throw "Could not fetch data. Invalid index!";
    if (pollingTimersRef.current[index] != null)
      clearTimeout(pollingTimersRef.current[index])
    pollingTimersRef.current[index] = null;
    config.levels[index].fetchData(_analysisId)
      .then(analysisData => {
        setInputArr(arr => {
          const newArr = [...arr];
          newArr[index] = analysisData.request;
          return newArr;
        })
        if (analysisData.status === AnalysisStatus.Completed) {
          setOutputsArr(arr => {
            const newArr = [...arr];
            if (levelId === 3) {
              newArr[index] = {
                rows: analysisData.rows,
                suggestions: (analysisData as Level3Data).suggestions,
                included: (analysisData as Level3Data).included
              }
            } else {
              newArr[index] = {
                rows: analysisData.rows
              }
            }
            return newArr;
          });
        } else {
          pollingTimersRef.current[index] = setTimeout(() => enableDataPollingForDomainAnalysis(_analysisId, levelId), config.analysisPoolingIntervalMillis);
        }
      }, err => {
        pollingTimersRef.current[index] = setTimeout(() => enableDataPollingForDomainAnalysis(_analysisId, levelId), config.analysisPoolingIntervalMillis);
      })
  }

  const domainAnalysisList = useGetAllDomainAnalysis(
    !config.alreadyExistingAnalysis,
    {revalidateOnFocus: false, revalidateOnMount: false, revalidateOnReconnect: false}
  );
  const mutateMany = useMutateManyV2();

  const [initialLoadingDone, setInitialLoadingDone] = useState(!config.alreadyExistingAnalysis);

  useEffect(() => {
    if (config.alreadyExistingAnalysis && domainAnalysisList.data) {
      const analysis = domainAnalysisList.data.find(a => a.uuid === config.analysisId);
      if (analysis) {
        setAnalysisInfo({
          user: analysis.user,
          updated_at: analysis.updated_at,
          created_at: analysis.created_at
        });
        const maxSubmittedLevelId = analysis.level_3 ? 3 : (analysis.level_2 ? 2 : 1);
        const maxSubmittedLevelIndex = getIndexByLevelId(maxSubmittedLevelId);
        config.levels
          .filter((level, index) => index <= maxSubmittedLevelIndex)
          .map(level => enableDataPollingForDomainAnalysis(analysisId, level.id));
        setLastSubmittedLevelIndex(maxSubmittedLevelIndex);
      } else {
        mutateMany(buildRegexMatchPattern("/domains/analysis"));
      }
    }
  }, [domainAnalysisList.data]);

  useEffect(() => {
    if (!initialLoadingDone && lastSubmittedLevelIndex !== null) {
      const allInputLoaded = !(_.range(0, lastSubmittedLevelIndex + 1).some(i => !inputDataArr[i]));
      if (allInputLoaded)
        setInitialLoadingDone(true);
    }
  }, [initialLoadingDone, inputDataArr, lastSubmittedLevelIndex])

  return {
    getSubmittedInputForAnalysisLevel: function (levelId: DomainAnalysisLevelId) {
      const index = getIndexByLevelId(levelId);
      if (index !== null && index <= lastSubmittedLevelIndex)
        return inputDataArr[index];
      else
        return null;
    },
    getLastSubmittedAnalysisLevel: function () {
      if (lastSubmittedLevelIndex !== null)
        return getLevelIdByIndex(lastSubmittedLevelIndex);
      else
        return null;
    },
    getMaxPossibleAnalysisLevel,
    getAnalysisStatus,
    submitDomainAnalysisLevel: function (levelId: DomainAnalysisLevelId, input: any) {
      const index = getIndexByLevelId(levelId);
      const maxIndex = getIndexByLevelId(getMaxPossibleAnalysisLevel());
      if (index === -1 || index > lastSubmittedLevelIndex + 1 || index > maxIndex)
        throw new Error("Operation not permitted!");
      setLastSubmittedLevelIndex(index);
      setInputArr(arr => {
        const newArr = [...arr];
        newArr[index] = input;
        return newArr;
      })
      return config.levels[index].runAnalysis(input)
        .then((response) => {
          let _analysisId = analysisId;
          if (levelId === 1) {
            _analysisId = (response as RunLevel1AnalysisResponse).data.id;
            setAnalysisId(_analysisId);
          }
          enableDataPollingForDomainAnalysis(_analysisId, levelId);
          setAnalysisSubmitError(null);
        }, (err) => {
          console.error("Error submitting results", err);
          setAnalysisSubmitError(err);
          setLastSubmittedLevelIndex(index > 0 ? index - 1 : null);
        });
    },
    getOutputForAnalysisLevel: function (levelId: DomainAnalysisLevelId) {
      const index = getIndexByLevelId(levelId);
      if (index !== null && index <= lastSubmittedLevelIndex && getAnalysisStatus(levelId) === "COMPLETED")
        return outputsArr[index];
      else
        return null;
    },
    isInitialLoadingDone: function () {
      return initialLoadingDone
    },
    getAnalysisSubmitFailure: function (levelId: DomainAnalysisLevelId) {
      const index = getIndexByLevelId(levelId);
      if (index <= lastSubmittedLevelIndex)
        return null;
      else
        return analysisSubmitError;
    },
    getAnalysisId: function () {
      return analysisId;
    },
    getAnalysisInfo: function () {
      return analysisInfo;
    }
  }
}