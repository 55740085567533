import React, {useState} from 'react';
import * as Yup from 'yup';
import {Button, Card} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik} from "formik";
import {InputField} from "../../../commons/forms/InputField";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {Routes} from "../../../routes";
import {InputCheckField} from "../../../commons/forms/InputCheckField";
import {useHistory} from "react-router-dom";
import {
  createDspFormat,
  DspFormat,
  DspFormatBase,
  updateDspFormat,
  useGetDspFormatNoStaleData
} from "../../../api/dsp/formats";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

const Schema = Yup.object({
  name: Yup.string().required(),
  width: Yup.number().typeError("Inserire una misura valida").required(),
  height: Yup.number().typeError("Inserire una misura valida").required(),
  active: Yup.boolean().required()
});

export const DspFormatFormPage = withMultiFetchSWR(
  (props: {}, urlParams: {formatId: string}) => [
    {
      useFetchFunc: () => useGetDspFormatNoStaleData(parseInt(urlParams.formatId)),
      shouldFetch: urlParams.formatId !== undefined,
      elseData: {
        data: {
          ad_format: getDefaultConfig()
        }
      }
    }
  ], ({urlParams, endpointsResponse}) => {
    const formatId = urlParams.formatId ? parseInt(urlParams.formatId) : undefined;
    const isNewFormat = formatId == undefined;
    const formatResponse = endpointsResponse.data[0];
    const format = formatResponse.data.ad_format;

    const history = useHistory();

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header>
          <h5
            className="mb-0">{isNewFormat ? "Creazione formato" : `Modifica formato ${format.name}`}</h5>
        </Card.Header>
        <Card.Body>
          <Formik<DspFormat | DspFormatBase>
            initialValues={format}
            validationSchema={Schema}
            onSubmit={(values, {setSubmitting}) => {
              let promise;
              if (isNewFormat)
                promise = createDspFormat(values);
              else
                promise = updateDspFormat(formatId, values);
              return promise
                .then(r => history.push(Routes.Dsp.Format.ViewAll.path))
                .catch(e => setResponseStatus("FAIL"));
            }}
          >
            {({isSubmitting, dirty, values}) => (
              <FormikForm style={{display: "flex", flexDirection: "column", gap: "25px"}}>
                <InputField name="name" type="text" label="Nome del formato" placeholder="Inserisci nome"/>
                <div style={{display: "flex", flexDirection: "row", gap: "15px"}}>
                  <InputField
                    name="width" type="number" label="Base [px]" placeholder="Inserisci misura base"
                    containerStyle={{flexGrow: 1}}
                  />
                  <InputField
                    name="height" type="number" label="Altezza [px]" placeholder="Inserisci misura altezza"
                    containerStyle={{flexGrow: 1}}
                  />
                </div>
                <InputCheckField name="active" label={values.active ? "Disattiva formato" : "Attiva formato"} type="switch"/>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "30px"}}>
                  <Button type="button" variant="outline-primary" onClick={() => history.push(Routes.Dsp.Format.ViewAll.path)}>
                    Annulla
                  </Button>
                  <Button type="submit" variant="primary" disabled={!dirty || isSubmitting}>
                    {isNewFormat ? "Crea formato" : "Conferma modifiche"}
                  </Button>
                </div>
                <FeedbackAlert status={responseStatus}/>
              </FormikForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);

function getDefaultConfig() {
  return {
    name: "",
    width: null,
    height: null,
    active: true
  }
}
