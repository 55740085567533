import moment from "moment";

export function compareDates(dateA: moment.Moment, dateB: moment.Moment) {
  if (dateA.isSame(dateB))
    return 0;
  if (dateA.isBefore(dateB))
    return -1;
  else
    return 1;
}

export function compareStrDates(dateA: string, dateB: string) {
  return compareDates(moment(dateA), moment(dateB));
}