import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {Col, FormSelect, Row} from "@themesberg/react-bootstrap";
import it from 'date-fns/locale/it';

import "../../commons/forms/InputDateField.scss";
import {Campaign, CampaignKPIData, CampaignRowsTimeDataSeries} from "../../api/campaigns";
import {RowBriefTable} from "./RowBriefTable";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {KPI, useGetKpis} from "../../api/kpis";
import {roundNumberToTwoDecimals} from "./CampaignDataPanel";

const CHART_COLORS = [
  {
    lineColor: 'rgb(20,96,182)',
    bgColor: 'rgb(20,96,182,0.35)'
  },
  {
    lineColor: 'rgb(135,190,231)',
    bgColor: 'rgb(135,190,231,0.35)'
  },
  {
    lineColor: 'rgb(112,122,187)',
    bgColor: 'rgb(112,122,187,0.35)'
  }
];

interface Props {
  campaignKpis: Campaign["campaign_kpis"],
  rows: CampaignRowsTimeDataSeries["rows"],
  initialGraphKpiIdList: number[],
  showGraph?: boolean,
  campaignPanelKey?: string
}

export default withMultiFetchSWR(
  (props: Props) => [
    {useFetchFunc: useGetKpis}
  ], ({
        props: {campaignKpis, campaignPanelKey = "", rows, initialGraphKpiIdList, showGraph = true},
        endpointsResponse
      }) => {
    const [kpisResponse] = endpointsResponse.data;
    const kpis = kpisResponse.data.kpis;
    const [shownKpis, setShownKpis] = useState(campaignKpis.filter(kpi => initialGraphKpiIdList.includes(kpi.kpi_id)).slice(0, 3));
    const kpiIds = campaignKpis.map(k => k.kpi_id);
    const shownKpiIds = shownKpis.map(k => k.kpi_id);

    const [datasets, setDatasets] = useState<DatasetsData>({});

    useEffect(() => {
      if (rows && shownKpiIds) {
        setDatasets(getDatasetsForKpis(rows, kpis));
      }
    }, [rows, campaignKpis])

    return (
      <div className="chart-container mt-2">

        <Row className="my-2">
          <p style={{marginLeft: '5px', marginBottom: 0}}>
            Scegli quali KPI vedere nel grafico
          </p>
          {shownKpis.map((shownKpi, i) => (
            <Col xs={12} md={4} key={shownKpi.kpi_id}>
              <FormSelect
                defaultValue={shownKpi.kpi_id}
                onChange={(evt) => {
                  // @ts-ignore
                  let picked = campaignKpis.filter(cK => cK.kpi_id === parseInt(evt.target.value))[0];
                  let newKpis = [...shownKpis];
                  newKpis[i] = shownKpis.includes(picked) ? null : picked;
                  setShownKpis(newKpis);
                }}
              >
                {
                  /*
                  We want each option to render the kpi is currently showing and the other that
                  can be rendered so that the user can't select the same KPI twice.
                  */
                  kpis.filter(kpi => kpiIds.includes(kpi.id))
                    .filter(kpi => shownKpi.kpi_id === kpi.id || !shownKpiIds.includes(kpi.id))
                    .map(kpi =>
                      <option
                        key={kpi.id} value={kpi.id}
                      >{kpi.name}</option>
                    )
                }
              </FormSelect>
            </Col>
          ))}
        </Row>
        <Row>
          {datasets && <Line
            height={80}
            data={{
              datasets: shownKpis.map((kpi, index) => ({
                label: kpi.name,
                data: datasets[kpi.kpi_id],
                yAxisID: `y-${kpi.kpi_id}`,
                borderColor: CHART_COLORS[index].lineColor,
                backgroundColor: CHART_COLORS[index].bgColor,
                fill: true,
                tension: 0.4,
                isPercentage: kpis.find(k => k.id === kpi.kpi_id).is_percentage
              })),
            }}
            options={{
              interaction: {
                mode: 'index',
                intersect: false,
              },
              plugins: {
                tooltip: {
                  enabled: true,
                  mode: "index",
                  callbacks: {
                    label: (tooltipItem) => {
                      // @ts-ignore
                      return `${tooltipItem.dataset.label}: ${tooltipItem.dataset.isPercentage ?
                        `${roundNumberToTwoDecimals(tooltipItem.parsed.y, true)}%` :
                        `${tooltipItem.parsed.y}`
                      }`
                    }
                  }
                },
                legend: {
                  display: true,
                  position: "top"
                }
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: "day",
                    tooltipFormat: "dd/MM/yy"
                  },
                  adapters: {
                    date: {
                      locale: it
                    }
                  }
                },
                ...shownKpiIds.map(kpiId => ({
                  [`y-${kpiId}`]: {
                    type: "linear",
                    display: false,
                    grid: {
                      drawOnChartArea: false, // only want the grid lines for one axis to show up
                    }
                  }
                })).reduce((obj, attr) => ({...obj, ...attr}), {})
              }
            }}
          />}
        </Row>
        {rows?.length > 0 &&
          <Row>
            <RowBriefTable rows={rows} type={"default"} campaignPanelKey={campaignPanelKey}/>
          </Row>
        }
      </div>
    );
  });

interface DatasetsData {
  [kpiID: number]: Array<{
    x: string, //YYYY-MM-DD
    y: number
  }>
}

function getDatasetsForKpis(data: CampaignKPIData, kpis: KPI[]): DatasetsData {
  const datasets: DatasetsData = {};
  const kpiIdList = kpis.map(k => k.id);
  const percentageKpiIdList = kpis.filter(kpi => kpi.is_percentage).map(k => k.id);
  kpiIdList.forEach(kpiId => {
    datasets[kpiId] = [];
  });
  data.forEach(dataRow =>
    kpiIdList
      .filter(kpiId => kpiId in dataRow.values)
      .map(kpiId => dataRow.values[kpiId])
      .forEach(kpiValueEntry => datasets[kpiValueEntry.kpi].push(
        {
          x: dataRow.kpi_date,
          y: percentageKpiIdList.includes(kpiValueEntry.kpi) ?
            roundNumberToTwoDecimals(kpiValueEntry.value * 100, false) as unknown as number :
            roundNumberToTwoDecimals(kpiValueEntry.value, false) as unknown as number
        }
      ))
  );
  return datasets;
}
