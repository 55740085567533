import React from "react";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {useGetHomepageWidgets} from "../../api/widgets";
import {Campaign, useGetAllCampaigns} from "../../api/campaigns";
import {CampaignPreview} from "./CampaignPreview";
import styles from "./DashboardOverview.module.scss";
import moment from "moment";
import {isAuthenticatedUserInternal} from "../../commons/Utils";
import {Button} from "@themesberg/react-bootstrap";
import {ExternalUserOnly} from "../../components/usersUtils/ExternalUserOnly";
import {useGetAllCampaignDsp} from "../../api/dsp/campaignDsp";
import {useHistory} from "react-router-dom";
import {Routes} from "../../routes";
import {InternalUserOnly} from "../../components/usersUtils/InternalUserOnly";
import {CampaignWidget} from "./CampaignWidget";
import {faChartArea} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InComponentPreloader from "../../components/utils/InComponentPreloader";

export const DashboardOverview = withMultiFetchSWR(
  (props, urlParams) => [
    {useFetchFunc: useGetHomepageWidgets, shouldFetch: isAuthenticatedUserInternal()},
    {useFetchFunc: useGetAllCampaigns},
    {useFetchFunc: useGetAllCampaignDsp}
  ],
  ({endpointsResponse}) => {

    const [widgets, campaigns, dspList] = endpointsResponse.data;

    const history = useHistory();

    const expiringCampaigns = campaigns
      .sort((cA, cB) => getCampaignMaxEndDate(cB).localeCompare(getCampaignMaxEndDate(cA)))
      .slice(0, 3);

    const expensiveCampaigns = campaigns.sort((cA, cB) => (cB.budget ?? 0) - (cA.budget ?? 0)).slice(0, 3);

    return (
      <div className={styles.main}>
        <div className={styles.firstRow}>
          <h1 className="mb-0" style={{lineHeight: 0.8}}>Panoramica campagne</h1>
          <div className={styles.firstRowContent}>
            <div className={styles.horizontalFlex}>
              <FontAwesomeIcon className={styles.icon} icon={faChartArea}/>
              <div className={styles.number}>
                {campaigns.length}
              </div>
              <div className={styles.verticalFlex}>
                {
                  Object.entries(campaigns.reduce((acc, c) => ({...acc, [c.status]: acc[c.status] + 1}), {
                    PLANNED: 0,
                    ACTIVE: 0,
                    COMPLETED: 0
                  }))
                    .map(e => (
                      <div>{getCampaignsCounterLabel(e[1], e[0])}</div>
                    ))
                }
              </div>
            </div>
            <div className={styles.dsp} >
              <ExternalUserOnly>
                <div className={styles.externalDspText} role="link" onClick={() => history.push(Routes.Dsp.Campaign.ViewAll.path)}> {dspList.length} richieste campagne già create </div>
                <Button className={styles.button} onClick={() => history.push(Routes.Dsp.Campaign.Create.path)}>Crea nuova richiesta campagna</Button>
              </ExternalUserOnly>
              <InternalUserOnly>
                <div className={styles.internalDspText} role="link" onClick={() => history.push(Routes.Dsp.Campaign.ViewAll.path)}> {dspList.length} richieste campagne sono state create dai clienti</div>
              </InternalUserOnly>
            </div>
          </div>
          <InternalUserOnly>
            <div className={styles.widgetsGroup}>
              {widgets?.data.widgets.map(widget => (
                <CampaignWidget
                  title={widget.title}
                  value={widget.value}
                  campaignList={widget.campaigns
                    .map(campaign => (campaigns.find(c => c.id === campaign.campaign_id)))
                    .filter(campaign => campaign !== undefined)}
                />
              ))}
            </div>
          </InternalUserOnly>
          {expiringCampaigns.length > 0 &&
            <div className={styles.campaignsGroup}>
              <h2 className={styles.title}>Campagne in scadenza</h2>
              <div className={styles.campaigns}>
                {expiringCampaigns.map(c => <CampaignPreview campaign={c}/>)}
              </div>
            </div>
          }
          {
            expensiveCampaigns.length > 0 &&
            <div className={styles.campaignsGroup}>
              <h2 className={styles.title}>Campagne principali</h2>
              <div className={styles.campaigns}>
                {expensiveCampaigns.map(c => <CampaignPreview campaign={c}/>)}
              </div>
            </div>
          }
        </div>
      </div>
    );
  },
  InComponentPreloader
);

function getCampaignMaxEndDate(campaign: Campaign): string {
  return campaign.dates.map(d => moment(d.ends_at)).sort((dA, dB) => dB.valueOf() - dA.valueOf())[0].format("YYYYMMDD");
}

function getCampaignsCounterLabel(numberOfCampaigns: number, typeOfCampaigns: string): string {
  switch (typeOfCampaigns) {
    case "PLANNED":
      if(numberOfCampaigns === 1)
        return "1 Programmata";
      return `${numberOfCampaigns} Programmate`;
    case "ACTIVE":
      if(numberOfCampaigns === 1)
        return "1 Attiva";
      return `${numberOfCampaigns} Attive`;
    case "COMPLETED":
      if(numberOfCampaigns === 1)
        return "1 Completata";
      return `${numberOfCampaigns} Completate`;
  }
}
