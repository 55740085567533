import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "../common";
import axios, {AxiosResponse} from "axios";
import {useGetPaginatedItems, useSWRNoStaleData} from "../helpersSWR";
import useSWR from "swr";
import {axiosFetcher} from "../swr-fetcher";

export interface DspFormatBase {
  name: string,
  width: number,
  height: number,
  active: boolean
}

export type DspFormat = EntityWithId<DspFormatBase>;
export type DspFormatsPage = EndpointWrapperPaged<{ ad_formats: DspFormat[] }>

export function useGetAllDspFormats() {
  return useGetPaginatedItems(
    "dsp/ad-formats",
    (p: DspFormatsPage) => p.data.ad_formats
  )
}

export function useGetAllDspFormatsNoStaleData() {
  return useGetPaginatedItems(
    "dsp/ad-formats",
    (p: DspFormatsPage) => p.data.ad_formats,
    {},
    true
  );
}

export type DspFormatResponse = EndpointWrapper<{ ad_format: DspFormat }>;

export function useGetDspFormat(dspFormatId: number) {
  return useSWR<DspFormatResponse>(`/dsp/ad-formats/${dspFormatId}`, axiosFetcher);
}

export function useGetDspFormatNoStaleData(dspFormatId: number) {
  return useSWRNoStaleData<DspFormatResponse>(`/dsp/ad-formats/${dspFormatId}`, axiosFetcher) as ReturnType<typeof useSWR<DspFormatResponse>>;
}

export const createDspFormat = (data: DspFormatBase) => {
  return axios.post('/dsp/ad-formats', data) as Promise<AxiosResponse<DspFormatResponse>>;
};

export const updateDspFormat = (dspFormatId: number, data: DspFormatBase) => {
  return axios.put(`/dsp/ad-formats/${dspFormatId}`, data) as Promise<AxiosResponse<DspFormatResponse>>;
}

export const deleteDspFormat = (dspFormatId: number) => {
  return axios.delete(`/dsp/ad-formats/${dspFormatId}`);
};
