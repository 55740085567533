import {Button, CloseButton, Modal} from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import {DateRangePicker} from "react-dates/esm";
import {deleteCampaignInADateRange} from "../../../api/campaigns";
import {FeedbackAlert} from "../../utils/FeedbackAlert";

interface Props {
  isShown: boolean;
  campaignId: number;
  campaignName: string;
  onClosePopup: () => void;
}

export function RemoveCampaignDataInDateRangePopup(props: Props) {

  const {campaignId, campaignName, isShown, onClosePopup} = props;

  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  return (
    <Modal centered show={isShown} size="lg">
      <Modal.Header
        style={{justifyContent: "space-between", alignItems: "center", paddingBottom: "0.7rem", paddingTop: "0.7rem"}}
      >
        <Modal.Title style={{fontSize: "20px"}}>Rimuovi dati dalla campagna per range di date</Modal.Title>
        <CloseButton onClick={onClosePopup}/>
      </Modal.Header>
      <Modal.Body className="m-0 pt-2 px-3">
        <div className="mb-3">
          Seleziona un intervallo temporale per eliminare tutti i dati associati alla campagna
          <em style={{fontWeight: 700}}> {campaignName}</em> in queste date.
        </div>
        <DateRangePicker
          startDate={startDate}
          startDateId="start_date_filter_id"
          endDate={endDate}
          endDateId="end_date_filter_id"
          onDatesChange={({startDate, endDate}) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          isOutsideRange={() => false}
          startDatePlaceholderText="Data inizio"
          endDatePlaceholderText="Data fine"
          displayFormat="DD/MM/YYYY"
        />
      </Modal.Body>
      <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <Button variant="outline-primary" onClick={onClosePopup}>Annulla</Button>
          <Button
            onClick={() => {
              deleteCampaignInADateRange(
                campaignId,
                startDate.format('YYYY-MM-DD'),
                endDate.format('YYYY-MM-DD')
              )
                .then(r => setResponseStatus("SUCCESS"))
                .catch(e => setResponseStatus("FAIL"))
            }}
          >Rimuovi dati</Button>
        </div>
        <FeedbackAlert
          status={responseStatus}
          successMessage="I dati della campagna sono stati correttamente eliminati."
          failMessage="Ops! Qualcosa è andato storto nell'eliminazione dei dati nella campagna."
        />
      </Modal.Footer>
    </Modal>
  );
}