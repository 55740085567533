import {Form} from "@themesberg/react-bootstrap";
import {useField} from "formik";
import * as React from "react";
import {FormCheckType} from "@themesberg/react-bootstrap/lib/esm/FormCheck";

interface Props<F> {
  name: string;
  label?: string;
  className?: string;
  getIsChecked?: (value: F) => boolean;
  getValueToSetOnChange?: (isNowChecked: boolean, currentValue: F) => any;
  type?: FormCheckType;
  style?: any;
  disabled?: boolean;
  containerStyle?: any;
}

export const InputCheckField: <F>(props: Props<F>) => JSX.Element = (props) => {
  const [field, meta, helpers] = useField(props);

  const {label, className = "", getIsChecked, getValueToSetOnChange, containerStyle = {}, ...otherProps} = props;

  return (
    <Form.Group id={field.name} className={className} style={containerStyle}>
      <Form.Check
        inline
        label={label}
        checked={getIsChecked ? getIsChecked(field.value) : field.value}
        onChange={e => getValueToSetOnChange ?
          helpers.setValue(getValueToSetOnChange(e.target.checked, field.value)) : helpers.setValue(e.target.checked)}
        onBlur={field.onBlur}
        {...otherProps}
      />
    </Form.Group>
  );

};