import React, {useEffect, useRef, useState} from "react";
import {MultiStepProcessVisualizer} from "../../components/utils/MultiStepProcessVisualizer";
import {
  AnalysisStatus,
  deleteAnalysis,
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  Level1Data,
  Level1InputData,
  Level2Data,
  Level2InputData,
  Level3Data,
  Level3InputData,
  runLevel1Analysis,
  runLevel2Analysis,
  runLevel3Analysis
} from "../../api/DomainAnalysis";
import {Button, Card, CloseButton, Modal} from "@themesberg/react-bootstrap";
import {FormikProps} from "formik/dist/types";
import styles from "./DomainAnalysisPage.module.scss";
import _ from "lodash";
import {Level1Form} from "./Level1Form";
import {useHistory, useParams} from "react-router-dom";
import {Routes} from "../../routes";
import {Level2Form} from "./Level2Form";
import {Level3Form} from "./Level3Form";
import InComponentPreloader from "../../components/utils/InComponentPreloader";
import {Level1Output} from "./Level1Output";
import {useDomainAnalysisService} from "./DomainAnalysisPageHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "../../components/utils/Spinner";
import {FeedbackAlert} from "../../components/utils/FeedbackAlert";
import {ScrollToElementIfExists} from "../../components/utils/ScrollToElementIfExists";
import moment from "moment/moment";
import {Level2Output} from "./Level2Output";
import {Level3Output} from "./Level3Output";
import {buildRegexMatchPattern, useMutateManyV2} from "../../api/swr-fetcher";

export const DomainAnalysisPage = () => {

  const history = useHistory();
  const urlParams = useParams<{ analysisId }>();

  const {analysisId} = urlParams;
  const isNewAnalysis = analysisId === undefined;

  const {
    isInitialLoadingDone,
    getMaxPossibleAnalysisLevel,
    getLastSubmittedAnalysisLevel,
    getAnalysisStatus,
    submitDomainAnalysisLevel,
    getSubmittedInputForAnalysisLevel,
    getOutputForAnalysisLevel,
    getAnalysisSubmitFailure,
    getAnalysisId,
    getAnalysisInfo
  } = useDomainAnalysisService({
    levels: [
      {
        id: 1,
        fetchData: (id) => getLevel1Data(id).then(r => r.data),
        runAnalysis: (input) => runLevel1Analysis(input).then(r => r.data)
      },
      {
        id: 2,
        fetchData: (id) => getLevel2Data(id).then(r => r.data),
        runAnalysis: (input) => runLevel2Analysis(input).then(r => r.data)
      },
      {
        id: 3,
        fetchData: (id) => getLevel3Data(id).then(r => r.data),
        runAnalysis: (input) => runLevel3Analysis(input).then(r => r.data)
      },
    ],
    alreadyExistingAnalysis: !isNewAnalysis,
    analysisId: analysisId,
    analysisPoolingIntervalMillis: 15 * 1000
  });

  const [selectedLevelId, setSelectedLevelId] = useState<number>(1);
  const [isInputPanelVisible, setIsInputPanelVisible] = useState<boolean>(true);

  const [showDeleteAnalysisPopup, setShowDeleteAnalysisPopup] = useState<boolean>(false);
  const [errorMessageInDeleteAnalysisPopup, setErrorMessageInDeleteAnalysisPopup] = useState<string | null>(null);

  const mutateMany = useMutateManyV2();

  const removeAnalysis = () => {
    deleteAnalysis(analysisId).then(() => {
      mutateMany(buildRegexMatchPattern(("/domains/analysis")));
      history.push(Routes.DomainAnalysis.ViewAll.path);
    }).catch(e => {
      let messageError;
      if (e.response.status === 401)
        messageError = "Operazione non autorizzata";
      else
        messageError = "Ops, qualcosa è andato storto nell'eliminazione dell'analisi";
      setErrorMessageInDeleteAnalysisPopup(messageError);
    })
  };

  const formikRef = useRef<FormikProps<any>>();
  useEffect(() => {
    formikRef.current?.resetForm()
  }, [selectedLevelId, formikRef])

  const [futurePacingFromFormik, setFuturePacingFromFormik] = useState(false);

  function useSyncFuturePacingFromLvl1(values: Level1InputData) {
    useEffect(() => setFuturePacingFromFormik(values.use_future_pacing), [values.use_future_pacing])
  }

  const maxUILevel = getMaxPossibleAnalysisLevel() as number ?? (futurePacingFromFormik ? 3 : 1);

  if (!isInitialLoadingDone())
    return <InComponentPreloader/>;

  const allSubmittedInputs = _.range(1, getLastSubmittedAnalysisLevel() as number + 1)
    .reduce((o, lvl) => ({...o, [lvl]: getSubmittedInputForAnalysisLevel(lvl)}), {});

  function renderOutput() {
    const output = getOutputForAnalysisLevel(selectedLevelId);
    const outputData = {
      id: getAnalysisId(),
      request: getSubmittedInputForAnalysisLevel(selectedLevelId),
      status: AnalysisStatus.Completed,
      ...output
    }
    if (outputData.rows.length === 0) {
      return <div>L'analisi non ha prodotto risultati</div>
    } else {
      switch (selectedLevelId) {
        case 1:
          return <Level1Output outputData={outputData as Level1Data}/>
        case 2:
          return <Level2Output outputData={outputData as Level2Data}/>
        case 3:
          return <Level3Output outputData={outputData as Level3Data}/>
        default:
          return <div>Errore! La visualizzazione di questa analisi non è supportata</div>
      }
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column", gap: "30px"}}>
      <div>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px"
        }}>
          <h5 className="mb-0" style={{paddingLeft: "14px"}}>
            {isNewAnalysis ? "Nuova analisi" : `Analisi del ${moment(getAnalysisInfo().created_at).format("D MMMM yy, k:mm")}`}
          </h5>
          {!isNewAnalysis &&
            <Button
              type="button" variant="danger"
              onClick={() => setShowDeleteAnalysisPopup(true)}
            >
              Elimina analisi
            </Button>
          }
          {showDeleteAnalysisPopup &&
            <Modal centered show={showDeleteAnalysisPopup}>
              <Modal.Header style={{alignItems: "center"}}>
                <Modal.Title style={{fontSize: "18px"}}>Conferma eliminazione analisi</Modal.Title>
                <CloseButton onClick={() => setShowDeleteAnalysisPopup(false)}/>
              </Modal.Header>
              <Modal.Body className="m-0 pt-2 px-3">
                <div>
                  Confermi di voler eliminare definitivamente l'analisi del
                  <em>{` ${moment(getAnalysisInfo().created_at).format("D MMMM yy, k:mm")} `}</em>
                  ?
                </div>
              </Modal.Body>
              <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Button variant="outline-primary" onClick={() => setShowDeleteAnalysisPopup(false)}>
                    Annulla
                  </Button>
                  <Button variant="danger" onClick={removeAnalysis}>
                    Conferma
                  </Button>
                </div>
                {errorMessageInDeleteAnalysisPopup !== null &&
                  <FeedbackAlert
                    status="FAIL"
                    failMessage={errorMessageInDeleteAnalysisPopup}
                  />
                }
              </Modal.Footer>
            </Modal>
          }
        </div>
        <div>
          <MultiStepProcessVisualizer
            steps={_.range(1, maxUILevel + 1).map(level => ({
              title: `Analisi livello ${level}`,
              key: level,
              state: level === selectedLevelId ? "SELECTED" :
                ((
                  level <= (getLastSubmittedAnalysisLevel() as number) + 1 &&
                  getAnalysisStatus(Math.max(1, level - 1)) === "COMPLETED" &&
                  getOutputForAnalysisLevel(Math.max(1, level - 1)).rows.length > 0
                ) ? "SELECTABLE" : "DISABLED"),
              onClick: () => setSelectedLevelId(level)
            }))}
          />
        </div>
      </div>
      <Card>
        <Card.Header className={styles.inputHeader} onClick={() => setIsInputPanelVisible(prevState => !prevState)}>
          <h5 className="m-0">Input dell'analisi</h5>
          {isInputPanelVisible &&
            <FontAwesomeIcon className={styles.icon} icon={faAngleUp}/>
          }
          {!isInputPanelVisible &&
            <FontAwesomeIcon className={styles.icon} icon={faAngleDown}/>
          }
        </Card.Header>
        {isInputPanelVisible &&
          <Card.Body>
            {selectedLevelId === 1 &&
              <Level1Form
                submittedInputs={allSubmittedInputs}
                formikRef={formikRef}
                initialValues={getSubmittedInputForAnalysisLevel(selectedLevelId) ?? undefined}
                onSubmit={(input) => submitDomainAnalysisLevel(selectedLevelId, input)}
                useValues={selectedLevelId === 1 ? useSyncFuturePacingFromLvl1 : undefined}
              />
            }
            {selectedLevelId === 2 &&
              <Level2Form
                submittedInputs={allSubmittedInputs}
                formikRef={formikRef}
                initialValues={getSubmittedInputForAnalysisLevel(selectedLevelId) ?? undefined}
                onSubmit={(input) => submitDomainAnalysisLevel(selectedLevelId, input)}
                analysisId={getAnalysisId()}
              />
            }
            {selectedLevelId === 3 &&
              <Level3Form
                submittedInputs={allSubmittedInputs}
                formikRef={formikRef}
                initialValues={getSubmittedInputForAnalysisLevel(selectedLevelId) ?? undefined}
                onSubmit={(input) => submitDomainAnalysisLevel(selectedLevelId, input)}
                analysisId={getAnalysisId()}
              />
            }
          </Card.Body>
        }
      </Card>
      <div
        className={styles.buttonSection}
        style={getAnalysisStatus(selectedLevelId) === "NOT_STARTED" ? {paddingBottom: "70px"} : {}}
      >
        <Button
          className={styles.backBtn}
          variant="outline-primary"
          onClick={() => {
            if (selectedLevelId > 1)
              setSelectedLevelId(selectedLevelId - 1);
            else
              history.push(Routes.DomainAnalysis.ViewAll.path);
          }}
        >
          {selectedLevelId === 1 ? "Indietro" : `Vai al livello ${selectedLevelId - 1}`}
        </Button>
        <Button
          className={styles.runAnalysisBtn}
          variant={selectedLevelId <= getLastSubmittedAnalysisLevel() ? "outline-primary" : "primary"}
          onClick={() => formikRef.current.handleSubmit()}
        >
          {selectedLevelId <= getLastSubmittedAnalysisLevel() ? `Ricalcola livello ${selectedLevelId}` : `Calcola livello ${selectedLevelId}`}
        </Button>
        {selectedLevelId < getMaxPossibleAnalysisLevel() &&
          <Button
            className={styles.nextBtn}
            variant={selectedLevelId <= getLastSubmittedAnalysisLevel() && selectedLevelId < getMaxPossibleAnalysisLevel() ? "primary" : "outline-primary"}
            disabled={getAnalysisStatus(selectedLevelId) !== "COMPLETED" || getOutputForAnalysisLevel(selectedLevelId).rows.length === 0}
            onClick={() => setSelectedLevelId(selectedLevelId + 1)}
          >
            Vai al livello {selectedLevelId + 1}
          </Button>
        }
      </div>
      <ScrollToElementIfExists>
        {(ref) => <>
          {getAnalysisSubmitFailure(selectedLevelId) !== null &&
            <FeedbackAlert
              status={"FAIL"}
              failMessage="Errore! Il server non è riuscito a prendere in carico l'analisi"
              ref={ref}
            />
          }
        </>}
      </ScrollToElementIfExists>
      {getAnalysisStatus(selectedLevelId) !== "NOT_STARTED" &&
        <Card className="mb-4">
          <Card.Header style={{padding: "15px 24px"}}>
            <h5 className="m-0">Output dell'analisi</h5>
          </Card.Header>
          <Card.Body>
            {getAnalysisStatus(selectedLevelId) === "COMPLETED" &&
              renderOutput()
            }
            {getAnalysisStatus(selectedLevelId) === "IN_PROGRESS" &&
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "12px"}}>
                <Spinner/>
                Analisi in corso...
              </div>
            }
          </Card.Body>
        </Card>
      }
    </div>
  );

};


export interface InputDataMap {
  1?: Level1InputData,
  2?: Level2InputData,
  3?: Level3InputData
}

export interface LevelInputFormProps<LevelInputValues> {
  initialValues?: LevelInputValues,
  formikRef: ReturnType<typeof useRef<FormikProps<any>>>,
  onSubmit: (values: LevelInputValues) => Promise<void>,
  useValues?: (values: LevelInputValues) => void,
  submittedInputs: InputDataMap
}

export type LevelInputFormPropWithId<LevelInputValues> = LevelInputFormProps<LevelInputValues> & {
  analysisId: string
}

export function FormikHelperWithUseValues(props: { values: any, useValues?: (values: any) => void }) {
  if (props.useValues)
    props.useValues(props.values);
  return null;
}