import * as React from "react";
import {useEffect, useState} from "react";
import {Schema} from "yup";
import {Form as FormikForm, Formik, FormikValues, useFormikContext} from "formik";
import styles from "./SmartTable.module.scss";
import {Form, Pagination, Table} from "@themesberg/react-bootstrap";
import {faArrowDown, faArrowUp, faEye, faEyeSlash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SmallButton} from "../../components/utils/buttons/SmallButton";
import {FormikErrors} from "formik/dist/types";
import {ItemsSelectionPopup} from "../../components/utils/ItemsSelectionPopup";

interface Props<T, F> {
  elements?: T[];
  syncWithElementsProp?: boolean;
  pagination?: boolean;
  tableKey: string;
  getElementKey: (element: T) => number | string;
  enableHiddenColumns?: boolean;
  sort?: {
    defaultSortingColIndex: number,
    defaultArrowVisible: boolean,
    defaultSortingDirection?: "asc" | "desc"
  },
  filter?: {
    filterParamsInitialValue: F;
    applyFiltersAsyncFunction?: (filterParams: F) => Promise<T[]>;
    applyFiltersFunction?: (elements: T[], filterParams: F) => T[];
    useApplyFiltersHook?: (filterParams: F, setElements: (elements: T[]) => void, setIsLoading: (isLoading: boolean) => void, elements: T[]) => void,
    filterParamsValidationSchema?: Schema,
    filterParamsValidate?: (filterParams: F) => FormikErrors<F>
  };
  columns: Array<{
    title: string | JSX.Element;
    cellRenderer: (element: T, triggerElementsChanged: () => void) => JSX.Element | string | number;
    compareElementsByColumn?: (elementA: T, elementB: T) => number;
    columnIsInitiallyVisible?: boolean;
    columnCanBeHidden?: boolean;
    columnFilter?: {
      key: keyof F & string;
      fieldsRenderer: (helpers: {
        getFieldName: (name: string) => string,
        setFieldValue: (fieldValue: any) => void,
        fieldValue: any,
        values: F,
        setValues: (values: F) => any
      }) => JSX.Element;
      filterParamsFieldDefaultValue: () => any;
    };
  }>;
  getRowColor?: (element: T, rowKey: number | string) => React.CSSProperties["color"];
  onRowClicked?: (element: T, rowKey: number | string) => void;
}

export function SmartTable<T, F>(props: Props<T, F>) {

  const {
    columns,
    pagination,
    syncWithElementsProp = true,
    enableHiddenColumns,
    elements: elementsFromProp = [],
    filter,
    sort,
    tableKey,
    getElementKey,
    getRowColor,
    onRowClicked
  } = props;
  const defaultSortingColIndex = sort?.defaultSortingColIndex;
  const isSortingEnabled = sort !== undefined;
  if (isSortingEnabled && sort.defaultSortingDirection === undefined)
    sort.defaultSortingDirection = "asc";
  const isFilteringEnabled = filter !== undefined;
  const [showSelectFiltersPopup, setShowSelectFiltersPopup] = useState(false);

  const [elements, setElements] = useState(elementsFromProp);

  const storageKeyVisibleColIndexList = `smartTable_${tableKey}_visibleColumns`;
  const [visibleColumnsIndexList, _setVisibleColumnsIndexList] = useState([]);
  const [showVisibleColumnsPopup, setShowVisibleColumnsPopup] = useState(false);

  const [sortingColumnIndex, setSortingColumnIndex] = useState<number | null>(sort?.defaultArrowVisible ? sort.defaultSortingColIndex : null);
  const [sortingDirection, setSortingDirection] = useState<"asc" | "desc">(sort.defaultSortingDirection);
  const [sortedElements, setSortedElements] = useState([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (syncWithElementsProp === true)
      setElements(elementsFromProp);
  }, [syncWithElementsProp, elementsFromProp])

  useEffect(() => {
    _setVisibleColumnsIndexList(getInitialVisibleColIndexList());
  }, [columns])

  let filterStrategy: "fn" | "hook" | "disabled";
  if (!isFilteringEnabled)
    filterStrategy = "disabled";
  else if (filter.useApplyFiltersHook)
    filterStrategy = "hook";
  else if (filter.applyFiltersAsyncFunction || filter.applyFiltersFunction)
    filterStrategy = "fn";
  else
    throw Error("One of the fields 'applyFiltersFunction', 'applyFiltersComponent' must be defined if filtering is enabled");

  function runFiltering(filterParams) {
    if (isFilteringEnabled && filter.applyFiltersAsyncFunction) {
      return filter.applyFiltersAsyncFunction(filterParams).then(setElements);
    } else if (isFilteringEnabled && filter.applyFiltersFunction) {
      setElements(filter.applyFiltersFunction(elementsFromProp, filterParams));
      return Promise.resolve();
    } else {
      console.error("Filtering by filterFunction is not enabled!");
    }
  }

  function getInitialVisibleColIndexList() {
    const listFromStorage = window.localStorage.getItem(storageKeyVisibleColIndexList);

    if (listFromStorage !== null)
      return JSON.parse(listFromStorage) as number[];
    else
      return columns
        .map((col, index) => index)
        .filter(colIndex => columns[colIndex].columnIsInitiallyVisible === undefined || columns[colIndex].columnIsInitiallyVisible)
  }

  function setVisibleColumnsIndexList(visibleColumnsIndexList: number[]) {
    _setVisibleColumnsIndexList(visibleColumnsIndexList);
    window.localStorage.setItem(storageKeyVisibleColIndexList, JSON.stringify(visibleColumnsIndexList));
  }

  function hideColumn(columnIndex: number) {
    setVisibleColumnsIndexList(visibleColumnsIndexList.filter(i => columnIndex !== i));
  }

  function isColumnHidden(colIndex) {
    return columns[colIndex] && (columns[colIndex].columnCanBeHidden === undefined || columns[colIndex].columnCanBeHidden) &&
      !visibleColumnsIndexList.includes(colIndex);
  }

  useEffect(() => {
    if (isSortingEnabled && enableHiddenColumns) {
      if (isColumnHidden(sortingColumnIndex)) {
        setSortingColumnIndex(sort.defaultArrowVisible ? sort.defaultSortingColIndex : null);
        setSortingDirection("asc");
      }
    }
  }, [isSortingEnabled, enableHiddenColumns, sortingColumnIndex, visibleColumnsIndexList])

  useEffect(() => {
    if (isSortingEnabled) {
      let sortingIndex = sortingColumnIndex;
      if (sortingColumnIndex === null)
        sortingIndex = Math.min(defaultSortingColIndex, columns.length - 1);
      const sortingCol = columns[sortingIndex];
      if ("compareElementsByColumn" in sortingCol) {
        let newElements = [...elements];
        if (sortingDirection === "asc")
          newElements.sort(sortingCol.compareElementsByColumn);
        else
          newElements.sort((elA, elB) => sortingCol.compareElementsByColumn(elB, elA));
        setSortedElements(newElements);
      } else {
        setSortingColumnIndex(sort.defaultArrowVisible ? sort.defaultSortingColIndex : null);
        setSortingDirection("asc");
        setSortedElements(elements);
      }
    }
  }, [sortingColumnIndex, sortingDirection, elements, columns])

  function toggleSortDirectionForColumn(columnIndex: number) {
    if (sortingColumnIndex !== columnIndex) {
      setSortingColumnIndex(columnIndex)
    } else {
      if (sortingDirection === "asc")
        setSortingDirection("desc");
      else
        setSortingDirection("asc")
    }
  }

  const [onElementsChanged, setOnElementsChanged] = useState<() => void>(() => {
  });

  const [currentFilterParams, setCurrentFilterParams] = useState<F | null>(null);

  const tableElements = isSortingEnabled ? sortedElements : elements;

  useEffect(() => {
    if (page > Math.ceil(tableElements.length / pageSize) - 1)
      setPage(Math.max(Math.ceil(tableElements.length / pageSize) - 1, 0));
  }, [page, pageSize, tableElements])

  const [tableElementsPaginated, setTableElementsPaginated] = useState<T[]>([]);
  useEffect(() => {
    if (pagination)
      setTableElementsPaginated(tableElements.slice(page * pageSize, (page + 1) * pageSize));
    else
      setTableElementsPaginated(tableElements);
  }, [tableElements, page, pageSize]);

  if (filterStrategy === "hook")
    filter.useApplyFiltersHook(currentFilterParams, setElements, setIsLoading, elements);

  const optionalColumns = columns.map((col, index) => ({id: index, ...col}))
    .filter(col => col.columnCanBeHidden === undefined || col.columnCanBeHidden);

  let filtersSection = <></>;
  if (isFilteringEnabled) {
    filtersSection = (
      <Formik
        initialValues={filter.filterParamsInitialValue as FormikValues}
        validationSchema={filter.filterParamsValidationSchema}
        validate={filter.filterParamsValidate}
        onSubmit={(values, {setSubmitting}) => {
          if (filterStrategy === "fn") {
            setIsLoading(true);
            runFiltering(values).then(() => {
              setIsLoading(false);
            })
          } else {
            setCurrentFilterParams(values as F);
          }
        }}
      >
        {({values, setValues, submitForm, isSubmitting, setFieldValue, errors, setErrors, touched, setTouched}) => (
          <FormikForm>
            <div className={styles.filterHeaderContainer}
                 style={Object.keys(values).length > 0 ? {} : {marginBottom: 0}}>
              <h6 style={{display: "inline-block", marginBottom: 0}}>Filtri</h6>
              <SmallButton variant="outline-primary" onClick={() => setShowSelectFiltersPopup(true)}>
                {Object.keys(values).length > 0 ? "Modifica filtri" : "Abilita filtri"}
              </SmallButton>
              {Object.keys(values).length > 0 &&
                <SmallButton disabled={isSubmitting} onClick={submitForm}>Applica filtri</SmallButton>
              }
            </div>
            <div className={styles.filtersContainer}>
              {columns
                .filter(col => col.columnFilter !== undefined)
                .filter(col => col.columnFilter.key in values)
                .map(col => (
                  <React.Fragment key={`${tableKey}_filter_${col.columnFilter.key}`}>
                    <SmallButton
                      key={`${tableKey}_filter_${col.columnFilter.key}_btn`}
                      variant="danger"
                      onClick={() => {
                        const newFilterParams = {...values};
                        delete newFilterParams[col.columnFilter.key];
                        setValues(newFilterParams);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark}/>
                    </SmallButton>
                    <div key={`${tableKey}_filter_${col.columnFilter.key}_name`} className={styles.filterName}>
                      {col.title}
                    </div>
                    <div key={`${tableKey}_filter_${col.columnFilter.key}_fields`}
                         className={styles.filterFieldsContainer}>
                      {col.columnFilter.fieldsRenderer({
                        getFieldName: name => `${col.columnFilter.key}.${name}`,
                        setFieldValue: value => setFieldValue(col.columnFilter.key, value),
                        fieldValue: values[col.columnFilter.key],
                        values: values as F,
                        setValues
                      })}
                    </div>
                  </React.Fragment>
                ))
              }
            </div>
            <FilterParamsHelper
              isFilterEnabled={isFilteringEnabled}
              visibleColumnsIndex={visibleColumnsIndexList}
              showSelectFiltersPopup={showSelectFiltersPopup}
              setShowSelectFiltersPopup={setShowSelectFiltersPopup}
              setOnElementsChanged={setOnElementsChanged}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              baseProps={props}
              tableKey={tableKey}
            />
          </FormikForm>
        )}
      </Formik>
    );
  }

  return (
    <div className={styles.container}>
      {filtersSection}
      {enableHiddenColumns &&
        <SmallButton className={styles.colVisibilityBtn} onClick={() => setShowVisibleColumnsPopup(true)}>
          <FontAwesomeIcon style={{marginRight: "5px"}} icon={faEye}/>
          Visibilità colonne
        </SmallButton>
      }
      <div className={styles.tableContainer}>
        <Table hover className="user-table align-items-center">
          <thead className="thead-light">
          <tr>
            {columns.map((col, colIndex) => {
              if (isColumnHidden(colIndex))
                return null;
              const isColSortable = isSortingEnabled && col.compareElementsByColumn !== undefined;
              return (
                <th key={`${tableKey}_colHeader_${colIndex}`}>
                  <div
                    className={`${styles.tableHeaderCell} ${isColSortable ? styles.sortable : ""}`}
                  >
                    <div
                      className={styles.clickableToSort}
                      onClick={() => isColSortable && toggleSortDirectionForColumn(colIndex)}
                    >{col.title}</div>
                    {sortingColumnIndex === colIndex && sortingDirection === "asc" &&
                      <FontAwesomeIcon
                        className={styles.clickableToSort}
                        icon={faArrowDown}
                        onClick={() => isColSortable && toggleSortDirectionForColumn(colIndex)}
                      />
                    }
                    {sortingColumnIndex === colIndex && sortingDirection === "desc" &&
                      <FontAwesomeIcon
                        className={styles.clickableToSort}
                        icon={faArrowUp}
                        onClick={() => isColSortable && toggleSortDirectionForColumn(colIndex)}
                      />
                    }
                    {enableHiddenColumns && (col.columnCanBeHidden === undefined || col.columnCanBeHidden) &&
                      <span style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon={faEyeSlash} onClick={() => hideColumn(colIndex)}/>
                      </span>
                    }
                  </div>
                </th>
              )
            })}
          </tr>
          </thead>
          <tbody>
          {tableElementsPaginated.map((element) => (
            <tr
              key={`${tableKey}_element_${getElementKey(element)}`}
              style={getRowColor ? {cursor: "pointer", backgroundColor: getRowColor(element, getElementKey(element))} : {}}
              onClick={onRowClicked ? () => onRowClicked(element, getElementKey(element)) : undefined}
            >
              {columns.map((col, colIndex) => {
                if (isColumnHidden(colIndex))
                  return null;
                return (
                  <td
                    key={`${tableKey}_element_${getElementKey(element)}_col_${colIndex}`}
                  >
                    {col.cellRenderer(element, onElementsChanged)}
                  </td>
                )
              })}
            </tr>
          ))}
          </tbody>
        </Table>
      </div>
      {tableElements.length === 0 && <div style={{marginTop: "50px"}}/>}
      {pagination && tableElements.length > 0 &&
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "10px"
        }}>
          <div>
            Elementi per pagina:
            <Form.Control
              as="select"
              className="input form-select"
              value={pageSize}
              onChange={(e) => setPageSize(parseInt(e.target.value))}
              children={
                <>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </>
              }
            />
          </div>
          <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
            <p className="m-0">
              Stai visualizzando pagina {page + 1} di {Math.ceil(tableElements.length / pageSize)}
            </p>
            <Pagination style={{justifyContent: "flex-end"}}>
              <Pagination.Prev disabled={page === 0} onClick={() => setPage(page - 1)}>
                Precedente
              </Pagination.Prev>
              <Pagination.Next disabled={page === Math.ceil(tableElements.length / pageSize) - 1}
                               onClick={() => setPage(page + 1)}>
                Successiva
              </Pagination.Next>
            </Pagination>
          </div>
        </div>
      }
      <ItemsSelectionPopup<(typeof optionalColumns)[number]>
        popupKey={`${tableKey}-visible-col-popup`}
        itemList={optionalColumns}
        alreadyAssociatedItemList={optionalColumns.filter(col => visibleColumnsIndexList.includes(col.id))}
        selectAllItemsBtnDescription="Seleziona tutte"
        deselectAllItemsBtnDescription="Deseleziona tutte"
        title="Seleziona le colonne da visualizzare nella tabella"
        isShown={showVisibleColumnsPopup}
        onClosePopup={() => setShowVisibleColumnsPopup(false)}
        getItemView={col => <div>{col.title}</div>}
        canSelectAllItems={true}
        onItemsSelected={selectedCols => {
          setVisibleColumnsIndexList(selectedCols.map(col => col.id));
          setShowVisibleColumnsPopup(false);
        }}
      />
    </div>
  );
}

interface FilterParamsHelperProps {
  baseProps: Props<any, any>;
  isFilterEnabled: boolean;
  visibleColumnsIndex: number[];
  showSelectFiltersPopup: boolean;
  setShowSelectFiltersPopup: (shown: boolean) => void;
  setOnElementsChanged: (onElementsChanged: () => void) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  tableKey: string | number
}

function FilterParamsHelper(props: FilterParamsHelperProps) {
  const {
    baseProps,
    isLoading,
    setIsLoading,
    isFilterEnabled,
    visibleColumnsIndex,
    showSelectFiltersPopup,
    setShowSelectFiltersPopup,
    setOnElementsChanged,
    tableKey
  } = props;
  const {columns, enableHiddenColumns, filter, syncWithElementsProp} = baseProps;

  const {values, setValues, submitForm, setSubmitting, errors, isSubmitting} = useFormikContext();

  useEffect(() => {
    if (isFilterEnabled)
      submitForm();
  }, [values]);

  useEffect(() => {
    if (isFilterEnabled && (syncWithElementsProp === undefined || syncWithElementsProp) && (filter.applyFiltersFunction || filter.applyFiltersAsyncFunction))
      submitForm();
  }, [props.baseProps.elements]);

  useEffect(() => {
    setOnElementsChanged(submitForm)
  }, [submitForm, setOnElementsChanged])

  useEffect(() => {
    setSubmitting(isLoading);
  }, [isLoading, isSubmitting])

  useEffect(() => {
    setIsLoading(false)
  }, [values])

  return (
    <>
      <ItemsSelectionPopup<(typeof columns)[number]>
        popupKey={`${tableKey}-filter-col-popup`}
        itemList={columns.filter(col => col.columnFilter !== undefined)}
        alreadyAssociatedItemList={columns
          .filter(col => col.columnFilter !== undefined && Object.keys(values).includes(col.columnFilter.key))
        }
        title="Seleziona le colonne su cui applicare un filtro"
        isShown={showSelectFiltersPopup}
        onClosePopup={() => setShowSelectFiltersPopup(false)}
        getItemView={col => <div>{col.title}</div>}
        canSelectAllItems={true}
        selectAllItemsBtnDescription="Seleziona tutte"
        deselectAllItemsBtnDescription="Deseleziona tutte"
        onItemsSelected={(selectedCols) => {
          const oldKeys = Object.keys(values);
          const newColsToFilter = (selectedCols as Props<any, any>["columns"]).filter(col => !oldKeys.includes(col.columnFilter.key));
          const newColKeys = (selectedCols as Props<any, any>["columns"]).map(col => col.columnFilter.key)
          const oldColKeysToRemove = oldKeys.filter(oldKey => !newColKeys.includes(oldKey));

          const newValues = {...(values as any)};
          newColsToFilter.forEach(col => {
            newValues[col.columnFilter.key] = col.columnFilter.filterParamsFieldDefaultValue();
          });
          oldColKeysToRemove.forEach(key => {
            delete newValues[key];
          });
          setValues(newValues);
          setShowSelectFiltersPopup(false);
        }}
      />
    </>
  );
}