import React, {ReactNode} from 'react';
import {Button, Card, Col, Nav, Row, Tab} from "@themesberg/react-bootstrap";
import {withMultiFetchSWR} from "../api/helpersSWR";
import {Campaign, useGetCampaign, useGetCampaignDataRowsForAttachedTemplate} from "../api/campaigns";
import CampaignDataPanel from "../components/Campaigns/CampaignDataPanel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine, faChartPie} from "@fortawesome/free-solid-svg-icons";
import CampaignGraphAndTableTimeSeries from "../components/Campaigns/CampaignGraphAndTableTimeSeries";
import styles from "./CampaignReportTemplateViewPage.module.scss";
import CampaignGraphAndTableSubdomainSeries from "../components/Campaigns/CampaignGraphAndTableSubdomainSeries";
import {useHistory} from "react-router-dom";
import {Routes} from "../routes";
import InComponentPreloader from "../components/utils/InComponentPreloader";

interface UrlParams {
  campaignId: number;
}

const ComponentCardWrapper = (props: { children: ReactNode, campaign: Campaign }) => (
  <Card border="light" className="table-wrapper table-responsive shadow-sm mt-2 mb-4">
    <Card.Header>
      <Row className="align-items-center">
        <Col xs={12} md={6}>
          <h5 className="mb-0">{props.campaign.name}</h5>
        </Col>
        <Col xs={12} md={6} className="text-right">
          <h5 className="mb-0">Advertiser: {props.campaign.advertiser}</h5>
        </Col>
      </Row>
    </Card.Header>
    <Card.Body className="pt-0 mt-3">
      {props.children}
    </Card.Body>
  </Card>
);

export const CampaignReportTemplateViewPage = withMultiFetchSWR(
  (props: {}, {campaignId}: UrlParams) => [
    {useFetchFunc: () => useGetCampaign(campaignId)},
    {useFetchFunc: () => useGetCampaignDataRowsForAttachedTemplate(campaignId, "default")},
    {useFetchFunc: () => useGetCampaignDataRowsForAttachedTemplate(campaignId, "subdomain")},
  ], ({urlParams, endpointsResponse}) => {

    const history = useHistory();

    const [campaignResponse, campaignTimeSeriesRows, campaignSubdomainsSeriesRows] = endpointsResponse.data;
    const campaign = campaignResponse.data.campaign;

    const reportTemplate = campaign.templates?.[0];

    if (!reportTemplate) {
      return (
        <ComponentCardWrapper campaign={campaign}>
          <div style={{display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-start"}}>
            <div> Non è stato ancora associato alcun Report Template a questa campagna</div>
            <Button onClick={() => history.push(Routes.Campaigns.ViewAll.path)}>
              Torna alla lista di tutte le campagne
            </Button>
          </div>
        </ComponentCardWrapper>
      )
    }

    const timeDataSeries = reportTemplate.tabs.find(tab => tab.view_type === "default");
    const isTimeDataSeriesActive = timeDataSeries !== undefined;
    const subdomainDataSeries = reportTemplate.tabs.find(tab => tab.view_type === "subdomain");
    const isSubdomainDataSeriesActive = subdomainDataSeries !== undefined;

    return (
      <ComponentCardWrapper campaign={campaign}>
        {campaign.status === "PLANNED" &&
          <h5 className="mb-0">Campagna non ancora iniziata</h5>
        }
        {campaign.status !== "PLANNED" &&
          <Tab.Container defaultActiveKey="default-tab">
            <Nav fill className={`mx-0 nav-tabs ${styles.tabContainer}`}>
              {isTimeDataSeriesActive &&
                <Nav.Item className="mb-0">
                  <Nav.Link eventKey="default-tab">
                    <FontAwesomeIcon icon={faChartLine} className="me-2"/> Serie temporale
                  </Nav.Link>
                </Nav.Item>
              }
              {isSubdomainDataSeriesActive &&
                <Nav.Item className="mb-0">
                  <Nav.Link eventKey="subdomain-tab">
                    <FontAwesomeIcon icon={faChartPie} className="me-2"/> Serie per domini
                  </Nav.Link>
                </Nav.Item>
              }
            </Nav>
            <Tab.Content className={styles.tabContent}>
              {isTimeDataSeriesActive &&
                <Tab.Pane eventKey="default-tab">
                  <div>
                    <CampaignGraphAndTableTimeSeries
                      rows={campaignTimeSeriesRows}
                      campaignKpis={campaign.campaign_kpis.filter(kpi => timeDataSeries.table.kpis.includes(kpi.kpi_id))}
                      initialGraphKpiIdList={timeDataSeries.charts[0]?.chart_type === "line" ? timeDataSeries.charts[0].kpis : []}
                      showGraph={timeDataSeries.charts[0]?.chart_type === "line"}
                      campaignPanelKey={`campaign-[${campaign.id}]-visualize-report-data-time-series`}
                    />
                  </div>
                </Tab.Pane>
              }
              {isSubdomainDataSeriesActive &&
                <Tab.Pane eventKey="subdomain-tab">
                  <div>
                    <CampaignGraphAndTableSubdomainSeries
                      rows={campaignSubdomainsSeriesRows}
                      campaignKpis={campaign.campaign_kpis.filter(kpi => subdomainDataSeries.table.kpis.includes(kpi.kpi_id))}
                      initialGraphKpiId={subdomainDataSeries.charts[0]?.chart_type === "pie" ? subdomainDataSeries.charts[0].kpi_id : null}
                      showGraph={subdomainDataSeries.charts[0]?.chart_type === "pie"}
                      campaignPanelKey={`campaign-[${campaign.id}]-visualize-report-data-domain-series`}
                    />
                  </div>
                </Tab.Pane>
              }
            </Tab.Content>
          </Tab.Container>
        }
      </ComponentCardWrapper>
    );
  },
  InComponentPreloader
);
