import {useSWRNoStaleData} from "../helpersSWR";
import {axiosFetcher} from "../swr-fetcher";
import axios from "axios";
import {EndpointWrapper} from "../common";
import useSWR from "swr";

interface TargetKpiBase {
  kpi_id: number,
  suggestion: string
}

export interface TargetKpiSuggestionsGetResponse {
  kpis: TargetKpiSuggestions
}

export interface TargetKpiSuggestions {
    vid: TargetKpiBase[]
    disp: TargetKpiBase[]
}

export function useGetTargetKpiSuggestions() {
  return useSWR<EndpointWrapper<TargetKpiSuggestionsGetResponse>>(`/settings/dsp/kpis-and-suggestions`, axiosFetcher);
}

export function useGetTargetKpiSuggestionsNoStaleData() {
  return useSWRNoStaleData<EndpointWrapper<TargetKpiSuggestionsGetResponse>>(`/settings/dsp/kpis-and-suggestions`, axiosFetcher);
}

export const updateTargetKpiSuggestions = (data: TargetKpiSuggestions) => {
  return axios.put(`/settings/dsp/kpis-and-suggestions`, data).then(res => res.data);
};
