import React from 'react';
import {Image} from '@themesberg/react-bootstrap';

import LoadLogo from "../../assets/img/helm.png";

export default (props) => {
    const {show} = props;

  return (
    <div
      className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}
      style={{backgroundColor: "rgba(255, 255, 255, 0.4)"}}
    >
      <Image className="loader-element animate__animated animate__jackInTheBox" src={LoadLogo} height={40}/>
    </div>
  );
};
