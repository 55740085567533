import {withMultiFetchSWR} from "../api/helpersSWR";
import {CampaignNotification, deleteNotification} from "../api/notifications";
import {useGetNotificationsSettings} from "../api/settings";
import {Badge, ListGroup} from "@themesberg/react-bootstrap";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useSWRConfig} from "swr";
import {buildRegexMatchPattern, useMutateManyV2} from "../api/swr-fetcher";

interface Props {
  notification: CampaignNotification
}

export const Notification = withMultiFetchSWR(
  (props: Props) => [
    {useFetchFunc: useGetNotificationsSettings}
  ], ({props, endpointsResponse}) => {

    const {notification} = props;
    const [notificationSettings] = endpointsResponse.data;

    const mutateMany = useMutateManyV2();

    const thresholdValue = notificationSettings.data.thresholds.find(threshold => threshold.kpi_id === notification.kpi.id)?.value;

    return (
      <ListGroup.Item className="border-bottom border-light">
        <div style={{display: "flex", flexDirection: "column"}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
            <Badge style={{fontSize: "16px", width: "auto"}} bg="light" text="dark">{notification.campaign.name}</Badge>
            <button
              style={{alignSelf: "flex-end", padding: 0, margin: 0, backgroundColor: "transparent", border: 0}}
              onClick={() =>
                deleteNotification(notification.id).then(r => mutateMany(buildRegexMatchPattern('notifications')))
              }
            >
              <FontAwesomeIcon
                icon={faXmark}/>
            </button>
          </div>
          <small style={{paddingLeft: "7px"}}>Il kpi {notification.kpi.name} ha superato la soglia di {thresholdValue ?? "UNKNOWN"}</small>
        </div>
      </ListGroup.Item>
    );
  }
)