import {useGetPaginatedItems, useSWRNoStaleData} from "./helpersSWR";
import {EndpointWrapper, EndpointWrapperPaged} from "./common";
import axios, {AxiosResponse} from "axios";
import useSWR, {SWRConfiguration} from "swr";
import {axiosFetcher} from "./swr-fetcher";

export interface Level1InputData {
  use_future_pacing: boolean,
  campaigns: number[],
  objectives: Array<{
    kpi_id: number,
    target: number
  }>,
  attributes: number[],
  domain_whitelist?: string[] | null,
  domain_blacklist?: string[] | null,
  dates: Array<{
    starts_at: string, //YYYY-MM-DD
    ends_at: string, //YYYY-MM-DD
  }>
}

export interface Level2InputData {
  level_1_id: string,
  delivered_kpi_threshold: number
}

export interface Level3InputData {
  level_1_id: string,
  win_rate_threshold: number,
  combinations: ExcludedRow[]
}

export interface DomainAnalysisLevelData<I, OR> {
  id: string,
  request: I,
  rows: OR[],
  status: AnalysisStatus
}

export type ExcludedRow = Omit<Level1OutputRow, "values">;

export interface Level1OutputRow {
  combination: Array<{
    attribute_id: number,
    value: string
  }>,
  domain: string,
  values: Array<{
    kpi_id: number,
    kpi_value: number
  }>
}

export type Level2OutputRow = Level1OutputRow;
export type Level3OutputRow = Level1OutputRow;

export type Level1Data = DomainAnalysisLevelData<Level1InputData, Level1OutputRow>;
export type Level2Data = DomainAnalysisLevelData<Level2InputData, Level2OutputRow>;
export type Level3Data = DomainAnalysisLevelData<Level3InputData, Level3OutputRow> & {
  suggestions: Level3OutputRow[],
  included: Level3OutputRow[]
};

export function useGetLevel1Data(analysisId: string | null) {
  return useSWR<EndpointWrapper<Level1Data>>(analysisId !== null ? `/domains/analysis/${analysisId}/level-1` : null, axiosFetcher);
}

export function useGetLevel2Data(analysisId: string | null) {
  return useSWR<EndpointWrapper<Level2Data>>(analysisId !== null ? `/domains/analysis/${analysisId}/level-2` : null, axiosFetcher);
}

export function useGetLevel3Data(analysisId: string | null) {
  return useSWR<EndpointWrapper<Level3Data>>(analysisId !== null ? `/domains/analysis/${analysisId}/level-3` : null, axiosFetcher);
}

export function useGetLevel1DataNoStaleData(analysisId: string | null, options?: SWRConfiguration) {
  return useSWRNoStaleData<EndpointWrapper<Level1Data>>(analysisId !== null ? `/domains/analysis/${analysisId}/level-1` : null, axiosFetcher, options);
}

export function useGetLevel2DataNoStaleData(analysisId: string | null, options?: SWRConfiguration) {
  return useSWRNoStaleData<EndpointWrapper<Level2Data>>(analysisId !== null ? `/domains/analysis/${analysisId}/level-2` : null, axiosFetcher, options);
}

export function useGetLevel3DataNoStaleData(analysisId: string | null, options?: SWRConfiguration) {
  return useSWRNoStaleData<EndpointWrapper<Level3Data>>(analysisId !== null ? `/domains/analysis/${analysisId}/level-3` : null, axiosFetcher, options);
}

export async function getLevel1Data(analysisId: string | null) {
  return (await axios.get<EndpointWrapper<Level1Data>>(`/domains/analysis/${analysisId}/level-1`)).data;
}

export async function getLevel2Data(analysisId: string | null) {
  return (await axios.get<EndpointWrapper<Level2Data>>(`/domains/analysis/${analysisId}/level-2`)).data;
}

export async function getLevel3Data(analysisId: string | null) {
  return (await axios.get<EndpointWrapper<Level3Data>>(`/domains/analysis/${analysisId}/level-3`)).data;
}

export type ExcludedRowsForLevel3InputPage = EndpointWrapperPaged<{
  rows: {
    excluded: ExcludedRow[]
  }
}>;

export function useGetLevel2ExcludedRowsForLevel3InputNoStaleData(analysisId: string | null) {
  return useGetPaginatedItems(
    `/domains/analysis/${analysisId}/excluded`,
    (p: ExcludedRowsForLevel3InputPage) => p.data.rows.excluded,
    {},
    true
  );
}

export enum AnalysisStatus {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2
}

export interface DomainAnalysisInfo {
  uuid: string,
  status: AnalysisStatus,
  level_2: string | null,
  level_3: string | null,
  level_1_request: Level1InputData | null,
  level_2_request: Level2InputData | null,
  level_3_request: Level3InputData | null,
  created_at: string,
  updated_at: string,
  user: {
    id: number,
    first_name: string,
    last_name: string
  }
}

export type DomainAnalysisPage = EndpointWrapperPaged<{
  analysis: Array<DomainAnalysisInfo>
}>

export function useGetAllDomainAnalysis(disable?: boolean, config?: SWRConfiguration) {
  return useGetPaginatedItems(
    !disable ? "/domains/analysis" : null,
    (p: DomainAnalysisPage) => p.data.analysis,
    {},
    false,
    50,
    config
  )
}

export function useGetAllDomainAnalysisNoStaleData() {
  return useGetPaginatedItems(
    "/domains/analysis",
    (p: DomainAnalysisPage) => p.data.analysis,
    {},
    true
  );
}

export type RunLevel1AnalysisResponse = EndpointWrapper<{
  id: string
}>;

export const runLevel1Analysis = (data: Level1InputData) => {
  return axios.post('/domains/level1', data) as Promise<AxiosResponse<RunLevel1AnalysisResponse>>;
};

export const runLevel2Analysis = (data: Level2InputData) => {
  return axios.post('/domains/level2', data) as Promise<AxiosResponse<any>>;
};

export const runLevel3Analysis = (data: Level3InputData) => {
  return axios.post('/domains/level3', data) as Promise<AxiosResponse<any>>;
};

export const deleteAnalysis = (analysisId) => {
  return axios.delete(`/domains/analysis/${analysisId}`);
};