import {Level2Data, Level2OutputRow} from "../../api/DomainAnalysis";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {useGetAttributes} from "../../api/attributes";
import {useGetKpis} from "../../api/kpis";
import {SmartTable} from "../../commons/table/SmartTable";
import React from "react";
import {getCommonColumnsInOutputTable, getOutputAttributes, getOutputKpis} from "./utils";

interface Props {
  outputData: Level2Data;
}

export const Level2Output = withMultiFetchSWR((props: Props) => [
    {useFetchFunc: useGetAttributes},
    {useFetchFunc: useGetKpis}
  ],
  ({props, endpointsResponse}) => {

    const {outputData} = props;
    const [{data: attributeList}, {data: kpiList}] = endpointsResponse.data;

    const outputAttributes = getOutputAttributes(attributeList, outputData.rows);
    const outputKpis = getOutputKpis(kpiList.kpis, outputData.rows);

    if (outputData.rows.length === 0)
      return <div>L'analisi non ha prodotto risultati.</div>

    return (
      <SmartTable<Level2OutputRow, never>
        pagination={false}
        tableKey="level-2-output-table"
        syncWithElementsProp={true}
        enableHiddenColumns={false}
        getElementKey={(row) => `${row.domain}-${row.combination.map(c => c.value).join('-')}`}
        sort={{
          defaultSortingColIndex: 0,
          defaultArrowVisible: true
        }}
        elements={outputData.rows ?? []}
        columns={getCommonColumnsInOutputTable(outputAttributes, outputKpis)}
      />
    );
  }
);