import React, {useRef} from "react";
import styles from "./MultiStepProcessVisualizer.module.scss";

interface Props {
  steps: Array<{
    key?: number | string,
    title?: string,
    state?: "SELECTABLE" | "SELECTED" | "DISABLED",
    onClick?: () => void
  }>
}

export function MultiStepProcessVisualizer({steps: PropSteps}: Props) {

  const componentId = useRef(Math.floor(Math.random() * 10000)).current;

  const steps = PropSteps.map((step, index) => ({
    ...step,
    key: step.key !== undefined ? step.key : index + 1,
    state: step.state !== undefined ? step.state : "SELECTABLE",
    onClick: step.onClick !== undefined ? step.onClick : () => {
    }
  }));

  return (
    <div className={styles.container}>
      {steps.map((step, index) => {
        let arrowColor;
        if (index < steps.length - 1) {
          if (steps[index + 1].state === "DISABLED")
            arrowColor = "grey";
          else
            arrowColor = "black";
        }
        return (
          <>
            <button
              className={`${styles.node} ${step.state === "DISABLED" ? styles.disabled : (step.state === "SELECTED" ? styles.selected : '')}`}
              onClick={step.onClick}
              disabled={step.state === "DISABLED"}
            >
              {step.key}
              {step.title &&
                <div className={styles.title}>{step.title}</div>
              }
            </button>
            {index < steps.length - 1 &&
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 50" className={styles.arrow}>
                <g strokeWidth="4"
                   stroke={arrowColor}
                   fill="none"
                   strokeLinecap="square"
                >
                  <line x1="0" y1="25" x2="86" y2="25" markerEnd={`url(#${componentId}-${index}SvgjsMarker)`}/>
                </g>
                <defs>
                  <marker markerWidth="6" markerHeight="6" refX="3" refY="3" viewBox="0 0 6 6" orient="auto"
                          id={`${componentId}-${index}SvgjsMarker`}>
                    <polyline points="0,3 3,1.5 0,0" fill="none" strokeWidth="1"
                              stroke={arrowColor} strokeLinecap="square"
                              transform="matrix(1,0,0,1,1,1.5)"></polyline>
                  </marker>
                </defs>
              </svg>
            }
          </>
        )
      })}
    </div>
  )
}
