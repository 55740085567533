import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "../common";
import axios, {AxiosResponse} from "axios";
import {useGetPaginatedItems, useSWRNoStaleData} from "../helpersSWR";
import useSWR from "swr";
import {axiosFetcher} from "../swr-fetcher";
import {useGetPriceListNoStaleData} from "./priceLists";

export interface WebCategoryBase {
  name: string,
  domains: string[]
}

export type WebCategory = EntityWithId<WebCategoryBase>;
export type WebCategoryPage = EndpointWrapperPaged<{ categories: WebCategory[] }>

function _useGetAllWebCategories(noStaleData = false) {
  return useGetPaginatedItems(
    "dsp/web-categories",
    (p: WebCategoryPage) => p.data.categories,
    {},
    noStaleData,
    10
  )
}
export const useGetAllWebCategories = () => _useGetAllWebCategories(false);
export const useGetAllWebCategoriesNoStaleData = () => _useGetAllWebCategories(true);

type WebCategoryResponse = EndpointWrapper<{ category: WebCategory }>;

export function useGetWebCategory(webCategoryId: number) {
  return useSWR<WebCategoryResponse>(`/dsp/web-categories/${webCategoryId}`, axiosFetcher);
}

export function useGetWebCategoryNoStaleData(webCategoryId: number) {
  return useSWRNoStaleData<WebCategoryResponse>(`/dsp/web-categories/${webCategoryId}`, axiosFetcher);
}

export const createWebCategory = (data: WebCategoryBase) => {
  return axios.post('/dsp/web-categories', data) as Promise<AxiosResponse<WebCategoryResponse>>;
};

export const updateWebCategory = (webCategoryId: number, data: WebCategoryBase) => {
  return axios.put(`/dsp/web-categories/${webCategoryId}`, data) as Promise<AxiosResponse<WebCategoryResponse>>;
}

export const deleteWebCategory = (webCategoryId: number) => {
  return axios.delete(`/dsp/web-categories/${webCategoryId}`);
};
