import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {InputField} from "../commons/forms/InputField";
import * as Yup from 'yup';
import {Button, Card} from "@themesberg/react-bootstrap";
import {InputSelectField} from "../commons/forms/InputSelectField";
import {FormFieldsArray} from "../commons/forms/FormFieldsArray";
import {SmallButton} from "../components/utils/buttons/SmallButton";
import styles from "./CampaignKpiCorrectionPage.module.scss"
import {FeedbackAlert} from "../components/utils/FeedbackAlert";
import {updateKpi} from "../api/kpis";
import axios from "axios";
import {useGetCampaignNoStaleData} from "../api/campaigns";
import {withMultiFetchSWR} from "../api/helpersSWR";
import InComponentPreloader from "../components/utils/InComponentPreloader";

const Schema = Yup.object({
  kpiCorrections: Yup.array(
    Yup.object({
      kpi_id: Yup.number().required().min(0, "Campo obbligatorio"),
      fixes: Yup.array(
        Yup.object({
          type: Yup.string().required().oneOf(["increment", "percentage"]),
          value: Yup.number().required().when('type', {
            is: 'percentage',
            then: (schema) =>
              schema.min(0, "Inserire una percentuale valida"),
            otherwise: (schema) =>
              schema.min(0, "Inserire un incremento positivo")
          })
        })
      ).length(1)
    })
  ).min(0)
});

interface UrlParams {
  campaignId: number;
}

export const CampaignKpiCorrectionPage = withMultiFetchSWR(
  (prop: {}, urlParams: UrlParams) => [
    {
      useFetchFunc: () => useGetCampaignNoStaleData(urlParams.campaignId)
    }
  ], ({endpointsResponse}) => {

    const [campaign] = endpointsResponse.data;

    const {id: campaignId, name: campaignName, campaign_kpis: campaignInitialKpis} = campaign.data.campaign;

    const initialKpis = campaignInitialKpis
      .filter(kpi => kpi.fixes?.length > 0)
      .map(kpi => (
        {
          kpi_id: kpi.kpi_id,
          fixes: [
            ...kpi.fixes.filter(obj => obj.type === "percentage").map(obj => ({...obj, value: obj.value * 100})),
            ...kpi.fixes.filter(obj => obj.type === "increment")
          ]
        }
      ));

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Header>
          <h5 className="mb-0">Correzione dei KPI per la campagna {campaignName}</h5>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={{kpiCorrections: initialKpis}}
            validationSchema={Schema}
            onSubmit={(values, {setSubmitting}) => {
              const valuesToSubmit = {
                kpiCorrections:
                  values.kpiCorrections.map(obj => ({
                    kpi_id: obj.kpi_id,
                    fixes: obj.fixes.map(f => f.type === "percentage" ? ({...f, value: f.value / 100}) : f)
                  }))
              };
              axios.all([
                ...valuesToSubmit.kpiCorrections.map(kpi => updateKpi(campaignId, {
                  ...kpi,
                  objective: campaignInitialKpis.find(initialKpi => initialKpi.kpi_id === kpi.kpi_id).objective
                })),
                ...campaignInitialKpis
                  .filter(kpi => kpi.fixes?.length > 0)// get kpi that had a fix
                  .filter(initialKpi => valuesToSubmit.kpiCorrections.find(kpi => kpi.kpi_id === initialKpi.kpi_id) === undefined) // but now that fix has been removed
                  .map(initialKpi => updateKpi(campaignId, { // thus, we update these kpi removing their fixes (fixes = [])
                    kpi_id: initialKpi.kpi_id,
                    objective: initialKpi.objective,
                    fixes: []
                  }))
              ]).then(r => setResponseStatus("SUCCESS"))
                .catch(e => setResponseStatus("FAIL"))
                .finally(() => setSubmitting(false));
            }}
          >
            {({isSubmitting, dirty, values}) => (
              <Form className={styles.formContainer}>
                <FormFieldsArray
                  subtitle="Inserisci opzionalmente un correttivo (come incremento o percentuale) per i KPI associati
                alla campagna. Si può inserire al massimo un correttivo per ogni KPI."
                  getDefaultItem={() => ({
                    kpi_id: "",
                    fixes: [
                      {
                        type: "",
                        value: "",
                      }
                    ]
                  })}
                  addNewItemBtnLabel="Aggiungi correttivo"
                  disableAddNewItemBtn={(kpiCorrections) => kpiCorrections.length === campaignInitialKpis.length}
                  name={"kpiCorrections"}
                  renderItem={({buildFieldName, removeItem, item}) => (
                    <div className={styles.itemContainer}>
                      <InputSelectField
                        name={buildFieldName("kpi_id")} label="KPI" optionValueType="number"
                        children={
                          <>
                            <option key='blankChoice' hidden value={-1}>
                              Seleziona il KPI
                            </option>
                            {campaignInitialKpis.filter(kpi =>
                              !(values.kpiCorrections.map(value => value.kpi_id as unknown as number).includes(kpi.kpi_id)) ||
                              kpi.kpi_id === item.kpi_id
                            ).map((kpi) => (
                              <option key={buildFieldName(kpi.kpi_id)} value={kpi.kpi_id}>{kpi.name}</option>
                            ))
                            }
                          </>
                        }
                      />
                      <InputSelectField
                        name={buildFieldName("fixes[0].type")} label="Tipo"
                        children={
                          <>
                            <option key='blankChoice' hidden value="">
                              Seleziona tipo
                            </option>
                            <option key={`${buildFieldName("fixes[0].type")}-increment`} value="increment">Incremento
                            </option>
                            <option key={`${buildFieldName("fixes[0].type")}-percentage`} value="percentage">Percentuale
                            </option>
                          </>
                        }
                      />
                      <InputField
                        name={buildFieldName("fixes[0].value")}
                        type="number"
                        label="Valore correttivo"
                        placeholder={`Inserisci valore ${item.fixes[0].type === "percentage" ? "percentuale" : ""}`}
                      />
                      <SmallButton
                        className={styles.deleteItemBtn} style={{alignSelf: "flex-start"}} variant="danger"
                        onClick={removeItem}
                      >
                        Elimina correttivo
                      </SmallButton>
                    </div>
                  )}
                />
                <Button className={styles.submitBtn} type="submit" variant="primary" disabled={!dirty || isSubmitting}>
                  Conferma modifiche
                </Button>
                <FeedbackAlert status={responseStatus}/>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  }, InComponentPreloader);