import useSWR, {SWRConfiguration} from "swr";
import {axiosFetcher} from "./swr-fetcher";
import {useSWRNoStaleData} from "./helpersSWR";
import axios, {AxiosResponse} from "axios";
import {EndpointWrapper} from "./common";

interface BaseTab {
  name: string,
  table: {
    kpis: Array<number>,
    name: string,
  },
  view_type: string,
  charts: Array<any>
}

export interface DefaultTab extends BaseTab {
  view_type: "default",
  charts: Array<{
    kpis: Array<number>,
    chart_type: "line" | null
  }>
}

export interface SubdomainTab extends BaseTab {
  view_type: "subdomain",
  charts: Array<{
    kpi_id: number,
    chart_type: "pie" | null
  }>
}

type Tab = DefaultTab | SubdomainTab;

export interface ReportTemplate {
  name: string;
  id: string;
  tabs: Array<Tab>
}

export type GetAllReportTemplates = EndpointWrapper<{
  templates: ReportTemplate[]
}>

export function useGetAllReportTemplates() {
  return useSWR<GetAllReportTemplates>(`/settings/viewers`, axiosFetcher);
}

export function useGetAllReportTemplatesNoStaleData() {
  return useSWRNoStaleData<GetAllReportTemplates>(`/settings/viewers`, axiosFetcher);
}

export function useGetReportTemplate(id: string) {
  const {data: allTemplateData, isValidating, error} = useGetAllReportTemplates();
  let template: ReportTemplate = null;
  if(!error && allTemplateData) {
    template = allTemplateData.data.templates.find(t => t.id === id);
    if(template === undefined)
      template = null;
  }
  return {
    data: template,
    isValidating,
    error
  }
}

export function useGetReportTemplateNoStaleData(id: string) {
  const {data: allTemplateData, isValidating, error} = useGetAllReportTemplatesNoStaleData();
  let template: ReportTemplate = null;
  if(!error && allTemplateData) {
    template = allTemplateData.data.templates.find(t => t.id === id);
    if(template === undefined)
      template = null;
  }
  return {
    data: template,
    isValidating,
    error
  }
}

export const createReportTemplate = (data: ReportTemplate) => {
  return axios.post<GetAllReportTemplates>('/settings/viewers', data) as Promise<AxiosResponse<GetAllReportTemplates>>;
};

export const updateReportTemplate = (data: ReportTemplate) => {
  return axios.put<GetAllReportTemplates>('/settings/viewers', data) as Promise<AxiosResponse<GetAllReportTemplates>>;
};

export const deleteCampaign = (id: string) => {
  return axios.delete("/settings/viewers", {data: {id: id}});
};

interface Viewer {
  id: number,
  user_id: number,
  template_id: string
}

interface TemplateForCampaign {
  template: string | null;
  user_id_list: number[]
}

interface CampaignViewerAssociation {
  user_id: number,
  template: string // template id
}

export function useGetAssociationBetweenReportTemplateAndCampaign(campaignId: number) {
  const response = useSWR<EndpointWrapper<{viewers: Viewer[]}>>(campaignId !== null ? `/campaigns/${campaignId}/viewers` : null, axiosFetcher);
  if(!response.data)
    return {...response, data: null};
  const viewers = response.data;
  let association: TemplateForCampaign;
  if (viewers.data.viewers.length === 0)
    association = {
      template: null,
      user_id_list: []
    }
  else
    association = {
      template: viewers.data.viewers[0].template_id,
      user_id_list: viewers.data.viewers.map(v => v.user_id)
    }
  return {
    ...response,
    data: {
      data: association,
      success: true
    }
  }
}

export function useGetAssociationBetweenReportTemplateAndCampaignNoStaleData(campaignId: number) {
  const response = useSWRNoStaleData<EndpointWrapper<{viewers: Viewer[]}>>(`/campaigns/${campaignId}/viewers`, axiosFetcher);
  if(!response.data)
    return {...response, data: null};
  const viewers = response.data;
  let association: TemplateForCampaign;
  if (viewers.data.viewers.length === 0)
    association = {
      template: null,
      user_id_list: []
    }
  else
    association = {
      template: viewers.data.viewers[0].template_id,
      user_id_list: viewers.data.viewers.map(v => v.user_id)
    }
  return {
    ...response,
    data: {
      data: association,
      success: true
    }
  }
}


export function useGetReportTemplateAttachedToCampaign(campaignId: number) {
  const templateInfo = useGetAssociationBetweenReportTemplateAndCampaign(campaignId);
  return useGetReportTemplate(templateInfo?.data?.data?.template);
}

export const attachViewerToCampaign = (campaignId: number, data: CampaignViewerAssociation) => {
  return axios.post(`/campaigns/${campaignId}/viewers`, data);
}
