import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {useGetWebCategory} from "../../../api/dsp/webCategories";
import {Button, CloseButton, ListGroup, ListGroupItem, Modal} from "@themesberg/react-bootstrap";
import React, {useRef, useState} from "react";
import {useGetAllUsers} from "../../../api/users";
import {
  createDspPriceList,
  DspPriceList,
  DspPriceListBase,
  updateDspPriceList,
  useGetAllDspPriceLists
} from "../../../api/dsp/priceLists";
import {Form as FormikForm, Formik, useFormikContext} from "formik";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import axios from "axios";
import {attachViewerToCampaign} from "../../../api/reportTemplates";
import {buildRegexMatchPattern, useMutateManyV2} from "../../../api/swr-fetcher";
import {useGetAllCustomersForAgency} from "../../../api/dsp/agencyCustomers";

interface PopupProps {
  onClosePopup: () => void
}

interface FormikValues {
  sourceAgencyId: number | null,
  targetAgencyId: number | null
}

export const CopyPriceListingsPopup = withMultiFetchSWR(
  (props: PopupProps) => [
    {useFetchFunc: useGetAllUsers},
  ], ({props, endpointsResponse}) => {

    const {onClosePopup} = props;

    const mutateMany = useMutateManyV2();

    const [users] = endpointsResponse.data;
    const agencyList = users.filter(user => user.role === "customer");

    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    const {data: pricingList} = useGetAllDspPriceLists();

      return (
      <Modal centered show={true} size="xl">
        <Modal.Header
          style={{justifyContent: "space-between", alignItems: "center", paddingBottom: "0.7rem", paddingTop: "0.7rem"}}
        >
          <Modal.Title style={{fontSize: "20px"}}>Copia listino prezzi</Modal.Title>
          <CloseButton onClick={onClosePopup}/>
        </Modal.Header>
        <Modal.Body className="m-0">
          <Formik<FormikValues>
            initialValues={{
              sourceAgencyId: null,
              targetAgencyId: null
            }}
            validate={(values) => {
              const errors = {}
              if (values.sourceAgencyId === null)
                errors["sourceAgencyId"] = "Campo obbligatorio";
              else if (getPricingListForAgency(values.sourceAgencyId, pricingList).length === 0)
                errors["sourceAgencyId"] = "Non è presente un listino prezzi per l'agenzia selezionata";
              if (values.targetAgencyId === null)
                errors["targetAgencyId"] = "Campo obbligatorio";
              return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {

              const sourcePricingList = getPricingListForAgency(values.sourceAgencyId, pricingList);
              const targetPricingList = getPricingListForAgency(values.targetAgencyId, pricingList);

              axios.all(
                sourcePricingList.map(sourcePricing => {
                  const targetPricing = targetPricingList.find(p =>
                    p.format === sourcePricing.format &&
                    p.type === sourcePricing.type &&
                    p.weblist_id === sourcePricing.weblist_id &&
                    p.price_per_category === sourcePricing.price_per_category &&
                    p.price_per_customs === sourcePricing.price_per_customs
                  );
                  if(targetPricing)
                    return updateDspPriceList(targetPricing.id, sourcePricing);
                  else
                    return createDspPriceList(sourcePricing);
                })
              ).then(() => setResponseStatus("SUCCESS"))
              .then(() => mutateMany(buildRegexMatchPattern("dsp/pricings")))
              .catch(() => setResponseStatus("FAIL"))
              .finally(() => setSubmitting(false));

            }}
          >
            {({isSubmitting, dirty, values, setFieldValue, touched, setFieldTouched}) => (
              <FormikForm style={{display: "grid", flexDirection: "column", gap: "25px"}}>
                <div style={{display: "flex", gap: "25px", flexDirection: "row", alignItems: "flex-start"}}>
                  <InputSelectField
                    name="sourceAgencyId"
                    label="Agenzia da cui copiare il listino"
                    optionValueType="number"
                    containerStyle={{flexGrow: 1}}
                    children={
                      <>
                        <option key='blankChoice' hidden value="">Seleziona agenzia</option>
                        {agencyList.map(agency => (
                          <option key={agency.id} value={agency.id}>
                            {`${agency.first_name} ${agency.last_name}`}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <InputSelectField
                    name="targetAgencyId"
                    label="Agenzia a cui applicare il listino copiato"
                    optionValueType="number"
                    containerStyle={{flexGrow: 1}}
                    children={
                      <>
                        <option key='blankChoice' hidden value="">Seleziona agenzia</option>
                        {agencyList.map(agency => (
                          <option key={agency.id} value={agency.id}>
                            {`${agency.first_name} ${agency.last_name}`}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <Button
                    type="submit"
                    variant="primary" disabled={!dirty || isSubmitting}
                    style={{height: "44px", width: "130px", marginTop: "32px"}}
                  >
                    Copia listino
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Button type="button" variant="outline-primary" onClick={onClosePopup}> Chiudi </Button>
          </div>
          <FeedbackAlert
            status={responseStatus}
            successMessage="L'importazione del file è avvenuta con successo!"
            failMessage="Ops! Qualcosa è andato storto, l'importazione non è riuscita."
          />
        </Modal.Footer>
      </Modal>
    );
  }
);

function getPricingListForAgency(agencyId: number, pricingList: DspPriceList[]) {
  return pricingList.filter(p => p.agency_id === agencyId && (p.agency_customer_id === null || p.agency_customer_id === undefined));
}
