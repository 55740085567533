import {Form} from "@themesberg/react-bootstrap";
import {useField} from "formik";
import * as React from "react";
import styles from "./InputField.module.scss";

interface Props {
  name: string;
  label?: string | JSX.Element;
  placeholder?: string;
  type: string;
  className?: string;
  style?: any;
  step?: any;
  min?: number;
  max?: number;
  containerStyle?: any;
  disabled?: boolean;
  showValid?: boolean;
  isMultiLine?: boolean;
  multiLineNumber?: number;
  showOptionalFieldLabel?: boolean;
  autoCompleteEnabled?: boolean;
  onChange?: (event: any) => void;
  value?: string | number;
  onBlur?: (event: any) => void;
}

export const InputField = (props: Props) => {
  const [field, meta, helpers] = useField(props);

  const {
    label,
    className = "",
    containerStyle = {},
    showValid = true,
    isMultiLine = false,
    multiLineNumber = 3,
    showOptionalFieldLabel = false,
    disabled = false,
    autoCompleteEnabled = true,
    onChange,
    value,
    onBlur,
    ...otherProps
  } = props;

  return (
    <Form.Group
      id={field.name} className={`${(showOptionalFieldLabel && !disabled) ? styles.container : ""} ${className}`}
      style={containerStyle}
    >
      {label &&
        <Form.Label className={`${(showOptionalFieldLabel && !disabled) ? styles.fieldLabel : ""} label`}>
          {label}
        </Form.Label>
      }
      <Form.Control
        className="input"
        as={isMultiLine ? "textarea" : undefined}
        rows={isMultiLine ? multiLineNumber : undefined}
        disabled={disabled}
        {...otherProps}
        {...field}
        onBlur={e => {
          if (onBlur)
            onBlur(e);
          field.onBlur(e);
        }}
        onChange={onChange ? onChange : field.onChange}
        value={value ? value : field.value}
        isValid={showValid && meta.error === undefined && meta.touched}
        isInvalid={meta.error !== undefined && meta.touched}
        autoComplete={!autoCompleteEnabled ? "new-password" : undefined}
      />
      <Form.Control.Feedback className="error" type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );

};