import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    default: 'Campo non valido',
    required: 'Campo obbligatorio'
  },
  string: {
    email: 'Il campo email non è valido'
  }
});

