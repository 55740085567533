import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Card, Col, Row} from "@themesberg/react-bootstrap";
import useSWR from 'swr';
import Preloader from "../utils/Preloader";
import {axiosFetcher} from "../../api/swr-fetcher";
import CampaignDataPanel from "./CampaignDataPanel";

export default ({data}) => {
    let params = useParams();

    let { data: campaignResponse } = useSWR(`/campaigns/${params.campaignId}`, axiosFetcher);

    const [campaignData, setCampaignData] = useState(null);
    const [campaignRows, setCampaignRows] = useState(null);

    useEffect(() => {
        if (campaignData === null && campaignResponse) {
            setCampaignData(campaignResponse.data.campaign);
            setCampaignRows(data.flatMap(row => row.data.rows));
        }
    }, [campaignResponse]);

    if (!campaignResponse || campaignData === null) {
        return <Preloader show={true} />;
    }

    if (campaignData.status === "PLANNED" || campaignRows.length === 0) {
        return (
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Header>
                    <Row className="align-items-center">
                        <Col xs={12} md={6}>
                            <h5 className="mb-0">{campaignData.name}</h5>
                        </Col>
                        <Col xs={12} md={6} className="text-right">
                            <h5 className="mb-0">Advertiser: {campaignData.advertiser}</h5>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {campaignData.status === "PLANNED" &&
                        <div>
                            Campagna non ancora iniziata
                        </div>
                    }
                    {campaignData.status !== "PLANNED" && campaignRows.length === 0 &&
                      <div>
                          Non sono presenti dati per questa campagna
                      </div>
                    }
                </Card.Body>
            </Card>
        );
    }

    return (
        <Card border="light" className="table-wrapper table-responsive shadow-sm mt-2 mb-4">
            <Card.Header>
                <Row className="align-items-center">
                    <Col xs={12} md={6}>
                        <h5 className="mb-0">{campaignData.name}</h5>
                    </Col>
                    <Col xs={12} md={6} className="text-right">
                        <h5 className="mb-0">Advertiser: {campaignData.advertiser}</h5>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="pt-0">
                <div>
                    <CampaignDataPanel
                      rows={campaignRows}
                      campaignKpis={campaignData.campaign_kpis}
                      initialGraphKpiIdList={campaignData.campaign_kpis.map(kpi =>kpi.kpi_id).slice(0, 3)}
                      campaignId={campaignData.id}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};