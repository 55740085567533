import {Button, CloseButton, Modal} from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {
  attachViewerToCampaign,
  useGetAllReportTemplates,
  useGetAssociationBetweenReportTemplateAndCampaignNoStaleData
} from "../../../api/reportTemplates";
import {GetResponseUser, useGetAllUsers} from "../../../api/users";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {number} from "yup";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {SmallButton} from "../../utils/buttons/SmallButton";
import {ItemsSelectionPopup} from "../../utils/ItemsSelectionPopup";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {FeedbackAlert} from "../../utils/FeedbackAlert";

const Schema = Yup.object({
  template: Yup.string().required(),
  user_id_list: Yup.array(number()).required().min(1, "Associa almeno un utente.")
});

interface Props {
  campaignName: string;
  campaignId: number;
  isShown: boolean;
  onClosePopup: () => void;
}

interface UrlParams {
}

export const CampaignViewersSelectionPopup = withMultiFetchSWR(
  ({campaignId}: Props, {}: UrlParams) => [
    {
      useFetchFunc: () => useGetAssociationBetweenReportTemplateAndCampaignNoStaleData(campaignId)
    },
    {
      useFetchFunc: useGetAllReportTemplates
    },
    {
      useFetchFunc: useGetAllUsers
    }
  ], ({props, endpointsResponse}) => {

    const {data: [associationBetweenTemplateAndCampaign, templateList, userList]} = endpointsResponse;
    const {isShown, onClosePopup, campaignName, campaignId} = props;

    let initialValues;

    if(associationBetweenTemplateAndCampaign == null) {
      initialValues = {
        template: "",
        user_id_list: []
      }
    } else
      initialValues = associationBetweenTemplateAndCampaign.data;

    const history = useHistory();

    const [showUserSelectionPopup, setShowUserSelectionPopup] = useState(false);
    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={(values, {setSubmitting}) => {
          axios.all(
            values["user_id_list"].map(userId =>
              attachViewerToCampaign(campaignId, {
                template: values.template,
                user_id: userId
              })
            )
          ).then(() => setResponseStatus("SUCCESS"))
          .catch(() => setResponseStatus("FAIL"))
          .finally(() => setSubmitting(false));
        }}
      >
        {({values, submitForm, setFieldValue, errors, touched}) => (
          <Form>
            <Modal centered show={isShown}>
              <Modal.Header
                style={{justifyContent: "space-between", alignItems: "center", paddingBottom: "0.7rem", paddingTop: "0.7rem"}}
              >
                <Modal.Title style={{fontSize: "20px"}}>Gestisci visualizzatori report</Modal.Title>
                <CloseButton onClick={onClosePopup}/>
              </Modal.Header>
              <Modal.Body className="m-0 pt-2 px-3" style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                <div>
                  Seleziona il template con cui gli utenti autorizzati potranno visualizzare il report della campagna
                  <em style={{fontWeight: 700}}> {campaignName}</em>.
                </div>
                <InputSelectField
                  name="template"
                  label="Template di report"
                  optionValueType="text"
                  children={
                    <>
                      <option key='blankChoice' hidden value="">Seleziona template</option>
                      {templateList.data.templates.map(template => (
                        <option key={template.id} value={template.id}>{template.name}</option>
                      ))}
                    </>
                  }
                />
                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                  <div style={{display: "flex", flexDirection: "row", gap: "25px", alignItems: "center"}}>
                    <h6 className="m-0 p-0">Utenti autorizzati</h6>
                    <SmallButton onClick={() => setShowUserSelectionPopup(true)}>Modifica</SmallButton>
                  </div>
                  <div>
                    {(values["user_id_list"].length > 0 ?
                        userList
                          .filter(user => values["user_id_list"].includes(user.id))
                          .map(user => `${user.first_name} ${user.last_name}`)
                          .join(", ")
                        : "Nessun utente autorizzato."
                    )}
                  </div>
                  { //@ts-ignore
                    errors?.user_id_list !== undefined && touched?.user_id_list !== undefined &&
                    <div style={{color: "red", fontSize: "14px"}}>
                      { //@ts-ignore
                        errors?.user_id_list
                      }
                    </div>
                  }
                </div>
                {showUserSelectionPopup &&
                  <ItemsSelectionPopup<GetResponseUser>
                    popupKey={`${campaignId}-auth-user-viewers-popup`}
                    getItemView={(user) => <div>{user.first_name} {user.last_name}</div>}
                    onItemsSelected={(selectedUsers) => {
                      setFieldValue(`user_id_list`, selectedUsers.map(user => user.id));
                      setShowUserSelectionPopup(false);
                    }}
                    onClosePopup={() => setShowUserSelectionPopup(false)}
                    isShown={true}
                    itemList={userList.filter(u => u.role === "customer" || u.role === "agency" || u.role === "aggregators")}
                    title="Utenti autorizzati"
                    canSelectAllItems={true}
                    isMultiSelect={true}
                    alreadyAssociatedItemList={
                      userList.filter(user => values["user_id_list"].includes(user.id))
                    }
                  />
                }
              </Modal.Body>
              <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Button onClick={onClosePopup} variant="outline-primary">Annulla</Button>
                  <Button onClick={submitForm}>Conferma modifiche</Button>
                </div>
                <FeedbackAlert
                  status={responseStatus}
                  failMessage="Ops! Qualcosa è andato storto."
                  successMessage="Modifica avvenuta con successo!"
                />
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    );
  }
);
