import React, {useEffect} from "react";
import {Button, Card} from '@themesberg/react-bootstrap';
import {Form, Formik} from 'formik';
import axios from 'axios';
import BgImage from "../assets/img/illustrations/signin.svg";
import * as Yup from "yup";
import {submitLogin} from "../api/login";
import {InputField} from "../commons/forms/InputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {
  getDefaultCredentialPassword,
  getDefaultCredentialUser,
  shouldUseDefaultCredentials
} from "../commons/api-config";

const Schema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(5, "La password inserita è troppo corta")
})

export function LoginPage() {

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.href = '#dashboard';
    }
  }, []);

  return (
    <Formik
      initialValues={getInitialCredentials()}
      validationSchema={Schema}
      onSubmit={(values, {setErrors}) =>
        submitLogin(values)
          .then(res => {
            const {token, token_type, user} = res.data;

            localStorage.setItem('token', token);
            localStorage.setItem('token_type', token_type);
            localStorage.setItem('user', JSON.stringify(user));

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            window.location.href = '#dashboard';
          })
          .catch(e => {
              setErrors({
                email: "Email e/o password non sono corretti",
                password: "Email e/o password non sono corretti"
              })
            }
          )
      }
    >
      {({isSubmitting, submitForm}) => (
        <Form>
          <main style={{backgroundImage: `url(${BgImage})`, height: `100vh`, position: "relative"}}>
            <Card
              style={{
                padding: "45px", position: "absolute", top: "50%", left: "50%", width: "550px",
                transform: "translate(-50%, -50%)"
              }}
            >
              <div style={{display: "flex", flexDirection: "column", gap: "25px"}}>
                <InputField
                  name="email" type="email" placeholder="Inserisci indirizzo email" autoCompleteEnabled={true} showValid={false}
                  label={
                    <div style={{display: "flex", flexDirection: "row", gap: "8px", alignItems: "center", paddingLeft: "8px"}}>
                      <FontAwesomeIcon icon={faEnvelope} style={{color: "#66799e"}}/>
                      Email
                    </div>
                  }
                />
                <InputField
                  name="password" placeholder="Inserisci password" type="password" autoCompleteEnabled={true} showValid={false}
                  label={
                    <div style={{display: "flex", flexDirection: "row", gap: "8px", alignItems: "center", paddingLeft: "8px"}}>
                      <FontAwesomeIcon icon={faUnlockAlt} style={{color: "#66799e"}}/>
                      Password
                    </div>
                  }
                />
                <Button type="submit" disabled={isSubmitting}>
                  Login
                </Button>
              </div>
            </Card>
          </main>
        </Form>
      )}
    </Formik>
  )
}


function getInitialCredentials() {
  if(shouldUseDefaultCredentials())
    return {
      email: getDefaultCredentialUser(),
      password: getDefaultCredentialPassword()
    }
  else
    return {
      email: "",
      password: ""
    }
}