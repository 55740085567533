import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "../common";
import {useGetPaginatedItems, useSWRNoStaleData} from "../helpersSWR";
import axios, {AxiosResponse} from "axios";
import useSWR from "swr";
import {axiosFetcher} from "../swr-fetcher";

export type DspPriceListCommon = {
  price: number,
  extra_audience: number,
  weblist_id: number,
  agency_customer_id: number,
  agency_id: number,
  price_per_customs: boolean,
  price_per_category: boolean
}

export type DisplayType = "cpc" | "cpm" | "cpv";
export type VideoType = "cpm" | "cpcv";

export interface DspPriceListDisplayFormat extends DspPriceListCommon {
  format: "disp",
  type: DisplayType
}

export interface DspPriceListVideoFormat extends DspPriceListCommon {
  format: "vid",
  type: VideoType
}

export type DspPriceListBase = DspPriceListDisplayFormat | DspPriceListVideoFormat;

export type DspPriceList = EntityWithId<DspPriceListBase>;
export type DspPriceListsPage = EndpointWrapperPaged<{ pricings: DspPriceList[] }>

export function useGetAllDspPriceLists() {
  return useGetPaginatedItems(
    "dsp/pricings",
    (p: DspPriceListsPage) => p.data.pricings
  );
}

export function useGetAllDspPriceListsNoStaleData() {
  return useGetPaginatedItems(
    "dsp/pricings",
    (p: DspPriceListsPage) => p.data.pricings,
    {},
    true
  );
}

export type DspPriceListResponse = EndpointWrapper<{ pricing: DspPriceList }>

export function useGetPriceList(idPriceList: number) {
  return useSWR<DspPriceListResponse>(`/dsp/pricings/${idPriceList}`, axiosFetcher);
}

export function useGetPriceListNoStaleData(idPriceList: number) {
  return useSWRNoStaleData<DspPriceListResponse>(`/dsp/pricings/${idPriceList}`, axiosFetcher);
}

export const createDspPriceList = (data: DspPriceListBase) => {
  return axios.post('/dsp/pricings', data) as Promise<AxiosResponse<DspPriceListResponse>>;
};

export const updateDspPriceList = (idPriceList: number, data: DspPriceListBase) => {
  return axios.put(`/dsp/pricings/${idPriceList}`, data) as Promise<AxiosResponse<DspPriceListResponse>>;
}

export const deleteDspPriceList = (idPriceList: number) => {
  return axios.delete(`/dsp/pricings/${idPriceList}`);
};
