import React, {useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import {Button, ButtonGroup, Card, Form} from "@themesberg/react-bootstrap";
import {Form as FormikForm, Formik, useFormikContext} from "formik";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {Routes} from "../../../routes";
import {useHistory} from "react-router-dom";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {
  calculateDspPricing,
  CampaignDsp,
  CampaignDspRequest,
  CampaignDspRequestWithId,
  createCampaignDsp,
  uploadZipFileToCampaignDsp,
  useGetCampaignDspNoStaleData, useGetDownloadLinkInfoForCampaignDspZipFile
} from "../../../api/dsp/campaignDsp";
import {InputField} from "../../../commons/forms/InputField";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {InputDateRangeField} from "../../../commons/forms/InputDateRangeField";
import {useGetAllWebLists} from "../../../api/dsp/webLists";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCircleInfo, faPlus} from "@fortawesome/free-solid-svg-icons";
import {MultipleCheckField} from "../../../commons/forms/MultipleCheckField";
import {useGetAllWebCategories} from "../../../api/dsp/webCategories";
import {CategoryWebsiteInfoPopup} from "../website/CategoryWebsiteInfoPopup";
import {ItemsSelectionPopup} from "../../../components/utils/ItemsSelectionPopup";
import {DspWebsiteResponse, useGetAllDspWebsites} from "../../../api/dsp/websites";
import {DspTarget, useGetAllDspTargets} from "../../../api/dsp/targets";
import {useGetAllDspFormats} from "../../../api/dsp/formats";
import {InputFileField} from "../../../commons/forms/InputFileField";
import {SmallButton} from "../../../components/utils/buttons/SmallButton";
import {FormFieldsArray} from "../../../commons/forms/FormFieldsArray";
import {CreateOrEditAgencyCustomerPopup} from "./CreateOrEditAgencyCustomerPopup";
import moment from "moment";
import {InputCheckField} from "../../../commons/forms/InputCheckField";
import styles from "./DspCampaignFormPage.module.scss";
import {debounce} from "lodash";
import {Spinner} from "../../../components/utils/Spinner";
import {AgencyCustomerOptions} from "../utils/AgencyCustomerOptions";
import {isAuthenticatedUserInternal} from "../../../commons/Utils";
import {getAuthenticatedUser} from "../../../commons/auth";
import {useGetKpis} from "../../../api/kpis";
import {
  TargetKpiSuggestions,
  TargetKpiSuggestionsGetResponse,
  useGetTargetKpiSuggestions
} from "../../../api/dsp/targetKpis";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

function getSchema({kpis: targetKpiSuggestions}: TargetKpiSuggestionsGetResponse) {

  let displayTargetKpisSchema = {};
  targetKpiSuggestions.disp.forEach(({kpi_id}) =>
    displayTargetKpisSchema[kpi_id] = Yup.number().typeError("Campo obbligatorio").min(0, "Campo obbligatorio").required()
  );

  let videoTargetKpisSchema = {};
  targetKpiSuggestions.vid.forEach(({kpi_id}) =>
    videoTargetKpisSchema[kpi_id] = Yup.number().typeError("Campo obbligatorio").min(0, "Campo obbligatorio").required()
  );

  return (Yup.object({
    name: Yup.string().required(),
    agency_customer_id: Yup.number().typeError("Campo obbligatorio").nullable(),
    starts_at: Yup.string().required(),
    ends_at: Yup.string().required(),
    ad_type: Yup.string().required(),
    type: Yup.string().required(),
    audience: Yup.object({
      age_range: Yup.array(Yup.string()).min(0),
      gender: Yup.array(Yup.string()).min(0),
      locality: Yup.string().nullable()
    }),
    objectives: Yup.array(Yup.number()).min(0),
    custom_categories: Yup.array(Yup.number()).min(1, "Seleziona almeno 1 settore").nullable(),
    weblist_id: Yup.number().typeError("Campo obbligatorio").nullable(),
    custom_websites: Yup.array(Yup.string()).min(1).nullable(),
    budget: Yup.number().typeError("Campo obbligatorio").min(0).required(),
    notes: Yup.string().notRequired().nullable().transform((value) => !!value ? value : null),
    snippets: Yup.array(Yup.object({
      snippet: Yup.string().required(),
      adformat: Yup.number().typeError("Campo obbligatorio").required()
    })).min(0).nullable(),
    url: Yup.string().required(),
    zipFile: Yup.mixed().when('snippets', {
      is: null,
      then: (schema) => schema.required("Seleziona il file zip da importare"),
      otherwise: schema => schema.nullable()
    }),
    target_kpis: Yup.object().when('ad_type', {
      is: "disp",
      then: schema => schema.shape(displayTargetKpisSchema),
      otherwise: schema => schema.shape(videoTargetKpisSchema)
    })
  }));
}

type CalculatedPriceStatus = "invalidValues" | "fetching" | "calculated" | "priceNotAvailable" | "fetchError";

export const DspCampaignFormPage = withMultiFetchSWR(
  (props: {}, urlParams: { campaignId: string }) => [
    {
      useFetchFunc: () => useGetCampaignDspNoStaleData(parseInt(urlParams.campaignId)),
      shouldFetch: urlParams.campaignId !== undefined,
      elseData: {
        data: {
          campaign: getDefaultConfig()
        }
      }
    },
    {useFetchFunc: useGetAllWebCategories},
    {useFetchFunc: useGetAllWebLists},
    {useFetchFunc: useGetAllDspWebsites},
    {useFetchFunc: useGetAllDspTargets},
    {useFetchFunc: useGetAllDspFormats},
    {useFetchFunc: useGetKpis},
    {
      useFetchFunc: useGetTargetKpiSuggestions
    }
  ], ({urlParams, endpointsResponse}) => {

    const campaignId = urlParams.campaignId ? parseInt(urlParams.campaignId) : undefined;
    const isCreateMode = campaignId == undefined;
    const uploadRef = useRef(null);

    const {
      data: [
        campaignResponse, webCategoryList, webListList, websiteList, targetList, formatList, kpiList, targetKpiSuggestions
      ]
    } = endpointsResponse;

    const campaign = campaignResponse.data.campaign;

    const agencyId = isAuthenticatedUserInternal() ? campaign.agency_id : getAuthenticatedUser().id;

    const history = useHistory();

    const [calculatedPrice, setCalculatedPrice] = useState<number | null>(null);
    const [calculatedPriceStatus, setCalculatedPriceStatus] = useState<CalculatedPriceStatus>("invalidValues");

    const [infoPopupForCategoryId, setInfoPopupForCategoryId] = useState<null | number>(null);
    const [isShownCreateAgencyCustomerPopup, setIsShownCreateAgencyCustomerPopup] = useState<boolean>(false);
    const [isShownCustomWebsiteSelectionPopup, setIsShownCustomWebsiteSelectionPopup] = useState<boolean>(false);
    const [isShownTargetSelectionPopup, setIsShownTargetSelectionPopup] = useState<boolean>(false);
    const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");

    const shouldFetchZipFile = !isCreateMode && campaign.snippets === null;
    const {data: zipFileInfo, error: zipFileInfoError} = useGetDownloadLinkInfoForCampaignDspZipFile(shouldFetchZipFile ? parseInt(urlParams.campaignId) : null);

    // this should not be needed
    const WeirdIssueWithValidationFixer = ({values, validateForm}) => {
      useEffect(() => {
          validateForm();
        }, [values.ends_at]
      );
      return null;
    }

    return (
      <Formik<(CampaignDspRequest | CampaignDspRequestWithId) & { zipFile: null }>
        initialValues={{
          ...campaign,
          starts_at: campaign.starts_at ? moment(campaign.starts_at) as unknown as string : null,
          ends_at: campaign.ends_at ? moment(campaign.ends_at) as unknown as string : null,
          custom_categories: campaign.categories?.length > 0 ? campaign.categories?.map(c => c.id) : null,
          custom_websites: campaign.custom_websites?.length > 0 ? campaign.custom_websites : null,
          weblist_id: campaign.weblist?.id ?? null,
          zipFile: null,
          objectives: (campaign.objectives as DspTarget[])?.map(obj => obj.id) ?? []
        }}
        validationSchema={getSchema(targetKpiSuggestions.data)}
        onSubmit={(values) => {
          const zipFile = values.zipFile;
          const valuesToSubmit = {
            ...values,
            starts_at: (values.starts_at as unknown as moment.Moment).format('YYYY-MM-DD'),
            ends_at: (values.ends_at as unknown as moment.Moment).format('YYYY-MM-DD')
          };
          delete valuesToSubmit["zipFile"];
          return createCampaignDsp(valuesToSubmit)
            .then(r => {
              if (zipFile !== null)
                return uploadZipFileToCampaignDsp(r.data.data.campaign.id, uploadRef.current.files[0]);
            })
            .then(r => history.push(Routes.Dsp.Campaign.ViewAll.path))
            .catch(e => setResponseStatus("FAIL"));
        }}
      >
        {({isSubmitting, dirty, values, errors, setFieldValue, setFieldTouched, touched, validateForm}) => (
          <FormikForm>
            <div style={{display: "flex", flexDirection: "column", gap: "30px"}}>
              <h5 className="mb-0" style={{paddingLeft: "14px"}}>
                {isCreateMode ? "Richiesta nuova campagna" : `Richiesta campagna ${campaign.name}`}
              </h5>
              <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <WeirdIssueWithValidationFixer values={values} validateForm={validateForm}/>
                <Card.Body style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px"}}>
                  <InputField
                    name="name" type="text" label="Nome della campagna" placeholder="Inserisci nome campagna"
                    disabled={!isCreateMode} style={{height: "48px"}}
                  />
                  <div>
                    <label style={{marginBottom: "8px"}}>
                      Periodo di attività
                    </label>
                    <InputDateRangeField
                      startDateName="starts_at"
                      endDateName="ends_at"
                      isOutsideRange={() => false}
                      isDayBlocked={() => false}
                      disabled={!isCreateMode}
                    />
                  </div>
                  <div style={{display: "flex", gap: "10px", alignItems: "start", gridColumn: "-1/1"}}>
                    {(isCreateMode || values.agency_customer_id !== null) &&
                      <div style={{display: "flex", alignItems: "center", height: "43.6px"}}>
                        <InputCheckField
                          name="agency_customer_id" type="switch" label="Attiva selezione cliente"
                          disabled={!isCreateMode}
                          getIsChecked={value => value !== null}
                          getValueToSetOnChange={checked => {
                            setFieldTouched("agency_customer_id", false);
                            if (checked)
                              return "none";
                            return null;
                          }}
                        />
                      </div>
                    }
                    {!(isCreateMode || values.agency_customer_id !== null) &&
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        gridColumn: "-1/1"
                      }}>
                        <Form.Label className={`label mb-0`}>Cliente</Form.Label>
                        <div>
                          Non è stato specificato il cliente
                        </div>
                      </div>
                    }
                    {values.agency_customer_id !== null &&
                      <>
                        <InputSelectField
                          containerStyle={{flexGrow: 1}}
                          name="agency_customer_id"
                          optionValueType="number"
                          disabled={!isCreateMode}
                          children={
                            <>
                              <option key='blankChoice' hidden value="none">Seleziona cliente</option>
                              <AgencyCustomerOptions agencyId={agencyId}/>
                            </>
                          }
                        />
                        {isCreateMode &&
                          <Button onClick={() => setIsShownCreateAgencyCustomerPopup(true)} style={{order: 1}}>
                            <FontAwesomeIcon icon={faPlus}/>
                          </Button>
                        }
                        {isShownCreateAgencyCustomerPopup &&
                          <CreateOrEditAgencyCustomerPopup
                            isShown={true}
                            onClosePopup={() => setIsShownCreateAgencyCustomerPopup(false)}
                            onSuccessfulSubmit={(newAgencyCustomerId) => {
                              setFieldValue("agency_customer_id", newAgencyCustomerId);
                              setIsShownCreateAgencyCustomerPopup(false);
                            }}
                          />
                        }
                      </>
                    }
                  </div>
                </Card.Body>
              </Card>
              <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Header style={{padding: "13px 23px"}}>
                  <h5 className="mb-0">Formato campagna e obiettivi KPI</h5>
                </Card.Header>
                <Card.Body style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                  <ButtonGroup>
                    <Button
                      disabled={!isCreateMode}
                      onClick={() => {
                        setFieldValue("ad_type", "disp");
                        setFieldValue("type", "");
                        setFieldTouched("type", false);
                        setFieldTouched("target_kpis", false);
                      }}
                      className={`${styles.buttongroupBackground} ${values.ad_type === "vid" ?
                        styles.disabledButton : ""}`}
                      style={{width: "50%"}}
                    >
                      Display/Native
                    </Button>
                    <Button
                      disabled={!isCreateMode}
                      onClick={() => {
                        setFieldValue("ad_type", "vid");
                        setFieldValue("type", "");
                        setFieldTouched("type", false);
                        setFieldTouched("target_kpis", false);
                      }}
                      className={`${styles.buttongroupBackground} ${values.ad_type === "disp" ?
                        styles.disabledButton : ""}`}
                      style={{width: "50%"}}
                    >
                      Video
                    </Button>
                  </ButtonGroup>
                  <InputSelectField
                    name="type"
                    label="Tipologia di rendicontazione del costo"
                    optionValueType="text"
                    disabled={values.ad_type == undefined || !isCreateMode}
                    children={
                      <>
                        <option key='blankChoice' hidden value="">Seleziona tipologia</option>
                        {values.ad_type === "disp" && <option key="cpc" value="cpc">CPC (costo per click)</option>}
                        <option key="cpm" value="cpm">CPM (costo per mille impression)</option>
                        {values.ad_type === "disp" && <option key="cpv" value="cpv">CPV (costo per visit)</option>}
                        {values.ad_type === "vid" &&
                          <option key="cpcv" value="cpcv">CPCV (costo per completed view)</option>}
                      </>
                    }
                  />
                  <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px"}}>
                    {values.ad_type === "disp" &&
                      targetKpiSuggestions.data.kpis.disp.map(({kpi_id, suggestion}) => (
                        <InputField
                          name={`target_kpis[${kpi_id}]`} type="number" key={`disp-${kpi_id}`} disabled={!isCreateMode}
                          label={`Obiettivo ${kpiList.data.kpis.find(kpi => kpi.id === kpi_id).name}`}
                          placeholder={suggestion} min={0} step={0.01}
                        />
                      ))
                    }
                    {values.ad_type === "vid" &&
                      targetKpiSuggestions.data.kpis.vid.map(({kpi_id, suggestion}) => (
                        <InputField
                          name={`target_kpis[${kpi_id}]`} type="number" key={`vid-${kpi_id}`} disabled={!isCreateMode}
                          label={`Obiettivo ${kpiList.data.kpis.find(kpi => kpi.id === kpi_id).name}`}
                          placeholder={suggestion} min={0} step={0.01}
                        />
                      ))
                    }
                  </div>
                </Card.Body>
              </Card>
              <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Header style={{padding: "13px 23px"}}>
                  <h5 className="mb-0">Target siti</h5>
                </Card.Header>
                <Card.Body style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px"}}>
                  <ButtonGroup style={{gridColumn: "-1/1"}}>
                    <Button
                      disabled={!isCreateMode}
                      onClick={() => {
                        // @ts-ignore
                        if (!(values.custom_websites === null && values.custom_categories === null)) {
                          setFieldValue("weblist_id", "none");
                          setFieldTouched("weblist_id", false);
                          setFieldValue("custom_websites", null);
                          setFieldValue("custom_categories", null);
                        }
                      }}
                      className={`${styles.buttongroupBackground} ${getModeSelectorValue(values) === "list" ?
                        "" : styles.disabledButton}`}
                      style={{width: "33.33%"}}
                    >
                      Lista
                    </Button>
                    <Button
                      disabled={!isCreateMode}
                      onClick={() => {
                        if (values.custom_categories === null) {
                          setFieldValue("custom_categories", []);
                          setFieldTouched("custom_categories", false);
                          setFieldValue("weblist_id", null);
                          setFieldValue("custom_websites", null);
                        }
                      }}
                      className={`${styles.buttongroupBackground} ${getModeSelectorValue(values) === "category" ?
                        "" : styles.disabledButton}`}
                      style={{width: "33.33%"}}
                    >
                      Settori
                    </Button>
                    <Button
                      disabled={!isCreateMode}
                      onClick={() => {
                        if (values.custom_websites === null) {
                          setFieldValue("custom_websites", []);
                          setFieldTouched("custom_wesites", false);
                          setFieldValue("weblist_id", null);
                          setFieldValue("custom_categories", null);
                        }
                      }}
                      className={`${styles.buttongroupBackground} ${getModeSelectorValue(values) === "custom" ?
                        "" : styles.disabledButton}`}
                      style={{width: "33.33%"}}
                    >
                      Siti custom
                    </Button>
                  </ButtonGroup>
                  {getModeSelectorValue(values) === "list" &&
                    <InputSelectField
                      name="weblist_id"
                      label="Lista"
                      disabled={!isCreateMode}
                      showValid={false}
                      optionValueType="number"
                      children={
                        <>
                          <option key='blankChoice' hidden value="">Seleziona lista</option>
                          {webListList.map(webList => (
                            <option key={webList.id} value={webList.id}>{webList.name}</option>
                          ))}
                        </>
                      }
                    />
                  }
                  {getModeSelectorValue(values) === "category" &&
                    <MultipleCheckField
                      containerStyle={{gridColumn: "-1/1"}}
                      label={"Seleziona settori di competenza: "}
                      name={"custom_categories"}
                      disabled={!isCreateMode}
                      items={webCategoryList.map(c => ({
                        label: (
                          <div
                            role={"button"}
                            onClick={() => setInfoPopupForCategoryId(c.id)}
                            style={{display: "flex", flexDirection: "row", gap: "7px", alignItems: "center"}}
                          >
                            {`${c.name} (${c.domains.length !== 1 ? c.domains.length + " siti" : "1 sito"})`}
                            <FontAwesomeIcon icon={faCircleInfo}></FontAwesomeIcon>
                          </div>
                        ),
                        id: c.id
                      }))}
                      isMultiSelect={true}
                      numOfColumns={3}
                    />
                  }
                  {getModeSelectorValue(values) === "custom" &&
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      gridColumn: "-1/1"
                    }}>
                      <Form.Label className="label mb-0">Siti custom</Form.Label>
                      <div
                        style={{display: "grid", gridTemplateColumns: "auto 1fr", gap: "10px", alignItems: "center"}}>
                        {isCreateMode &&
                          <Button
                            style={{padding: "4px 5px 1px 1px"}}
                            onClick={() => setIsShownCustomWebsiteSelectionPopup(true)}
                          >
                            <FontAwesomeIcon style={{fontSize: "18px", paddingLeft: "5px"}}
                                             icon={faArrowUpRightFromSquare}/>
                          </Button>
                        }
                        {values.custom_websites?.length > 0 ?
                          websiteList
                            .filter(website => values.custom_websites.includes(website.id))
                            .map(website => website.domain)
                            .join(", ")
                          : "Nessun sito selezionato"
                        }
                        {errors["custom_websites"] && touched["custom_websites"] &&
                          <div style={{color: "red", fontSize: "0.875em", gridColumn: 2}}>
                            Seleziona almeno 1 sito
                          </div>
                        }
                      </div>
                    </div>
                  }
                  {isShownCustomWebsiteSelectionPopup &&
                    <ItemsSelectionPopup<DspWebsiteResponse>
                      listGroupItemStyle={{padding: "2px 6px"}}
                      closePopupBtnDescription={"Chiudi"}
                      popupKey={"custom-websites-sel-popup"}
                      getItemView={(website) => <div>{website.domain}</div>}
                      onItemsSelected={(selectedWebsiteList) => {
                        setFieldValue("custom_websites", selectedWebsiteList.map(w => w.id));
                        setIsShownCustomWebsiteSelectionPopup(false);
                      }}
                      onClosePopup={() => setIsShownCustomWebsiteSelectionPopup(false)}
                      isShown={true}
                      itemList={websiteList}
                      title="Modalità custom"
                      subtitle="Seleziona i siti custom per la campagna:"
                      alreadyAssociatedItemList={
                        websiteList.filter(website => values.custom_websites.includes(website.id))
                      }
                      canSelectAllItems={true}
                      isMultiSelect={true}
                    />
                  }
                </Card.Body>
              </Card>
              <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Header style={{padding: "13px 23px"}}>
                  <h5 className="mb-0">Audience clienti</h5>
                </Card.Header>
                <Card.Body style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: "25px"}}>
                  <MultipleCheckField
                    showOptionalFieldLabel={true}
                    containerStyle={{gridColumn: "-1/1", justifySelf: "start"}}
                    label={"Seleziona fasce di età dell'audience: "}
                    name={"audience.age_range"}
                    items={["18-24", "25-34", "35-44", "45-54", "55-64", "65+"]
                      .map(range => ({
                        label: (<div>{range}</div>),
                        id: range
                      }))
                    }
                    isMultiSelect={true}
                    numOfColumns={6}
                    disabled={!isCreateMode}
                  />
                  <MultipleCheckField
                    showOptionalFieldLabel={true}
                    containerStyle={{gridColumn: "-1/1", justifySelf: "start"}}
                    label={"Seleziona genere dell'audience: "}
                    name={"audience.gender"}
                    items={["m", "f", "o"]
                      .map(gender => {
                        const genderItalianLabel = {m: "Uomo", f: "Donna", o: "Altro"};
                        return {
                          label: (<div>{genderItalianLabel[gender]}</div>),
                          id: gender
                        };
                      })
                    }
                    isMultiSelect={true}
                    numOfColumns={3}
                    disabled={!isCreateMode}
                  />
                  {(isCreateMode || (values.audience.locality !== null && values.audience.locality !== "")) &&
                    <InputField
                      showOptionalFieldLabel={true}
                      containerStyle={{gridColumn: "1"}}
                      name="audience.locality" type="text" label="Località audience" placeholder="Inserisci località"
                      disabled={!isCreateMode} showValid={false}
                    />
                  }
                  {!isCreateMode && (values.audience.locality === null || values.audience.locality === "") &&
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      gridColumn: "-1/1"
                    }}>
                      <Form.Label className={`label mb-0`}>Località</Form.Label>
                      <div>Nessuna località specificata</div>
                    </div>
                  }
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    gridColumn: "-1/1"
                  }}>
                    <Form.Label className={`${isCreateMode ? styles.fieldLabel : "mb-0"} label`}>Audience
                      custom</Form.Label>
                    <div style={{display: "grid", gridTemplateColumns: "auto 1fr", gap: "10px", alignItems: "center"}}>
                      {isCreateMode &&
                        <Button style={{padding: "4px 5px 1px 1px"}}
                                onClick={() => setIsShownTargetSelectionPopup(true)}>
                          <FontAwesomeIcon style={{fontSize: "18px", paddingLeft: "5px"}}
                                           icon={faArrowUpRightFromSquare}/>
                        </Button>
                      }
                      {values.objectives?.length > 0 ?
                        <div>
                          {targetList
                            .filter(target => values.objectives.includes(target.id))
                            .map((target, index, arr) => (<>
                              <span style={{fontWeight: 700}}>{`${target.group} / `}</span>{target.name}
                              {index === arr.length - 1 ? "" : `, `}
                            </>))
                          }
                        </div> :
                        "Nessun tipo custom di audience è stato selezionato"
                      }
                    </div>
                  </div>
                  {isShownTargetSelectionPopup &&
                    <ItemsSelectionPopup<DspTarget>
                      listGroupItemStyle={{padding: "2px 6px"}}
                      size={"lg"}
                      closePopupBtnDescription={"Chiudi"}
                      canSelectAllItems={true}
                      popupKey={"custom-target-sel-popup"}
                      getItemView={(target) =>
                        <>
                          <span style={{fontWeight: 800}}>{`${target.group} / `}</span>{target.name}
                        </>
                      }
                      onItemsSelected={(selectedTargetList) => {
                        setFieldValue("objectives", selectedTargetList.map(t => t.id));
                        setIsShownTargetSelectionPopup(false);
                      }}
                      onClosePopup={() => setIsShownTargetSelectionPopup(false)}
                      isShown={true}
                      itemList={targetList}
                      title="Audience custom"
                      subtitle="Seleziona gli audience custom per la campagna:"
                      alreadyAssociatedItemList={
                        targetList.filter(target => values.objectives.includes(target.id))
                      }
                    />
                  }
                </Card.Body>
              </Card>
              <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Header style={{padding: "13px 23px"}}>
                  <h5 className="mb-0">Stima preventivo campagna</h5>
                </Card.Header>
                <Card.Body
                  style={{display: "flex", flexDirection: "column", gap: "20px"}}
                >
                  {isCreateMode &&
                    <>
                      {calculatedPriceStatus === "invalidValues" &&
                        <em>Compila tutti i campi richiesti per ottenere il tuo preventivo</em>}
                      {calculatedPriceStatus === "fetching" &&
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"}}>
                          Caricamento preventivo
                          <Spinner/>
                        </div>
                      }
                      {calculatedPriceStatus === "calculated" &&
                        <div>
                          <strong>{`Prezzo unitario: `}</strong>
                          {`${calculatedPrice} \u20ac`}
                        </div>
                      }
                      {calculatedPriceStatus === "fetchError" &&
                        <div>Errore calcolo preventivo!</div>
                      }
                      {calculatedPriceStatus === "priceNotAvailable" &&
                        <div>Preventivo non disponibile</div>
                      }
                      {calculatedPriceStatus === "calculated" && values.budget as unknown as string === "" &&
                        <em>Inserisci budget per ottenere l'obiettivo minimo garantito</em>
                      }
                      {calculatedPriceStatus === "calculated" && values.budget as unknown as string !== "" &&
                        <div>
                          <strong>{`Obiettivo minimo garantito: `}</strong>
                          {getMinGuaranteedGoalLabel(values, calculatedPrice)}
                        </div>
                      }
                    </>
                  }
                  <InputField
                    style={{gridColumn: "1"}} name="budget" type="number" label={`Budget campagna [\u20ac]`}
                    placeholder="Inserisci budget" min={0} step={0.01} disabled={!isCreateMode}
                  />
                </Card.Body>
              </Card>
              <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Header style={{padding: "13px 23px"}}>
                  <h5 className="mb-0">Modalità di import</h5>
                </Card.Header>
                <Card.Body style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                  {isCreateMode &&
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          setFieldValue("snippets", null);
                          setFieldTouched("snippets", false);
                        }}
                        className={`${styles.buttongroupBackground} ${values.snippets !== null ?
                          styles.disabledButton : ""}`}
                        style={{width: "50%"}}
                      >
                        Importa file zip
                      </Button>
                      <Button
                        onClick={() => {
                          setFieldValue("snippets", [getDefaultSnippetConfig()]);
                          setFieldValue("zipFile", null);
                          setFieldTouched("zipFile", false);
                        }}
                        className={`${styles.buttongroupBackground} ${values.snippets === null ?
                          styles.disabledButton : ""}`}
                        style={{width: "50%"}}
                      >
                        Inserisci snippet di codice e formato
                      </Button>
                    </ButtonGroup>
                  }
                  {values.snippets === null && isCreateMode &&
                    <InputFileField
                      name="zipFile" accept=".zip" label="File zip da importare" withRef={uploadRef}
                      disabled={!isCreateMode}
                    />
                  }
                  {values.snippets === null && !isCreateMode &&
                    <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                      <Form.Label className="label mb-0">File zip</Form.Label>
                      {zipFileInfo &&
                        <a className={styles.downloadLink} href={zipFileInfo?.data.url} download={"test.zip"}>Scarica file zip</a>
                      }
                      {!zipFileInfo && zipFileInfoError !== undefined &&
                        <div>Errore: file zip non disponibile</div>
                      }
                      {!zipFileInfo && zipFileInfoError === undefined &&
                        <div>Caricamento in corso...</div>
                      }
                    </div>
                  }
                  {values.snippets !== null &&
                    <FormFieldsArray
                      hideAddNewItemBtn={() => !isCreateMode}
                      disableAddNewItemBtn={() => !isCreateMode}
                      getDefaultItem={getDefaultSnippetConfig}
                      addNewItemBtnLabel="Aggiungi snippet di codice"
                      addNewItemBtnComponent={SmallButton}
                      name={"snippets"}
                      getTitleForItem={({index}) => <div style={{fontSize: "15px"}}>Snippet #{index + 1}</div>}
                      renderItem={({buildFieldName, removeItem}) => (
                        <div style={{display: "flex", flexDirection: "column", gap: "10px", padding: "10px"}}>
                          <InputSelectField
                            name={buildFieldName("adformat")} label="Formato" optionValueType="number"
                            disabled={!isCreateMode}
                            children={<>
                              <option key='blankChoice' hidden value="">Seleziona formato</option>
                              {formatList
                                .filter(format => format.active)
                                .map(format => (
                                  <option key={format.id} value={format.id}>
                                    {format.name}
                                  </option>
                                ))}
                            </>}
                          />
                          <InputField
                            name={buildFieldName("snippet")} type="text" label="Snippet di codice"
                            disabled={!isCreateMode} isMultiLine={true} multiLineNumber={5}
                            placeholder={"Inserisci snippet"}
                          />
                          {isCreateMode &&
                            <SmallButton
                              variant="danger" onClick={removeItem} disabled={values.snippets.length === 1}
                              style={{alignSelf: "flex-start"}}
                            >
                              Elimina snippet
                            </SmallButton>
                          }
                        </div>
                      )}
                    />
                  }
                </Card.Body>
              </Card>
              <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                  <InputField
                    name="url" type="text" label="Url landing page" placeholder="Inserisci url landing page"
                    disabled={!isCreateMode}
                  />
                  {(isCreateMode || (values.notes !== null && values.notes !== "")) &&
                    <InputField
                      name="notes" type="text" label="Note aggiuntive" placeholder="Inserisci note aggiuntive"
                      disabled={!isCreateMode} isMultiLine={true} showValid={false} showOptionalFieldLabel={true}
                    />
                  }
                  {!isCreateMode && (values.notes === null || values.notes === "") &&
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      gridColumn: "-1/1"
                    }}>
                      <Form.Label className={`label mb-0`}>Note aggiuntive</Form.Label>
                      <div>Non sono state specificate note</div>
                    </div>
                  }
                </Card.Body>
              </Card>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "20px 24px 10px 24px"
                }}>
                <Button type="button" variant="outline-primary"
                        onClick={() => history.push(Routes.Dsp.Campaign.ViewAll.path)}>
                  {isCreateMode ? "Annulla" : "Indietro"}
                </Button>
                {isCreateMode &&
                  <Button
                    type="submit" variant="primary"
                    disabled={!dirty || isSubmitting || getModeSelectorValue(values) === ""}
                  >
                    Conferma creazione campagna
                  </Button>
                }
              </div>
              <FeedbackAlert status={responseStatus}/>
              {infoPopupForCategoryId !== null &&
                // @ts-ignore
                <CategoryWebsiteInfoPopup
                  key={infoPopupForCategoryId}
                  categoryId={infoPopupForCategoryId}
                  onClosePopup={() => setInfoPopupForCategoryId(null)}
                />
              }
            </div>
            <FormikFormHelper
              isCreateMode={isCreateMode} setCalculatedPrice={setCalculatedPrice}
              setCalculatedPriceStatus={setCalculatedPriceStatus} targetKpiSuggestions={targetKpiSuggestions.data.kpis}
            />
          </FormikForm>
        )}
      </Formik>
    );
  },
  InComponentPreloader
);

function getDefaultConfig(): CampaignDsp {
  return {
    name: "",
    agency_customer_id: null,
    starts_at: undefined, //YYYY-MM-DD
    ends_at: undefined,  //YYYY-MM-DD
    ad_type: "disp",
    // @ts-ignore
    type: "",
    audience: {
      age_range: [],
      gender: [],
      locality: null
    },
    objectives: [],
    custom_categories: null,
    weblist_id: null,
    custom_websites: null,
    // @ts-ignore
    budget: "",
    notes: "",
    snippets: null,
    url: "",
    zipFile: null,
    target_kpis: {}
  }
}

function getDefaultSnippetConfig() {
  return {
    snippet: "",
    adformat: ""
  }
}

function getDefaultTargetKpiObject(targetKpiSuggestions: TargetKpiSuggestions, type: "disp" | "vid") {
  let object = {};
  targetKpiSuggestions[type].forEach(({kpi_id}) => object[kpi_id] = "")
  return object;
}

function FormikFormHelper(props: {
  isCreateMode: boolean,
  setCalculatedPrice: (price: number) => void,
  setCalculatedPriceStatus: (status: CalculatedPriceStatus) => void,
  targetKpiSuggestions: TargetKpiSuggestions
}) {
  const {isCreateMode, setCalculatedPrice, setCalculatedPriceStatus, targetKpiSuggestions} = props;
  const formikContext = useFormikContext();
  const {setFieldValue, errors, dirty} = formikContext;
  const values = formikContext.values as unknown as (CampaignDspRequest | CampaignDspRequestWithId);

  useEffect(() => {
    if (isCreateMode)
      setFieldValue("weblist_id", "none");
  }, []);

  useEffect(() => {
    if (isCreateMode)
      setFieldValue("target_kpis", getDefaultTargetKpiObject(targetKpiSuggestions, values.ad_type));
  }, [values.ad_type]);

  const fetchPrice = React.useRef(
    debounce(
      async (values: CampaignDspRequest) => {
        const priceReq = {
          agency_customer_id: values.agency_customer_id,
          type: values.type,
          format: values.ad_type,
          custom_categories: values.custom_categories !== null,
          weblist_id: values.weblist_id,
          has_custom_audience:
            values.objectives?.length > 0 ||
            values.audience.gender?.length > 0 ||
            values.audience.age_range?.length > 0 ||
            values.audience.locality !== null,
          has_custom_websites: values.custom_websites?.length > 0
        }
        // @ts-ignore
        await calculateDspPricing(priceReq)
          .then(r => {
            setCalculatedPrice(r.data.data.price);
            setCalculatedPriceStatus("calculated");
          }).catch(e => {
            if (e.response.status === 404)
              setCalculatedPriceStatus("priceNotAvailable");
            else
              setCalculatedPriceStatus("fetchError");
          })
      },
      2000,
      {
        leading: false,
        trailing: true
      }
    )
  ).current;

  useEffect(() => {
    if (!isCreateMode)
      return;
    const canBeComputed = dirty && getModeSelectorValue(values) !== "" &&
      Object.keys(errors).filter(e => e !== "url" && e !== "zipFile" && e !== "budget").length === 0;
    if (canBeComputed) {
      setCalculatedPriceStatus("fetching");
      fetchPrice(values);
    } else {
      setCalculatedPriceStatus("invalidValues");
      fetchPrice.cancel();
    }
  }, [values, errors]);

  useEffect(() => {
    return () => {
      fetchPrice.cancel();
    };
  }, [])

  useEffect(() => {
    if (props.isCreateMode)
      setFieldValue("type", "");
  }, [values.ad_type]);

  return null;
}

function getMinGuaranteedGoalLabel(values: CampaignDspRequest, price: number) {
  let goal;
  if (values.type === "cpm")
    goal = values.budget / price * 1000;
  else
    goal = values.budget / price;
  switch (values.type) {
    case "cpc":
      return `${goal} click`;
    case "cpm":
      return `${goal} impressioni`;
    case "cpv":
      return `${goal} visite`;
    case "cpcv":
      return `${goal} visioni complete`;
  }
}

function getModeSelectorValue(values: CampaignDspRequest | CampaignDspRequestWithId): string {
  if (values.custom_websites !== null)
    return "custom";
  if (values.custom_categories !== null)
    return "category";
  if (values.weblist_id !== null)
    return "list";
  return "";
}
