import React, {useEffect, useState} from "react";
import {ListGroup, ListGroupItem} from "@themesberg/react-bootstrap";

interface Props<I> {
  itemList: I[]
  getItemView: (item: I) => JSX.Element,
  isMultiSelect: boolean,
  selectedItems: I[]
  setSelectedItems: (selectedItems: I[]) => void,
  disabledItemList: I[],
  selectableItemsMaxNumber: number,
  listKey: string | number,
  listGroupItemStyle?: object
}

export function SelectableItemList<I>(props: Props<I>) {

  const {
    itemList,
    getItemView,
    isMultiSelect,
    selectedItems,
    setSelectedItems,
    disabledItemList,
    selectableItemsMaxNumber,
    listKey,
    listGroupItemStyle = {}
  } = props;

  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState<boolean>(false);

  return (
    <>
      <ListGroup>
        {itemList.map((item, index) => {
          return <ListGroupItem
            key={`${listKey}-item-${index}`}
            action
            disabled={disabledItemList.includes(item)}
            style={{backgroundColor: getColorForItem(props, item), ...listGroupItemStyle}}
            onClick={() => {
              if (isMultiSelect) {
                if (selectedItems.includes(item)) {
                  setSelectedItems(selectedItems.filter(i => i !== item));
                  setShouldShowErrorMessage(false);
                } else if (selectedItems.length + 1 <= selectableItemsMaxNumber) {
                  if (!shouldShowErrorMessage)
                    setSelectedItems([...selectedItems, item]);
                } else
                  setShouldShowErrorMessage(true);
              } else if (selectedItems.includes(item))
                setSelectedItems([]);
              else
                setSelectedItems([item]);
            }}
          >
            {getItemView(item)}
          </ListGroupItem>
        })}
      </ListGroup>
      {shouldShowErrorMessage &&
        <div style={{color: "red", fontSize: "12px"}}>
          Puoi selezionare al massimo {selectableItemsMaxNumber} elementi.
        </div>
      }
    </>
  );
}

function getColorForItem(props, item) {
  let bgColor;
  if (props.selectedItems.includes(item))
    bgColor = "rgb(157,194,227)";
  else if (props.disabledItemList.includes(item))
    bgColor = "rgba(234,234,234,0.63)";
  else
    bgColor = "transparent";
  return bgColor;
}