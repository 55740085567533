import React from "react";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {AnalysisStatus, DomainAnalysisInfo, useGetAllDomainAnalysis} from "../../api/DomainAnalysis";
import {useHistory} from "react-router-dom";
import {Button, Card} from "@themesberg/react-bootstrap";
import {SmartTable} from "../../commons/table/SmartTable";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {Routes} from "../../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InComponentPreloader from "../../components/utils/InComponentPreloader";
import moment from "moment";
import {compareStrDates} from "../../components/utils/DateUtils";
import {useGetAllCampaigns} from "../../api/campaigns";

export const DomainAnalysisListPage = withMultiFetchSWR(
  () => [
    {useFetchFunc: useGetAllDomainAnalysis},
    {useFetchFunc: useGetAllCampaigns}
  ],
  ({endpointsResponse}) => {
    const history = useHistory();

    const [analysisList, campaignList] = endpointsResponse.data;

    return (
      <Card className="mb-4">
        <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h5 style={{display: "inline-block", margin: 0}}>Lista analisi avanzate sui domini</h5>
          <Button onClick={() => history.push(Routes.DomainAnalysis.Create.path)}>
            Crea nuova analisi
          </Button>
        </Card.Header>
        <Card.Body className="pt-3">
          <SmartTable<DomainAnalysisInfo, {}>
            pagination={true}
            tableKey="domain-analysis-list-table"
            syncWithElementsProp={true}
            enableHiddenColumns={false}
            getElementKey={(analysis) => analysis.uuid}
            sort={{
              defaultSortingColIndex: 5,
              defaultSortingDirection: "desc",
              defaultArrowVisible: true
            }}
            elements={analysisList}
            columns={[
              {
                title: "",
                cellRenderer: (a) =>
                  <span>
                    <FontAwesomeIcon
                      style={{cursor: "pointer", fontSize: "16px"}}
                      icon={faArrowUpRightFromSquare}
                      onClick={() => history.push(Routes.DomainAnalysis.Edit.buildPath(a.uuid))}
                    />
                  </span>
              },
              {
                title: "Campagne analizzate",
                cellRenderer: (a) =>
                  <div style={{display: "flex", flexDirection: "column", gap: "3px"}}>
                    {a.level_1_request.campaigns.slice(0, 3).map((campaignId) => (
                      <div>
                        {campaignList.find(c => c.id === campaignId)?.name ?? "CAMPAGNA ELIMINATA"}
                      </div>
                    ))}
                    {a.level_1_request.campaigns.length === 4 &&
                      <div style={{fontSize: "0.8rem"}}>
                        ...e 1 altra campagna
                      </div>
                    }
                    {a.level_1_request.campaigns.length > 4 &&
                      <div style={{fontSize: "0.8rem"}}>
                        {`...e altre ${a.level_1_request.campaigns.length - 4} campagne`}
                      </div>
                    }
                  </div>
              },
              {
                title: "Livello",
                cellRenderer: (a) =>
                  <div style={{textAlign: "center"}}>
                    {calculateLastLevelRequested(a.level_2, a.level_3)}
                  </div>,
                compareElementsByColumn: (A, B) =>
                  calculateLastLevelRequested(A.level_2, A.level_3) - calculateLastLevelRequested(B.level_2, B.level_3)
              },
              {
                title: "Stato analisi",
                cellRenderer: (a) => calculateAnalysisStatus(a.status),
                compareElementsByColumn: (A, B) =>
                  calculateAnalysisStatus(A.status).localeCompare(calculateAnalysisStatus(B.status))
              },
              {
                title: "Creazione",
                cellRenderer: (a) => moment(a.created_at).format("D MMM, k:mm"),
                compareElementsByColumn: (A, B) => compareStrDates(A.created_at, B.created_at)
              },
              {
                title: "Ultima operazione",
                cellRenderer: (a) => moment(a.updated_at).format("D MMM, k:mm"),
                compareElementsByColumn: (A, B) => compareStrDates(A.updated_at ?? A.created_at, B.updated_at ?? B.created_at)
              },
              {
                title: "Utente",
                cellRenderer: (a) => <div
                  style={{textAlign: "center"}}>{`${a.user.first_name ?? '-'} ${a.user.last_name ?? ''}`}</div>,
                compareElementsByColumn: (A, B) => `${A.user.first_name} ${A.user.last_name}`.localeCompare(`${B.user.first_name} ${B.user.last_name}`),
              },
            ]}
          />
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);

function calculateLastLevelRequested(level2Obj: string | null, level3Obj: string | null): number {
  if (level3Obj !== null)
    return 3;
  else if (level2Obj !== null)
    return 2;
  else
    return 1;
}

function calculateAnalysisStatus(status: AnalysisStatus): string {
  if (status === AnalysisStatus.Completed)
    return "Completata";
  else if (status === AnalysisStatus.InProgress)
    return "In corso";
  else if (status === AnalysisStatus.NotStarted)
    return "Non iniziata";
  else
    return "Sconosciuto";
}