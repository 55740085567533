import React, {useContext, useEffect, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {Card, Col, FormSelect, Row} from "@themesberg/react-bootstrap";

import "../../commons/forms/InputDateField.scss";
import {Campaign, CampaignRowsSubdomainDataSeries, CampaignRowsTimeDataSeries} from "../../api/campaigns";
import {RowBriefTable} from "./RowBriefTable";
import {useMultiFetchSWR, withMultiFetchSWR} from "../../api/helpersSWR";
import {useGetKpis} from "../../api/kpis";
import {roundNumberToTwoDecimals} from "./CampaignDataPanel";

const CHART_COLORS = [
  {
    lineColor: 'rgb(20,96,182)',
    bgColor: 'rgb(20,96,182,0.35)'
  },
  {
    lineColor: 'rgb(135,190,231)',
    bgColor: 'rgb(135,190,231,0.35)'
  },
  {
    lineColor: 'rgb(112,122,187)',
    bgColor: 'rgb(112,122,187,0.35)'
  }
];

interface Props {
  campaignKpis: Campaign["campaign_kpis"],
  rows: CampaignRowsSubdomainDataSeries["rows"],
  initialGraphKpiId: number,
  showGraph?: boolean,
  campaignPanelKey?: string
}

export default withMultiFetchSWR(
  (props: Props) => [
    {useFetchFunc: useGetKpis}
  ], ({props, endpointsResponse}) => {
    const {campaignKpis, rows, initialGraphKpiId, showGraph = true, campaignPanelKey = ""} = props;
    const [kpisResponse] = endpointsResponse.data;
    const kpis = kpisResponse.data.kpis;
    const [shownKpi, setShownKpi] = useState(campaignKpis.find(kpi => kpi.kpi_id === initialGraphKpiId));
    const kpiIds = campaignKpis.map(k => k.kpi_id);
    const shownKpiId = shownKpi.kpi_id;

    const enoughDataForChart = rows.map(r => r.values[shownKpi.kpi_id].value).reduce((sum, v) => v + sum, 0) > 0;

    return (
      <div className="chart-container mt-2">

        <Row className="my-2">
          <p style={{marginLeft: '5px', marginBottom: 0}}>
            Scegli quale KPI vedere nel grafico
          </p>
          <Col xs={12} md={4} key={shownKpi.kpi_id}>
            <FormSelect
              defaultValue={shownKpi.kpi_id}
              onChange={(evt) => {
                // @ts-ignore
                let picked = campaignKpis.find(cK => cK.kpi_id === parseInt(evt.target.value));
                setShownKpi(picked);
              }}
            >
              {
                /*
                We want each option to render the kpi is currently showing and the other that
                can be rendered so that the user can't select the same KPI twice.
                */
                kpis.filter(kpi => kpiIds.includes(kpi.id))
                  .map(kpi =>
                    <option
                      key={kpi.id} value={kpi.id}
                    >{kpi.name}</option>
                  )
              }
            </FormSelect>
          </Col>
        </Row>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
          {!enoughDataForChart &&
            <div style={{padding: "35px"}}>Dati non presenti per il kpi {shownKpi.name}</div>
          }
          {enoughDataForChart &&
            <div style={{width: "300px"}}>
              <Doughnut
                data={{
                  labels: rows.map(r => r.domain),
                  datasets: [{
                    label: shownKpi.name,
                    data: rows.map(r => r.values[shownKpi.kpi_id].value),
                    backgroundColor: CHART_COLORS.map(c => c.lineColor),
                    hoverOffset: 4,
                    // @ts-ignore
                    isPercentage: kpis.find(k => k.id === shownKpi.kpi_id).is_percentage
                  }],
                }}
                options={{
                  plugins: {
                    tooltip: {
                      enabled: true,
                      mode: "index",
                      callbacks: {
                        label: (tooltipItem) => {
                          // @ts-ignore
                          return `${tooltipItem.label}: ${tooltipItem.dataset.isPercentage ?
                            `${roundNumberToTwoDecimals(tooltipItem.parsed, true)}%` :
                            `${tooltipItem.parsed}`
                          }`
                        }
                      }
                    },
                    legend: {
                      display: false
                    }
                  },
                }}
              />
            </div>
          }
        </div>
        {rows?.length > 0 &&
          <Row>
            <RowBriefTable rows={rows} type={"subdomain"} campaignPanelKey={`${campaignPanelKey}`}/>
          </Row>
        }
      </div>

    );
  }
);
