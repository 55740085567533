import React, {useState} from 'react';
import {Button, ButtonGroup, Card, Dropdown} from "@themesberg/react-bootstrap";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH, faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useSWRConfig} from "swr";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {Routes} from "../../../routes";
import {SmartTable} from "../../../commons/table/SmartTable";
import {TargetImportPopup} from "./TargetImportPopup";
import {deleteDspTarget, DspTarget, useGetAllDspTargetsNoStaleData} from "../../../api/dsp/targets";
import {buildRegexMatchPattern, useMutateMany} from "../../../api/swr-fetcher";
import {InputField} from "../../../commons/forms/InputField";
import {TopLevelDropdownMenu} from "../../../components/utils/TopLevelDropdownMenu";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

interface Filters {
  name?: {
    searchFor: string,
  },
  group?: {
    searchFor: string,
  }
}

export const DspTargetListPage = withMultiFetchSWR(
  (props: {}, urlParams: {}) => [{useFetchFunc: () => useGetAllDspTargetsNoStaleData()}],
  ({props, endpointsResponse}) => {

    const {data: [targetList]} = endpointsResponse;
    const [showTargetImportPopup, setShowTargetImportPopup] = useState(false);

    const mutateMany = useMutateMany();
    const {cache} = useSWRConfig();
    const history = useHistory();

    return (
      <Card className="mb-4">
        <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h5 style={{display: "inline-block", margin: 0}}>Lista audience</h5>
          <div style={{display: "flex", flexDirection: "row", gap: "15px"}}>
            <Button onClick={() => setShowTargetImportPopup(true)}>
              Importa audience da csv
            </Button>
            <Button onClick={() => history.push(Routes.Dsp.Target.Create.path)}>
              Crea audience
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pt-3">
          <SmartTable<DspTarget, Filters>
            pagination={true}
            tableKey="dsp-target-list-table"
            syncWithElementsProp={true}
            enableHiddenColumns={false}
            filter={{
              filterParamsInitialValue: {},
              applyFiltersFunction: (elements, filterParams) => elements
                .filter(el => !filterParams.name || filterParams.name.searchFor === "" || el.name.toLowerCase().includes(filterParams.name.searchFor.toLowerCase().trim()))
                .filter(el => !filterParams.group || filterParams.group.searchFor === "" || el.group.toLowerCase().includes(filterParams.group.searchFor.toLowerCase().trim()))
            }}
            getElementKey={(target) => target.id}
            sort={{
              defaultSortingColIndex: 1,
              defaultArrowVisible: true
            }}
            elements={targetList ?? []}
            columns={[
              {
                title: "",
                cellRenderer: (target) =>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                    </span>
                    </Dropdown.Toggle>
                    <TopLevelDropdownMenu align="left">
                      <Dropdown.Item
                        onClick={() => history.push(Routes.Dsp.Target.Edit.buildPath(target.id))}>
                        <FontAwesomeIcon icon={faPencilAlt}/> Modifica
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => deleteDspTarget(target.id).then(() => mutateMany(cache, buildRegexMatchPattern("dsp/objectives")))}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/> Elimina
                      </Dropdown.Item>
                    </TopLevelDropdownMenu>
                  </Dropdown>
              },
              {
                title: "Gruppo",
                cellRenderer: (target) => target.group,
                compareElementsByColumn:
                  (targetA, targetB) => targetA.group.localeCompare(targetB.group),
                columnFilter: {
                  key: "group",
                  fieldsRenderer: ({getFieldName}) =>
                    <InputField
                      name={getFieldName("searchFor")} type="text" placeholder="Inserisci il gruppo..."
                      showValid={false}
                      style={{maxWidth: "500px"}}
                    />,
                  filterParamsFieldDefaultValue: () => ({
                    searchFor: ""
                  })
                }
              },
              {
                title: "Audience",
                cellRenderer: (target) => target.name,
                compareElementsByColumn:
                  (targetA, targetB) => targetA.name.localeCompare(targetB.name),
                columnFilter: {
                  key: "name",
                  fieldsRenderer: ({getFieldName}) =>
                    <InputField
                      name={getFieldName("searchFor")} type="text" placeholder="Inserisci il nome dell'audience..."
                      showValid={false}
                      style={{maxWidth: "500px"}}
                    />,
                  filterParamsFieldDefaultValue: () => ({
                    searchFor: ""
                  })
                }
              }
            ]}
          />
        </Card.Body>
        {showTargetImportPopup &&
          <TargetImportPopup isShown={showTargetImportPopup} onClosePopup={() => setShowTargetImportPopup(false)}/>
        }
      </Card>
    );
  },
  InComponentPreloader
);