import React, {useState} from 'react';
import {Button, ButtonGroup, Card, Dropdown} from "@themesberg/react-bootstrap";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisH, faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useSWRConfig} from "swr";
import {withMultiFetchSWR} from "../../../api/helpersSWR";
import {Routes} from "../../../routes";
import {SmartTable} from "../../../commons/table/SmartTable";
import {deleteDspWebsite, DspWebsiteResponse, useGetAllDspWebsitesNoStaleData} from "../../../api/dsp/websites";
import {WebsiteImportPopup} from "./WebsiteImportPopup";
import {buildRegexMatchPattern, useMutateMany} from "../../../api/swr-fetcher";
import moment from "moment/moment";
import * as Yup from "yup";
import {InputField} from "../../../commons/forms/InputField";
import {InputSelectField} from "../../../commons/forms/InputSelectField";
import {useGetAllWebCategories} from "../../../api/dsp/webCategories";
import {useGetAllWebLists} from "../../../api/dsp/webLists";
import {TopLevelDropdownMenu} from "../../../components/utils/TopLevelDropdownMenu";
import InComponentPreloader from "../../../components/utils/InComponentPreloader";

interface Filters {
  domain?: {
    searchFor: string,
  },
  categoryId?: {
    value: number,
  },
  listId?: {
    value: number,
  }
}

export const DspWebsiteListPage = withMultiFetchSWR(
  (props: {}, urlParams: {}) => [
    {useFetchFunc: () => useGetAllDspWebsitesNoStaleData()},
    {useFetchFunc: useGetAllWebCategories},
    {useFetchFunc: useGetAllWebLists}
  ],
  ({props, endpointsResponse}) => {

    const {data: [websiteList, webCategories, webLists]} = endpointsResponse;
    const [showWebsiteImportPopup, setShowWebsiteImportPopup] = useState(false);

    const mutateMany = useMutateMany();
    const {cache} = useSWRConfig();
    const history = useHistory();

    return (
      <Card className="mb-4">
        <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h5 style={{display: "inline-block", margin: 0}}>Lista siti</h5>
          <div style={{display: "flex", flexDirection: "row", gap: "15px"}}>
            <Button onClick={() => setShowWebsiteImportPopup(true)}>
              Importa siti da csv
            </Button>
            <Button onClick={() => history.push(Routes.Dsp.Website.Create.path)}>
              Crea sito
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="pt-3">
          <SmartTable<DspWebsiteResponse, Filters>
            pagination={true}
            tableKey="dsp-website-list-table"
            syncWithElementsProp={true}
            enableHiddenColumns={false}
            filter={{
              filterParamsInitialValue: {},
              applyFiltersFunction: (elements, filterParams) => elements
                .filter(el => !filterParams.domain || filterParams.domain.searchFor === "" || el.domain.toLowerCase().includes(filterParams.domain.searchFor.toLowerCase().trim()))
                .filter(el => !filterParams.listId || filterParams.listId.value === -1 || el.lists.find(l => l.id === filterParams.listId.value))
                .filter(el => !filterParams.categoryId || filterParams.categoryId.value === -1 || el.category.id === filterParams.categoryId.value)
            }}
            getElementKey={(website) => website.id}
            sort={{
              defaultSortingColIndex: 1,
              defaultArrowVisible: true
            }}
            elements={websiteList ?? []}
            columns={[
              {
                title: "",
                cellRenderer: (website) =>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                    </span>
                    </Dropdown.Toggle>
                    <TopLevelDropdownMenu align="left">
                      <Dropdown.Item
                        onClick={() => history.push(Routes.Dsp.Website.Edit.buildPath(website.id))}>
                        <FontAwesomeIcon icon={faPencilAlt}/> Modifica
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => deleteDspWebsite(website.id).then(() => mutateMany(cache, buildRegexMatchPattern("dsp/websites")))}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/> Elimina
                      </Dropdown.Item>
                    </TopLevelDropdownMenu>
                  </Dropdown>
              },
              {
                title: "Dominio",
                cellRenderer: (website) => website.domain,
                compareElementsByColumn:
                  (websiteA, websiteB) => websiteA.domain.localeCompare(websiteB.domain),
                columnFilter: {
                  key: "domain",
                  fieldsRenderer: ({getFieldName}) =>
                    <InputField
                      name={getFieldName("searchFor")} type="text" placeholder="Inserisci il nome del dominio ..."
                      showValid={false}
                      style={{maxWidth: "500px"}}
                    />,
                  filterParamsFieldDefaultValue: () => ({
                    searchFor: ""
                  })
                }
              },
              {
                title: "Settore",
                cellRenderer: (website) => website.category?.name ?? "",
                compareElementsByColumn:
                  (websiteA, websiteB) => (websiteA.category?.name ?? "").localeCompare(websiteB.category?.name ?? ""),
                columnFilter: {
                  key: "categoryId",
                  fieldsRenderer: ({getFieldName}) =>
                    <InputSelectField
                      name={getFieldName("value")}
                      optionValueType="number"
                      showValid={false}
                      style={{maxWidth: "250px"}}
                    >
                      <>
                        <option key='blankChoice' value={-1} >TUTTI</option>
                        {webCategories.map(c => (
                          <option key={c.id} value={c.id} >{c.name}</option>
                        ))}
                      </>
                    </InputSelectField>,
                  filterParamsFieldDefaultValue: () => ({
                    value: -1
                  })
                }
              },
              {
                title: "Liste",
                cellRenderer: (website) =>
                  website.lists
                    .map(list => list.name)
                    .sort((nameA, nameB) => nameA.localeCompare(nameB))
                    .join(", "),
                columnFilter: {
                  key: "listId",
                  fieldsRenderer: ({getFieldName}) =>
                    <InputSelectField
                      name={getFieldName("value")}
                      optionValueType="number"
                      showValid={false}
                      style={{maxWidth: "250px"}}
                    >
                      <>
                        <option key='blankChoice' value={-1} >TUTTI</option>
                        {webLists.map(l => (
                          <option key={l.id} value={l.id} >{l.name}</option>
                        ))}
                      </>
                    </InputSelectField>,
                  filterParamsFieldDefaultValue: () => ({
                    value: -1
                  })
                }
              }
            ]}
          />
        </Card.Body>
        {showWebsiteImportPopup &&
          <WebsiteImportPopup
            isShown={showWebsiteImportPopup}
            onClosePopup={() => setShowWebsiteImportPopup(false)}
          />
        }
      </Card>
    );
  },
  InComponentPreloader
);