export const USER_STATUSES = [
    {value: 1, label: 'Attivo'},
    {value: 0, label: 'Disattivo'},
];

export const USER_ROLES = [
    {value: 'sysadmin', label: 'Amministratore di sistema'},
    {value: 'admin', label: 'Amministratore'},
    {value: 'operator', label: 'Operatore'},
    {value: 'customer', label: 'Cliente'},
    // {value: 'agency', label: 'Agenzia'},
    // {value: 'aggregators', label: 'Aggregatore'},
];

function getLabelFromMapping(mapping, value, altDescription) {
    const description = mapping.find(r => r.value === value);
    if (description !== undefined)
        return description.label;;
    return altDescription;
}

export function getUserStatusLabel(value) {
    return getLabelFromMapping(USER_STATUSES, value, "NON DEFINITO");
}

export function getUserRoleLabel(value) {
    return getLabelFromMapping(USER_ROLES, value, "");
}

