import {Button, CloseButton, Modal} from "@themesberg/react-bootstrap";
import {InputField} from "../../../commons/forms/InputField";
import React, {useState} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  AgencyCustomer, AgencyCustomerResponse,
  createNewCustomerForLoggedAgency,
  updateCustomerForLoggedAgency
} from "../../../api/dsp/agencyCustomers";
import {FeedbackAlert} from "../../../components/utils/FeedbackAlert";
import {Routes} from "../../../routes";
import {useHistory} from "react-router-dom";
import {buildRegexMatchPattern, useMutateManyV2} from "../../../api/swr-fetcher";
import {AxiosResponse} from "axios";

interface Props {
  isShown: boolean;
  onClosePopup: () => void;
  customer?: null | AgencyCustomer;
  onSuccessfulSubmit: (newCustomerId: number) => void | undefined | null;
}

const Schema = Yup.object({
  name: Yup.string().required()
});

export function CreateOrEditAgencyCustomerPopup(props: Props) {

  const {isShown, onClosePopup, customer = null, onSuccessfulSubmit} = props;
  const isCreateMode = customer === null;
  const [responseStatus, setResponseStatus] = useState<"DEFAULT" | "SUCCESS" | "FAIL">("DEFAULT");
  const history = useHistory();
  const mutateMany = useMutateManyV2();

  return (
    <Formik
      initialValues={customer ?? {name: ""}}
      validationSchema={Schema}
      onSubmit={(values, {setSubmitting}) => {
        let promise;
        if(isCreateMode)
          promise = createNewCustomerForLoggedAgency(values);
        else
          promise = updateCustomerForLoggedAgency(customer.id, values);
        promise.then((r: AxiosResponse<AgencyCustomerResponse>) => {
            if(onSuccessfulSubmit)
              onSuccessfulSubmit(r.data.data.agency_customer.id);
            setResponseStatus("SUCCESS");
          }).then(e => mutateMany(buildRegexMatchPattern("dsp/agency-customers")))
          .catch(e => setResponseStatus("FAIL"))
          .finally(() => setSubmitting(false));
      }}
    >
      {({submitForm}) => (
        <Modal centered show={isShown}>
          <Modal.Header style={{alignItems: "center"}}>
            <Modal.Title style={{fontSize: "18px"}}>{isCreateMode ? "Crea nuovo cliente" : "Modifica cliente"}</Modal.Title>
            <CloseButton onClick={onClosePopup}/>
          </Modal.Header>
          <Modal.Body className="m-0 pt-2 px-3">
            <InputField name="name" type="text" label="Nome cliente" placeholder="Inserisci nome"/>
          </Modal.Body>
          <Modal.Footer style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Button variant="outline-primary" onClick={onClosePopup} type={"button"}>
                Annulla
              </Button>
              <Button variant="danger" onClick={submitForm}>
                Conferma
              </Button>
            </div>
            <FeedbackAlert
              status={responseStatus}
              successMessage={isCreateMode ? "Nuovo utente creato con successo!" : "Modifica avvenuta con successo!"}
              failMessage="Ops! Qualcosa è andato storto."
            />
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}