import React, {useEffect, useState} from 'react';
import {axiosFetcher} from '../api/swr-fetcher';
import useSWR from 'swr';
import Preloader from "../components/utils/Preloader";

const AppWithContext = ({children}) => {
    const token = localStorage.getItem('token');

    const [kpis, setKpis] = useState(null);
    const [attributes, setAttributes] = useState(null);

    const { data: kpisResponse } = useSWR(token && !Array.isArray(kpis) ? '/kpis' : null, axiosFetcher);
    const { data: attributesResponse } = useSWR(token && !Array.isArray(attributes) ? '/attributes' : null, axiosFetcher);

    useEffect(() => {
        if (kpisResponse) {
            setKpis(kpisResponse.data.kpis);
        }
    }, [kpisResponse]);

    useEffect(() => {
        if (attributesResponse) {
            setAttributes(attributesResponse.data);
        }
    }, [attributesResponse]);

    if (token && (!Array.isArray(kpis) || !Array.isArray(attributes))) {
        return <Preloader show={true} />;
    }

    return children;
};

export default AppWithContext;