import Dropdown from "@themesberg/react-bootstrap/lib/esm/Dropdown";
import {BsPrefixRefForwardingComponent} from "@themesberg/react-bootstrap/lib/esm/helpers";
import {DropdownMenuProps} from "@themesberg/react-bootstrap/lib/esm/DropdownMenu";
import {DOMContainer} from "@restart/ui/cjs/useWaitForDOMRef";
import {Portal} from "@restart/ui";
import React from "react";

export const TopLevelDropdownMenu: BsPrefixRefForwardingComponent<'div', DropdownMenuProps & {portalRef?: DOMContainer}> = (props) => {
  const {portalRef=document.body, ...menuProps} = props;
 if(!portalRef)
    return <></>;
  return (
    <Portal container={portalRef}>
      <Dropdown.Menu {...menuProps}>
      </Dropdown.Menu>
    </Portal>
  );
}