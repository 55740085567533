import useSWR, {BareFetcher, Fetcher, Key, KeyedMutator, KeyLoader, SWRConfiguration} from "swr";
import * as React from "react";
import {useEffect, useMemo} from "react";
import Preloader from "../components/utils/Preloader";
import {useParams} from "react-router-dom/esm/react-router-dom";
import useSWRInfinite from "swr/infinite";
import {fetcherWithParams} from "./swr-fetcher";
import {EndpointWrapperPaged} from "./common";
import {
  SWRInfiniteConfiguration,
  SWRInfiniteFetcher,
  SWRInfiniteKeyLoader,
  SWRInfiniteResponse
} from "swr/infinite/dist/infinite/types";

interface DataType {
  [dataKey: string]: any
}

interface DataFetchConfigBase<K extends Key, T> {
  useFetchFunc: () => Omit<ReturnType<typeof useSWR<T>>, "mutate">
}

interface DataFetchConfigOptionalFetch<K extends Key, T> extends DataFetchConfigBase<K, T> {
  shouldFetch: boolean,
  elseData: T
}

type DataFetchConfig<K extends Key, T> = DataFetchConfigBase<K, T> | DataFetchConfigOptionalFetch<K, T>;

export function useMultiFetchSWR<T extends readonly DataFetchConfig<unknown, unknown>[] | []>(endpoints: T): {
  allEndpointsLoaded: boolean,
  data: { -readonly [P in keyof T]: ReturnType<T[P]["useFetchFunc"]>["data"]},
  error: { -readonly [P in keyof T]: ReturnType<T[P]["useFetchFunc"]>["error"]}
} {
  let isLoading = false;
  const responses = endpoints.map(e => {
    if (!("shouldFetch" in e) || e.shouldFetch ) {
      const res: ReturnType<typeof useSWR<unknown>> = e.useFetchFunc();
      if(res.data == null)
        isLoading = true;
      return res;
    } else {
      return {data: e.elseData, error: null};
    }
  });
  return {
    allEndpointsLoaded: !isLoading,
    // @ts-ignore
    data: responses.map(r => r.data),
    // @ts-ignore
    error: responses.map(r => r.error)
  };
};

export function withMultiFetchSWR<P, T extends readonly DataFetchConfig<unknown, unknown>[] | [], U>(
  endpointsConfig: (props: P, urlParams: U) => T,
  component: React.ComponentType<{
    props: P,
    endpointsResponse: {
      allEndpointsLoaded: boolean,
      data: { -readonly [P in keyof T]: ReturnType<T[P]["useFetchFunc"]>["data"]},
      error: { -readonly [P in keyof T]: ReturnType<T[P]["useFetchFunc"]>["error"]}
    },
    urlParams: U,
  }>,
  preloader?: React.ComponentType<{show: boolean}>
): React.ComponentType<P> {
  return (props) => {

    const urlParams = useParams();

    const endpointsConf = endpointsConfig(props, urlParams);

    const endpointsResponse = useMultiFetchSWR(endpointsConf);

    if (endpointsResponse.error.some(e => e)) {
      console.error(endpointsResponse.error)
      throw "Error with api call:" + endpointsResponse.error.filter(e => e).join(", ")
    }

    const PreloaderComp = preloader ?? Preloader;

    if(!endpointsResponse.allEndpointsLoaded)
      return <PreloaderComp show={true}/>;

    const Comp = component;
    return (
      // @ts-ignore
      <Comp props={props} endpointsResponse={endpointsResponse} urlParams={urlParams}/>
    )

  };
}

export function useSWRNoStaleData<D = unknown>(key: Key, fetcher: BareFetcher<D>, options?: SWRConfiguration): ReturnType<typeof useSWR<D>>;
export function useSWRNoStaleData<D = unknown, K extends Key = unknown>(key: K, fetcher: Fetcher<D, K>, options?: SWRConfiguration) {
  const random = React.useRef(Date.now());
  return useSWR<D>(key !== null ? [key, random] : null, (key, rand) => fetcher(key), options);
}


export function useSWRInfiniteNoStaleData<DataPage = unknown>(getKey: (index: number, previousPageData: DataPage | null) => null, fetcher: SWRInfiniteFetcher<DataPage, (index: number, previousPageData: DataPage | null) => null> | null): ReturnType<typeof useSWRInfinite<DataPage>>;
export function useSWRInfiniteNoStaleData<DataPage = unknown>(getKey: (index: number, previousPageData: DataPage | null) => any, fetcher: SWRInfiniteFetcher<DataPage, (index: number, previousPageData: DataPage | null) => null> | null): ReturnType<typeof useSWRInfinite<DataPage>>;
export function useSWRInfiniteNoStaleData<DataPage = unknown>(getKey: (index: number, previousPageData: DataPage | null) => any, fetcher: SWRInfiniteFetcher<DataPage, (index: number, previousPageData: DataPage | null) => null> | null, config: SWRInfiniteConfiguration<DataPage, Error, SWRInfiniteFetcher<DataPage, KeyLoader>> | undefined): ReturnType<typeof useSWRInfinite<DataPage>>;
export function useSWRInfiniteNoStaleData<
  DataPage = any,
  Error = any,
  KeyLoader extends SWRInfiniteKeyLoader = (index: number, previousPageData: DataPage | null) => null
>(
  getKey: KeyLoader,
  fetcher: SWRInfiniteFetcher<DataPage, KeyLoader> | null,
  config: SWRInfiniteConfiguration<DataPage, Error, SWRInfiniteFetcher<DataPage, KeyLoader>> | undefined = undefined
): SWRInfiniteResponse<DataPage, Error> {
  const random = React.useRef(Date.now());
  return useSWRInfinite<DataPage>((pageIndex, previousPageData) => [getKey(pageIndex, previousPageData), random], (key, rand) => fetcher(key), config);
}

export function useGetPaginatedItems<P extends EndpointWrapperPaged<any>, I>(
  url: string | null | undefined,
  getItemsInPage: (page: P) => I[],
  params: any = {},
  noStaleData = false,
  pageSize = 50,
  config: SWRInfiniteConfiguration = {}
) {
  const swrFunc: typeof useSWRInfiniteNoStaleData<P> | typeof useSWRInfinite<P> = noStaleData ? useSWRInfiniteNoStaleData : useSWRInfinite;
  const {data, error, mutate, isValidating, size, setSize} = swrFunc(
    (pageIndex, previousPageData) => {
      if (url && (pageIndex === 0 || pageIndex < previousPageData.data.total_pages))
        return {
          url,
          params: {
            ...params,
            limit: pageSize,
            page: pageIndex,
          }
        };
      else
        return null;
    },
    fetcherWithParams,
    {
      revalidateFirstPage: true,
      revalidateOnMount: true,
      revalidateIfStale: true,
      ...config
    }
  )
  useEffect(() => {
      const newSize = Math.max(data?.[0]?.data?.total_pages ?? 1, 1);
      if(size != newSize)
        setSize(newSize)
    },
    [data?.[0]?.data?.total_pages]
  );

  const flatPageData = useMemo(() => data != null ? data.flatMap(d => getItemsInPage(d)) : null, [data]);

  return {
    data: flatPageData,
    error,
    mutate,
    isValidating
  };

}