import {EndpointWrapper, EndpointWrapperPaged, EntityWithId} from "../common";
import {useGetPaginatedItems, useSWRNoStaleData} from "../helpersSWR";
import axios, {AxiosResponse} from "axios";
import useSWR from "swr";
import {axiosFetcher} from "../swr-fetcher";

// GET
export interface DspWebsiteBaseResponse {
  domain: string,
  category: {
      id: number,
      name: string
  },
  lists: Array<{
    id: number,
    name: string
  }>
}

// POST, PUT
export interface DspWebsiteRequestBody {
  domain: string,
  category_id: number | null,
  lists: number[] | null
}

export type DspWebsiteResponse = EntityWithId<DspWebsiteBaseResponse>;
export type DspWebsitesPage = EndpointWrapperPaged<{ websites: DspWebsiteResponse[] }>

export function useGetAllDspWebsites() {
  return useGetPaginatedItems(
    "dsp/websites",
    (p: DspWebsitesPage) => p.data.websites
  );
}

export function useGetAllDspWebsitesNoStaleData() {
  return useGetPaginatedItems(
    "dsp/websites",
    (p: DspWebsitesPage) => p.data.websites,
    {},
    true
  );
}

export type WebsiteResponse = EndpointWrapper<{ website: DspWebsiteResponse }>

export function useGetDspWebsite(idWebsite: number) {
  return useSWR<WebsiteResponse>(`/dsp/websites/${idWebsite}`, axiosFetcher);
}

export function useGetDspWebsiteNoStaleData(idWebsite: number) {
  return useSWRNoStaleData<WebsiteResponse>(`/dsp/websites/${idWebsite}`, axiosFetcher);
}

export const createDspWebsite = (data: DspWebsiteRequestBody) => {
  return axios.post('/dsp/websites', data) as Promise<AxiosResponse<WebsiteResponse>>;
};

export const updateDspWebsite = (idWebsite: number, data: DspWebsiteRequestBody) => {
  return axios.put(`/dsp/websites/${idWebsite}`, data) as Promise<AxiosResponse<WebsiteResponse>>;
}

export const deleteDspWebsite = (idWebsite: number) => {
  return  axios.get(`/dsp/websites/${idWebsite}`)
    .then((r: AxiosResponse<WebsiteResponse>) => updateDspWebsite(idWebsite, {domain: r.data.data.website.domain, lists: [], category_id: null}))
    .then(r => axios.delete(`/dsp/websites/${idWebsite}`));
};

const windowUrl = window.URL || window.webkitURL;
export function browserDownloadCSVTemplateForWebsitesBulkImport() {
  axios.get("dsp/websites/bulkTemplate", {headers: {'Content-Type': 'text/csv',}})
    .then(response => {
      const blob = new Blob([response.data], { type: 'text/bin' });
      const url = windowUrl.createObjectURL(blob);
      var n = document.createElement('a');
      n.href = url;
      n.download = 'import_domini_template.csv';
      n.click();
      windowUrl.revokeObjectURL(url);
    })
}

interface UploadWebsitesCsvResponse {
  count: number,
  errors: Array<{
    domain: string,
    error: 1 | 2    // 1 = Domain already exists, 2 = Category not found
  }>
}

export function uploadWebsitesCsv(csv: File) {
  const formData = new FormData();
  formData.append('file', csv);
  return axios.post(`dsp/websites/bulk`, formData) as Promise<AxiosResponse<EndpointWrapper<UploadWebsitesCsvResponse>>>;
}
