import React, {useState} from 'react';
import {Button, ButtonGroup, Card, Dropdown} from "@themesberg/react-bootstrap";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEllipsisH,
  faEye, faKey,
  faPencilAlt,
  faTimesCircle,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {deleteUser, GetResponseUser, useGetAllUsers} from "../../api/users";
import {buildRegexMatchPattern, useMutateManyV2} from "../../api/swr-fetcher";
import {Routes} from "../../routes";
import {SmartTable} from "../../commons/table/SmartTable";
import {EditUserPasswordPopup} from "./EditUserPasswordPopup";
import {TopLevelDropdownMenu} from "../../components/utils/TopLevelDropdownMenu";
import InComponentPreloader from "../../components/utils/InComponentPreloader";

export const userRoles = {
  customer: "Cliente",
  operator: "Operatore",
  admin: "Amministratore",
  sysadmin: "Amministratore di sistema",
  agency: "Agenzia",
  aggregators: "Aggregatore"
}

export const UserListPage = withMultiFetchSWR(
  (props: {}, urlParams: {}) => [{useFetchFunc: useGetAllUsers}],
  ({props, endpointsResponse}) => {

    const {data: [userList]} = endpointsResponse;
    const [showEditPasswordPopup, setShowEditPasswordPopup] = useState(false);

    const mutateMany = useMutateManyV2();
    const history = useHistory();

    return (
      <Card className="mb-4">
        <Card.Header style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <h5 style={{display: "inline-block", margin: 0}}>Lista utenti</h5>
          <Button onClick={() => history.push(Routes.Users.Create.path)}>
            Crea utente
          </Button>
        </Card.Header>
        <Card.Body className="pt-3">
          <SmartTable<GetResponseUser, never>
            pagination={true}
            tableKey="user-list-table"
            syncWithElementsProp={true}
            enableHiddenColumns={false}
            getElementKey={(user) => user.id}
            sort={{
              defaultSortingColIndex: 1,
              defaultArrowVisible: true
            }}
            elements={userList ?? []}
            columns={[
              {
                title: "",
                cellRenderer: (user) =>
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                    <span className="icon icon-sm">
                      <FontAwesomeIcon icon={faEllipsisH} className="icon-dark"/>
                    </span>
                    </Dropdown.Toggle>
                    <TopLevelDropdownMenu align="left">
                      <Dropdown.Item
                        onClick={() => history.push(Routes.Users.View.buildPath(user.id))}>
                        <FontAwesomeIcon icon={faEye}/> Visualizza profilo
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => history.push(Routes.Users.Edit.buildPath(user.id))}>
                        <FontAwesomeIcon icon={faPencilAlt}/> Modifica profilo
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setShowEditPasswordPopup(true)}>
                        <FontAwesomeIcon icon={faKey}/> Modifica password
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => deleteUser(user.id).then(() => mutateMany(buildRegexMatchPattern("users")))}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}/> Elimina
                      </Dropdown.Item>
                      {showEditPasswordPopup &&
                        <EditUserPasswordPopup
                          isShown={true} onClosePopup={() => setShowEditPasswordPopup(false)} userInfo={user}
                        />
                      }
                    </TopLevelDropdownMenu>
                  </Dropdown>
              },
              {
                title: "Nome",
                cellRenderer: (user) => user.first_name,
                compareElementsByColumn: (userA, userB) =>
                  userA.first_name.localeCompare(userB.first_name)
              },
              {
                title: "Cognome",
                cellRenderer: (user) => user.last_name,
                compareElementsByColumn: (userA, userB) =>
                  userA.last_name.localeCompare(userB.last_name)
              },
              {
                title: "Email",
                cellRenderer: (user) => user.email,
                compareElementsByColumn: (userA, userB) =>
                  userA.email.localeCompare(userB.email)
              },
              {
                title: "Ruolo",
                cellRenderer: (user) => userRoles[user.role],
                compareElementsByColumn: (userA, userB) =>
                  userA.role.localeCompare(userB.role)
              },
              {
                title: "Attivo",
                cellRenderer: (user) => user.status === 1 ?
                  <FontAwesomeIcon icon={faCheckCircle} color={"green"} style={{fontSize: "17px"}}/> :
                  <FontAwesomeIcon icon={faTimesCircle} style={{fontSize: "17px"}}/>
                ,
                compareElementsByColumn: (userA, userB) => userA.status - userB.status
              }
            ]}
          />
        </Card.Body>
      </Card>
    );
  },
  InComponentPreloader
);