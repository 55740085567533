import axios from 'axios';
import useSWR from "swr";
import {axiosFetcher} from "./swr-fetcher";
import {useSWRNoStaleData} from "./helpersSWR";
import {EndpointWrapper, EntityWithId} from "./common";

interface ExtraXls {
    header_row: number,
    data_starts_at_row: number,
    sheet_name: string
}

interface ExtraCsv {
    delimiter: string // e.g. ","
}

export interface MappingCommon {
    name: string,
    created_at: string; //YYYY-MM-DDTHH:MM:SS+HH:MM
    updated_at: string; //YYYY-MM-DDTHH:MM:SS+HH:MM
    fields: Array<{
        id: number,
        name: string,
        type: "kpi" | "attribute",
    }>
}

interface MappingXls extends MappingCommon {
    file_type: "xls",
    extra: ExtraXls
}

interface MappingCsv extends MappingCommon {
    file_type: "csv",
    extra: ExtraCsv
}

export type MappingBase = MappingXls | MappingCsv;
export type Mapping = EntityWithId<MappingBase>;

interface MappingCreateCommon {
    template: string;
    field_mappings: Array<{
        id: number,
        type: "kpi" | "attribute",
        column_name: string
    }>;
}

interface MappingCreateFileXls extends MappingCreateCommon, ExtraXls {
    file_type: "xls"
}

interface MappingCreateFileCsv extends MappingCreateCommon, ExtraCsv {
    file_type: "csv",
}

export type MappingCreate = MappingCreateFileXls | MappingCreateFileCsv;

export const createMapping = (mapping: MappingCreate) => {
    return axios.post('/imports/mappings', mapping);
}

export const updateMapping = (id, mapping: MappingCreate) => {
    return axios.put(`/imports/mappings/${id}`, mapping);
}

export const deleteMapping = (id) => {
    return axios.delete(`/imports/mappings/${id}`);
}

export function useGetMappings() {
     return useSWR<EndpointWrapper<Mapping[]>>('/imports/mappings', axiosFetcher);
}

export function useGetMappingsNoStaleData() {
    return useSWRNoStaleData<EndpointWrapper<Mapping[]>>('/imports/mappings', axiosFetcher);
}