import React, {useEffect, useState} from "react";
import {Button, CloseButton, Modal} from '@themesberg/react-bootstrap';
import {SelectableItemList} from "./SelectableItemList";

interface Props<I> {
  title?: string,
  subtitle?: string,
  isShown: boolean,
  onItemsSelected: (selectedItems: I[]) => void,
  onClosePopup: () => void,
  itemList: I[],
  getItemView: (item: I) => JSX.Element,
  alreadyAssociatedItemList?: I[],
  disabledItemList?: I[],
  canSelectAllItems?: boolean,
  selectAllItemsBtnDescription?: string,
  deselectAllItemsBtnDescription?: string,
  selectableItemsMaxNumber?: number,
  isMultiSelect?: boolean,
  popupKey: string | number,
  size?: "sm" | "lg" | "xl" | undefined,
  closePopupBtnDescription?: string,
  confirmBtnDescription?: string,
  listGroupItemStyle?: any
}

export function ItemsSelectionPopup<I>(props: Props<I>) {

  const {
    title = "",
    subtitle = "",
    isShown,
    onItemsSelected,
    onClosePopup,
    itemList,
    getItemView,
    alreadyAssociatedItemList = [],
    disabledItemList = [],
    canSelectAllItems = false,
    selectAllItemsBtnDescription = "Seleziona tutti",
    deselectAllItemsBtnDescription = "Deseleziona tutti",
    selectableItemsMaxNumber = itemList.length,
    isMultiSelect = true,
    popupKey,
    size = undefined,
    closePopupBtnDescription = "Annulla",
    confirmBtnDescription = "Conferma",
    listGroupItemStyle = {}
  } = props;

  const [selectedItems, setSelectedItems] = useState<I[]>(alreadyAssociatedItemList);

  const areAllItemsSelected = selectedItems.length === itemList.length;

  useEffect(() => {
    if (isShown)
      setSelectedItems(alreadyAssociatedItemList);
  }, [isShown]);

  return (
    <Modal centered show={isShown} size={size}>
      <Modal.Header
        style={{justifyContent: "space-between", alignItems: "center", paddingBottom: "0.7rem", paddingTop: "0.7rem"}}
      >
        <Modal.Title style={{fontSize: "20px"}}> {title} </Modal.Title>
        <CloseButton onClick={onClosePopup}/>
      </Modal.Header>
      <Modal.Body className="m-0 pt-2 px-3">
        <div style={{marginTop: "5px", marginBottom: "10px"}}>{subtitle}</div>
        <div style={{justifyContent: "center", display: "flex", flexDirection: "column", gap: "10px"}}>
          {canSelectAllItems && !areAllItemsSelected &&
            <Button onClick={() => setSelectedItems([...itemList])} style={{padding: "5px"}}>
              {selectAllItemsBtnDescription}
            </Button>
          }
          {canSelectAllItems && areAllItemsSelected &&
            <Button onClick={() => setSelectedItems([])} style={{padding: "5px"}}>
              {deselectAllItemsBtnDescription}
            </Button>
          }
          <SelectableItemList
            listKey={popupKey}
            itemList={itemList}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            getItemView={getItemView}
            disabledItemList={disabledItemList}
            isMultiSelect={isMultiSelect}
            selectableItemsMaxNumber={selectableItemsMaxNumber}
            listGroupItemStyle={listGroupItemStyle}
          />
        </div>
      </Modal.Body>
      <Modal.Footer style={{justifyContent: "space-between"}}>
        <Button variant="outline-primary" onClick={onClosePopup} style={{padding: "6px 12px"}}>
          {closePopupBtnDescription}
        </Button>
        <Button onClick={() => onItemsSelected(selectedItems)} style={{padding: "6px 12px"}}>
          {confirmBtnDescription}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
