import React, {useEffect} from "react";
import {LevelInputFormPropWithId} from "./DomainAnalysisPage";
import {Level2InputData} from "../../api/DomainAnalysis";
import {withMultiFetchSWR} from "../../api/helpersSWR";
import {useGetCampaign} from "../../api/campaigns";
import {Form as FormikForm, Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import {MultipleCheckField} from "../../commons/forms/MultipleCheckField";
import {InputField} from "../../commons/forms/InputField";

const Schema = Yup.object({
  delivered_kpi_threshold: Yup.number().typeError("Inserisci l'obiettivo")
    .min(0, "Inserisci l'obiettivo").required("Inserisci l'obiettivo").nullable(),
  delivered_kpi_threshold_custom: Yup.number().when('delivered_kpi_threshold', {
    is: null,
    then: (schema) => schema
      .typeError("Inserisci valore di soglia personalizzato")
      .min(0, "Inserisci valore di soglia personalizzato")
      .required("Inserisci valore di soglia personalizzato"),
    otherwise: schema => schema.nullable()
  })
});

type Props = LevelInputFormPropWithId<Level2InputData>;

const thresholdValues = [1, 50, 100, 500, 1000, 5000];

export const Level2Form = withMultiFetchSWR(
  (props: Props, urlParams: { analysisId: string }) => [
    {
      useFetchFunc: () => {
        return useGetCampaign(props.submittedInputs["1"].campaigns[0]);
      }
    }
  ], ({props, urlParams, endpointsResponse}) => {

    const {initialValues, onSubmit, formikRef, useValues, submittedInputs, analysisId} = props;
    const {data: [campaign]} = endpointsResponse;

    return (
      <Formik<Level2InputData>
        initialValues={getFormattedInitialValues(initialValues, analysisId)}
        validationSchema={Schema}
        innerRef={formikRef}
        onSubmit={(values: Level2InputData & { delivered_kpi_threshold_custom: number | null }) => {
          const valuesToSubmit = {
            delivered_kpi_threshold: values.delivered_kpi_threshold_custom !== null ?
              values.delivered_kpi_threshold_custom : values.delivered_kpi_threshold,
            level_1_id: values.level_1_id
          };
          return onSubmit(valuesToSubmit);
        }}
      >
        {({values, errors}) => (
          <FormikForm style={{display: "flex", flexDirection: "column", gap: "20px"}}>
            <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
              <label
                className="mb-0">{`Seleziona valore soglia di ${campaign.data.campaign.delivered_kpi.name}:`}</label>
              <MultipleCheckField
                containerStyle={{marginLeft: "15px"}}
                name={"delivered_kpi_threshold"}
                items={[
                  ...thresholdValues.map(value => ({
                    label: <div>{value}</div>,
                    id: value
                  })),
                  {
                    label: <div>Inserimento manuale</div>,
                    id: null
                  }
                ]}
                isMultiSelect={false}
                numOfColumns={1}
              />
            </div>
            {values.delivered_kpi_threshold === null &&
              <InputField
                name="delivered_kpi_threshold_custom" type="number" label="Soglia personalizzata" min={0} step={0.01}
                placeholder="Inserisci soglia" containerStyle={{width: "50%"}}
              />
            }
            <FormikHelpers/>
          </FormikForm>
        )}
      </Formik>
    );
  }
);

function FormikHelpers(props) {

  const {values, setFieldValue, setFieldTouched} = useFormikContext();
  const formValues = values as Level2InputData & { delivered_kpi_threshold_custom: number | null };

  useEffect(
    () => {
      if (formValues.delivered_kpi_threshold !== null)
        setFieldValue("delivered_kpi_threshold_custom", null);
      setFieldTouched("delivered_kpi_threshold_custom", false);
    },
    [formValues.delivered_kpi_threshold]
  );

  return <></>;
}

function getFormattedInitialValues(initialValues: Level2InputData, id: string) {
  if (initialValues === null || initialValues === undefined)
    return {
      delivered_kpi_threshold: null,
      delivered_kpi_threshold_custom: null,
      level_1_id: id
    };
  else
    return {
      level_1_id: id,
      delivered_kpi_threshold_custom: thresholdValues.includes(initialValues.delivered_kpi_threshold) ?
        null : initialValues.delivered_kpi_threshold,
      delivered_kpi_threshold: thresholdValues.includes(initialValues.delivered_kpi_threshold) ?
        initialValues.delivered_kpi_threshold : null
    };
}
