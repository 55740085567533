import React from "react";
import {Button, Form, Row} from "@themesberg/react-bootstrap";
import * as Yup from "yup";
import {InputField} from "../../commons/forms/InputField";
import {Formik} from "formik";
import styles from "./Step1.module.scss";
import {InputSelectField} from "../../commons/forms/InputSelectField";
import {FormFieldsArray} from "../../commons/forms/FormFieldsArray";
import {InputDateRangeField} from "../../commons/forms/InputDateRangeField";
import {SmallButton} from "../../components/utils/buttons/SmallButton";
import {CampaignStep1Data, CampaignStep3Data} from "./CampaignConfigurationPage";
import moment from "moment";
import {KPI} from "../../api/kpis";

const Schema = Yup.object({
  name: Yup.string().required(),
  advertiser: Yup.string().required(),
  budget: Yup.number().required().moreThan(0, "Il budget deve essere positivo"),
  price_per_impression: Yup.number().required().moreThan(0, "Il prezzo deve essere positivo"),
  delivered_kpi_id: Yup.number().required().positive("Seleziona il KPI"),
  dates: Yup.array(
    Yup.object().shape({
      starts_at: Yup.object().required(),
      ends_at: Yup.object().required()
    })
  ).min(1)
});

interface Props {
  initialValues: CampaignStep1Data,
  next: (values: CampaignStep1Data) => void,
  showCampaignListPage: () => void,
  kpis: KPI[]
}

export default (props: Props) => {
  const {initialValues, next, showCampaignListPage, kpis} = props;

  if (!kpis) {
    return null;
  }

  return (
    <Row className="mt-3">
      <Formik
        initialValues={{
          ...initialValues,
          dates: parseInitialDates(initialValues.dates)
        }}
        validationSchema={Schema}
        onSubmit={values => {
          const valuesToSubmit = {
            ...values,
            dates: values.dates.map(date => ({
              starts_at: date.starts_at.format('YYYY-MM-DD'),
              ends_at: date.ends_at.format('YYYY-MM-DD')
            }))
          }
          next(valuesToSubmit as CampaignStep1Data);
        }}
      >
        {({values, submitForm, errors}) => (
          <Form className={styles.gridContainer}>
            <InputField className={styles.name} name="name" label="Nome campagna"
                        placeholder="Inserisci il nome della campagna" type="text"/>
            <InputField name="advertiser" label="Advertiser" placeholder="Inserisci l'advertiser" type="text"/>
            <InputSelectField
              name="delivered_kpi_id" label="KPI di erogazione"
              optionValueType="number"
              children={
                <>
                  <option key='blankChoice' hidden value={-1}>
                    Seleziona il KPI
                  </option>
                  {
                    kpis
                        .filter(kpi => kpi.formula === null)
                        .map((kpi) => (
                      <option key={kpi.id} value={kpi.id}>{kpi.name}</option>
                    ))
                  }
                </>
              }
            />
            <InputField name="budget" label="Budget" placeholder="Inserisci il budget" type="number"/>
            <InputField name="price_per_impression" label="Prezzo per mille erogazioni"
                        placeholder="Inserisci il prezzo per mille erogazioni" type="number"
                        style={{marginBottom: "20px"}}
            />
            <FormFieldsArray
              subtitle={
                <>
                  <div><strong>Periodi di attività</strong></div>
                  <div>Inserisci uno o più range di date.</div>
                </>
              }
              getDefaultItem={() => ({starts_at: undefined, ends_at: undefined})}
              addNewItemBtnLabel="Aggiungi intervallo"
              addNewItemBtnComponent={SmallButton}
              name={"dates"}
              getTitleForItem={({index}) => <div style={{fontSize: "15px"}}>Intervallo #{index + 1}</div>}
              renderItem={({buildFieldName, removeItem, item, index}) => (
                <div style={{display: "flex", flexDirection: "column", gap: "10px", padding: "10px"}}>
                  <InputDateRangeField
                    startDateName={buildFieldName("starts_at")}
                    endDateName={buildFieldName("ends_at")}
                    isOutsideRange={() => false}
                    isDayBlocked={day => {
                      const otherIntervals = values.dates.filter((_, i) => i !== index);
                      return otherIntervals.some(interval => {
                        const start = interval.starts_at || new Date();
                        const end = interval.ends_at || new Date();
                        return start <= day && day <= end;
                      });
                    }}
                  />
                  <SmallButton
                    variant="danger" onClick={removeItem} disabled={values.dates.length === 1}
                    style={{alignSelf: "flex-start"}}
                  >
                    Elimina intervallo
                  </SmallButton>
                </div>
              )}
            />
            <div className={styles.buttonsSection}>
              <Button type="button" variant="primary" onClick={showCampaignListPage}>
                Indietro
              </Button>
              <Button className={styles.button} type="button" variant="primary" onClick={submitForm}>
                Continua
              </Button>
            </div>

          </Form>
        )}
      </Formik>
    </Row>
  );
}

function parseInitialDates(initialDates: CampaignStep1Data["dates"]) {
  if(initialDates.length > 0) {
    return initialDates.map(date => ({
      starts_at: moment(date.starts_at),
      ends_at: moment(date.ends_at)
    }));
  } else {
    return [{
      starts_at: undefined,
      ends_at: undefined
    }];
  }
}