import {useField} from "formik";
import * as React from "react";
import {useState} from "react";
import {SingleDatePicker} from "react-dates";
import "react-dates/initialize";
import "./InputDateField.scss";

interface Props {
  dateName: string;
  isDayBlocked: (day) => boolean;
  datePlaceholderText?: string;
  displayFormat?: string;
  isOutsideRange?: () => boolean;
  disabled?: boolean;
}

export const InputDateField = (props: Props) => {
  const {
    dateName,
    datePlaceholderText,
    isDayBlocked,
    displayFormat,
    isOutsideRange,
    disabled = false
} = props;

  const [field, meta, helpers] = useField(dateName);

  const [focusedInput, setFocusedInput] = useState(false);

  return (
    <div className={`${meta.touched && meta.error ? "error" : ""} inputDateRangeFieldContainer`}>
      <SingleDatePicker
        date={field.value}
        onDateChange={date => helpers.setValue(date)}
        focused={focusedInput}
        onFocusChange={({focused}) => {
          setFocusedInput(focused);
          helpers.setTouched(true);
        }}
        id={field.name}
        displayFormat={displayFormat}
        small={true}
        placeholder={datePlaceholderText}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        disabled={disabled}
      />
      {(meta.touched && meta.error) &&
        <div className="errorMsg">{meta.error}</div>
      }
    </div>
  );
};

InputDateField.defaultProps = {
  datePlaceholderText: "Data",
  displayFormat: "DD/MM/YYYY"
}